<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
      <v-card class="screen-only" :elevation="0" width="793" >
        <v-card-actions  >
          <v-checkbox
            v-if="Sizepage!='a4' "
            v-model="checkbox_tax"
            label= "ใบกำกับภาษีอย่างย่อ"
            @change="checktax_inv()"
            hide-details
            />
              <template v-slot:label> <span>{{use_language.abbreviated_tax_invoice}}</span> </template>
            <v-checkbox
              v-if="Sizepage!='a4'"
              class="pl-8"
              v-model="checkbox_vat"
              label= "แสดง vat"
              hide-details
            />
               <template v-slot:label> <span>{{use_language.show_vat}}</span> </template>
            <v-checkbox
              v-if="Sizepage!='a4'"
              class="pl-8"
              v-model="checkbox_detaivat"
              label= "แสดงรายละเอียดภาษี"
              hide-details
            />
              <template v-slot:label> <span>{{use_language.show_tax_details}}</span> </template>

          <v-spacer></v-spacer>
          <v-btn depressed :disabled="!authorize" @click="printpdfinvoice()" outlined :loading="loading">
            <v-icon left>mdi-printer</v-icon> {{use_language.print}}
          </v-btn>
          <v-btn depressed :disabled="!authorize" color="info"  dark @click="saveinvoice()" v-if="btn_save == null" :loading="loading">
            <v-icon left>mdi-content-save</v-icon> {{use_language.document_storage}}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="Sizepage=='a4'" class="screen-only" :elevation="0" width="793">
        <v-card-text>
          <v-row>
            <v-col cols="6" md="3" class="pa-0">
              <v-checkbox
                v-model="checkbox_shoptel"
                label= "เบอร์โทรร้านค้า"
                hide-details
              />
              <template v-slot:label> <span>{{use_language.shop_phone_number}}</span> </template>
            </v-col>
            <v-col cols="6" md="3" class="pa-0">
              <v-checkbox
                v-model="checkbox_shipingtel"
                label= "เบอร์โทรลูกค้า"
                hide-details
              />
              <template v-slot:label> <span>{{use_language.shop_phone_number}}</span> </template>
            </v-col>
            
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="Sizepage!='a4'" class="screen-only" :elevation="0" width="793">
        <v-card-text>
          <v-row>
            <v-col cols="6" md="6" class="pa-0">
              <v-checkbox
                v-model="checkbox_createdate_order"
                label= "กำหนดวันที่ให้เป็นวันที่สร้างออเดอร์"
                hide-details
              />
            </v-col>
            <v-col cols="6" md="6" class="pa-0">
              <v-checkbox
                v-model="checkbox_Payee_AuthorizedSignatory"
                label= "ผู้รับเงิน/ผู้รับมอบอำนาจ"
                hide-details
              />
              <template v-slot:label> <span>{{use_language.Payee_AuthorizedSignatory}}</span> </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="pa-0">
              <v-checkbox
                v-model="checkbox_shoptel"
                label= "เบอร์โทรร้านค้า"
                hide-details
              />
                 <template v-slot:label> <span>{{use_language.shop_phone_number}}</span> </template>
            </v-col>
            <v-col cols="3" class="pa-0">
              <v-checkbox
                v-model="checkbox_shipingtel"
                label= "เบอร์โทรลูกค้า"
                hide-details
              />
                 <template v-slot:label> <span>{{use_language.shop_phone_number}}</span> </template>
            </v-col>
            <v-col cols="3" class="pa-0">
              <v-checkbox
                v-model="checkbox_shopaddress"
                label= "ที่อยู่ร้านค้า"
                hide-details
              />
                <template v-slot:label> <span>{{use_language.store_address}}</span> </template>
            </v-col>
            <v-col cols="3" class="pa-0">
              <v-checkbox
                v-model="checkbox_ppu"
                label= "แสดงราคาต่อชิ้น"
                hide-details
              />
                 <template v-slot:label> <span>{{use_language.show_price_per_piece}}</span> </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pa-0">
              <v-checkbox
                v-model="checkbox_headerdetail"
                label= "หัวตารางรายละเอียดสินค้า"
                hide-details
              />
                 <template v-slot:label> <span>{{use_language.product_details_table}}</span> </template>
            </v-col>
            <v-col cols="6" class="pa-0">
              <v-checkbox
                v-model="checkbox_remark"
                label= "หมายเหตุ"
                hide-details
              />
                 <template v-slot:label> <span>{{use_language.note}}</span> </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div v-for="(p,index) in Data" :key="index">
        <div v-for="(item,index) in p.order" :key="index">
          <v-card v-if="Sizepage=='a4'" :elevation="0" width="793"  style="break-after:page" >

              <v-card-text>
                <!-- ส่วนหัว -->
                <v-row justify="center" >
                  <v-col cols="7" align="left" class="py-0 pl-0">
                      <v-row >
                        <v-col cols="2" class="px-0 pl-1 py-0 mt-1" v-if="item.shopAddress.shopLogo != null">
                          <p>
                            <img class="size-pic" :src="item.shopAddress.shopLogo == null ? '' : item.shopAddress.shopLogo" >
                          </p>
                        </v-col>
                        <v-col cols="10" class="px-0 pl-1 py-0">
                          <p  class="mb-0 font-weight-bold">{{item.shopAddress.name}}</p>
                          <div v-if="item.shopAddress.address != null">
                            <p v-if="item.shopAddress.province == 'กรุงเทพมหานคร'" class="mb-0">
                              {{  item.shopAddress.address + ' แขวง' + item.shopAddress.tumbon + ' เขต' + item.shopAddress.amphoe + ' จังหวัด' + item.shopAddress.province + ' ' + item.shopAddress.postcode}}
                            </p>
                            <p v-else class="mb-0">
                              {{  item.shopAddress.address + ' ตำบล' + item.shopAddress.tumbon + ' อำเภอ' + item.shopAddress.amphoe + ' จังหวัด' + item.shopAddress.province + ' ' + item.shopAddress.postcode}}
                            </p>
                          </div>
                          <p class="mb-0" v-if="checkbox_shoptel && item.shopAddress.phone != null">
                            {{use_language.tel}} {{item.shopAddress.phone}}
                          </p>
                          <p class="mb-0" v-if="item.shopAddress.idcardNumber != null ">
                            {{use_language.tax_invoice_number}} {{item.shopAddress.idcardNumber}}
                          </p>
                        </v-col>
                      </v-row>
                  </v-col>
                  <v-col cols="5" align="right" class="py-0">
                      <p class="headline mb-1">
                        <span>{{p.titleprint_main.text}}</span>
                        <span v-if="p.titleprint_secondary != null &&  p.titleprint_secondary.text!= null">
                          / {{p.titleprint_secondary.text}}
                        </span>
                      </p>
                  </v-col>
                </v-row>
                
              <v-row >
                    <v-col cols="7"  align="left" class="py-1 pl-0">
                        <!-- ข้อมูลลูกค้า -->
                        <v-row justify="center">
                            <v-col cols="3" class="font-weight-bold py-1 pl-0"> {{use_language.customer_name}} : </v-col>
                            <v-col cols="9" class="py-1" v-if="item.customerAddress !=null"> {{item.customerAddress.name}} </v-col>
                            <v-col cols="9" class="py-1" v-else>  </v-col>
                        </v-row>
                        <v-row justify="center" v-if="checkbox_shipingtel == true">
                            <v-col cols="3" class="font-weight-bold py-1 pl-0"> {{use_language.tel}} : </v-col>
                            <v-col cols="9" class="py-1" v-if="item.customerAddress !=null"> {{item.customerAddress.phone}} </v-col>
                            <v-col cols="9" class="py-1" v-else>  </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="3" class="font-weight-bold py-1 pl-0">{{use_language.address}} : </v-col>
                            <v-col cols="9" class="py-1" v-if="item.customerAddress != null">
                              <div >
                                <p v-if="item.customerAddress.province == 'กรุงเทพมหานคร'" class="mb-0">
                                  {{  item.customerAddress.address + ' แขวง' + item.customerAddress.tumbon + ' เขต' + item.customerAddress.amphoe + ' จังหวัด' + item.customerAddress.province + ' ' + item.customerAddress.postcode}}
                                </p>
                                <p v-else class="mb-0"> 
                                  {{ item.customerAddress.address + ' ตำบล' + item.customerAddress.tumbon + ' อำเภอ' + item.customerAddress.amphoe + ' จังหวัด' + item.customerAddress.province + ' ' + item.customerAddress.postcode}}
                                </p>
                              </div>
                            </v-col>
                            <v-col cols="9" class="py-1" v-else>  </v-col>
                        </v-row>
                        <v-row justify="center" v-if="item.customerAddress !=null && item.customerAddress.idcardNumber != null">
                            <v-col cols="3" class="font-weight-bold py-1 pl-0">{{use_language.tax_invoice_number}} :</v-col>
                            <v-col cols="9" class="py-1" v-if="item.customerAddress !=null"> {{item.customerAddress.idcardNumber}} </v-col>
                            <v-col cols="9" class="py-1" v-else>  </v-col>
                        </v-row >
                    </v-col>
                    <v-col cols="5" class="py-1">
                        <v-row>
                            <v-col cols="4"  md="5" lg="5" align="right" class="font-weight-bold py-1">{{use_language.no_2}} : </v-col>
                            <v-col cols="8" md="7" lg="7" align="right" class="py-1" v-if="item.invoiceNo != null">{{item.invoiceNo}}</v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4"  md="5" lg="5" align="right" class="font-weight-bold py-1">{{use_language.date}} : </v-col>
                            <v-col cols="8" md="7" lg="7" align="right" class="py-1" v-if="item.dateString != null">{{item.dateString}}</v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <div class="py-3">
                  <table width="100%" border="0" style="border-collapse: collapse;">
                    <tr class="border-a">
                      <th width="2%" align="center" class="py-2 border-right">
                        {{use_language.number}}<br>
                        No
                      </th>
                      <th width="50%" align="center" class="py-2 border-right">
                        {{use_language.list}}<br>
                        Order
                      </th>
                      <th width="12%" align="center" class="py-2 border-right">
                        {{use_language.quantity}}<br>
                        Quantity
                      </th>
                      <th width="12%" align="center" class="py-2 border-right">
                        {{use_language.unit_price}} <br>
                        Unit Price
                      </th>
                      <th width="12%" align="center" class="py-2 border-right" >
                        {{use_language.discount}} <br>
                        Discount
                      </th>
                      <th width="12%" align="center" class="py-2">
                        {{use_language.amount}} <br>
                        Amount
                      </th>
                    </tr>
                    <tr  Valign="top" v-for="(i,index) in item.orderItemInvs"  v-bind:key="index">
                      <td align="center" class="px-2 border-x">{{index+1}}</td>
                      <td align="left" class="px-2 border-right">{{i.name}}
                        <br v-if="i.prop1Description != null">
                        <span v-if="i.prop1Description != null" class="pl-4" style="font-size: 11px;"> ลักษณะ : {{ i.prop1Description + (i.prop2Description==null? "":", "+i.prop2Description)}}</span>

                      </td>
                      <td align="right" class="px-2 border-right">{{i.quantity}}</td>
                      <td align="right" class="px-2 border-right">{{i.unitPrice}}</td>
                      <td align="right" class="px-2 border-right">{{format_price(i.discount)}}</td>
                      <td align="right" class="px-2 border-right">{{format_price((i.quantity*i.unitPrice)-i.discount)}}</td>
                    </tr>
                    <tr :height="calendar_last_row_height(item.orderItemInvs.length)" Valign="top" class="border-bottom">
                      <td align="left" class="px-2 border-x"></td>
                      <td align="left" class="px-2 border-right"></td>
                      <td align="right" class="px-2 border-right"></td>
                      <td align="right" class="px-2 border-right"></td>
                      <td align="right" class="px-2 border-right"></td>
                      <td align="right" class="px-2 border-right"></td>
                    </tr>
                    <tr Valign="top" class="border-bottom" height="120">
                      <td align="left" colspan="2" class="px-2 py-1 border-a">
                        <span class="font-weight-bold">{{use_language.note}} / remark</span><br>
                        <span>{{ item.orderMaster.remark }}</span>
                      </td>
                      <td align="left" colspan="3" class="px-0 py-2 border-a">
                        <v-row >
                        <v-col cols="12" align="left" class="font-weight-bold py-0 pt-1 ">{{use_language.product_price}}</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="left" class="font-weight-bold py-0">{{use_language.freight}}</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="left" class="font-weight-bold py-0">{{use_language.discount}}</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="left" class="font-weight-bold py-0">{{use_language.value_calculated_tax}}</v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="left" class="font-weight-bold py-0">
                            <span v-if="item.orderMaster.vatType== 1">{{use_language.vat}}</span>
                            <span v-else-if="item.orderMaster.vatType== '' || item.orderMaster.vatType== null ">{{use_language.vat}}</span>
                            <span v-else-if="item.orderMaster.vatType== 2">{{use_language.vat_included_}}</span>
                            <span v-else-if="item.orderMaster.vatType== 3">{{use_language.vat_excluded_}}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="left" class="font-weight-bold py-0 pb-1">{{use_language.total_amount}}</v-col>
                        </v-row>
                      </td>
                      <td align="left" class="px-0 py-2 border-a">
                        <v-row >
                          <v-col cols="12" align="right" class="py-0 pt-1">
                            {{format_price((item.orderMaster.totalPrice == 0 || item.orderMaster.totalPrice == '') ? 0.00 : item.orderMaster.totalPrice)}}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="right" class="py-0 ">
                            {{format_price((item.orderMaster.deliveryCost == 0 || item.orderMaster.deliveryCost == '') ? 0.00 : item.orderMaster.deliveryCost)}}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="right" class="py-0 ">
                            {{format_price((item.orderMaster.orderDiscount == 0 || item.orderMaster.orderDiscount == '') ? 0.00 : -  item.orderMaster.orderDiscount)}}
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="right" class="py-0 ">
                            {{format_price((item.orderMaster.sumTotalPrice == 0 || item.orderMaster.sumTotalPrice  == '') ? 0.00 : (item.orderMaster.sumTotalPrice ))}}
                            </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="right" class="py-0 ">
                            {{format_price((item.orderMaster.vatAmount == 0 || item.orderMaster.vatAmount == '') ? 0.00 : item.orderMaster.vatAmount)}}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" align="right" class="py-0 pb-1">
                            {{format_price(item.orderMaster.sumTotalPriceVat)}}
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </table>
                  <table width="100%" class="my-2" border="0" style="border-collapse: collapse;">
                    <tr class="border-a" height="120px">
                      <td width="5%" align="center" class="py-2 border-right">
                        <p class="mb-2"></p>
                        <p class="mb-2 mt-4"> {{use_language.consignee}} </p>
                        <p class="mb-2">{{use_language.date}}........................</p>
                      </td>
                      <td width="5%" align="center" class="py-2 border-right">
                        <p class="mb-2"></p>
                        <p class="mb-2 mt-4"> {{use_language.shipper}} </p>
                        <p class="mb-2">{{use_language.date}}........................</p>
                      </td>
                      <td width="5%" align="center" class="py-2 border-right">
                        <p class="mb-2"></p>
                        <p class="mb-2 mt-4" v-if="p.titleprint_main.text=='ใบส่งของ'|| p.titleprint_secondary.text=='ใบส่งของ'"> {{use_language.forwarder}} </p>
                        <p class="mb-2 mt-4" v-else> {{use_language.on_behalf}} </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-card-text>
          </v-card>

          <v-card v-else class="pages font-size-print pb-2" :elevation="0" style="break-after:page">
            <!-- <v-row justify="center" class="pt-2">
              <v-col cols="" md="12" lg="12"  align="center" class="py-0 ">

              </v-col>
            </v-row> -->
            <v-row justify="center" >
              <v-col cols="8" md="12" lg="12"  align="center" class="py-0 ">
                {{ item.shopAddress.province }}

                <div v-if="item.shopAddress.province == 'กรุงเทพมหานคร'">
                  <p  class="mb-0 mt-3 font-weight-bold">{{item.shopAddress.name}}</p>
                  <!-- <p class="mb-0" v-if="checkbox_shopaddress">{{item.shopAddress['TextAddress']}}</p> -->
                  <p class="mb-0" v-if="checkbox_shopaddress">
                    {{item.shopAddress.address + ' แขวง' + item.shopAddress.tumbon + ' เขต' + item.shopAddress.amphoe + ' จังหวัด' + item.shopAddress.province + ' ' + item.shopAddress.postcode}}
                  </p>
                </div>
                <div v-else>
                  <p  class="mb-0 mt-3 font-weight-bold">{{item.shopAddress.name}}</p>
                  <!-- <p class="mb-0" v-if="checkbox_shopaddress">{{item.shopAddress['TextAddress']}}</p> -->
                  <p class="mb-0" v-if="checkbox_shopaddress">
                    {{item.shopAddress.address + ' ตำบล' + item.shopAddress.tumbon + ' อำเภอ' + item.shopAddress.amphoe + ' จังหวัด' + item.shopAddress.province + ' ' + item.shopAddress.postcode}}
                  </p>
                </div>

                <p class="mb-0" v-if="checkbox_shoptel && item.shopAddress.phone != null ">
                  {{use_language.tel}} {{item.shopAddress.phone}}
                </p>
                <p class="mb-0" v-if="item.shopAddress.idcardNumber != null ">
                  {{use_language.tax_invoice_number}} {{item.shopAddress.idcardNumber}}
                </p>
                <p class="mb-0" v-if="checkbox_vat">
                  {{item.orderMaster.vatType== 2 ?"(VAT Included)" : "(VAT Excluded)"}}
                </p>
                <p class="mb-2 fonttitle-print">
                  <span>{{p.titleprint_main.text}}</span>
                  <span v-if="p.titleprint_secondary.length != 0 && p.titleprint_secondary.text != null && p.titleprint_secondary.text != '' ">
                    / {{p.titleprint_secondary.text}}
                  </span>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" md="3" lg="3" align="left" class="font-weight-bold py-1">{{use_language.no_2}} : </v-col>
              <v-col cols="9" md="9" lg="9" align="left" class="py-1 px-0" v-if="item.invoiceNo != null">{{item.invoiceNo}}</v-col>
            </v-row>
            <v-row class="pb-1">
              <v-col cols="3" md="3" lg="3" align="left" class="font-weight-bold py-1">{{use_language.date}} : </v-col>
              <v-col cols="9" md="4" lg="4" align="left" class="py-1 px-0" v-if="p.date != null">{{ formatDate(showDatetime_invoice) }}</v-col>
            </v-row>
            <!-- <v-divider class="my-1" light ></v-divider> -->
                  <!-- ข้อมูลลูกค้า -->
            <div class="border-top my-1 mx-0" v-if="item.customerAddress!=null">
              <v-row class="pt-2">
                <v-col cols="3" align="left" class="font-weight-bold py-0 mb-0 "> {{use_language.customer}} : </v-col>
                <v-col cols="9" md="8" lg="8" align="left" class="py-0 mb-1 px-0">
                  {{item.customerAddress.name}} <br>
                </v-col>
              </v-row>
              <v-row class="pt-2" v-if="checkbox_shipingtel">
                <v-col cols="3" align="left" class="font-weight-bold py-0 mb-0 "> {{use_language.tel}} : </v-col>
                <v-col cols="9" md="8" lg="8" align="left" class="py-0 mb-1 px-0">
                  {{item.customerAddress.phone}} <br>
                </v-col>
              </v-row>
              <v-row class="pt-2">
                <v-col cols="3" align="left" class="font-weight-bold py-0 mb-0 ">
                  <!-- {{use_language.address}} :  -->
                </v-col>
                <v-col cols="9" md="8" lg="8" align="left" class="py-0 mb-1 px-0">
                  <p v-if="item.customerAddress.province == 'กรุงเทพมหานคร'" class="mb-0">
                    {{  item.customerAddress.address + ' แขวง' + item.customerAddress.tumbon + ' เขต' + item.customerAddress.amphoe + ' จังหวัด' + item.customerAddress.province + ' ' + item.customerAddress.postcode}}
                  </p>
                  <p v-else class="mb-0">
                    {{  item.customerAddress.address + ' ตำบล' + item.customerAddress.tumbon + ' อำเภอ' + item.customerAddress.amphoe + ' จังหวัด' + item.customerAddress.province + ' ' + item.customerAddress.postcode}}
                  </p>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="8" md="8" lg="8" align="left" class="py-1 mb-0">
                  {{item.customerAddress.name}} <br>
                  {{item.customerAddress.address}}
                </v-col>
              </v-row> -->
              <v-row align="left" v-if="item.customerAddress.idcardNumber != null">
                  <v-col cols="3" class="font-weight-bold py-0 mb-0">{{use_language.tax_invoice_number}} :</v-col>
                  <v-col cols="9" class="py-1 px-0"> {{item.customerAddress.idcardNumber}} </v-col>
              </v-row >
            </div>
            <!-- <v-divider class="my-1" light ></v-divider> -->
            <div class="py-1 border-top">
              <table width="100%" border="0" style="border-collapse: collapse;">
                <tr Valign="top" v-if="checkbox_headerdetail">
                  <th width="1%" align="left" class="pl-1 pr-0">
                    {{use_language.number}}
                  </th>
                  <th width="49%" align="center" class="pl-1 pr-2">
                    {{use_language.list}}
                  </th>
                  <th width="20%" align="right" class="px-2t">
                    {{use_language.quantity}}
                  </th>
                  <th width="30%" align="right" class="px-2">
                    {{use_language.amount}}
                  </th>
                </tr>
                <tr  Valign="top" v-for="(i,index) in item.orderItemInvs"  v-bind:key="index">
                  <td align="left" class="pl-1 pr-0" width="1%">{{index+1 + ". "}} </td>
                  <td align="left" class="pl-1 pr-2" width="49%">{{i.name}}</td>
                  <td align="right" class="px-2" width="20%">
                    <span v-if="checkbox_headerdetail == true && checkbox_ppu == false">{{i.quantity }}</span>
                    <span v-else-if="checkbox_headerdetail == true && checkbox_ppu == true">{{i.quantity }}</span>
                    <span v-else-if="checkbox_headerdetail == false && checkbox_ppu == false">{{i.quantity +"x"}}</span>
                    <span v-else-if="checkbox_headerdetail == false && checkbox_ppu == true">{{i.quantity +"@"+i.unitPrice}}</span>
                  </td>
                  <td align="right" class="px-2" width="30%">{{format_price(i.quantity*i.unitPrice)}}</td>
                </tr>
                <tr Valign="top" class="border-top" v-if="checkbox_remark && item.orderMaster.remark != null">
                  <td colspan="4" align="left">
                    <v-row >
                      <v-col cols="12" class="pl-0">
                       <span>{{use_language.note}} :  {{ item.orderMaster.remark }}</span>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr Valign="top" class="border-top">
                  <td align="right" colspan="3" class="px-2 py-2">
                    <v-row >
                      <v-col cols="12" class="font-weight-bold py-0 pt-1 ">{{use_language.product_price}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="font-weight-bold py-0">{{use_language.shipping_cost}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="font-weight-bold py-0">{{use_language.discount}}</v-col>
                    </v-row>
                    <v-row v-if="checkbox_detaivat">
                      <v-col cols="12" class="font-weight-bold py-0">{{use_language.value_calculated_tax}}</v-col>
                    </v-row>
                    <v-row v-if="checkbox_detaivat">
                      <v-col cols="12"  class="font-weight-bold py-0">
                        <span v-if="item.orderMaster.vatType== 1">{{use_language.no_tax}}</span>
                        <span v-else-if="item.orderMaster.vatType== '' || item.orderMaster.vatType== null ">{{use_language.vat}}</span>
                        <span v-else-if="item.orderMaster.vatType== 2 && checkbox_vat == false">{{use_language.vat_included_}}</span>
                        <span v-else-if="item.orderMaster.vatType== 3 && checkbox_vat == false">{{use_language.vat_excluded_}}</span>
                        <span v-else>{{use_language.vat}}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="font-weight-bold py-0 pb-1">{{use_language.total_amount}}</v-col>
                    </v-row>
                  </td>
                  <td align="right"  class="px-2 py-2">
                    <v-row >
                      <v-col cols="12" class="py-0 pt-1">
                        {{format_price((item.orderMaster.totalPrice == 0 || item.orderMaster.totalPrice == '') ? 0.00 : item.orderMaster.totalPrice)}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"  class="py-0 ">
                        {{format_price((item.orderMaster.deliveryCost == 0 || item.orderMaster.deliveryCost == '') ? 0.00 : item.orderMaster.deliveryCost)}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12"  class="py-0 ">
                        {{format_price((item.orderMaster.orderDiscount == 0 || item.orderMaster.orderDiscount == '') ? 0.00 : - item.orderMaster.orderDiscount)}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="py-0 " v-if="checkbox_detaivat">
                        {{format_price((item.orderMaster.sumTotalPrice  == 0 || item.orderMaster.sumTotalPrice  == '') ? 0.00 : (item.orderMaster.sumTotalPrice ))}}
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" align="right" class="py-0 " v-if="checkbox_detaivat">
                        {{format_price((item.orderMaster.vatAmount == 0 || item.orderMaster.vatAmount == '') ? 0.00 : item.orderMaster.vatAmount)}}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="py-0 pb-1">
                        {{format_price(item.orderMaster.sumTotalPriceVat)}}
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </table>
            </div>
            <div style="border-style: groove;" v-if="checkbox_Payee_AuthorizedSignatory">
              <v-row>
                <v-col style="min-height: 130px"></v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col align="center">
                  <b>ผู้รับเงิน/ผู้รับมอบอำนาจ</b>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </div>
    </div>
</template>
<script>
  import Loading from '@/website/components/Loading'
  import Swal from "sweetalert2"
  import axios from 'axios'
  import { reportService_dotnet,generalService_dotnet, orderService_dotnet} from '@/website/global'
  import { format_number, format_order_id, format_price, FormatInvoice,get_languages, set_format_date_time ,checkAuthorize } from '@/website/global_function'
  import { token_api, header_token } from '@/website/backend/token'
  import { order_language } from "@/website/language/order_language";
export default {
    components: {
      Loading,
    },
    data: () => ({
        page_loading: false,
        loading: false,
        use_language: null,
        set_language: null,
        authorize:false,

        headerDetailOrderProduct: [
            { align: 'center', text: 'No', value: 'No', sortable: false, width: '70px'  },
            { align: 'center', text: 'ชื่อสินค้า', value: 'NameProduct', sortable: false  },
            { align: 'center', text: 'จำนวน', value: 'Quantity', sortable: false, width: '130px' },
            { align: 'center', text: 'ราคาต่อหน่วย', value: 'UnitPrice', sortable: false, width: '130px' },
            { align: 'center', text: 'ส่วนลด', value: 'Discount', sortable: false, width: '130px' },
            { align: 'center', text: 'ราคารวม', value: 'Price', sortable: false, width: '100px' }
        ],
        DetailOrderProduct:[],
        Invoice_ID: null,
        INV_Orderid: null,
        INV_Date: null,
        INV_Print:[],
        Data:[],
        dialogsizepage: false,
        btn_save: null,
        Sizepage: null,
        checkbox_tax:false,
        checkbox_shopaddress:true,
        checkbox_shoptel:false,
        checkbox_shipingtel:false,
        checkbox_headerdetail:false,
        checkbox_remark:false,
        checkbox_vat:false,
        checkbox_ppu:false,
        checkbox_detaivat:true,

        checkbox_createdate_order: false,
        checkbox_Payee_AuthorizedSignatory : false,
        createdDatetime_order: null,
        nowDatetime_invoice : null,
        showDatetime_invoice : null,

    }),
   async  created (){
      this.getdata_order()

     //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));
         //console.log(  this.use_language);
        //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
        this.get_authorize_staffshop();
 


      this.page_loading = false

    },

    watch : {
    
    },
    methods: {
      get_languages,
      calendar_last_row_height (row_len) {
        if(row_len > 0){
          var max_height = 400
          var row_data = row_len
          var fix_height = 39
          var res =  (fix_height*row_data)
          return max_height - res
        } else {
          return 630
        }
      },
        formatDate (date) {
          if (date == null) return null
          if (date != null) {
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
          }
        },
        async get_orderMaster (order_id){
          try {
            let response = await axios.post(orderService_dotnet+'Order/get-order-master', {
              "orderID": order_id
            },
            { headers: header_token})
            this.createdDatetime_order = (response.data.createdDatetime).substring(0, 10)
          } catch (e) {
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              title: "เกิดข้อผิดพลาด !!",
              text: e.message,
            })
          }

        },

        async getdata_order(){

          try {
            this.loading = true
            this.INV_Print = JSON.parse(localStorage.getItem('INVPrint'))
            
            this.Sizepage = localStorage.getItem('Sizepage')
            axios.all([
                axios.post(reportService_dotnet+'ShopInvoiceOrder/get-invoice-order', {
                  // 'testOrderInvoiceRequest':this.INV_Print
                    'shopID': this.INV_Print.ShopID,
                    'invorder': this.INV_Print.invorder,
                    'titleprint_main': this.INV_Print.titleprint_main,
                    'titleprint_secondary': this.INV_Print.titleprint_secondary,
                },{ headers: header_token}),
                axios.post(generalService_dotnet+'General/get-postcodemapping', {},{ headers: header_token}),
            ])
            .then(axios.spread((Customer,postcodemapping) => {
                if (Customer.status == 200) {
                    this.Data = Customer.data
                }
            }));
            
            this.loading = false
          } catch (e) {
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              title: "เกิดข้อผิดพลาด !!",
              text: e.message,
            })
          }


        },
        printpdfinvoice(){
          print()
        },
        async get_invoiceid(){
          try {
            this.loading = true
            axios.all([
              axios.post(reportService_dotnet+'ShopInvoiceOrder/check-invoice-order', {
                  'ShopID': this.INV_Print.shopID,
                  'printorder': this.INV_Print.printorder,
                  'StaffID': localStorage.getItem("staff_id"),
              },{ headers: header_token}),
            ])
            .then(axios.spread((invoice) => {
              if (invoice.status == 200) {
                if (invoice.data == true) {
                  this.btn_save = 1
                }
              } else {
                  this.$swal({
                      type: 'warning',
                      title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
              }
            }));
            this.loading = false
          } catch (e) {
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              title: "เกิดข้อผิดพลาด !!",
              text: e.message,
            })
          }

        },
        async saveinvoice(){

          try {
            this.loading = true
            axios.all([
              axios.post(reportService_dotnet+'ShopInvoiceOrder/insert-invoice-order', {
                  'ShopID': this.INV_Print.ShopID,
                  'invorder': this.INV_Print.invorder,
                  'titleprint_main': this.INV_Print.titleprint_main,
                  'titleprint_secondary': this.INV_Print.titleprint_secondary,
                  'StaffID': localStorage.getItem("staff_id"),
              },{ headers: header_token}),
            ])
            .then(axios.spread((invoice) => {
              if (invoice.status == 200) {
                 
                this.$swal({
                    type: 'success',
                    title: invoice.data,
                })
              } else {
                  this.$swal({
                      type: 'warning',
                      title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                  })
              }
            }));
            this.loading = false
          } catch (e) {
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              title: "เกิดข้อผิดพลาด !!",
              text: e.message,
            })
          }

        },
        checktax_inv(){
          if(this.checkbox_tax == true){
            for(var t in this.Data) {
              if (this.Data[t].titleprint_main['value'] == 2 && this.Data[t].titleprint_secondary.length == 0) {
                this.Data[t].titleprint_main['text'] = "ใบกำกับภาษีอย่างย่อ"
              }
              if(this.Data[t].titleprint_secondary['value'] == 2){
                this.Data[t].titleprint_secondary['text'] = "ใบกำกับภาษีอย่างย่อ"
              }
            }
          }else{
            for(var t1 in this.Data) {
              if (this.Data[t1].titleprint_main['value'] == 2 && this.Data[t1].titleprint_secondary.length == 0) {
                this.Data[t1].titleprint_main['text'] = "ใบกำกับภาษี"
              }
              if(this.Data[t1].titleprint_secondary['value'] == 2){
                this.Data[t1].titleprint_secondary['text'] = "ใบกำกับภาษี"
              }
            }
          }
        },
        async get_authorize_staffshop () {
          if(this.checkAuthorize(24) == true || this.checkAuthorize(25) == true )
          {
           this.authorize = true
          }
        },
        format_number,
        format_order_id,
        format_price,
        FormatInvoice,
        set_format_date_time,
        checkAuthorize,
    }
}
</script>
<style scoped>

    >>>.set-font-size thead tr th{

      font-weight: bold !important;
      font-size: 11px !important;
    }

    /* border table */

    >>>.border-left{
      border-left: 1px solid black !important;
    }
    >>>.border-right{
      border-right: 1px solid black !important;
    }
    >>>.border-top{
      border-top: 1px solid black !important;
    }
    >>>.border-bottom{
      border-bottom: 1px solid black !important;
    }
    >>>.border-x{
      border-left: 1px solid black !important;
      border-right: 1px solid black !important;
    }
    >>>.border-y{
      border-top: 1px solid black !important;
      border-bottom: 1px solid black !important;
    }
    >>>.border-a{
      border: 1px solid black !important;
    }
    @media print {
      .print-only { }
      .screen-only {display: none;}
      .size-pic{
        margin-top: 4px;
        width: 100%
      }
      .font-size-inv{
        font-size: 0.6rem !important;
      }
      .font-size-print{
          font-size: 0.8rem !important;
          color-adjust: exact;
          color: #000000;
        }
      .fonttitle-print{
        font-size: 0.75rem !important;
        font-weight: 600 !important
      }
      .pages {
        size: 80mm 80mm;
        width: 80mm;
        height: 80mm;
        margin: 0;
        margin-left: 4px !important;
        margin-right: 4px !important;
        margin-top: 2px !important;
        margin-bottom: 0px !important;
      }
    }
    @media screen {
        .print-only {
          display: none;
        }
        .screen-only {}
        .size-pic{
          width: 100%
        }
        .fonttitle-print{
          font-size: 1rem !important;
          font-weight: 700 !important
        }
        .pages{
        width: 100mm;
        height: auto;
        margin: 0;
        margin-top: 2px !important;
        margin-left: 2px  !important;
        margin-right: 2px  !important;
        padding-left: 2px !important;
      }
    }
</style>
