<template>
    <div v-if="page_loading">
      <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
  
      <!-- นำทาง -->
      <v-card elevation="0" class="v-card-bottom-30">
        <v-card-title class="body-1">
          สต๊อก
          <v-icon>mdi-chevron-right</v-icon>
          <a @click="toBack('my-stock')"> สต๊อกสินค้า </a>
          <v-icon>mdi-chevron-right</v-icon>
          เพิ่มสินค้า Excel
        </v-card-title>
      </v-card>
  
      <!-- ส่วนอัพโหลด -->
      <v-card>
        <v-row>
          <v-col cols="12" md="12">
            <v-row align="center">
              <v-col cols="12" lg="6" class="py-1" align="start">
          <v-icon size="22" color="teal darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3">นำเข้าสินค้าจาก Excel</span>
              </v-col>
              <v-col cols="12" md="6" class="py-3" align="end">
                <v-btn :elevation="1" color="success" class="mr-3 mt-1" href='https://packhaistorage.s3.ap-southeast-1.amazonaws.com/shop/template_upload_stock_my-product.xlsx'
                  ><v-icon left>mdi-download</v-icon>
                    Download template
                </v-btn>
              </v-col>
            </v-row>
            <v-divider />
          </v-col>
        </v-row>
        <v-row class="pb-2">
          <v-col cols="12" md="6" align="start">
            <v-row  align="center">
              <v-col cols="12" md="4" class="py-0">
                <h3 class="fn-14">Step 1 : เลือกคลัง</h3>
              </v-col>
              <v-col cols="12" md="8" class="py-0">
                <v-autocomplete
                  ref="selcet_shop"
                  v-model="warehouseId"
                  :items="warehouseList"
                  item-text="name"
                  item-value="id"
                  persistent-hint
                  return-object
                  dense
                  hide-details
                  outlined
                  single-line
                  
                ></v-autocomplete>
              </v-col>
            </v-row>
  
            <v-row align="center">
              <v-col cols="12" md="4" class="py-0">
                <h3 class="fn-14">Step 2 : อัพโหลดไฟล์</h3>
              </v-col>
              <v-col cols="12" md="8">
                <v-file-input
                  v-model="file_excel"
                  ref="file_excel"
                  color="deep-purple accent-4"
                  placeholder="กรุณาเลือกไฟล์ excel เท่านั้น"
                  prepend-icon=""
                  outlined
                  hide-details
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>
                    <span
                      v-else-if="index === 2"
                      class="overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} file_excel(s)
                    </span>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
  
            <v-row  align="center">
              <v-col cols="12" md="4" class="py-0">
                <h3 class="fn-14">Step 3 : บันทึก</h3>
              </v-col>
              <v-col cols="12" lg="8" align="start">
                <v-btn
                  color="primary"
                  @click="upload_order_excel()"
                  v-if="!fetch_api_present"
                >
                  <v-icon left >mdi-content-save</v-icon><span>บันทึก</span>
                </v-btn>
                <v-btn color="danger" v-else>
                  <h3>กำลังดำเนินงาน {{ present_wait }} %</h3>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
  
      <!-- pop up แสดงผลหลักทำงานเสร็จ -->
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" md="10 " align-self="center"
                >ผลการนำเข้าสินค้า</v-col
              >
              <v-col cols="12" md="2">
                <v-btn icon @click="(dialog = false), updatePage(1)"
                  ><v-icon right>mdi-close</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
  
          <v-card-text>
            <v-row justify="center" class="py-5">
              <v-col cols="12" md="4"
                ><h3 style="color: black">จำนวนทั้งหมด :</h3></v-col
              >
              <v-col cols="12" md="2"
                ><h3 style="color: black">{{ this.total_excal }}</h3></v-col
              >
              <v-col cols="12" md="4"><h3 style="color: black">รายการ</h3></v-col>
            </v-row>
            <v-row justify="center" class="py-5">
              <v-col cols="12" md="4"
                ><h3 style="color: green">บันทึกสำเร็จ :</h3></v-col
              >
              <v-col cols="12" md="2"
                ><h3 style="color: green">{{ this.count_successes }}</h3></v-col
              >
              <v-col cols="12" md="4"><h3 style="color: green">รายการ</h3></v-col>
            </v-row>
            <v-row justify="center" class="py-5">
              <v-col cols="12" md="4"
                ><h3 style="color: red">บันทึกไม่สำเร็จ :</h3></v-col
              >
              <v-col cols="12" md="2"
                ><h3 style="color: red">{{ this.count_failed }}</h3></v-col
              >
              <v-col cols="12" md="4"><h3 style="color: red">รายการ</h3></v-col>
            </v-row>
            <v-row class="py-5">
              <v-col col="12" md="6"
                ><v-btn
                  block
                  elevation="2"
                  outlined
                  x-large
                  @click="(dialog = false), updatePage(1)"
                  ><label>เช็ครายการไม่สำเร็จ</label></v-btn
                ></v-col
              >
              <v-col col="12" md="6"
                ><v-btn
                  block
                  color="primary"
                  elevation="2"
                  tile
                  x-large
                  @click="toBack('my-stock')"
                  ><label>ไปหน้าสต๊อกสินค้า</label></v-btn 
                ></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
  
      <!-- ส่วน data-table -->
      <v-card class="py-3 ma-7 px-6 mx-0 v-card-margin-bottom-30" v-if="length_page > 0">
        <v-row>
          <v-col cols="4">
            <h3>ข้อมูลทั้งหมด {{ this.total_excal }} แถว</h3>
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="12" md="4" class="text-center" style="color: orange">
                <h3>
                  รอดำเนินงาน
                  {{
                    this.total_excal - this.count_successes - this.count_failed
                  }}
                </h3></v-col
              >
              <v-col cols="12" md="4" class="text-center" style="color: green">
                <h3>ดำเนินงานสำเร็จ {{ this.count_successes }}</h3></v-col
              >
              <v-col cols="12" md="4" class="text-center" style="color: red">
                <h3>ไม่สำเร็จ {{ this.count_failed }}</h3></v-col
              >
            </v-row>
          </v-col>
        </v-row>
  
        <v-simple-table class="packhai-border-table" width="100%">
          <thead>
            <tr>
              <th class="text-center" width="10%">สถานะ</th>
              <th class="text-center" width="50%">สินค้า</th>
              <th class="text-center" width="10%">sku</th>
              <th class="text-center" width="10%">ราคาขาย</th>
              <th class="text-center" width="10%">buffer</th>
              <th class="text-center" width="10%">สต๊อก</th>
            </tr>
          </thead>
          <tbody>
            <tr align="center" v-for="item in historyList" :key="item.index">
              <td v-if="item.status == 0">
                <span style="color: orange">รออัพโหลด</span>
              </td>
              <td v-if="item.status == 1">
                <span style="color: green"
                  ><v-icon left style="color: green"
                    >mdi-checkbox-marked-circle-outline</v-icon
                  >สำเร็จ</span
                >
              </td>
              <td v-if="item.status == 2 || item.status == 3">
                <span style="color: red"
                  ><v-icon left style="color: red"
                    >mdi-close-octagon-outline</v-icon
                  >ไม่สำเร็จ <small>{{ item.error }}</small></span
                >
              </td>
              <td>
                <table width="100%">
                  <tr>
                    <td
                      width="15%"
                      align="center"
                      class="tablenone"
                      v-if=" !item.url_img || ( item.url_img.includes('drive.google') ||
                        item.url_img.includes('scontent.fbkk')  )
                       
                      "
                    >
                      <div class="box_img" />
                    </td>
                    <td width="15%" align="center" class="tablenone" v-else>
                      <v-img
                        max-height="100"
                        max-width="100"
                        :src="item.url_img"
                      ></v-img>
                    </td>
  
                    <td width="85%" class="tablenone">
                      <v-row>
                        <v-col cols="12" md="12"
                          ><h4>{{ item.name }}</h4></v-col
                        >
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12"
                          ><span style="color: gray">
                            <template
                              v-if="item.barcode != null || item.barcode == ''"
                            >
                              Barcode : {{ item.barcode }}
                            </template>
                            <template v-if="item.code != null || item.code == ''"
                              >, รหัสสินค้า : {{ item.code }}
                            </template>
                            <template v-if="item.cf != null || item.cf == ''"
                              >, รหัสCF : {{ item.cf }}
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="12">
                          <span style="color: gray">
                            <template
                              v-if="
                                item.attribute1 != null || item.attribute1 == ''
                              "
                            >
                              {{ item.attribute1 }} :
                              {{ item.details_attribute1 }}
                            </template>
                            <template
                              v-if="
                                item.attribute2 != null || item.attribute2 == ''
                              "
                            >
                              ,{{ item.attribute2 }} :
                              {{ item.details_attribute2 }}
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <span> {{ item.sku }}</span>
              </td>
              <td>
                <span>{{ item.price }}</span>
              </td>
              <td>
                <span>{{ item.buffer }}</span>
              </td>
              <td>
                <span>{{ item.stock }}</span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
  
        <v-row> </v-row>
  
        <div class="text-center py-3"  v-if="length_page > 0">
          <v-pagination
            v-model="page"
            :length="length_page"
            :total-visible="7"
            @input="updatePage"
          ></v-pagination>
        </div>
      </v-card>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import Swal from "sweetalert2"; 
  import Loading from "@/website/components/Loading";
  import {
    stockService_dotnet,
    shopService_dotnet,
  } from "@/website/global";
  import readXlsxFile from "read-excel-file";
  
  import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
  
  export default {
    components: {
      Loading,
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      dialog: false,
      ShopID : parseInt(localStorage.getItem('shop_id')),
      // page
      page_loading: true,
      loading: false,
  
      page: 1,
      total_excal: 0,
      pageSize: 10,
      historyList: [],
      length_page: 0,
  
      //excel
      dataTable_excal: [],
      file_excel: [],
      count_excel: 0,
      count_successes: 0,
      count_failed: 0,
      fetch_api_present: 0,
      present_status: false,
  
      // download template
      dataTable: [],
  
      //seclectwarehouse
      warehouseList: [{ id: 0, name: "-- เลือกคลัง --"  , disabled: true}],
      warehouseId: { id: 0, name: "-- เลือกคลัง --" , disabled: true },
  
      productMaster: {},
  
      productMasterItem: [{}],
  
      
    }),
    methods: {
      async toBack(path) {
        this.$router.push("/" + path);
      },
      async upload_order_excel() {
        if (this.file_excel == null) {
          this.file_excel = [];
        }
        if (this.warehouseId.id == 0) {
          this.$refs.selcet_shop.focus();
          return;
        }
        if (this.file_excel.length == 0) {
          this.$refs.file_excel.focus();
          return;
        }
  
        var typeFile = false;
        var file_type = this.file_excel["type"];
        if (
          file_type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          typeFile = true;
        } else {
          this.AlertWarning("ประเภทไฟล์ไม่ถูกต้อง");
          return;
        }
        if (typeFile) {
          // อ่านค่าบนไฟล์ excel
  
          Swal.fire({
            title: "บันทึก",
            text: "ต้องการบันทึกข้อมูลใช้หรือไม่ ?",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
          }).then((result) => {
            if (result.value) {
              this.fetch_api_excel();
            }
          });
        }
      },
      async getSelcetWarehouse() {
          await axios.post(shopService_dotnet+'Shop/get-warehouse-list', {
            shopID:this.ShopID,
          },
          { headers: this.header_token }
        ).then((res) => {
            if (res.data.length > 0) {
              for (var s in res.data) {
                this.warehouseList.push({
                  id: res.data[s].id,
                  name: res.data[s].name,
                  shopID:res.data[s].shopID
                });
              } 
            }
          }).catch((error) => { });
                
  
         
        
      },
  
      async fetch_api_excel() {
        this.count_failed = 0;
        this.count_successes = 0;
        this.present_status = true;
        let body_error = [];
        for (var item in this.dataTable_excal) {
          //  เช็คสถานะการยิงอีกครั้ง
          if (
            this.dataTable_excal[item].status == 0 ||
            this.dataTable_excal[item].status == 3
          ) {

            let body_productMasterItem = [
              {
                id: this.dataTable_excal[item].productMasterItemID ,
                productMasterId: this.dataTable_excal[item].productMasterID,
                photoLink: this.dataTable_excal[item].url_img,
                unitPrice: this.dataTable_excal[item].price,
                barcode:
                  this.dataTable_excal[item].barcode != null
                    ? this.dataTable_excal[item].barcode.toString()
                    : null,
                sku:
                  this.dataTable_excal[item].sku != null
                    ? this.dataTable_excal[item].sku.toString()
                    : null,
                prop1Description: this.dataTable_excal[item].details_attribute1,
                prop2Description: this.dataTable_excal[item].details_attribute2,
                weight: this.dataTable_excal[item].weight,
                cubic1: this.dataTable_excal[item].cubic1,
                cubic2: this.dataTable_excal[item].cubic2,
                cubic3: this.dataTable_excal[item].cubic3,
              },
            ];

            let body_productMaster = {
              id: this.dataTable_excal[item].productMasterID,
              shopId:this.ShopID,
              name: this.dataTable_excal[item].name,
              productCode: (this.dataTable_excal[item].code !=null ? this.dataTable_excal[item].code.toString():null),
              prop1: this.dataTable_excal[item].attribute1,
              prop2: this.dataTable_excal[item].attribute2,
              cfCode: (this.dataTable_excal[item].cf !=null ? this.dataTable_excal[item].cf.toString():null),
              brand : this.dataTable_excal[item].brand,
              IsProductSet: this.dataTable_excal[item].isproductset
            };

            let stock_list = 
            [{
                stockShopID : this.dataTable_excal[item].stockShopID,
                warehouseID: this.warehouseId.id,
                buffer:  (this.dataTable_excal[item].buffer === undefined|| this.dataTable_excal[item].buffer==null? 0 : this.dataTable_excal[item].buffer) ,
                quantity: (this.warehouseId.shopID ==null &&  this.dataTable_excal[item].stockShopID==0)? 0: (this.dataTable_excal[item].stock === undefined||this.dataTable_excal[item].stock==null ? 0 : this.dataTable_excal[item].stock) ,
            }]

            body_productMasterItem[0].stockList = stock_list
  
            this.productMaster = body_productMaster;
            this.productMasterItem = body_productMasterItem;
 
            await axios
              .post(
                stockService_dotnet + "Stock/update-product",
                {
                  productMaster: this.productMaster,
                  productMasterItem: this.productMasterItem,
                },
                { headers: this.header_token }
              )
              .then((res) => {
                if (res.data.status == 200) {
                  this.count_successes++;
                  this.dataTable_excal[item].status = 1;
                  this.fetch_api_log(this.dataTable_excal[item]);
                } else {
                  this.count_failed++;
                  this.dataTable_excal[item].status = 2; //status = 2 ;ของยิงผ่านแต่ข้อมูลซ้ำ
                  this.dataTable_excal[item].error = res.data.text;
                  body_error.push(this.dataTable_excal[item]);
                  this.fetch_api_log(this.dataTable_excal[item]);
                }
              })
              .catch((error) => {
                this.count_failed++;
                this.dataTable_excal[item].status = 3; //status = 3 ; ผิดพลาดที่ดาต้าเบส รอยิงซ้ำอีกรอบ
                this.dataTable_excal[item].error = error;
                body_error.push(this.dataTable_excal[item]);
                this.fetch_api_log(this.dataTable_excal[item]);
  
              });
          }
  
          this.fetch_api_present++;
        }
  
        this.dataTable_excal = body_error;
        this.total_excal = this.count_failed;
        this.updatePage();
        this.dialog = true;
        this.fetch_api_present = 0;
      },
  
      async fetch_api_log(bodyHistory) {
  
        await axios
          .post(
              stockService_dotnet + "Stock/update-history-product-excel",
            {
              shopID: this.ShopID,
              branchID: localStorage.getItem("branch_id"),
              createdByStaffID: localStorage.getItem("staff_id"),
              name: bodyHistory.name != null ? bodyHistory.name.toString() : bodyHistory.name,
              productCode:  bodyHistory.code != null ? bodyHistory.code.toString() : bodyHistory.code ,
              sku:  bodyHistory.sku != null ? bodyHistory.sku.toString() : bodyHistory.sku ,
              barcode:  bodyHistory.barcode != null ? bodyHistory.barcode.toString() : bodyHistory.barcode,
              cfCode:  bodyHistory.cf != null ? bodyHistory.cf.toString() : bodyHistory.cf  ,
              unitPrice:  bodyHistory.price != null ? bodyHistory.price.toString() : bodyHistory.price,
              prop1:  bodyHistory.attribute1 ,
              prop2:  bodyHistory.attribute2 ,
              prop1Desc:  bodyHistory.details_attribute1 ,
              prop2Desc:  bodyHistory.details_attribute2 ,
              productLink:  bodyHistory.url_img  ,
            },
            { headers: this.header_token }
          )
          .then((res) => {})
          .catch((error) => {});
      },
  
      updatePage(pageIndex) {
        let start = (pageIndex - 1) * this.pageSize;
        let end = pageIndex * this.pageSize;
        this.historyList = this.dataTable_excal.slice(start, end);
        this.page = pageIndex;
        this.length_page = Math.ceil(this.total_excal / this.pageSize);
      },
      AlertWarning,
    },
    watch: {
      file_excel() {
        let row = [];
        readXlsxFile(this.file_excel).then(
          async (rows) => {
            var rowColumnLength = rows[0].length;
            console.log(rowColumnLength);
            
            if(rows[0].length != 17&&rows[0].length != 28)
            {
              this.$swal({
                type: 'warning',
                text: 'คอลัมน์ excel มีไม่ครบหรือไม่ถูกต้อง !!',
              })
              this.file_excel = null
              return
            }
            if(rows[0].length == 17 && rows[0][0] != "ชื่อสินค้า" && rows[0][12] != "สต๊อก")
            {
              this.$swal({
                type: 'warning',
                text: 'template excel ไม่ถูกต้อง !!',
              })
              this.file_excel = null
              return
            }
            if(rows[0].length == 28 && rows[0][0] != "ProductID"  )
            {
              this.$swal({
                type: 'warning',
                text: 'template excel ไม่ถูกต้อง !!',
              })
              this.file_excel = null
              return
            }
            for (var i in rows) {
              if (i > 0) {
                if(rowColumnLength==17){
                  row.push({
                    productMasterID: 0,
                    productMasterItemID: 0,
                    stockShopID: 0,
                    name: rows[i][0],
                    code: rows[i][1],
                    sku: rows[i][2],
                    barcode: rows[i][3],
                    cf: rows[i][4],
                    price: rows[i][5],
                    attribute1: rows[i][6],
                    details_attribute1: rows[i][7],
                    attribute2: rows[i][8],
                    details_attribute2: rows[i][9],
                    url_img: rows[i][10],
                    weight: rows[i][11],
                    cubic1: rows[i][12],
                    cubic2: rows[i][13],
                    cubic3: rows[i][14],
                    buffer: rows[i][15],
                    stock:rows[i][16],
                    isproductset:false,
                    status: 0,
                    index: i - 1,
                    isAddTemplate:true
                  });
                }
                else
                {
                  row.push({
                    productMasterID: rows[i][0],
                    productMasterItemID: rows[i][1],
                    stockShopID: rows[i][2],
                    name: rows[i][4],
                    attribute1: rows[i][5],
                    details_attribute1: rows[i][6],
                    attribute2: rows[i][7],
                    details_attribute2: rows[i][8],
                    price: rows[i][9],
                    code: rows[i][10],
                    cf: rows[i][11],
                    sku: rows[i][12],
                    barcode: rows[i][13],
                    weight: rows[i][14],
                    cubic1: rows[i][15],
                    cubic2: rows[i][16],
                    cubic3: rows[i][17],
                    stock:rows[i][18],
                    buffer:rows[i][19],
                    brand:rows[i][25],
                    isproductset :(rows[i][26] =="จัดเซ็ท")? true:false,
                    url_img: rows[i][27],
                    status: 0,
                    index: i - 1,
                    isAddTemplate:false
                  });
                }
              }
            }
            this.count_excel = this.total_excal = row.length;
            this.dataTable_excal = row;
            this.updatePage(1);
          }
        );
      },
    },
    computed: {
      present_wait() {
        let num = this.count_successes + this.count_failed;
        let num100 = num * 100;
  
        return parseInt(num100 / this.count_excel);
      },
    },
    async created() {
      await this.getSelcetWarehouse();
      this.page_loading = false;
    },
  };
  </script>
  
  <style scoped>
  .tablenone {
    border: none !important;
    margin: 10px 0px 10px 0px;
  }
  .box_img {
    width: 100px;
    height: 100px;
    border: 2px solid #837e7e4f;
    margin: 10px 0px 10px 0px;
  }
  </style>