<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card class="v-card-bottom-30">
      <v-card-title>
        <div>  
        {{use_language.stock}}
        <v-icon>mdi-chevron-right</v-icon>
        {{use_language.my_product}}
        </div> 
        <v-spacer/>
                <div>
                
                
                  <!-- สร้างสินค้าจาก Marketplace -->
                  <v-btn :elevation="1"  :disabled='!authorize' outlined color="primary" class="px-2 ml-1" @click="OPPopup(false)">
                  {{use_language.create_products_from_marktplace}} 
                  
                  </v-btn>
                  <v-btn :elevation="1" :disabled='!authorize'  color="primary" class="px-2 ml-1" @click="UpdateStock(null)">
                    <v-icon size="22" class="mr-1">mdi-plus</v-icon>
                    {{use_language.add_new_product}}
                  </v-btn>
                </div>
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- ผูกสินค้า -->
    <v-dialog v-model="mp.dialog" scrollable :max-width="mp.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1">
        <v-card-title class="height-57">
          {{use_language.tie_the_goods}}
          <v-spacer/>
          <v-btn icon @click="mp.dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-x-auto pt-1 pb-1">
          <v-row>
            <v-col cols="12">
              <div class="px-0 v-list-item">
                <a v-if="mp.photoLink!=null && mp.photoLink!=''" @click="OpenPhoto(mp.photoLink)" class="v-avatar v-list-item__avatar my-0 rounded-0 v-avatar--tile">
                  <img :src="mp.photoLink" class="picsize">
                </a>
                <div class="v-list-item__content">
                  <div class="v-list-item__title subtitle-1 packhai-showproduct">
                    <div>
                      <div>
                        {{use_language.name_product}}
                      </div>
                      <div>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on" class="cut-text">
                              <span v-if=" mp.name != null">: {{ mp.name }}</span>
                            </div>
                          </template>
                          <span v-if=" mp.name != null">{{ mp.name }}</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="v-markdown subtitle-1 packhai-showproduct">
                      <div>
                        <div>
                          {{use_language.type}}
                        </div>
                        <div class="cut-text">
                          : {{ (mp.prop1Description!=null && mp.prop1Description!="" ? mp.prop1Description : "") + (mp.prop2Description!=null && mp.prop2Description!="" ? " , "+mp.prop2Description : "") }}
                        </div>
                      </div>

                      <br/>
                      <div>
                        <div>
                          {{use_language.sku}}
                        </div>
                        <div class="cut-text">
                          : {{ mp.sku }}
                        </div>
                      </div>

                      <br/>
                      <div>
                        <div class="cut-text">
                          {{use_language.barcode}}
                        </div>
                        <div>
                          : {{ mp.barcode }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn v-if="!mp.loading" :elevation="1" color="primary" class="px-2 ml-1" @click="OPPopup(true)"> {{use_language.tie_the_goods}} </v-btn>
              <v-btn v-else :elevation="1" :loading="mp.loading" color="primary" class="px-2 ml-1"> {{use_language.tie_the_goods}} </v-btn>
            </v-col>
          </v-row>

          <!-- PC hidden-xs-only -->
          <v-data-table
            :headers="mp.header"
            :items="mp.dataset"
            :items-per-page="9999999999"
            hide-default-footer
            class="packhai-border-table hidden-xs-only"
          >
            <template v-slot:item.no="{ item }">
              {{ formatMoney(mp.total-mp.dataset.indexOf(item)) }}
            </template>
            <template v-slot:item.marketplace="{ item }">
              <v-avatar size="25px" v-if="item.logo!=null && item.logo!=''">
                <img :src="item.logo"/>
              </v-avatar>
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize mt-1">
              </a>
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
              </span>
            </template>
            <template v-slot:item.salePrice="{ item }">
              <span v-if="item.salePrice!=null && item.salePrice!=0">{{ formatMoney(item.salePrice) }}</span>
              <span v-else>{{ formatMoney(item.unitPrice) }}</span>
       
            </template>
            <template v-slot:item.mappedDate="{ item }">
         
              {{ formatDatetime(item.mappedDate) +  ((item.staffShopName==null &&item.staffBranchName==null) ? "": "("+ (item.staffShopName==null? item.staffBranchName : item.staffShopName)+")") }}  
            </template>
            <template v-slot:item.delete="{ item }">
              <a @click="DeleteMarketplace(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
            </template>
            <template v-slot:item.dialog="{ item }">
              <a @click="HistoryMapper(item)">รายละเอียด</a>
            </template>
          </v-data-table>

          <!-- Mobile hidden-sm-and-up -->
            <v-data-iterator
              :items="mp.dataset"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer
              class="hidden-sm-and-up"
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                    <v-card>
                      <v-card-title class="subheading font-weight-bold pa-0">
                        <div class="px-0 v-list-item">
                          <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                            <img :src="item.photoLink" class="picsize">
                          </a>
                          <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                            <span class="bold-color-gray-12"> {{use_language.no_picture}} </span>
                          </a>
                          <div class="v-list-item__content pa-2 pl-0">

                            <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  {{use_language.name}}
                                </div>
                                <div style="line-height: 1rem !important;">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on" class="cut-text">
                                        <span v-if=" item.name != null">: {{ item.name }}</span>
                                      </div>
                                    </template>
                                    <span v-if=" item.name != null">{{ item.name }}</span>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="v-markdown subtitle-1 packhai-showproduct-2">
                                <div>
                                  <div>
                                    {{use_language.type}}
                                  </div>
                                  <div class="cut-text">
                                    : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                  </div>
                                </div>

                                <br/>
                                <div>
                                  <div>
                                    {{use_language.sku}}
                                  </div>
                                  <div class="cut-text">
                                    : {{ item.sku }}
                                  </div>
                                </div>

                                <br/>
                                <div>
                                  <div>
                                    {{use_language.shop}}
                                  </div>
                                  <div class="cut-text">
                                    : {{ item.shopName }}
                                  </div>
                                </div>

                                <br/>
                                <div>
                                  <div>
                                    {{use_language.marketplace}}
                                  </div>
                                  <div>
                                    <v-row>
                                      <v-col cols="6" class="pa-0">
                                        : <img style="width: 20px;" class="mx-1" v-if="item.logo!=null && item.logo!=''" :src="item.logo"/>
                                      </v-col>
                                      <v-col cols="6" class="pa-0"/>
                                    </v-row>
                                  </div>
                                  <a @click="DeleteMarketplace(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- เลือกสินค้า -->
    <v-dialog v-model="mp.popup.dialog" scrollable :max-width="mp.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card v-if="mp.popup.dataresult.length==0" elevation="1" class="ma-0">
        <v-card-title class="pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span><h5> {{use_language.search}} : </h5></span>
            </div>
            <div class="v-list-item__content pa-2">
              <v-text-field
                ref="refpopupnamesearch"
                :value="popupnamesearch"
                autocomplete="off"
                class="pl-2"
                dense
                hide-details
                @change="value => popupnamesearch = Trim_value(value)"
                @keyup.enter="PopupsearchStock()"
              ></v-text-field>
            </div>
            <div class="pa-2">
              <v-btn :elevation="1" color="primary" class="pa-2" dark @click="PopupsearchStock()">
                <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                {{use_language.search}}
              </v-btn>
            </div>
            <div>
              <v-btn icon @click="mp.popup.dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-x-auto pa-0" :style="{height: mp.MaxHeight+'px'}">
          <v-tabs v-model="mp.popup.tab">
            <v-tabs-slider color="red"></v-tabs-slider>
            <v-tab v-for="i in mp.popup.tab_list" :key="i.id" @click="PopupChangeTab(i.id)">
              <v-avatar size="20px" v-if="i.logo!=null && i.logo!=''">
                <img :src="i.logo"/>
              </v-avatar>
              <span class="px-1"> {{ i.english }} </span>
              ({{ i.total }})
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="mp.popup.tab" touchless>
            <v-tab-item v-for="i in mp.popup.tab_list" :key="i.id">

              <!-- PC hidden-xs-only -->
              <v-data-table
                :headers="mp.popup.header"
                :items="mp.popup.dataset"
                :items-per-page="9999999999"
                hide-default-footer
                class="elevation-0 packhai-checkbox-select hidden-xs-only"
              >
                <template v-slot:item.selectProduct="{ item }">
                  <v-btn v-if="item.selectProduct" small depressed color="primary" class="px-2" @click="ProductSelectCheckbox(item,mp.popup.dataset.indexOf(item))"> เลือก <v-icon size="18" class="ml-2">mdi-checkbox-multiple-marked-circle-outline</v-icon> </v-btn>
                  <v-btn v-else outlined small depressed color="primary" class="px-2" @click="ProductSelectCheckbox(item,mp.popup.dataset.indexOf(item))"> เลือก </v-btn>
                </template>
                <template v-slot:item.no="{ item }">
                  {{ formatMoney((mp.popup.total-mp.popup.dataset.indexOf(item))-mp.popup.offsets) }}
                </template>
                <template v-slot:item.salePrice="{ item }">
                  {{ formatMoney(item.salePrice) }}
                </template>
                <template v-slot:item.photoLink="{ item }">
                  <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                    <img :src="item.photoLink" class="picsize mt-1">
                  </a>
                </template>
                <template v-slot:item.prop1="{ item }">
                  <span class="fn-13">
                    {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                  </span>
                </template>
              </v-data-table>

              <!-- Mobile hidden-sm-and-up -->
              <v-data-iterator
                :items="mp.popup.dataset"
                :items-per-page="9999999999"
                hide-default-header
                hide-default-footer
                class="hidden-sm-and-up"
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                      <v-card>
                        <v-card-title class="subheading font-weight-bold pa-0">
                          <div class="px-0 v-list-item">
                            <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                              <img :src="item.photoLink" class="picsize">
                            </a>
                            <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                              <span class="bold-color-gray-12"> {{use_language.no_picture}} </span>
                            </a>
                            <div class="v-list-item__content pa-2 pl-0">

                              <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                                <div>
                                  <div>
                                    {{use_language.name}}
                                  </div>
                                  <div style="line-height: 1rem !important;">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" class="cut-text">
                                          <span v-if=" item.name != null">: {{ item.name }}</span>
                                        </div>
                                      </template>
                                      <span v-if=" item.name != null">{{ item.name }}</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="v-markdown subtitle-1 packhai-showproduct-2">
                                  <div>
                                    <div>
                                      {{use_language.type}}
                                    </div>
                                    <div class="cut-text">
                                      : {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                                    </div>
                                  </div>

                                  <br/>
                                  <div>
                                    <div>
                                      {{use_language.sku}}
                                    </div>
                                    <div class="cut-text">
                                      : {{ item.sku }}
                                    </div>
                                  </div>

                                  <br/>
                                  <div>
                                    <div>
                                      {{use_language.shop}}
                                    </div>
                                    <div class="cut-text">
                                      : {{ item.shopName }}
                                    </div>
                                  </div>

                                  <br/>
                                  <div class="pr-2">
                                    <v-spacer/>
                                    <v-btn v-if="item.selectProduct" small depressed color="primary" class="px-2" @click="ProductSelectCheckbox(item,mp.popup.dataset.indexOf(item))"> {{use_language.choose}} <v-icon size="18" class="ml-2">mdi-checkbox-multiple-marked-circle-outline</v-icon> </v-btn>
                                    <v-btn v-else outlined small depressed color="primary" class="px-2" @click="ProductSelectCheckbox(item,mp.popup.dataset.indexOf(item))"> {{use_language.choose}} </v-btn>
                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>

            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <!-- เปลี่ยนหน้า -->
        <div v-if="mp.popup.pageLength>1 && mp.popup.dataset.length>0" class="text-center pa-1 ma-0" @click="PopupChangePage()">
          <v-pagination
            v-model="mp.popup.page"
            :length="mp.popup.pageLength"
            :total-visible="mp.popup.pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider/>
          <v-row>
            <v-col cols="12">

           
         
            <table style="width:100%">
              <tr>
                <td>
                  <span class="pa-2"> {{use_language.quantity}} {{ formatMoney(mp.popup.selectProductList.length) }} {{use_language.list}} </span>
                </td>
                <td class="text-right">
                  <v-btn class="mx-2" color="primary" outlined :loading="btn_prosess_loading" dark @click="reload_stock_all_marketplace()">{{use_language.retrieve_product_information_from_marketplace}}</v-btn>
                  <v-btn :elevation="1" color="primary" class="px-5" @click="MarketplaceUpdate(mp.popup.mapper)">
                    <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                    <span v-if="mp.popup.mapper"> {{use_language.tie_the_goods}} </span>
                    <span v-else> {{use_language.creare_product + CreatingProductPercent}}   </span>
                  </v-btn>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

      </v-card>
      <v-card v-else elevation="1" class="ma-0">
        <v-card-title class="pa-0">
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn icon @click="mp.popup.dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-x-auto pa-0" :style="{height: mp.MaxHeight+'px'}">
          <!-- PC hidden-xs-only -->
          <v-data-table
            :headers="mp.popup.headerresult"
            :items="mp.popup.dataresult"
            :items-per-page="9999999999"
            hide-default-footer
            class="elevation-0 packhai-checkbox-select hidden-xs-only"
          >
            <template v-slot:item.success="{ item }">
              <v-icon size="22" color="success darken-3">mdi-check-circle-outline</v-icon>
            </template>
            <template v-slot:item.marketplace="{ item }">
              <v-avatar size="25px" v-if="item.logo!=null && item.logo!=''">
                <img :src="item.logo"/>
              </v-avatar>
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize mt-1">
              </a>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.prop1="{ item }">
              <span class="fn-13">
                {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
              </span>
            </template>
            <template v-slot:item.sku="{ item }">
              {{ item.sku }}
            </template>
            <template v-slot:item.salePrice="{ item }">
                  {{ formatMoney(item.salePrice) }}
            </template>
            <template v-slot:item.detail="{ item }">
              {{ item.createProductResult }}
            </template>
          </v-data-table>

          <!-- Mobile hidden-sm-and-up -->
          <v-data-iterator
            :items="mp.popup.dataresult"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
            class="hidden-sm-and-up"
            >
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> {{use_language.no_picture}} </span>
                        </a>
                        <div class="v-list-item__content pa-2 pl-0">

                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>
                               {{use_language.name}}
                              </div>
                              <div style="line-height: 1rem !important;">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="cut-text">
                                      <span v-if=" item.name != null">: {{ item.name }}</span>
                                    </div>
                                  </template>
                                  <span v-if=" item.name != null">{{ item.name }}</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>
                                  {{use_language.sku}}
                                </div>
                                <div class="cut-text">
                                  : {{ item.sku }}
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.marketplace}}
                                </div>
                                <div>
                                  :
                                  <v-avatar style="width: 25px;" size="25px" v-if="item.logo!=null && item.logo!=''">
                                    <img :src="item.logo"/>
                                  </v-avatar>
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div>
                                  {{use_language.succeed}}
                                </div>
                                <div>
                                  :
                                  <v-icon   size="22" color="success darken-3">mdi-check-circle-outline</v-icon>
                                
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>

        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="mp.updateHistory.dialog"   scrollable width="700px" persistent >
        <v-card>
          <v-card-title >
            <span>ประวัติการอัพเดทสต๊อกไปยัง marketplace</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="mp.updateHistory.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                :headers="header_list"
                :items="itemDataHistory"
                  :items-per-page="9999999999"
                hide-default-footer
                class="packhai-border-table packhai-table"
                >
                <template v-slot:item.updated="{ item }">
                  {{ formatDatetime(item.updated) }}
                </template>
                <template v-slot:item.quantity="{ item }">
                  {{ item.quantity }}
                </template>
                <template v-slot:item.error="{ item }">
                  {{ item.error }}
                </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    
     <!-- เลือกรูปแบบรายงาน -->
     <v-dialog v-model="ChooseExcelType" max-width="500">
      <v-card class="ma-0">
        <v-card-title >
          <span>เลือกรูปแบบรายงาน</span>
        <v-spacer></v-spacer>
        <v-btn icon  @click="ChooseExcelType = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-radio-group  v-model="ExcelType"  >
            <v-radio  
              label="รายงานสินค้าทั่วไป"
              value="1"
            >
            </v-radio>
            <v-radio 
              label="รายงานการจับคู่กับสินค้าใน platform"
              value="2">
            </v-radio>
            <v-radio 
              label="รายงานสินค้าภายในเซ็ท"
              value="3">
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider/>
        <v-card-actions>    
          <v-spacer></v-spacer> 
          <v-btn color="primary"    @click="ProcessExportExcel()">ดำเนินการ</v-btn>
        
         
        </v-card-actions>
      </v-card>
    </v-dialog>

   

    <!-- แสดงตาราง -->
 
    <v-tabs v-model="tab"  >
      <v-tabs-slider  ></v-tabs-slider>
      <v-tab v-for="i in tab_list" :key="i.id"  @click="ChangeTab(i.id)"> 
        <v-avatar size="20px" v-if="i.logo!=null && i.logo!=''">
          <img :src="i.logo"/>
        </v-avatar>
        <span class="px-1"> {{ i.name+" ("+formatMoney(i.itemCount)+")" }}  </span>
      </v-tab>
    </v-tabs>

  
       
    <v-tabs-items v-model="tab"  touchless style="background-color: transparent !important ">
      <v-tab-item v-for="i in tab_list" :key="i.id">

          <!-- ค้นหาสินค้า -->
        <div v-if="i.id==0">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                    <v-col cols="12"  lg="3" class="pt-1 pb-2">
                      <h3 class="fn-14"> ประเภทสินค้า : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8" class="pt-1 pb-2">
                      <v-select
                        v-model="search.isProductSetTemp"
                        :items="search.isProductSetList"
                        item-text="name"
                        item-value="id"
                        persistent-hint
                        return-object
                        dense hide-details
                        outlined
                        single-line
                      ></v-select>
                    </v-col>


                    <v-col cols="12"  lg="3" class="pt-1 pb-2">
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8" class="pt-1 pb-2">
                      <v-text-field
                        :value="search.name"
                        @change="value => search.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12"  lg="3" class="pt-1 pb-2">
                      <h3 class="fn-14"> {{use_language.number_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8" class="pt-1 pb-2">
                      <v-text-field
                        :value="search.productCode"
                        @change="value => search.productCode = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                         placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12"  lg="3" class="pt-1 pb-2">
                      <h3 class="fn-14"> {{use_language.barcode}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8" class="pt-1 pb-2">
                      <v-text-field
                        :value="search.barcode"
                        @change="value => search.barcode = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                         placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                      ></v-text-field>
                    </v-col>

                  

                  </v-row>
                </v-col>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>

                    <v-col cols="12"  lg="3" class="pt-1 pb-2">
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8" class="pt-1 pb-2">
                      <v-text-field
                        :value="search.sku"
                        @change="value => search.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                         placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12"  lg="3" class="pt-1 pb-2">
                      <h3 class="fn-14">Brand : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8" class="pt-1 pb-2">
                      <v-text-field
                        :value="search.brand"
                        @change="value => search.brand = Trim_value(value)"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>


                  

                  

                    <v-col cols="12"  lg="3" class="pt-1 pb-2"/>
                    <v-col cols="12"  lg="8" class="pt-1 pb-2">
                      <v-btn :elevation="1" color="primary" class="pa-2" dark @click="SearchStock()">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                      <v-btn :elevation="1" outlined color="success" class="ml-2" @click="openExportExceLChoose()">
                        <v-icon size="18"   left>fa-file-excel</v-icon>
                        Excel
                        <span v-if="loadingExcel!=0"> ( {{ loadingExcel }} % ) </span>
                      </v-btn>
                      <vue-excel-xlsx
                          id="GetExcel"
                          v-show="false"
                          :data="datasetExcel"
                          :columns="headerExcel"
                          :file-name="'รายงานสินค้า'"
                          :sheet-name="'sheet1'"
                      />
                      <vue-excel-xlsx
                          id="GetExcelProductMapping"
                          v-show="false"
                          :data="datasetExcel"
                          :columns="headerExcelProductMapping"
                          :file-name="'รายงานการจับคู่กับสินค้าใน platform'"
                          :sheet-name="'sheet1'"
                      />
                      <vue-excel-xlsx
                          id="GetExcelProductSetMapping"
                          v-show="false"
                          :data="datasetExcel"
                          :columns="headerExcelProductSetMapping"
                          :file-name="'รายงานสินค้าภายในเซ็ท'"
                          :sheet-name="'sheet1'"
                      />
                    </v-col>

                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(MyProductSearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headermaster"
              :items="dataset"
              :loading="tableloading"
              :items-per-page="9999999999"
              hide-default-footer
              class="packhai-border-table hidden-xs-only"
            >
              <template v-for="(header, index) in headermaster" v-slot:[`header.${header.value}`]="{ header }">
                <v-tooltip :key="index" v-slot:activator="{ on }">
                  <span>{{ header.text }}</span>
                  <span v-if="header.value=='name' || header.value=='prop1' || header.value=='unitPrice' || header.value=='productCode' || header.value=='cfCode'|| header.value=='sku' || header.value=='barcode'">
                    <v-icon v-if="header.icon==0" @click="OrderByCollum(index)" size="16" class="icon-white">
                      mdi-arrow-up
                    </v-icon>
                    <v-icon v-else-if="header.icon==1" @click="OrderByCollum(index)" size="16" >
                      mdi-arrow-up
                    </v-icon>
                    <v-icon v-else-if="header.icon==2" @click="OrderByCollum(index)" size="16" >
                      mdi-arrow-down
                    </v-icon>
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.name="{ item }">
                <v-row>
                  <v-col cols="12" align="left" class="pa-0">
                    <span class="fn-13" style="font-weight: bold">{{
                      item.name
                    }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" align="left" class="pa-0">
                    <span class="color-gray-13">{{!item.barcode ? "" : use_language.barcode +" : "}} {{ item.barcode }} {{item.barcode && item.sku ? ",": "" }}  </span>
                  </v-col>

                </v-row>

                <v-row>
                  <v-col cols="12" align="left" class="pa-0">
                    <span class="color-gray-13">
                        {{!item.productCode ? "" :  use_language.number_product + " : "}}  {{ item.productCode }}
                        {{item.productCode && ((item.prop1Description || item.prop2Description )) ? ",": "" }}
                        {{!item.prop1Description && !item.prop2Description ? "" : use_language.type + "  : "}}  {{ item.prop1Description }}  {{item.prop2Description }}
                    </span>
                  </v-col>

                </v-row>
                <v-row>
                  <span v-if="item.isProductSet" class="fn-13" >
                  <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                    {{use_language.set_products}}
                </span>

                </v-row>
              </template>
              <template v-slot:item.productCode="{ item }">
                <span class="fn-13">
                  {{item.productCode}}
                </span>
              </template>
              <template v-slot:item.prop1="{ item }">
                <span class="fn-13">
                  {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                </span>
              </template>
              <template v-slot:item.barcode="{ item }">
                <span class="fn-13">
                  {{item.barcode}}
                </span>
              </template>
              <template v-slot:item.sku="{ item }">
                <span class="fn-13">
                  {{item.sku}}
                </span>
              </template>
              <template v-slot:item.isProductSet="{ item }">
                <span v-if="item.isProductSet" class="fn-13">
                  <v-icon size="22" color="orange darken-3">mdi-package-variant-closed-plus</v-icon>
                </span>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OPMapper(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
              <template v-slot:item.edit="{ item }">
                <a v-if ="authorize" @click="UpdateStock(item)"><v-icon size="17" color="orange darken-3">fa-edit</v-icon></a>
                <a v-else><v-icon disabled size="17" color="orange darken-3">fa-edit</v-icon></a>
              </template>
              <template v-slot:item.delete="{ item }">
                <a v-if="!authorize"><v-icon disabled size="17" color="red darken-3">fa-trash-alt</v-icon></a>
                <a v-else @click="DeleteStock(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
              </template>
              <template v-slot:item.unitPrice="{ item }">
                {{ formatMoney( item.salePrice == null? item.unitPrice : item.salePrice) }} 
              </template>
            </v-data-table>
            <!-- Mobile hidden-sm-and-up -->

            <v-data-iterator
              :items="dataset"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer
              class="hidden-sm-and-up"
            >

              <template v-slot:default="props">
                <v-row class="ma-2">
                  <v-col v-for="item in props.items" :key="item.id" cols="12" class="">
                    <v-card >
                      <v-row >
                        <v-col cols="auto" class="text-right" >
                        <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize">
                        </a>
                          <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile" style="height: 90px; min-width: 90px; width: 90px;">
                          <span class="bold-color-gray-12"> {{use_language.no_picture}} </span>
                        </a>
                        </v-col>

        <v-col cols="auto"  class="pl-0">
          <v-row class="flex-column ma-0 "  >

            <v-col class="px-0 py-1" >
              <div style="line-height: 1rem !important;">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <b>
                      <div v-bind="attrs" v-on="on" class="cut-text">
                        <span v-if="item.name != null"> {{ item.name }}</span>
                        </div>
                    </b>
                    </template>
                      <span v-if=" item.name != null">{{ item.name }}</span>
                  </v-tooltip>
                </div>
              </v-col>

            <v-col class="px-0 py-0" v-if="item.unitPrice " >
            <span v-if="item.unitPrice" class="color-gray-12" >{{use_language.selling_price}}  : {{ item.unitPrice }}</span>
            </v-col>

            <v-col class="px-0 py-0" v-if="item.sku ||(item.prop1Description || item.prop2Description)" >
            <span v-if="item.sku" class="color-gray-12" >{{use_language.sku}}  : {{ item.sku }}</span>
            <span  v-if="item.sku && (item.prop1Description || item.prop2Description)" class="color-gray-12"> , </span>
            <span v-if="item.prop1Description || item.prop2Description"  class="color-gray-12">{{use_language.type}} :
              {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "")
              + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
            </span>
            </v-col>

            <v-col class="px-0  py-0" v-if="item.productCode || item.barcode" >
            <span  v-if="item.productCode " class="color-gray-12">{{use_language.number_product}} : {{ item.productCode }}</span>
            <span v-if="item.productCode && item.barcode"  class="color-gray-12"> , </span>
            <span v-if="item.barcode"  class="color-gray-12" > {{use_language.barcode}}  : {{ item.barcode }}</span>
            </v-col>

            <v-col class="px-0  py-0" v-if="item.cfCode ">
            <span class="color-gray-12">{{use_language.code_cf}} : {{ item.cfCode }}</span>
            </v-col>

              <v-col class="px-0  py-0"  v-if="item.isProductSet">
            <v-icon size="18" color="orange darken-3" disabled>mdi-package-variant-closed-plus</v-icon>
              <span class="color-gray-12"> {{use_language.set_products}} </span>

              </v-col>
          </v-row>
        </v-col>
                  </v-row>

                          <v-row>
                            <v-col cols="4"  class="text-center" >
                                <v-btn   @click="OPMapper(item)" outlined color="success darken-1" width="100%" class="pa-1">
                                <v-icon size="13" color="success darken-1">mdi-sitemap</v-icon>
                                <span class="fn-10"> ({{ formatMoney(item.mappedItemCount) }})  {{use_language.tie_the_goods}}</span>
                                  </v-btn>
                            </v-col>
                            <v-col cols="4" class="text-center" >
                                <v-btn  @click="UpdateStock(item)" outlined color="orange darken-3"  width="100%" class="pa-1">
                                <v-icon size="13" >fa-edit</v-icon>
                                <span > {{use_language.edit}}</span>
                                  </v-btn>
                            </v-col>
                            <v-col cols="4" class="text-center">
                                <v-btn   @click="DeleteStock(item)" outlined color="red darken-3"  width="100%" class="pa-1">
                                <v-icon size="13" >fa-trash-alt</v-icon>
                                <span>{{use_language.delete}}</span>
                                  </v-btn>
                            </v-col>
                          </v-row>


                      <!-- <div class="px-0 v-list-item">
                        <div class="v-list-item__content pa-2 pt-1">
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">




                              <br/>


                            </div>
                          </div>

                        </div>
                        <div class="pa-2 pt-1">
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div class="pr-1">
                                  <v-spacer/>
                                  <a @click="OPMapper(item)"><v-icon size="17" color="success darken-1">mdi-sitemap</v-icon></a>
                                  ({{ formatMoney(item.mappedItemCount) }})
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div class="pr-1">
                                  <v-spacer/>
                                  <a @click="UpdateStock(item)"><v-icon size="15" color="orange darken-3">fa-edit</v-icon></a>
                                </div>
                              </div>

                              <br/>
                              <div>
                                <div class="pr-1">
                                  <v-spacer/>
                                  <a @click="DeleteStock(item)"><v-icon size="15" color="red darken-3">fa-trash-alt</v-icon></a>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div> -->

                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="pageLength>1 && dataset.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="changePage()">
                <v-pagination
                  v-model="page"
                  :length="pageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="limits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="LimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="i.id==1">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                  


                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8"  > 
                      <v-text-field
                        :value="ShopeeSearch.name"
                        @change="value => ShopeeSearch.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8"  >
                      <v-text-field
                        :value="ShopeeSearch.sku"
                        @change="value => ShopeeSearch.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                        placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="1">
                      <v-btn  color="primary" class="pa-2" dark @click="ShopeeSearchStock (ShopeePageLimits,0, 1)">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                
              
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(ShopeeSearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headerTableShopee"
              :items="datasetShopee"
              :items-per-page="9999999999"
              hide-default-footer
              class="elevation-0 packhai-checkbox-select hidden-xs-only"
            >

              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.name="{ item }">
                <span>{{ item.name  }}</span><br>
                <span style="color:#b5b5b5">สถานะ : {{ item.platformItemStatus  }}</span>
              </template>
              <template v-slot:item.salePrice="{ item }">
                {{ formatMoney(item.salePrice) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OpenMapperFromMarketplace(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
              
            </v-data-table>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="ShopeePageLength>1 && datasetShopee.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="ShopeeChangePage()">
                <v-pagination
                  v-model="ShopeeCurrentPage"
                  :length="ShopeePageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="ShopeePageLimits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="ShopeePageLimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="i.id==2">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                  


                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8"  > 
                      <v-text-field
                        :value="LazadaSearch.name"
                        @change="value => LazadaSearch.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8"  >
                      <v-text-field
                        :value="LazadaSearch.sku"
                        @change="value => LazadaSearch.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                        placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="1">
                      <v-btn  color="primary" class="pa-2" dark @click="LazadaSearchStock (LazadaPageLimits,0, 1)">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                
              
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(LazadaSearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headerTableLazada"
              :items="datasetLazada"
              :items-per-page="9999999999"
              hide-default-footer
              class="elevation-0 packhai-checkbox-select hidden-xs-only"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.name="{ item }">
                <span>{{ item.name  }}</span><br>
                <span style="color:#b5b5b5">สถานะ : {{ item.platformItemStatus  }}</span>
              </template>
              <template v-slot:item.salePrice="{ item }">
                {{ formatMoney(item.salePrice) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OpenMapperFromMarketplace(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
            </v-data-table>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="LazadaPageLength>1 && datasetLazada.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="LazadaChangePage()">
                <v-pagination
                  v-model="LazadaCurrentPage"
                  :length="LazadaPageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="LazadaPageLimits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="LazadaPageLimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="i.id==3">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                  


                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8"  > 
                      <v-text-field
                        :value="TiktokSearch.name"
                        @change="value => TiktokSearch.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8"  >
                      <v-text-field
                        :value="TiktokSearch.sku"
                        @change="value => TiktokSearch.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                        placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="1">
                      <v-btn  color="primary" class="pa-2" dark @click="TiktokSearchStock (TiktokPageLimits,0, 1)">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                
              
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(TiktokSearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headerTableTiktok"
              :items="datasetTiktok"
              :items-per-page="9999999999"
              hide-default-footer
              class="elevation-0 packhai-checkbox-select hidden-xs-only"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.name="{ item }">
                <span>{{ item.name  }}</span><br>
                <span style="color:#b5b5b5">สถานะ : {{ item.platformItemStatus  }}</span>
              </template>
              <template v-slot:item.salePrice="{ item }">
                {{ formatMoney(item.salePrice) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OpenMapperFromMarketplace(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
            </v-data-table>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="TiktokPageLength>1 && datasetTiktok.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="TiktokChangePage()">
                <v-pagination
                  v-model="TiktokCurrentPage"
                  :length="TiktokPageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="TiktokPageLimits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="TiktokPageLimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="i.id==4">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                  


                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8"  > 
                      <v-text-field
                        :value="WooSearch.name"
                        @change="value => WooSearch.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8"  >
                      <v-text-field
                        :value="WooSearch.sku"
                        @change="value => WooSearch.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="1">
                      <v-btn  color="primary" class="pa-2" dark @click="WooSearchStock (WooPageLimits,0, 1)">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                
              
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(WooSearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headerTableWoo"
              :items="datasetWoo"
              :items-per-page="9999999999"
              hide-default-footer
              class="elevation-0 packhai-checkbox-select hidden-xs-only"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.salePrice="{ item }">
                {{ formatMoney(item.salePrice) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OpenMapperFromMarketplace(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
            </v-data-table>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="WooPageLength>1 && datasetWoo.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="WooChangePage()">
                <v-pagination
                  v-model="WooCurrentPage"
                  :length="WooPageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="WooPageLimits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="WooPageLimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="i.id==5">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                  


                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8"  > 
                      <v-text-field
                        :value="LineSearch.name"
                        @change="value => LineSearch.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8"  >
                      <v-text-field
                        :value="LineSearch.sku"
                        @change="value => LineSearch.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="1">
                      <v-btn  color="primary" class="pa-2" dark @click="LineSearchStock (LinePageLimits,0, 1)">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                
              
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(LineSearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headerTableLine"
              :items="datasetLine"
              :items-per-page="9999999999"
              hide-default-footer
              class="elevation-0 packhai-checkbox-select hidden-xs-only"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.salePrice="{ item }">
                {{ formatMoney(item.salePrice) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OpenMapperFromMarketplace(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
            </v-data-table>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="LinePageLength>1 && datasetLine.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="LineChangePage()">
                <v-pagination
                  v-model="LineCurrentPage"
                  :length="LinePageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="LinePageLimits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="LinePageLimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="i.id==6">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                  


                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8"  > 
                      <v-text-field
                        :value="ShopifySearch.name"
                        @change="value => ShopifySearch.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8"  >
                      <v-text-field
                        :value="ShopifySearch.sku"
                        @change="value => ShopifySearch.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="1">
                      <v-btn  color="primary" class="pa-2" dark @click="ShopifySearchStock (ShopifyPageLimits,0, 1)">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                
              
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(ShopifySearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headerTableShopify"
              :items="datasetShopify"
              :items-per-page="9999999999"
              hide-default-footer
              class="elevation-0 packhai-checkbox-select hidden-xs-only"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.salePrice="{ item }">
                {{ formatMoney(item.salePrice) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OpenMapperFromMarketplace(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
            </v-data-table>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="ShopifyPageLength>1 && datasetShopify.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="ShopifyChangePage()">
                <v-pagination
                  v-model="ShopifyCurrentPage"
                  :length="ShopifyPageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="ShopifyPageLimits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="ShopifyPageLimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
        <div v-if="i.id==7">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pa-0">
                  <v-row>
                  


                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.name_product}} : </h3>
                    </v-col>
                    <v-col cols="12"  lg="8"  > 
                      <v-text-field
                        :value="PlugoSearch.name"
                        @change="value => PlugoSearch.name = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="3"  >
                      <h3 class="fn-14"> {{use_language.sku}} : </h3>
                    
                    </v-col>
                    <v-col cols="12"  lg="8"  >
                      <v-text-field
                        :value="PlugoSearch.sku"
                        @change="value => PlugoSearch.sku = Trim_value(value)"
                        @keyup.enter="SearchStock()"
                        outlined
                        dense hide-details
                        placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  lg="1">
                      <v-btn  color="primary" class="pa-2" dark @click="PlugoSearchStock (PlugoPageLimits,0, 1)">
                        <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                        {{use_language.search}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                
              
              </v-row>
            </v-card-text>
          </v-card>
          
          <v-row class="mt-5">
            <v-col cols="12" md="6" class="pa-0"  >
              {{use_language.all_info}} {{ formatMoney(PlugoSearchResultCount) }} {{use_language.row}}
            </v-col>
          </v-row>
          
          <!-- แสดงแถวข้อมูล -->
          <div style="background-color: white;"> 
            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headerTablePlugo"
              :items="datasetPlugo"
              :items-per-page="9999999999"
              hide-default-footer
              class="elevation-0 packhai-checkbox-select hidden-xs-only"
            >
              <template v-slot:item.no="{ item }">
                {{ formatMoney(item.no) }}
              </template>
              <template v-slot:item.salePrice="{ item }">
                {{ formatMoney(item.salePrice) }}
              </template>
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.mapper="{ item }">
                <a v-if="authorize" @click="OpenMapperFromMarketplace(item)"><v-icon size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                <a v-else ><v-icon disabled size="19" color="success darken-1">mdi-sitemap</v-icon></a>
                
                ({{ formatMoney(item.mappedItemCount) }})
              </template>
            </v-data-table>

            <!-- เปลี่ยนหน้า -->
            <v-row v-if="PlugoPageLength>1 && datasetPlugo.length>0" class="text-center pa-2">
              <v-col cols="2" lg="1" >
              </v-col>
              <v-col cols="8"  lg="10"  @click="PlugoChangePage()">
                <v-pagination
                  v-model="PlugoCurrentPage"
                  :length="PlugoPageLength"
                  :total-visible="pageTotalVisible"
                  color="error"
                ></v-pagination>
              </v-col>
              <v-col cols="2"  class="text-right" lg="1" >
              <v-select  
                v-model="PlugoPageLimits"
                label="จำนวนแสดง"
                :items="[10,20,50,100,500,1000]"
                @change="PlugoPageLimitChange()"
                outlined
              ></v-select>
            </v-col>
            </v-row>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import { stockService_dotnet, shopService_dotnet, ecomService_dotnet  } from '@/website/global'
  import { formatDatetime,formatMoney, format_price, isNumberWithDot, isNumberWNoDot, DateNowNotNull, Trim_value, Trim_rules ,get_languages,checkAuthorize} from '@/website/global_function'

  export default {
    components: {
      Loading
    },
    data: () => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      staffShopID:localStorage.getItem('staff_id'),
      staffBranchID:localStorage.getItem('staff_branch_id'),
      language:'th',
      reveal: false,
      CreatingProductPercent :'',
      MyProductSearchResultCount:0,
      // page
      page_loading: true,
      loading: false,

      use_language: null,
      set_language: null,

      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',
      btn_prosess_loading: false,

      // ค้นหา
      search: {
        "name": null,
        "productCode": null,
        "barcode": null,
        "sku": null,
        "brand":null,
        "isProductSet": 2,
        "isProductSetTemp": {"id":2, "name":"--ทั้งหมด--"},
        "isProductSetList": [
          {"id":2, "name":"--ทั้งหมด--"},
          {"id":0, "name":"สินค้าทั่วไป"},
          {"id":1, "name":"สินค้าจัดเซ็ต"} 
        ],
        orderbyName: 'id',
        isAscending: false,
      },

      // Export Excel
      loadingExcel: 0,
      datasetExcel: [],
      headerExcel: [
        
        { label: "ลำดับ", field: "no", width: 10},
        { label: "ProductMasterID", field: "productMasterId", width: 10},
        { label: "ProductMasterItemID", field: "id", width: 10},
        { label: "ชื่อ", field: "name", width: '50px'},
        { label: "คุณลักษณะ1", field: "prop1", width: 20},
        { label: "คำอธิบายลักษณะ1", field: "prop1Description", width: 20},
        { label: "คุณลักษณะ2", field: "prop2", width: 20},
        { label: "คำอธิบายลักษณะ2", field: "prop2Description", width: 20},
        { label: "รหัสสินค้า", field: "productCode", width: 15},
        { label: "รหัส CF", field: "cfCode", width: 15},
        { label: "SKU", field: "sku", width: 15},
        { label: "Barcode", field: "barcode", width: 15},
        { label: "Brand", field: "brand", width: 20},
        { label: "หมายเหตุ", field: "remark", width: 20},
        { label: "น้ำหนัก", field: "weight", width: 20},
        { label: "กว้าง", field: "cubic1", width: 20},
        { label: "ยาว", field: "cubic2", width: 20},
        { label: "สูง", field: "cubic3", width: 20},
        { label: "จำนวนแจ้งเตือ", field: "alertQuantity", width: 20},
        { label: "เช็ตสินค้า", field: "isProductSet", width: 10},
      ],
      headerExcelProductMapping: [
        { label: "No", field: "no", width: 10},
        { label: "ProductMasterID", field: "productMasterID", width: '50px'},
        { label: "ProductMasterItemID", field: "productMasterItemID", width: '50px'},
        { label: "ชื่อสินค้า", field: "name", width: '50px'},
        { label: "รูปภาพ", field: "photoLink", width: '50px'},
        { label: "ลักษณะ1", field: "prop1Description", width: '50px'},
        { label: "ลักษณะ2", field: "prop2Description", width: '50px'},
        { label: "รหัสสินค้า", field: "productCode", width: '50px'},
        { label: "ราคาขาย", field: "unitPrice", width: '50px'},
        { label: "ราคาส่วนลด", field: "salePrice", width: '50px'},
        { label: "Barcode", field: "barcode", width: '50px'},
        { label: "Sku", field: "sku", width: '50px'},
        { label: "จำนวนที่ผูกกับ marketplace", field: "mappedCount", width: '50px'},
        { label: "ช่องทาง", field: "channelName", width: '50px'},
        { label: "ร้านค้า", field: "marketplace_ShopName", width: '50px'},
        { label: "Sku", field: "marketplace_Sku", width: '50px'},
        { label: "ลักษณะ1", field: "marketplace_Prop1Description", width: '50px'},
        { label: "ลักษณะ2", field: "marketplace_Prop2Description", width: '50px'},
        { label: "รูปภาพ", field: "marketplacePhoto", width: '50px'},
      
      ],
      headerExcelProductSetMapping: [
        { label: "No", field: "no", width: 10},
        { label: "ProductMasterID", field: "productMasterID", width: 10},
        { label: "ProductMasterItemID", field: "productMasterItemID", width: '50px'},
        { label: "ชื่อสินค้า", field: "name", width: '50px'},
        { label: "รูปภาพ", field: "photoLink", width: '50px'},
        { label: "ลักษณะ1", field: "prop1Description", width: '50px'},
        { label: "ลักษณะ2", field: "prop2Description", width: '50px'},
        { label: "รหัสสินค้า", field: "productCode", width: '50px'},
        { label: "ราคาขาย", field: "unitPrice", width: '50px'},
        { label: "ราคาส่วนลด", field: "salePrice", width: '50px'},
        { label: "Barcode", field: "barcode", width: '50px'},
        { label: "Sku", field: "sku", width: '50px'},
        { label: "จำนวนสินค้าใน set", field: "totalItem", width: '50px'},
        { label: "ชื่อสินค้า", field: "item_Name", width: '50px'},
        { label: "Barcode", field: "item_Barcode", width: '50px'},
        { label: "Sku", field: "item_Sku", width: '50px'},
        { label: "รหัสสินค้า", field: "item_ProductCode", width: '50px'},
        { label: "จำนวนชิ้น", field: "item_Quantity", width: '50px'},
        { label: "ราคาขาย", field: "item_UnitPrice", width: '50px'},
        { label: "รูปภาพ", field: "item_PhotoLink", width: '50px'},
      ],

      // tab
      tab: 0,
      tab_list: [
        {"id":0, "name":"--ทั้งหมด--", "total": 0},
        {"id":1, "name":"สินค้าของฉัน", "total": 0},
        {"id":2, "name":"เช็ตสินค้า", "total": 0},
      ],
      ShopID: localStorage.getItem('shop_id'),
      // แสดงตาราง
      tableloading: false,
      total: 0,
      dataset: [],
      headermaster: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', icon: 0},
        { text: 'รหัส CF',align: 'center',sortable: false,value: 'cfCode', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode', icon: 0},
        { text: 'หมายเหตุ',align: 'center',sortable: false,value: 'remark', icon: 0},
        { text: 'เช็ตสินค้า',align: 'center',sortable: false,value: 'isProductSet', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'แก้ไข',align: 'center',sortable: false,value: 'edit', width: '60px', icon: 0},
        { text: 'ลบ',align: 'center',sortable: false,value: 'delete', width: '60px', icon: 0},
      ],

      ShopeePageLimits:10,
      ShopeePageTemp:1,
      ShopeeTabLoaded:false,
      ShopeePageLength:1,
      ShopeeCurrentPage:1,
      ShopeeSearchResultCount:0,
      ShopeeSearch:{
        name:'',
        sku:''
      },
      datasetShopee: [],
      headerTableShopee: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
      ],
     
      LazadaPageLimits:10,
      LazadaPageTemp:1,
      LazadaTabLoaded:false,
      LazadaPageLength:1,
      LazadaCurrentPage:1,
      LazadaSearchResultCount:0,
      LazadaSearch:{
        name:'',
        sku:''
      }, 
      datasetLazada: [],
      headerTableLazada: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
      ],

      TiktokPageLimits:10,
      TiktokPageTemp:1,
      TiktokTabLoaded:false,
      TiktokPageLength:1,
      TiktokCurrentPage:1,
      TiktokSearchResultCount:0,
      TiktokSearch:{
        name:'',
        sku:''
      }, 
      datasetTiktok: [],
      headerTableTiktok: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
      ],

      WooPageTemp:1,
      WooPageLimits:10,
      WooTabLoaded:false,
      WooPageLength:1,
      WooCurrentPage:1,
      WooSearchResultCount:0,
      WooSearch:{
        name:'',
        sku:''
      }, 
      datasetWoo: [],
      headerTableWoo: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
      ],

      LinePageTemp:1,
      LinePageLimits:10,
      LineTabLoaded:false,
      LinePageLength:1,
      LineCurrentPage:1,
      LineSearchResultCount:0,
      LineSearch:{
        name:'',
        sku:''
      }, 
      datasetLine: [],
      headerTableLine: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
      ],

      ShopifyPageTemp:1,
      ShopifyPageLimits:10,
      ShopifyTabLoaded:false,
      ShopifyPageLength:1,
      ShopifyCurrentPage:1,
      ShopifySearchResultCount:0,
      ShopifySearch:{
        name:'',
        sku:''
      }, 
      datasetShopify: [],
      headerTableShopify: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
      ],

      PlugoPageTemp:1,
      PlugoPageLimits:10,
      PlugoTabLoaded:false,
      PlugoPageLength:1,
      PlugoCurrentPage:1,
      PlugoSearchResultCount:0,
      PlugoSearch:{
        name:'',
        sku:''
      }, 
      datasetPlugo: [],
      headerTablePlugo: [
        { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
        { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
        { text: 'ผูกสินค้า',align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
      ],


      //authorize
      authorize:false,
      itemDataHistory:[],
      header_list:[                    
            { text: 'วันที่อัพเดท',   align: 'center',   value: 'updated', width: '40%', sortable: false },
            { text: 'จำนวนที่อัพเดท',   align: 'center',   value: 'quantity', width: '20%', sortable: false },
             { text: 'หมายเหตุ',   align: 'center',   value: 'error', width: '40%', sortable: false },
          ],

      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 10,
      offsets: 0,

      // Mapper popup
      popupnamesearch: null,
      mp: {
        dialog: false,
        MaxWidth: "50%",
        MaxHeight: 90,
        loading: false,
        header: [
          { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
          { text: 'Marketplace',align: 'center',sortable: false,value: 'marketplace', icon: 0, width: '80px'},
          { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
          { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
          { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
          { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
          { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
          { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
          { text: 'ผูกวันที่',align: 'left',sortable: false,value: 'mappedDate', icon: 0},
          { text: 'ลบ',align: 'center',sortable: false,value: 'delete', width: '60px', icon: 0},
          { text: 'ประวัติการอัพเดท',align: 'center',sortable: false,value: 'dialog', width: '100px', icon: 0},
        ],
        dataset: [],
        total: 0,
        name: null,
        prop1Description: null,
        prop2Description: null,
        sku: null,
        barcode: null,
        photoLink: null,
        updateHistory: {
          dialog: false,
        },
        popup: {
          mapper: true,
          dialog: false,
          tab: 0,
          tab_list: [
            {"id": 0, "name": "ช้อปปี้", "english": "Shopee", "total": 0, "logo": "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/shopee.png"},
            {"id": 1, "name": "ลาซาด้า", "english": "Lazada", "total": 0, "logo": "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/lazada.png"},
            {"id": 2, "name": "เจดีเซ็นทรัล", "english": "JD Central", "total": 0, "logo": "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/Shop/shop83202111121433360"},
            {"id": 3, "name": "วูคอมเมิร์ช", "english": "Woo Commerce", "total": 0, "logo": "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/woo.png"},
            {"id": 4, "name": "ติ๊กต๊อก", "english": "Tiktok", "total": 0, "logo": "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/TikTok-Logo-1.png"},
            {"id": 5, "name": "ไลน์มายช้อป", "english": "LineMyShop", "total": 0, "logo": "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/linemyshop.png"},
            {"id": 6, "name": "Shopify", "english": "Shopify", "total": 0, "logo": "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Screenshot+2024-03-09+004759.png"},
            {"id": 7, "name": "Plugo", "english": "Plugo", "total": 0, "logo": "https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/plugo_logo.png"},
          ],
          header: [
            { text: 'เลือก',align: 'center',sortable: false,value: 'selectProduct', icon: 0, width: '110px' },
            { text: 'ลำดับ',align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
            { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
            { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
            { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
            { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
            { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
            { text: 'ร้านค้า',align: 'left',sortable: false,value: 'shopName', icon: 0},
          ],
          
          dataset: [],
          datasetMaster: [],
          total: 0,
          selectProductList: [],
          productIdList: [],
          headerresult: [
            { text: 'สำเร็จ',align: 'center',sortable: false,value: 'success', icon: 0, width: '110px' },
            { text: 'Marketplace',align: 'center',sortable: false,value: 'marketplace', icon: 0, width: '80px'},
            { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
            { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', icon: 0},
            { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', icon: 0},
            { text: 'SKU',align: 'center',sortable: false,value: 'sku', icon: 0},
            { text: 'ราคา',align: 'center',sortable: false,value: 'salePrice', icon: 0},
            { text: 'รายละเอียด',align: 'center',sortable: false,value: 'detail', icon: 0},
          ],
          dataresult: [],


        

          // paginations
          page: 1,
          pageTemp: 1,
          pageLength: 1,
          pageTotalVisible: 9,
          limits: 10,
          offsets: 0,
        },
      },

      // ขนาดจอ
      size: 'lg',
      window: {
        width: 0,
        height: 0
      },
      //เลือกประเภท Excel
      ExcelType:"1",
      ChooseExcelType:false,
    }),
    watch: {
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },
    async created() {

      ///เก็บภาษามาไว้ใน use_language
      this.use_language = await this.get_languages(localStorage.getItem("set_language"));

      //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
      this.get_authorize_staffshop();

      //ไว้ดึง headerของ data tabel ทำเป็น 3 ภาษา
      this.getHeaderDatatable();


      window.addEventListener('resize', this.handleResize)
      this.handleResize();
      if (this.window.width<960){this.mp.MaxWidth='100%'}
      else{this.mp.MaxWidth='70%'}
      if (this.size=='xs' || this.size=='sm'){
        this.mp.MaxHeight = (90*this.window.height)/100
      }else{
        this.mp.MaxHeight = (this.mp.MaxHeight*this.window.height)/100
      }
      this.mp.popup.limits = parseInt(((((((this.mp.MaxHeight-57)-48)-8)-48)-50)-52)/90)
       
      this.LoadTabCount();
      this.Load_Data(this.limits,this.offsets,this.search,1 ) 
      this.page_loading = false


    },
    methods: {
      get_languages,
      async ChangeTab(tab){
        if(tab==1 && !this.ShopeeTabLoaded){
          this.ShopeeTabLoaded=true;
          this.ShopeeSearchStock(this.ShopeePageLimits,0, 1)
        }
        else if(tab==2 && !this.LazadaTabLoaded){
          this.LazadaTabLoaded=true;
          this.LazadaSearchStock(this.LazadaPageLimits,0, 1)
        }
        else if(tab==3 && !this.TiktokTabLoaded){
          this.TiktokTabLoaded=true;
          this.TiktokSearchStock(this.TiktokPageLimits,0, 1)
        }
        else if(tab==4 && !this.WooTabLoaded){
          this.WooTabLoaded=true;
          this.WooSearchStock(this.WooPageLimits,0, 1)
        }
        else if(tab==5 && !this.LineTabLoaded){
          this.LineTabLoaded=true;
          this.LineSearchStock(this.LineTPageLimits,0, 1)
        }
        else if(tab==6 && !this.ShopifyTabLoaded){
          this.ShopifyTabLoaded=true;
          this.ShopifySearchStock(this.ShopifyPageLimits,0, 1)
        }
        else if(tab==7 && !this.PlugoTabLoaded){
          this.PlugoTabLoaded=true;
          this.PlugoSearchStock(this.PlugoPageLimits,0, 1)
        }
        // this.reSetSort()
        // this.Load_Data(this.limits,0,this.search,1 )
        // this.Load_Count(this.limits,0,this.search,tab,false)
        // this.reSetIconHeader()
      },
      reSetSort(){
        this.search.orderbyName = 'id'
        this.search.isAscending = false
      },
      reSetIconHeader(){
        for (var i in this.header){
          this.header[i].icon = 0
        }
      },
      async LoadTabCount(){

        let response = await axios.get(stockService_dotnet+'Stock/get-product-list-tab-count?ShopID='+this.ShopID,   {headers: this.header_token})
      
        if (response.status==200 ) 
        {
          if (response.data.status=="success" ) 
          {
            this.tab_list = response.data.items;
          }
          else
          {
            Swal.fire({
              type: "error",
              text: response.data.error_description.message[this.language] 
            })
          }
        }
        else
        {
          Swal.fire({
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }
      },
      async SearchStock(){
        this.reSetSort()
        this.Load_Data(this.limits,0,this.search,1 )
         
        this.reSetIconHeader()
      },
      async ShopeePageLimitChange(){
        await this.ShopeeSearchStock(this.ShopeePageLimits, 0,  1);
      },
      async ShopeeChangePage(){
        if (this.ShopeePageLength!=1 && this.ShopeePageTemp!=this.ShopeeCurrentPage){
          var offsets = (this.ShopeeCurrentPage*this.ShopeePageLimits)-this.ShopeePageLimits
          this.ShopeeSearchStock(this.ShopeePageLimits,offsets, this.ShopeeCurrentPage )
          this.ShopeePageTemp = this.ShopeeCurrentPage
        }
      },
      async ShopeeSearchStock(limits,offsets, page ) {
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-marketplace', {
          "marketplaceID" : 1,
          "name":this.ShopeeSearch.name,
          "sku":this.ShopeeSearch.sku,
          "shopID":this.ShopID,
          "MarketplaceShopID":null,
          "skip":offsets,
          "Take":limits
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.ShopeeCurrentPage = page
          this.ShopeePageTemp = page 
          this.datasetShopee = []
          this.datasetShopee = response.data.items;
          this.ShopeeSearchResultCount = response.data.resultCount;
          this.ShopeePageLength = (Math.ceil(this.ShopeeSearchResultCount/limits))
          if (this.ShopeePageLength<=0){this.ShopeePageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }

      },
      async LazadaPageLimitChange(){
        await this.LazadaSearchStock(this.LazadaPageLimits, 0,  1);
      },
      async LazadaChangePage(){
        if (this.LazadaPageLength!=1 && this.LazadaPageTemp!=this.LazadaCurrentPage){
          var offsets = (this.LazadaCurrentPage*this.LazadaPageLimits)-this.LazadaPageLimits
          this.LazadaSearchStock(this.LazadaPageLimits,offsets, this.LazadaCurrentPage )
          this.LazadaPageTemp = this.LazadaCurrentPage
        }
      },
      async LazadaSearchStock(limits,offsets, page ) {
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-marketplace', {
          "marketplaceID" : 2,
          "name":this.LazadaSearch.name,
          "sku":this.LazadaSearch.sku,
          "shopID":this.ShopID,
          "MarketplaceShopID":null,
          "skip":offsets,
          "Take":limits
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.LazadaCurrentPage = page
          this.LazadaPageTemp = page 
          this.datasetLazada = []
          this.datasetLazada = response.data.items;
          this.LazadaSearchResultCount = response.data.resultCount;
          this.LazadaPageLength = (Math.ceil(this.LazadaSearchResultCount/limits))
          if (this.LazadaPageLength<=0){this.LazadaPageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }

      },
      async TiktokPageLimitChange(){
        await this.TiktokSearchStock(this.TiktokPageLimits, 0,  1);
      },
      async TiktokChangePage(){
        if (this.TiktokPageLength!=1 && this.TiktokPageTemp!=this.TiktokCurrentPage){
          var offsets = (this.TiktokCurrentPage*this.TiktokPageLimits)-this.TiktokPageLimits
          this.TiktokSearchStock(this.TiktokPageLimits,offsets, this.TiktokCurrentPage )
          this.TiktokPageTemp = this.TiktokCurrentPage
        }
      },
      async TiktokSearchStock(limits,offsets, page ) {
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-marketplace', {
          "marketplaceID" : 3,
          "name":this.TiktokSearch.name,
          "sku":this.TiktokSearch.sku,
          "shopID":this.ShopID,
          "MarketplaceShopID":null,
          "skip":offsets,
          "Take":limits
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.TiktokCurrentPage = page
          this.TiktokPageTemp = page 
          this.datasetTiktok = []
          this.datasetTiktok = response.data.items;
          this.TiktokSearchResultCount = response.data.resultCount;
          this.TiktokPageLength = (Math.ceil(this.TiktokSearchResultCount/limits))
          if (this.TiktokPageLength<=0){this.TiktokPageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }

      },
      async WooPageLimitChange(){
        await this.WooSearchStock(this.WooPageLimits, 0,  1);
      },
      async WooChangePage(){
        if (this.WooPageLength!=1 && this.WooPageTemp!=this.WooCurrentPage){
          var offsets = (this.WooCurrentPage*this.WooPageLimits)-this.WooPageLimits
          this.WooSearchStock(this.WooPageLimits,offsets, this.WooCurrentPage )
          this.WooPageTemp = this.WooCurrentPage
        }
      },
      async WooSearchStock(limits,offsets, page ) {
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-marketplace', {
          "marketplaceID" : 4,
          "name":this.WooSearch.name,
          "sku":this.WooSearch.sku,
          "shopID":this.ShopID,
          "MarketplaceShopID":null,
          "skip":offsets,
          "Take":limits
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.WooCurrentPage = page
          this.WooPageTemp = page 
          this.datasetWoo = []
          this.datasetWoo = response.data.items;
          this.WooSearchResultCount = response.data.resultCount;
          this.WooPageLength = (Math.ceil(this.WooSearchResultCount/limits))
          if (this.WooPageLength<=0){this.WooPageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }

      },
      async LinePageLimitChange(){
        await this.LineSearchStock(this.LinePageLimits, 0,  1);
      },
      async LineChangePage(){
        if (this.LinePageLength!=1 && this.LinePageTemp!=this.LineCurrentPage){
          var offsets = (this.LineCurrentPage*this.LinePageLimits)-this.LinePageLimits
          this.LineSearchStock(this.LinePageLimits,offsets, this.LineCurrentPage )
          this.LinePageTemp = this.LineCurrentPage
        }
      },
      async LineSearchStock(limits,offsets, page ) {
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-marketplace', {
          "marketplaceID" : 5,
          "name":this.LineSearch.name,
          "sku":this.LineSearch.sku,
          "shopID":this.ShopID,
          "MarketplaceShopID":null,
          "skip":offsets,
          "Take":limits
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.LineCurrentPage = page
          this.LinePageTemp = page 
          this.datasetLine = []
          this.datasetLine = response.data.items;
          this.LineSearchResultCount = response.data.resultCount;
          this.LinePageLength = (Math.ceil(this.LineSearchResultCount/limits))
          if (this.LinePageLength<=0){this.LinePageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }

      },
      async ShopifyPageLimitChange(){
        await this.ShopifySearchStock(this.ShopifyPageLimits, 0,  1);
      },
      async ShopifyChangePage(){
        if (this.ShopifyPageLength!=1 && this.ShopifyPageTemp!=this.ShopifyCurrentPage){
          var offsets = (this.ShopifyCurrentPage*this.ShopifyPageLimits)-this.ShopifyPageLimits
          this.ShopifySearchStock(this.ShopifyPageLimits,offsets, this.ShopifyCurrentPage )
          this.ShopifyPageTemp = this.ShopifyCurrentPage
        }
      },
      async ShopifySearchStock(limits,offsets, page ) {
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-marketplace', {
          "marketplaceID" : 6,
          "name":this.ShopifySearch.name,
          "sku":this.ShopifySearch.sku,
          "shopID":this.ShopID,
          "MarketplaceShopID":null,
          "skip":offsets,
          "Take":limits
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.ShopifyCurrentPage = page
          this.ShopifyPageTemp = page 
          this.datasetShopify = []
          this.datasetShopify = response.data.items;
          this.ShopifySearchResultCount = response.data.resultCount;
          this.ShopifyPageLength = (Math.ceil(this.ShopifySearchResultCount/limits))
          if (this.ShopifyPageLength<=0){this.ShopifyPageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }

      },

      async PlugoPageLimitChange(){
        await this.PlugoSearchStock(this.PlugoPageLimits, 0,  1);
      },
      async PlugoChangePage(){
        if (this.PlugoPageLength!=1 && this.PlugoPageTemp!=this.PlugoCurrentPage){
          var offsets = (this.PlugoCurrentPage*this.PlugoPageLimits)-this.PlugoPageLimits
          this.PlugoSearchStock(this.PlugoPageLimits,offsets, this.PlugoCurrentPage )
          this.PlugoPageTemp = this.PlugoCurrentPage
        }
      },
      async PlugoSearchStock(limits,offsets, page ) {
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-marketplace', {
          "marketplaceID" : 7,
          "name":this.PlugoSearch.name,
          "sku":this.PlugoSearch.sku,
          "shopID":this.ShopID,
          "MarketplaceShopID":null,
          "skip":offsets,
          "Take":limits
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.PlugoCurrentPage = page
          this.PlugoPageTemp = page 
          this.datasetPlugo = []
          this.datasetPlugo = response.data.items;
          this.PlugoSearchResultCount = response.data.resultCount;
          this.PlugoPageLength = (Math.ceil(this.PlugoSearchResultCount/limits))
          if (this.PlugoPageLength<=0){this.PlugoPageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }

      },


      async Load_Data(limits,offsets,search,page ) {
        search.isProductSet = search.isProductSetTemp.id
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["brand"] = search.brand
        searchTemp["isProductSet"] = search.isProductSet==2 ? null : search.isProductSet
        searchTemp["orderbyName"] = search.orderbyName
        searchTemp["isAscending"] = search.isAscending
     
        this.loading = this.tableloading ? false : true
        let response = await axios.post(stockService_dotnet+'Stock/get-product-list-v2', {
          "shopId": this.ShopID,
          "keyword": searchTemp,
          "skip": offsets,
          "take": limits,
          "isNeedProductCount":true
        }, {headers: this.header_token})
        this.tableloading = false
        if (response.status==200) {
          this.loading = false
           
          this.page = page
          this.pageTemp = page
          this.offsets = offsets
          this.dataset = []
          this.dataset = response.data.resultData;
          this.MyProductSearchResultCount = response.data.resultCount;
          this.pageLength = (Math.ceil(this.MyProductSearchResultCount/this.limits))
          if (this.pageLength<=0){this.pageLength=1}

        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }
      },
      async Load_Count(limits,offsets,search,tab,isNeedWarehouseCout) {
        search.isProductSet = search.isProductSetTemp.id
        var searchTemp = {}
        searchTemp["name"] = search.name
        searchTemp["productCode"] = search.productCode
        searchTemp["barcode"] = search.barcode
        searchTemp["sku"] = search.sku
        searchTemp["brand"] = search.brand
        searchTemp["isProductSet"] = search.isProductSet==2 ? null : search.isProductSet
        let response = await axios.post(stockService_dotnet+'Stock/get-stock-productMasterItem-count', {
          "shopId": localStorage.getItem('shop_id'),
          "keyword": searchTemp,
          "skip": offsets,
          "take": limits,
          "isNeedWarehouseCout": isNeedWarehouseCout
        }, {headers: this.header_token})
        if (response.status==200) {
          if ((search.isProductSet==1 && tab==1) || (search.isProductSet==0 && tab==2)){
            response.data.resultCount = 0
            this.tab_list[tab].total = 0
          }
          this.total = response.data.resultCount
          this.tab_list[tab].total = response.data.resultCount
          if (isNeedWarehouseCout){
            for (var i in response.data.productCount){
              if (response.data.productCount[i].id==0){
                this.tab_list[1].total = response.data.productCount[i].totalProductCount
              }
              else if (response.data.productCount[i].id==1){
                this.tab_list[2].total = response.data.productCount[i].totalProductCount
              }
            }
            this.tab_list[0].total = response.data.resultCount
          }
          this.pageLength = (Math.ceil(response.data.resultCount/this.limits))
          if (this.pageLength<=0){this.pageLength=1}
        } else {
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }
      },
      openExportExceLChoose(){
        this.ChooseExcelType=true;
      },
      async ProcessExportExcel(){
      this.ChooseExcelType=false;
      if(this.ExcelType=="1"){
       this.ExportExcel();  
      }
      else if(this.ExcelType=="2")
      {
        this.ExportExcelProductMappingListExcel();
      }
      else if(this.ExcelType=="3")
      {
        this.ExportExcelProductSetMappingListExcel();
      }
    },
      async ExportExcel(){
        this.datasetExcel = []
        this.loadingExcel = 0
       
        var excelLimits = 200
        var offsets = 0
        this.loadingExcel = 1
        

        var dataResponse = await this.GetExportExcelProduct(excelLimits,offsets,true)
        var counter = 1 ;
        console.log("GetExportExcelProduct",dataResponse);
        for (var j in dataResponse.resultData){
          console.log("GetExportExcelProductresultData",dataResponse);
          dataResponse.resultData[j].no =counter
          this.datasetExcel.push(dataResponse.resultData[j])
          counter++
        }
        var total = dataResponse.resultCount;
        var round = parseInt( total/excelLimits)

        if ( excelLimits * round < total ){
          round = round+1
        }
        for (var i = 2; i <= round; i++) {
          offsets = offsets+ excelLimits
          var data = await this.GetExportExcelProduct(excelLimits,offsets)
          for (var k in data.resultData){
            data.resultData[k].no =counter
            this.datasetExcel.push(data.resultData[k])
            counter++
          }
          this.loadingExcel = (this.datasetExcel.length * 100 ) / total
          if(round < 100 ){
            this.loadingExcel = parseInt(this.loadingExcel)
          } else {
            this.loadingExcel = this.loadingExcel.toFixed(1)
          }
          
        }

      

        this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100
        document.getElementById("GetExcel").click();
          
      },
      async ExportExcelProductMappingListExcel(){
        this.datasetExcel = []
        this.loadingExcel = 0
       
        var excelLimits = 200
        var offsets = 0
        this.loadingExcel = 1
        

        var dataResponse = await this.GetProductMappingListExcel(excelLimits,offsets,true)
        var counter = 1;
        for (var item of dataResponse.items){


          if(item.isMasterRow == true){
            item.no = counter
            counter++;
          }
          this.datasetExcel.push(item)
            
        }
        var total = dataResponse.resultCount;
        var round = parseInt ( total/ excelLimits)

        if ( excelLimits * round < total ){
          round = round + 1
        }
        for (var i = 2; i <= round; i++) {
          offsets = offsets + excelLimits
          var data = await this.GetProductMappingListExcel(excelLimits,offsets)
          for (var obj of data.items){
            if(obj.isMasterRow  == true){
              obj.no = counter
              counter++
            }
            this.datasetExcel.push(obj)
          }
          this.loadingExcel = (this.datasetExcel.length * 100 ) / total
          if(round < 100 ){
            this.loadingExcel = parseInt(this.loadingExcel)
          } else {
            this.loadingExcel = this.loadingExcel.toFixed(1)
          }
          
        }


        this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100
        document.getElementById("GetExcelProductMapping").click();
         
      },
      async ExportExcelProductSetMappingListExcel(){
        this.datasetExcel = []
        this.loadingExcel = 0
        
        var excelLimits = 200
        var offsets = 0
        this.loadingExcel = 1
        

        var dataResponse = await this.GetProductSetMappingListExcel(excelLimits,offsets,true)
        var counter=1;
        for (var item of dataResponse.items){
          if(item.isMasterRow == true){
            item.no = counter
            counter++;
          }
          this.datasetExcel.push(item)
        }
        var total = dataResponse.resultCount;
        var round = parseInt( total/excelLimits)
    
        if ( excelLimits * round < total ){
          round = round + 1
        }
        for (var i = 2; i <= round; i++) {
          offsets = offsets+ excelLimits
          var data = await this.GetProductSetMappingListExcel(excelLimits,offsets)
          for (var obj of data.items){
            if(obj.isMasterRow  == true){
              obj.no = counter
              counter++
            } 
            this.datasetExcel.push(obj)
          }
          this.loadingExcel = (this.datasetExcel.length * 100 ) / total
          if(round < 100 ){
            this.loadingExcel = parseInt(this.loadingExcel)
          } else {
            this.loadingExcel = this.loadingExcel.toFixed(1)
          }
          
        }

      

        this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100
        document.getElementById("GetExcelProductSetMapping").click();
         
      },
      async GetExportExcelProduct(limits,offsets,IsNeedProductCount){
        try {
          var search = this.search
          search.isProductSet = search.isProductSetTemp.id
          var searchTemp = {}
          searchTemp["name"] = search.name
          searchTemp["productCode"] = search.productCode
          searchTemp["barcode"] = search.barcode
          searchTemp["sku"] = search.sku
          searchTemp["brand"] = search.brand
          searchTemp["isProductSet"] = search.isProductSet==2 ? null : search.isProductSet
          searchTemp["orderbyName"] = search.orderbyName
          searchTemp["isAscending"] = search.isAscending
          let response = await axios.post(stockService_dotnet+'Stock/get-product-list-v2', {
            "shopId": this.ShopID,
            "keyword": searchTemp,
            "skip": offsets,
            "take": limits,
            "IsNeedProductCount":IsNeedProductCount
          }, {headers: this.header_token})
          if (response.status == 200){
            return response.data
          }else{
            return []
          }
        } catch (e) {
          this.$swal({
            position: "top",
            type: "error",
            title: "เกิดข้อผิดพลาด !!",
            text: e.message,
          })
        }
      },
      async GetProductMappingListExcel(limits,offsets,IsNeedProductCount){
        try {
          var search = this.search
          search.isProductSet = search.isProductSetTemp.id
          var searchTemp = {}
          searchTemp["name"] = search.name
          searchTemp["productCode"] = search.productCode
          searchTemp["barcode"] = search.barcode
          searchTemp["sku"] = search.sku
          searchTemp["brand"] = search.brand
          searchTemp["isProductSet"] = search.isProductSet==2 ? null : search.isProductSet
          searchTemp["orderbyName"] = search.orderbyName
          searchTemp["isAscending"] = search.isAscending
          let response = await axios.post(stockService_dotnet+'Stock/get-product-mapping-list', {
            "shopId": this.ShopID,
            "keyword": searchTemp,
            "skip": offsets,
            "take": limits,
            "IsNeedProductCount":IsNeedProductCount
          }, {headers: this.header_token})
          if (response.status == 200){
            return response.data
          }else{
            return []
          }
        } catch (e) {
          this.$swal({
            position: "top",
            type: "error",
            title: "เกิดข้อผิดพลาด !!",
            text: e.message,
          })
        }
      },
      async GetProductSetMappingListExcel(limits,offsets,IsNeedProductCount){
        try {
          var search = this.search
          search.isProductSet = search.isProductSetTemp.id
          var searchTemp = {}
          searchTemp["name"] = search.name
          searchTemp["productCode"] = search.productCode
          searchTemp["barcode"] = search.barcode
          searchTemp["sku"] = search.sku
          searchTemp["brand"] = search.brand
          searchTemp["isProductSet"] = search.isProductSet==2 ? null : search.isProductSet
          searchTemp["orderbyName"] = search.orderbyName
          searchTemp["isAscending"] = search.isAscending
          let response = await axios.post(stockService_dotnet+'Stock/get-product-set-mapping-list', {
            "shopId": this.ShopID,
            "keyword": searchTemp,
            "skip": offsets,
            "take": limits,
            "IsNeedProductCount":IsNeedProductCount
          }, {headers: this.header_token})
          if (response.status == 200){
            return response.data
          }else{
            return []
          }
        } catch (e) {
          this.$swal({
            position: "top",
            type: "error",
            title: "เกิดข้อผิดพลาด !!",
            text: e.message,
          })
        }
      },
      UpdateStock(item){
        var data = {
          "productMasterId": null,
          "productMasterItemId": null,
          "isProductSet": 0,
          "part_return": "/my-product",
        }
        if (item!=null){
          data.productMasterId = item.productMasterId
          data.productMasterItemId = item.id
          data.isProductSet = item.isProductSet ? 1 : 0
          localStorage.setItem('my-product-search', JSON.stringify(this.search))
          localStorage.setItem('my-product-limits', this.limits)
          localStorage.setItem('my-product-offsets', this.offsets)
          localStorage.setItem('my-product-page', this.page)
          localStorage.setItem('my-product-tab', this.tab)
        } else {
          localStorage.removeItem("my-product-search");
          localStorage.removeItem("my-product-limits");
          localStorage.removeItem("my-product-offsets");
          localStorage.removeItem("my-product-page");
          localStorage.removeItem("my-product-tab");
        }
        localStorage.setItem('data', JSON.stringify(data))
        window.open("/my-product/update");
        // this.$router.push("/my-product/update")
      },
      async LimitChange(){
        await this.Load_Data(this.limits, 0, this.search, 1);
      },
      async changePage() {
        if (this.pageLength!=1 && this.pageTemp!=this.page){
          var offsets = (this.page*this.limits)-this.limits
          this.Load_Data(this.limits,offsets,this.search,this.page )
          this.pageTemp = this.page
        }
      },
      async OrderByCollum(index){
        this.tableloading = true
        this.search.orderbyName = this.header[index].value
        this.search.isAscending = this.header[index].icon==0 ? false : (this.header[index].icon==1 ? true : false)
        for (var i in this.header) {
          if (!(parseInt(index) == parseInt(i))){
            this.header[i].icon = 0
          }
        }
        this.header[index].icon = this.header[index].icon==0 ? 1 : (this.header[index].icon==1 ? 2 : 1)
        await this.Load_Data(this.limits,this.offsets,this.search,this.page )
        this.tableloading = false
      },
      async DeleteStock(item){
        Swal.fire({
          position: "top",
          type: 'warning',
          title: this.use_language.warning_yes_no,
          text: item.name +' '+ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : ""),
          confirmButtonText: this.use_language.confirm,
          cancelButtonText: this.use_language.cancel,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = null
            if (item.isProductSet){
              response = await axios.post(stockService_dotnet+'Stock/delete-productset', {
                "productMasterItemId": item.id,
              }, {headers: this.header_token})
            }else{
              response = await axios.post(stockService_dotnet+'Stock/delete-stock', {
                "productMasterItemId": item.id,
              }, {headers: this.header_token})
            }
            if (response.status==200 || response.status==204) {
              if (item.isProductSet){
                if (response.data.status=="success"){
                  await this.SearchStock()
                  this.loading = false
                }else{
                  this.loading = false
                  Swal.fire({
                    position: "top",
                    type: "error",
                    text: response.data.message[this.language],
                  })
                }

              }else{
                if (response.data.status=="success"){
                  await this.SearchStock()
                  this.loading = false
                  Swal.fire({
                    toast: true,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'top',
                    type: 'success',
                    title: 'Success'
                  })
                }else{
                  this.loading = false
                  Swal.fire({
                    position: "top",
                    type: "error",
                    text: response.data.message[this.language],
                  })
                }
              }
            }else{
              this.loading = false
              Swal.fire({
                position: "top",
                type: "error",
                text: this.use_language.warnin_can_not_transaction,
              })
            }
          }
        })
      },

      // Mapper เปิด
      async OPMapper(item){


        this.mp.productMasterItemId = item.id
        this.mp.name = item.name
        this.mp.prop1Description = item.prop1Description
        this.mp.prop2Description = item.prop2Description
        this.mp.sku = item.sku
        this.mp.barcode = item.barcode
        this.mp.photoLink = item.photoLink
        this.mp.loading = true
        this.mp.total = 0
        this.mp.dataset = []
        this.mp.dialog = true
        await this.getMapperMarketplace(item.id)
      },
      async OpenMapperFromMarketplace(item){
        let response = await axios.post(stockService_dotnet+'Stock/get-myproduct', {
            "shopId": localStorage.getItem('shop_id'),
            "productMasterItemId": item.productMasterItemID,
          }, {headers: this.header_token})
        

        this.mp.productMasterItemId = item.productMasterItemID
        this.mp.name = response.data.name;
        this.mp.prop1Description = response.data.prop1Description
        this.mp.prop2Description = response.data.prop2Description
        this.mp.sku = response.data.sku
        this.mp.barcode = response.data.barcode
        this.mp.photoLink = response.data.photoLink
        this.mp.loading = true
        this.mp.total = 0
        this.mp.dataset = []
        this.mp.dialog = true
        await this.getMapperMarketplace(item.productMasterItemID)
      },
      async HistoryMapper(item){
        this.mp.updateHistory.dialog = true
        if( item.updateHistory == null)
        {
          this.itemDataHistory = []
          return
        }
        for(var i in item.updateHistory)
        {
          this.itemDataHistory = item.updateHistory
        }
      },
      async OPPopup(mapper){
        this.mp.popup.mapper = mapper
        this.popupnamesearch = null
        this.mp.popup.selectProductList = []
        this.mp.popup.datasetMaster = []
        this.mp.popup.dataresult = []
        if(this.mp.total>0 && this.mp.popup.mapper){
          var data = this.mp.dataset
          for(var i of data){
            this.mp.popup.selectProductList.push(i)
          }
        }
        for (var j in this.mp.popup.tab_list){this.mp.popup.tab_list[j].total = 0}
        await this.getProductMarketplace(this.mp.popup.limits,0,1,null,0,false)
        
        this.mp.popup.dialog = true
        this.CreatingProductPercent =''
      },
      PopupsearchStock(){
        if (this.popupnamesearch!=null){
          this.popupnamesearch = this.popupnamesearch.trim()
          if (this.popupnamesearch.length>0){
            this.getProductMarketplace(this.mp.popup.limits,0,1,this.popupnamesearch,this.mp.popup.tab,false)
          }else{
            this.getProductMarketplace(this.mp.popup.limits,0,1,null,this.mp.popup.tab,false)
          }
        }
      },
      async getProductMarketplace(limits,offsets,page,namesearch,tab,changepage){
        let response = await axios.post(stockService_dotnet+'Stock/get-product-marketplace', {
          "shopId": localStorage.getItem('shop_id'),
          "marketplaceId": changepage ? tab : -1,
          "name": namesearch,
          "isCreateProductMarketplace": !this.mp.popup.mapper,
          "skip": offsets,
          "take": limits
        }, {headers: this.header_token})
        // this.loading = false
        if (response.status==200) {
          this.mp.popup.tab = tab
          this.mp.popup.page = page
          this.mp.popup.pageTemp = page
          this.mp.popup.offsets = offsets

          if (!changepage){
            if (tab==0){ // shopee
              this.mp.popup.total = response.data.resultCountShopee
            } else if (tab==1){ // lazada
              this.mp.popup.total = response.data.resultCountLazada
            } else if (tab==2){ // jd
              this.mp.popup.total = response.data.resultCountJD
            } else if (tab==3){ // woo
              this.mp.popup.total = response.data.resultCountWoo
            } else if (tab==4){ // tiktok
              this.mp.popup.total = response.data.resultCountTiktok
            } else if (tab==5){ // line my shop
              this.mp.popup.total = response.data.resultCountLine
            }
            else if (tab==6){ // line my shop
              this.mp.popup.total = response.data.resultCountShopify
            }
            else if (tab==7){ // line my shop
              this.mp.popup.total = response.data.resultCountPlugo
            }

            this.mp.popup.pageLength = (Math.ceil(this.mp.popup.total/limits))
            if (this.mp.popup.pageLength<=0){this.mp.popup.pageLength=1}

            for (var j in this.mp.popup.tab_list){
              if (this.mp.popup.tab_list[j].id==0){ // shopee
                this.mp.popup.tab_list[j].total = response.data.resultCountShopee
              } else if (this.mp.popup.tab_list[j].id==1){ // lazada
                this.mp.popup.tab_list[j].total = response.data.resultCountLazada
              } else if (this.mp.popup.tab_list[j].id==2){ // jd
                this.mp.popup.tab_list[j].total = response.data.resultCountJD
              } else if (this.mp.popup.tab_list[j].id==3){ // woo
                this.mp.popup.tab_list[j].total = response.data.resultCountWoo
              } else if (this.mp.popup.tab_list[j].id==4){ // tiktok
                this.mp.popup.tab_list[j].total = response.data.resultCountTiktok
              } else if (this.mp.popup.tab_list[j].id==5){ // line my shop
                this.mp.popup.tab_list[j].total = response.data.resultCountLine
              }
              else if (this.mp.popup.tab_list[j].id==6){ // shopify
                this.mp.popup.tab_list[j].total = response.data.resultCountShopify
              }
              else if (this.mp.popup.tab_list[j].id==7){ // shopify
                this.mp.popup.tab_list[j].total = response.data.resultCountPlugo
              }
            }
          }

          this.mp.popup.dataset = []
          var data = response.data.resultData
          for (var i in data){
            data[i].selectProduct = this.SetUp_Product(data[i].id,tab)
            data[i].page = page
          }

          if (changepage){
            var datatemp = this.mp.popup.datasetMaster.filter(x => x.marketPlaceId != tab)
            for (var k in data){
              datatemp.splice(0, 0, data[k])
            }
            this.mp.popup.datasetMaster = datatemp
          } else {
            this.mp.popup.datasetMaster = data
          }
          this.mp.popup.dataset = this.mp.popup.datasetMaster.filter(x => x.marketPlaceId == tab)
        } else {
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }
      },
      PopupChangeTab(tab) {
        this.mp.popup.total = this.mp.popup.tab_list.filter(x => x.id == tab)[0].total
        var data = this.mp.popup.datasetMaster.filter(x => x.marketPlaceId == tab)
        for (var i in data){
          data[i].selectProduct = this.SetUp_Product(data[i].id,tab)
        }
        this.mp.popup.dataset = data

        if (this.mp.popup.dataset.length > 0){
          var page = this.mp.popup.dataset[0].page
          this.mp.popup.page = page
          this.mp.popup.pageTemp = page
          this.mp.popup.offsets = (page * this.mp.popup.limits) - this.mp.popup.limits

          this.mp.popup.pageLength = (Math.ceil(this.mp.popup.total/this.mp.popup.limits))
          if (this.mp.popup.pageLength<=0){this.mp.popup.pageLength=1}
        }
      },
      async PopupChangePage() {
        if (this.mp.popup.pageLength!=1 && this.mp.popup.pageTemp!=this.mp.popup.page){
          if (this.popupnamesearch!=null){this.popupnamesearch = this.popupnamesearch.trim()}
          var offsets = (this.mp.popup.page*this.mp.popup.limits)-this.mp.popup.limits
          this.getProductMarketplace(this.mp.popup.limits,offsets,this.mp.popup.page,this.popupnamesearch,this.mp.popup.tab,true)
          this.mp.popup.pageTemp = this.mp.popup.page
        }
      },
      SetUp_Product(id,tab){
        var data = this.mp.popup.selectProductList
        for (var i in data){
          if (data[i].id == id && data[i].marketPlaceId == tab){
            return true
          }
        }
        return false
      },
      ProductSelectCheckbox(item,index){
        item.selectProduct = !item.selectProduct
        if (item.selectProduct){ //นำเข้า selectProductList
          this.mp.popup.selectProductList.splice(0, 0, item)
    
        }else{ //นำออก selectProductList
          var data = this.mp.popup.selectProductList
          for (var i in data){
            if (data[i].id == item.id && data[i].marketPlaceId == this.mp.popup.tab){
              this.mp.popup.selectProductList.splice(i,1)
              break
            }
          }
        }
      },
      GroupProductIdMarketplace(){
        var marketplaceProductIdList = {
          shopeeProductIdList: [],
          lazadaProductIdList: [],
          jdProductIdList: [],
          wooProductIdList: [],
          tiktokProductIdList: [],
          lineProductIdList: [],
          shopifyProductIdList:[],
          plugoProductIdList:[],
        }
        for (var i of this.mp.popup.selectProductList){
          // shopee
          if(i.marketPlaceId==0)
            marketplaceProductIdList.shopeeProductIdList.push(i.id)

          // lazada
          else if (i.marketPlaceId==1)
            marketplaceProductIdList.lazadaProductIdList.push(i.id)

          // jd
          else if (i.marketPlaceId==2)
            marketplaceProductIdList.jdProductIdList.push(i.id)

          // woo
          else if (i.marketPlaceId==3)
            marketplaceProductIdList.wooProductIdList.push(i.id)

          // tiktok
          else if (i.marketPlaceId==4)
            marketplaceProductIdList.tiktokProductIdList.push(i.id)

          // liline my shop
          else if (i.marketPlaceId==5)
            marketplaceProductIdList.lineProductIdList.push(i.id)

          else if (i.marketPlaceId==6)
            marketplaceProductIdList.shopifyProductIdList.push(i.id)

          else if (i.marketPlaceId==7)
            marketplaceProductIdList.plugoProductIdList.push(i.id)
        }
        return marketplaceProductIdList
      },
      async MarketplaceUpdate(mapper){
      
        if (mapper){
          this.loading = true
          var marketplaceProductIdList = await this.GroupProductIdMarketplace()
          this.UpdateMapper(marketplaceProductIdList)
        }
        else
          this.CreateProductMarketplace()
      },
      async UpdateMapper(marketplaceProductIdList){
        let response = await axios.post(stockService_dotnet+'Stock/update-mapper-marketplace', {
          "shopId": localStorage.getItem('shop_id'),
          "productMasterItemId": this.mp.productMasterItemId,
          "marketplaceProductIdList": marketplaceProductIdList,
          "staffShopID": this.staffBranchID? parseInt(this.staffShopID):null,
          "staffBranchID":this.staffBranchID? null:parseInt(this.staffBranchID) ,
        }, {headers: this.header_token})
        if (response.status==200) {
          await this.getMapperMarketplace(this.mp.productMasterItemId)
          this.loading = false
          this.mp.popup.dialog = false
          Swal.fire({
            toast: true,
            timer: 1500,
            showConfirmButton: false,
            position: 'top',
            type: 'success',
            title: 'Success'
          })
        } else {
          this.loading = false
          this.$swal({
            position: "top",
            type: "error",
            text: this.use_language.warnin_can_not_transaction,
          })
        }
      },
      async CreateProductMarketplace(){
        this.CreatingProductPercent =''
        let CurrentProcess=0;
        let TotalProcess = this.mp.popup.selectProductList.length
        let ResponseResult =[]
        let isCrateSuccess=true
        let error_description=''
        loop1:
        for (let product of this.mp.popup.selectProductList){
           
       
          if(this.mp.popup.dialog){
            let isPass=false

            while(!isPass){
              let response = await axios.post(stockService_dotnet+'Stock/create-product-marketplace', { 
                "id": product.id,
                "shopId": product.shopId,
                "marketPlaceId": product.marketPlaceId,
                "logo": product.logo,
                "itemId": product.itemId,
                "skuId": product.skuId,
                "shopIdMarketplace": product.shopIdMarketplace,
                "name":product.name,
                "prop1Name":product.prop1Name,
                "prop1Description": product.prop1Description,
                "prop2Name":product.prop2Name,
                "prop2Description": product.prop2Description,
                "sku": product.sku,
                "photoLink": product.photoLink,
                "shopName":product.shopName,
                "unitPrice": product.unitPrice,
                "salePrice": product.salePrice,

              }, {headers: this.header_token})
              if (response.status==200 ) {
                if(response.data.status=="success"){
                  
                  ResponseResult.push(response.data.data)
                  CurrentProcess++
                  this.CreatingProductPercent = (parseInt(parseFloat(CurrentProcess)/parseFloat(TotalProcess)*parseFloat(100))).toString()+' %'
                  isPass=true
                }else{
                  if(!response.data.isNetworkError){
                    isCrateSuccess=false
                    error_description = response.data.error_description
                    break loop1
                  }
                }
              }else{
                Swal.fire({
                  showConfirmButton: true,
                  type: "error",
                  title: "เกิดข้อผิดพลาดบางประการ",
                  text:"กรุณาติดต่อผู้ดูแลระบบ !!",
                })
                isCrateSuccess=false
                break loop1
              }
            }
          }else{
            isCrateSuccess=false
            break loop1
          }
        }

        if(!isCrateSuccess){
          Swal.fire({
            showConfirmButton: true,
            type: "error",
            title: "เกิดข้อผิดพลาด !!",
            text:error_description,
          })
          return
        } 

        this.mp.popup.dataresult = ResponseResult
        this.SearchStock()
          
        Swal.fire({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: 'top',
          icon: 'success',
          title: 'สร้างสินค้าสำเร็จ'
        })

       
      },
      async getMapperMarketplace(productMasterItemId){
        try {
          let response = await axios.post(stockService_dotnet+'Stock/get-mapper-marketplace', {
            "shopId": localStorage.getItem('shop_id'),
            "productMasterItemId": productMasterItemId,
          }, {headers: this.header_token})
          this.mp.loading = false
          this.mp.total = response.data.resultCount
          this.mp.dataset = response.data.resultData
        } catch (e) {
          this.$swal({
            position: "top",
            type: "error",
            title: "เกิดข้อผิดพลาด !!",
            text: e.message,
          })
        }

      },
      async DeleteMarketplace(item){
        Swal.fire({
          position: "top",
          type: 'warning',
          title: this.use_language.warning_yes_no,
          text: item.name,
          confirmButtonText: this.use_language.confirm,
          cancelButtonText: this.use_language.cancel,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = await axios.post(stockService_dotnet+'Stock/delete-mapper-marketplace', {
              "productMasterItemId": this.mp.productMasterItemId,
              "marketplaceId": item.marketPlaceId,
              "id": item.id,
            }, {headers: this.header_token})
            if (response.status==200) {
              await this.getMapperMarketplace(this.mp.productMasterItemId)
              this.loading = false
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: 'top',
                type: 'success',
                title: 'Success'
              })
            }else{
              this.loading = false
              Swal.fire({
                position: "top",
                type: "error",
                text: this.use_language.warnin_can_not_transaction,
              })
            }
           }
        })
      },


      // Mapper ปิด

      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      handleResize() {
        this.window.width = window.innerWidth
        this.window.height = window.innerHeight
        if (this.window.width < 600) {
          this.size = 'xs'
        } else if (this.window.width >= 600 && this.window.width < 960) {
          this.size = 'sm'
        } else if (this.window.width >= 960 && this.window.width < 1264) {
          this.size = 'md'
        } else if (this.window.width >= 1264 && this.window.width < 1904) {
          this.size = 'lg'
        } else if (this.window.width >= 1904) {
          this.size = 'xl'
        }
      },
      async getHeaderDatatable(){

          this.search = {

        "name": null,
        "productCode": null,
        "barcode": null,
        "sku": null,
        "isProductSet": 2,

        "isProductSetTemp": {"id":2, "name":this.use_language.all},
        "isProductSetList": [
          {"id":2, "name":this.use_language.all},
          {"id":0, "name":"สินค้าทั่วไป"},
          {"id":1, "name":"สินค้าจัดเซ็ต"}
 
        ],

        orderbyName: 'id',
        isAscending: false,
        }


         this.tab_list = [
        {"id":0, "name":this.use_language.all, "total": 0},
        {"id":1, "name": this.use_language.my_product, "total": 0},
        {"id":2, "name":this.use_language.check_product, "total": 0},
                         ]

         this.header =  [
          { text: this.use_language.number,align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
          { text: this.use_language.marketplace,align: 'center',sortable: false,value: 'marketplace', icon: 0, width: '80px'},
          { text: this.use_language.picture,align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
          { text: this.use_language.name,align: 'left',sortable: false,value: 'name', icon: 0},
          { text: this.use_language.type,align: 'center',sortable: false,value: 'prop1', icon: 0},
          { text: this.use_language.sku,align: 'center',sortable: false,value: 'sku', icon: 0},
          { text: this.use_language.shop,align: 'left',sortable: false,value: 'shopName', icon: 0},
         
          { text: this.use_language.delete,align: 'center',sortable: false,value: 'delete', width: '60px', icon: 0},
      ]


            this.headermaster = [
        { text: this.use_language.tb_no,align: 'center',sortable: false,value: 'no', icon: 0, width: '60px' },
        { text: this.use_language.tb_image,align: 'center',sortable: false,value: 'photoLink', icon: 0, width: '80px'},
        { text:  this.use_language.info,align: 'left',sortable: false,value: 'name', icon: 0},
        { text: this.use_language.sku,align: 'center',sortable: false,value: 'sku',width: '200px', icon: 0},
        { text:  this.use_language.selling_price,align: 'center',sortable: false,value: 'unitPrice',width: '90px', icon: 0},
        // { text: this.use_language.type ,align: 'center',sortable: false,value: 'prop1', icon: 0},
        // { text: this.use_language.number_product,align: 'center',sortable: false,value: 'productCode', icon: 0},
        // { text: this.use_language.code_cf,align: 'center',sortable: false,value: 'cfCode', icon: 0},
        
        // { text: this.use_language.barcode,align: 'center',sortable: false,value: 'barcode', icon: 0},
        // { text: this.use_language.tb_description,align: 'center',sortable: false,value: 'remark', icon: 0},
        // { text: this.use_language.check_product,align: 'center',sortable: false,value: 'isProductSet', icon: 0},
        { text: this.use_language.tie_the_goods,align: 'center',sortable: false,value: 'mapper', icon: 0},
        { text: this.use_language.edit,align: 'center',sortable: false,value: 'edit', width: '60px', icon: 0},
        { text: this.use_language.delete,align: 'center',sortable: false,value: 'delete', width: '60px', icon: 0},
      ]


      },

      async get_authorize_staffshop () {
        this.authorize =   this.checkAuthorize(19);
          // if(localStorage.getItem("220") == 2)
          // {
          //  this.authorize = true
          // }
        },

      async reload_stock_all_marketplace () {
        try {
          this.btn_prosess_loading = true
         
          let response = await axios.post(shopService_dotnet+'Shop/get-shopid-all-marketplace-by-shopid', {
             "shopId": localStorage.getItem('shop_id')
          }, {headers: this.header_token})
          var data_res = response.data

          

          if(data_res.lazada_ShopID!=null && data_res.lazada_ShopID.length > 0){
            for (var item2 of data_res.lazada_ShopID) {
              let response2 = await axios.get(ecomService_dotnet+'Lazada/load-lazada-product?lazadaSellerID='+item2,   {headers: this.header_token})
            }
          }

          if(data_res.shopee_ShopID!=null && data_res.shopee_ShopID.length > 0){
            for (var item3 of data_res.shopee_ShopID) {
              let response3 = await axios.get(ecomService_dotnet+'Shopee/load-shopee-product?ShopeeShopID='+item3,  {headers: this.header_token})

            }
          }

          if(data_res.tiktok_ShopID!=null && data_res.tiktok_ShopID.length > 0){
            for (var item4 of data_res.tiktok_ShopID) {
              let response4 = await axios.get(ecomService_dotnet+'Tiktok/load-tiktok-product1?TiktokShopID=' + item4, {headers: this.header_token})
            }
          }

          if(data_res.shop_WooID!=null && data_res.shop_WooID.length > 0){
            for (let item5 of data_res.shop_WooID) {
              let response4 = await axios.get(ecomService_dotnet+'Woo/load-woo-product?ShopWooID=' + item5,  {headers: this.header_token})
            }
          }

          if(data_res.shop_LineID!=null && data_res.shop_LineID.length > 0){
            for (let item5 of data_res.shop_LineID) {
              let response4 = await axios.get(ecomService_dotnet+ 'Line/load-product-by-shoplineID?ShopLineID=' + item5, {headers: this.header_token})
            }
          }
          
          if(data_res.shop_Shopify!=null && data_res.shop_Shopify.length > 0){
            for (let item6 of data_res.shop_Shopify) {
              let response5 = await axios.get(ecomService_dotnet+ 'Shopify/load-shopify-product?Shop_Shopify_ID=' + item6, {headers: this.header_token})
            }
          }

          if(data_res.shop_Plugo!=null && data_res.shop_Plugo.length > 0){
            for (let item7 of data_res.shop_Plugo) {
              let response6 = await axios.get(ecomService_dotnet+ 'Plugo/load-plugo-product?Shop_Plugo_ID=' + item7, {headers: this.header_token})
            }
          }
          
          await this.getProductMarketplace(this.mp.popup.limits,0,1,null,0,false)
          this.btn_prosess_loading = false
          this.$swal({
            showConfirmButton: true,
            type: "success",
            title: "ดึงข้อมูลสินค้าเรียบร้อย"
          })
        } catch (e) {
          this.btn_prosess_loading = false
          this.$swal({
            position: "top",
            type: "error",
            title: "เกิดข้อผิดพลาด !!",
            text: e.message,
          })
        }

      },

      formatMoney,
      format_price,
      isNumberWithDot,
      isNumberWNoDot,
      DateNowNotNull,
      Trim_value,
      Trim_rules,
      formatDatetime,
      checkAuthorize
    }
  }
</script>
<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
