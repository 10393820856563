<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
        <loading :loading="loading" />
        <!-- <div v-if="data_customer.length != 0"> -->

        <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
            <v-card-title style="height: 60px;">
                <h4> {{ use_language.customer }} </h4>
                <!-- <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">ลูกค้า</span> -->
                <v-spacer />
            </v-card-title>
        </v-card>

        <v-card elevation="1" class="v-card-bottom-30">
            <v-card-title>
                <v-icon color="primary">mdi-file-search-outline</v-icon>
                <span class="ml-2">{{ use_language.search }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <div>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col class="pb-0" cols="12" md="3">
                                    <h3 class="fn-14"> {{ use_language.customer_code }} : </h3>
                                </v-col>
                                <v-col class="pb-0" cols="12" md="8">
                                    <v-text-field v-model="search.customerCode"
                                        @change="value => search.customerCode = value" outlined dense hide-details
                                        autocomplete="off" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pb-0" cols="12" md="3">
                                    <h3 class="fn-14"> {{ use_language.name }} : </h3>
                                </v-col>
                                <v-col class="pb-0" cols="12" md="8">
                                    <v-text-field v-model="search.name" @change="value => search.name = value" outlined
                                        dense hide-details autocomplete="off" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pb-0" cols="12" md="3">
                                    <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                                </v-col>
                                <v-col class="pb-0" cols="12" md="8">
                                    <v-text-field v-model="search.tel" @change="value => search.tel = value" outlined
                                        dense hide-details autocomplete="off" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pb-0" cols="12" md="3">
                                    <h3 class="fn-14"> {{ use_language.email }} : </h3>
                                </v-col>
                                <v-col class="pb-0" cols="12" md="8">
                                    <v-text-field v-model="search.email" @change="value => search.line = email" outlined
                                        dense hide-details autocomplete="off" />
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="12" md="6">

                            <v-row>
                                <v-col class="pb-0" cols="12" md="3">
                                    <h3 class="fn-14"> {{ use_language.line }} : </h3>
                                </v-col>
                                <v-col class="pb-0" cols="12" md="8">
                                    <v-text-field v-model="search.line" @change="value => search.line = value" outlined
                                        dense hide-details autocomplete="off" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pb-0" cols="12" md="3">
                                    <h3 class="fn-14"> {{ use_language.facebook }} : </h3>
                                </v-col>
                                <v-col class="pb-0" cols="12" md="8">
                                    <v-text-field v-model="search.facebook" @change="value => search.facebook = value"
                                        outlined dense hide-details autocomplete="off" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pb-0" cols="12" md="3"> </v-col>
                                <v-col class="pb-0" cols="12" md="8" lg="4">
                                    <v-btn dark color="primary" @click="load_data_temp()"
                                        class="mr-3">{{ use_language.search }}</v-btn>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>



                    <!-- <v-row>
                        <v-col class="pb-0" cols="12" md="2" lg="1"></v-col>
                        <v-col class="pb-0" cols="12" md="4" lg="5">
                            <v-btn dark color="primary" @click="load_data()" class="mr-3">ค้นหา</v-btn>
                        </v-col>
                    </v-row> -->
                </div>
            </v-card-text>
        </v-card>
        <v-card class="v-card-bottom-30">
            <v-card-title>
                <v-icon color="primary">fa-user-alt</v-icon>
                <span class="ml-2">{{ use_language.customer }}</span>
                <v-spacer />
                <v-btn dark color="primary" outlined @click="dialog_addcustomer = true"><v-icon left>mdi-plus</v-icon>
                    {{ use_language.add }}</v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <!-- <v-tab v-for="item in tab_items" :key="item" @click="SeeDetails(item)"> {{ item }} </v-tab> -->
                    <v-tab v-for="item in tab_items" :key="item"> {{ use_language[item] }} </v-tab>
                    <v-spacer />
                    <v-tab disabled v-if="tab != 1"><span>{{ use_language.number_customers }} {{ countcustomer }}
                            {{ use_language.people }}</span></v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item v-for="item in tab_items" :key="item">

                        <!-- แก้ไขแสดงหน้าใหม่ customer เป็นลูกค้า  customer_info เป็นข้อมูลลูกค้า -->
                        <div v-if="item == 'customer'">
                            <div>
                                <v-data-table :headers="header_data_customer" :items="data_customer"
                                    class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="50">
                                    <!-- i.Discount == true ? true : flase -->
                                    <template v-slot:item.No="{ item }">{{
                                        countcustomer -offsets-(data_customer.indexOf(item)) }}</template>
                                    <template v-slot:item.Name="{ item }">
                                        <div class="text-left">
                                            <span v-if="item.shopCustomerName != null">{{ item.shopCustomerName }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.Tel="{ item }">
                                        <div class="text-left">
                                            <span
                                                v-if="item.shopCustomerPhone != null">{{ item.shopCustomerPhone }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.Email="{ item }">
                                        <div class="text-left">
                                            <span v-if="item.email != null">{{ item.email }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.Facebook="{ item }">
                                        <div class="text-left">
                                            <span v-if="item.facebook != null">{{ item.facebook }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.Line="{ item }">
                                        <div class="text-left">
                                            <span v-if="item.line != null">{{ item.line }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.Address="{ item }">
                                        <div class="text-left">
                                            <span v-if="item.fullAddress != null">{{ item.fullAddress }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:item.balance="{ item }">
                                         
                                        <span v-if="item.balance != null && item.balance >0" style="color: green;">{{ format_price( item.balance)}}</span>
                                        <span v-if="item.balance != null && item.balance <0"  style="color: red;">{{ format_price( item.balance)}}</span>
                                       
                                    </template>
                                    <template v-slot:item.detail="{ item }">
                                        <!-- <v-btn @click="DetailCustomer(item.shopCustomerID)" text color="info">{{use_language.see_details}}</v-btn> -->
                                        <v-btn @click="openPageDetail(item.shopCustomerID)" text
                                            color="info">{{ use_language.see_details }}</v-btn>
                                    </template>
                                </v-data-table>

                                <v-row class="packhai-card-padding">
                                    <v-col cols="2" lg="1">
                                    </v-col>
                                    <v-col cols="8" lg="10">
                                        <div class="text-center" @click="changePage()">
                                            <v-pagination v-model="page" :length="pageLength"
                                                :total-visible="pageTotalVisible" color="error"></v-pagination>
                                        </div>
                                    </v-col>
                                    <v-col cols="2" class="text-right" lg="1">
                                        <v-select v-model="limits" label="จำนวนแสดง" :items="[10, 20, 50, 100, 500, 1000]"
                                            @change="LimitChange()" outlined></v-select>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>

                        <div class="pa-2" v-else-if="item == 'customer_info'">
                            <v-card class="v-card-bottom-30">
                                <v-card-title>
                                    <v-icon size="20" color="primary darken-3">fa-address-card</v-icon><span
                                        class="ml-3">
                                        {{ use_language.customer_info }}</span>
                                    <v-spacer />
                                    <v-btn icon v-if="EditCustomerDisibled == true"
                                        @click="EditCustomerDisibled = !EditCustomerDisibled"><v-icon
                                            size="18">fa-edit</v-icon></v-btn>
                                    <v-btn icon v-else @click="EditCustomerDisibled = !EditCustomerDisibled"><v-icon
                                            size="18">fa-edit</v-icon></v-btn>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="2" lg="1">
                                            <h3 class="fn-14"> {{ use_language.customer_code }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="4">
                                            <v-text-field :value="customer.customerCode"
                                                @change="value => customer.customerCode = value" autocomplete="off"
                                                ref="refCustomerCode" outlined hide-details dense
                                                :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                                            <span v-else-if="customer.customerCode != null">{{ customer.customerCode
                                                }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="2" lg="1">
                                            <h3 class="fn-14"> {{ use_language.name }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="4">
                                            <v-text-field :value="customer.name"
                                                @change="value => customer.name = value" autocomplete="off"
                                                ref="refCustomerName" outlined hide-details dense
                                                :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                                            <span v-else-if="customer.name != null">{{ customer.name }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="2" lg="1">
                                            <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="4">
                                            <v-text-field :value="customer.phone"
                                                @change="value => customer.phone = value" autocomplete="off"
                                                ref="refCustomerPhone" outlined hide-details dense
                                                :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                                            <span v-else-if="customer.phone != null">{{ customer.phone }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="2" lg="1">
                                            <h3 class="fn-14"> {{ use_language.email }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="4">
                                            <v-text-field :value="customer.email"
                                                @change="value => customer.email = value" autocomplete="off"
                                                ref="refCustomerEmail" outlined hide-details dense
                                                :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                                            <span v-else-if="customer.email != null">{{ customer.email }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="2" lg="1">
                                            <h3 class="fn-14"> {{ use_language.facebook }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="4">
                                            <v-text-field :value="customer.facebook"
                                                @change="value => customer.facebook = value" autocomplete="off"
                                                ref="refCustomerFacebook" outlined hide-details dense
                                                :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                                            <span v-else-if="customer.facebook != null">{{ customer.facebook }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="2" lg="1">
                                            <h3 class="fn-14"> {{ use_language.line }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="4">
                                            <v-text-field :value="customer.line"
                                                @change="value => customer.line = value" autocomplete="off"
                                                ref="refCustomerLine" outlined hide-details dense
                                                :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                                            <span v-else-if="customer.line != null">{{ customer.line }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="2" lg="1">
                                            <h3 class="fn-14"> {{ use_language.note }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="4">
                                            <v-text-field :value="customer.remark"
                                                @change="value => customer.remark = value" autocomplete="off"
                                                ref="refCustomerRemark" outlined hide-details dense
                                                :disabled="EditCustomerDisibled" v-if="EditCustomerDisibled == false" />
                                            <span v-else-if="customer.remark != null">{{ customer.remark }}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="EditCustomerDisibled == false">
                                        <v-col cols="12" align="left">
                                            <v-btn dark color="primary" @click="EditCustomer()" class="mr-1"><v-icon
                                                    left>mdi-content-save</v-icon> {{ use_language.record }}</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                            <v-card class="v-card-bottom-30">
                                <v-card-title>
                                    <v-icon size="20" color="warning darken-3">fa-map-marked-alt</v-icon><span
                                        class="ml-3">{{ use_language.delivery_address }}</span>
                                    <v-spacer />
                                    <v-btn dark color="primary" @click="EditShipping(0)"><v-icon left>mdi-plus</v-icon>
                                        {{ use_language.add }}</v-btn>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <v-data-table :headers="header_data_shippping" :items="data_shippping"
                                        class="elevation-0 packhai-border-table" hide-default-footer>
                                        <!-- i.Discount == true ? true : flase -->
                                        <!-- <template v-slot:item.No="{ item }">{{ (data_customer.indexOf(item)+1) }}</template> -->
                                        <template v-slot:item.Name="{ item }">
                                            <span v-if="item.name != null">{{ item.name }}</span>
                                        </template>
                                        <template v-slot:item.Tel="{ item }">
                                            <span v-if="item.phone != null">{{ item.phone }}</span>
                                        </template>
                                        <template v-slot:item.FullAddress="{ item }">
                                            <span v-if="item.fullAddress != null">{{ item.fullAddress }}
                                                <v-btn v-if="item.isDefault" class="ml-2" height="20" color="teal" dark
                                                    rounded elevation="0">{{ use_language.default }}</v-btn>
                                            </span>

                                        </template>
                                        <template v-slot:item.edit="{ item }">
                                            <v-btn @click="EditShipping(item.id)" icon><v-icon
                                                    size="18">fa-edit</v-icon></v-btn>
                                        </template>
                                        <template v-slot:item.delete="{ item }">
                                            <v-btn icon v-if="item.isDefault" disabled><v-icon size="18"
                                                    color="danger">fa-trash-alt</v-icon></v-btn>
                                            <v-btn @click="DeleteShipping(item.id)" icon v-else><v-icon size="18"
                                                    color="danger">fa-trash-alt</v-icon></v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>

                            <v-card class="v-card-bottom-30">
                                <v-card-title>
                                    <v-icon size="20" color="success darken-3">fa-map-marked-alt</v-icon><span
                                        class="ml-3">{{ use_language.tax_invoice_address }}</span>
                                    <v-spacer />
                                    <v-btn dark color="primary" @click="EditInvoice(0)"><v-icon left>mdi-plus</v-icon>
                                        {{ use_language.add }}</v-btn>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <v-data-table :headers="header_data_invoice" :items="data_invoice"
                                        class="elevation-0 packhai-border-table" hide-default-footer>
                                        <!-- i.Discount == true ? true : flase -->
                                        <!-- <template v-slot:item.No="{ item }">{{ (data_customer.indexOf(item)+1) }}</template> -->
                                        <template v-slot:item.tax="{ item }">
                                            <span v-if="item.taxID != null">{{ item.taxID }}</span>
                                        </template>
                                        <template v-slot:item.Name="{ item }">
                                            <span v-if="item.name != null">{{ item.name }}</span>
                                        </template>
                                        <template v-slot:item.Tel="{ item }">
                                            <span v-if="item.phone != null">{{ item.phone }}</span>
                                        </template>
                                        <template v-slot:item.FullAddress="{ item }">
                                            <span v-if="item.fullAddress != null">{{ item.fullAddress }}
                                                <v-btn v-if="item.isDefault" class="ml-2" height="20" color="teal" dark
                                                    rounded elevation="0">{{ use_language.default }}</v-btn>
                                            </span>
                                        </template>
                                        <template v-slot:item.edit="{ item }">
                                            <v-btn @click="EditInvoice(item.id)" icon><v-icon
                                                    size="18">fa-edit</v-icon></v-btn>
                                        </template>
                                        <template v-slot:item.delete="{ item }">
                                            <v-btn icon v-if="item.isDefault" disabled><v-icon size="18"
                                                    color="danger">fa-trash-alt</v-icon></v-btn>
                                            <v-btn @click="DeleteInvoice(item.id)" icon v-else><v-icon size="18"
                                                    color="danger">fa-trash-alt</v-icon></v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </div>

                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-dialog v-model="dialog_shipping" :fullscreen="isFullscreen" scrollable max-width="45%" persistent>
                <v-card>
                    <v-card-title>
                        <span v-if="shipping.id != 0">{{ use_language.edit_address }}</span>
                        <span v-else>{{ use_language.add_address }}</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog_shipping = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row row class="pt-2">
                            <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                <h3 class="fn-14"> {{ use_language.name }} : </h3>
                            </v-col>
                            <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                <v-text-field :value="shipping.name" @change="value => shipping.name = value"
                                    autocomplete="off" ref="refShippingName" outlined hide-details dense />
                            </v-col>
                        </v-row>
                        <v-row row class="pt-2">
                            <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                            </v-col>
                            <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                <v-text-field :value="shipping.phone" @change="value => shipping.phone = value"
                                    autocomplete="off" ref="refShippingPhone" outlined hide-details dense maxlength="10"
                                    @keypress="isNumberWNoDot($event)" />
                            </v-col>
                        </v-row>
                        <v-row row class="pt-2">
                            <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                <h3 class="fn-14"> {{ use_language.address }} : </h3>
                            </v-col>
                            <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                <v-text-field :value="shipping.address" @change="value => shipping.address = value"
                                    autocomplete="off" ref="refShippingTextAddress" outlined hide-details dense />
                            </v-col>
                        </v-row>
                        <v-row row class="pt-2">
                            <v-col class="pb-0" cols="12" md="4" lg="2">
                                <h3>{{ use_language.zipcode }} :</h3>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="8" lg="10">
                                <v-autocomplete :value="shipping.postcodeMappingID"
                                    @change="value => shipping.postcodeMappingID = value" ref="refShippingPostcode"
                                    :items="postcode_mapping"
                                    :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                                    outlined dense hide-details autocomplete="off" required return-object>
                                    <!-- แก้จากเดิมเป็น 3 ภาษา -->
                                    <template v-slot:label> <span>{{ use_language.all_address }}</span> </template>

                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="4" lg="2">
                                <h3>{{ use_language.set_default }} :</h3>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="8" lg="10">
                                <v-checkbox :readonly="IsReadonly" v-model="shipping.isDefault"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="12" align="left">
                                <v-btn dark color="primary" @click="EditShippingConfirm()" class="mr-1"> <v-icon
                                        left>mdi-content-save</v-icon> {{ use_language.record }} </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_invoice" :fullscreen="isFullscreen" scrollable max-width="45%" persistent>
                <v-card>
                    <v-card-title>
                        <span v-if="invoice.id != 0">{{ use_language.edit_tax_invoice_address }}</span>
                        <span v-else>{{ use_language.add_tax_invoice_address }}</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialog_invoice = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <v-row row class="pt-2">
                            <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                <h3 class="fn-14"> {{ use_language.tax_invoice_number }} : </h3>
                            </v-col>
                            <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                <v-text-field :value="invoice.taxID" @change="value => invoice.taxID = value"
                                    autocomplete="off" required ref="refInvoiceTaxID" outlined hide-details
                                    maxlength="13" dense @keypress="isNumberWNoDot($event)" />
                            </v-col>
                        </v-row>
                        <v-row row class="pt-2">
                            <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                <h3 class="fn-14"> {{ use_language.name }} : </h3>
                            </v-col>
                            <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                <v-text-field :value="invoice.name" @change="value => invoice.name = value"
                                    autocomplete="off" required ref="refInvoiceName" outlined hide-details dense />
                            </v-col>
                        </v-row>
                        <v-row row class="pt-2">
                            <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                            </v-col>
                            <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                <v-text-field :value="invoice.phone" @change="value => invoice.phone = value"
                                    autocomplete="off" ref="refInvoicePhone" outlined hide-details dense maxlength="10"
                                    @keypress="isNumberWNoDot($event)" />
                            </v-col>
                        </v-row>
                        <v-row row class="pt-2">
                            <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                <h3 class="fn-14"> {{ use_language.address }} : </h3>
                            </v-col>
                            <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                <v-text-field :value="invoice.address" @change="value => invoice.address = value"
                                    autocomplete="off" ref="refInvoiceTextAddress" outlined hide-details dense />
                            </v-col>
                        </v-row>
                        <v-row row class="pt-2">
                            <v-col class="pb-0" cols="12" md="4" lg="2">
                                <h3>{{ use_language.zipcode }} :</h3>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="8" lg="10">
                                <v-autocomplete v-model="invoice.postcodeMappingID" ref="refInvoiceAddress"
                                    placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                                    :items="postcode_mapping"
                                    :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                                    outlined required return-object hide-details dense>
                                    <template v-slot:label> <span>{{ use_language.all_address }}</span> </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="4" lg="2">
                                <h3>{{ use_language.set_default }} :</h3>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="8" lg="10">
                                <v-checkbox :readonly="IsReadonly" v-model="invoice.isDefault"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row row>
                            <v-col class="pb-0" cols="12" md="12" align="left">
                                <v-btn dark color="primary" @click="EditInvoiceConfirm()" class="mr-1"><v-icon
                                        left>mdi-content-save</v-icon> บันทึก</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialog_addcustomer" :fullscreen="isFullscreen" scrollable max-width="50%" persistent>
                <v-form ref="form" lazy-validation>
                    <v-card>
                        <v-card-title>
                            <span>{{ use_language.add_new_customers }}</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialog_addcustomer = false"><v-icon
                                    color="danger">mdi-close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-card class="v-card-bottom-30">
                                <v-card-title>
                                    <span>{{ use_language.customers_info }}</span>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="4" lg="2">
                                            <h3 class="fn-14"> {{ use_language.customer_code }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="8" lg="10">
                                            <v-text-field :value="customer.customerCode"
                                                @change="value => customer.customerCode = value" autocomplete="off"
                                                ref="refNewCustomerCode" outlined hide-details="auto" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="4" lg="2">
                                            <h3 class="fn-14"> {{ use_language.name }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="8" lg="10">
                                            <v-text-field :value="customer.name"
                                                @change="value => customer.name = value" autocomplete="off"
                                                ref="refNewCustomerName" outlined hide-details="auto"
                                                :rules="rules.customername" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="4" lg="2">
                                            <h3 class="fn-14"> {{ use_language.email }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="8" lg="10">
                                            <v-text-field :value="customer.email"
                                                @change="value => customer.email = value" autocomplete="off"
                                                ref="refNewCustomerEmail" outlined hide-details="auto" dense
                                                :rules="rules.customeremail" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="4" lg="2">
                                            <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="8" lg="10">
                                            <v-text-field :value="customer.phone"
                                                @change="value => customer.phone = value" autocomplete="off"
                                                ref="refNewCustomerPhone" @keypress="isNumberWNoDot($event)" outlined
                                                hide-details="auto" maxlength="10" :rules="rules.customerphone" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="4" lg="2">
                                            <h3 class="fn-14"> {{ use_language.facebook }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="8" lg="10">
                                            <v-text-field :value="customer.facebook"
                                                @change="value => customer.facebook = value" autocomplete="off"
                                                ref="refNewCustomerFacebook" outlined hide-details="auto"
                                                :rules="rules.customerfacebook" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="4" lg="2">
                                            <h3 class="fn-14"> {{ use_language.line }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="8" lg="10">
                                            <v-text-field :value="customer.line"
                                                @change="value => customer.line = value" autocomplete="off"
                                                ref="refNewCustomerLine" outlined :rules="rules.customerline"
                                                hide-details="auto" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pb-1" cols="12" md="4" lg="2">
                                            <h3 class="fn-14"> {{ use_language.note }} : </h3>
                                        </v-col>
                                        <v-col class="pb-1" cols="12" md="8" lg="10">
                                            <v-text-field :value="customer.remark"
                                                @change="value => customer.remark = value" autocomplete="off"
                                                ref="refNewCustomerRemark" outlined hide-details="auto" dense />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card class="v-card-bottom-30">
                                <v-card-title>
                                    <span>{{ use_language.delivery_address }}</span>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <v-row row class="pt-2">
                                        <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                            <h3 class="fn-14"> {{ use_language.name }} : </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                            <v-text-field :value="shipping.name"
                                                @change="value => shipping.name = value" autocomplete="off"
                                                ref="refNewShippingName" outlined hide-details="auto"
                                                :rules="rules.shippingname" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row row class="pt-2">
                                        <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                            <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                            <v-text-field :value="shipping.phone"
                                                @change="value => shipping.phone = value" autocomplete="off"
                                                ref="refNewShippingPhone" outlined hide-details="auto" dense
                                                maxlength="10" :rules="rules.shippingphone"
                                                @keypress="isNumberWNoDot($event)" />
                                        </v-col>
                                    </v-row>
                                    <v-row row class="pt-2">
                                        <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                            <h3 class="fn-14"> {{ use_language.address }} : </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                            <v-text-field :value="shipping.address"
                                                @change="value => shipping.address = value" autocomplete="off"
                                                ref="refNewShippingTextAddress" outlined :rules="rules.shippingaddress"
                                                hide-details="auto" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row row class="pt-2">
                                        <v-col class="pb-0" cols="12" md="4" lg="2">
                                            <h3>{{ use_language.address }} :</h3>
                                        </v-col>
                                        <v-col class="pb-0" cols="12" md="8" lg="10">
                                            <v-autocomplete v-model="shipping.postcodeMappingID"
                                                ref="refShippingAddress"
                                                placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                                                :items="postcode_mapping"
                                                :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                                                outlined required return-object hide-details="auto" dense>
                                                <template v-slot:label> <span>{{ use_language.all_address }}</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card>
                                <v-card-title>
                                    <span>{{ use_language.tax_invoice_address }}</span>
                                </v-card-title>
                                <v-divider />
                                <v-card-text>
                                    <v-row row class="pt-2">
                                        <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                            <h3 class="fn-14"> {{ use_language.tax_invoice_number }} : </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                            <v-text-field :value="invoice.taxID"
                                                @change="value => invoice.taxID = value" autocomplete="off"
                                                ref="refNewInvoiceTaxID" outlined hide-details="auto" dense
                                                maxlength="13" @keypress="isNumberWNoDot($event)" />
                                        </v-col>
                                    </v-row>
                                    <v-row row class="pt-2">
                                        <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                            <h3 class="fn-14"> {{ use_language.name }} : </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                            <v-text-field :value="invoice.name" @change="value => invoice.name = value"
                                                autocomplete="off" ref="refNewInvoiceName" outlined hide-details="auto"
                                                dense />
                                        </v-col>
                                    </v-row>
                                    <v-row row class="pt-2">
                                        <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                            <h3 class="fn-14"> {{ use_language.tel }} : </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                            <v-text-field :value="invoice.phone"
                                                @change="value => invoice.phone = value" autocomplete="off"
                                                ref="refNewInvoicePhone" outlined hide-details="auto" dense
                                                maxlength="10" @keypress="isNumberWNoDot($event)" />
                                        </v-col>
                                    </v-row>
                                    <v-row row class="pt-2">
                                        <v-col cols="12" md="4" lg="2" class="py-0 pt-1">
                                            <h3 class="fn-14"> {{ use_language.address }} : </h3>
                                        </v-col>
                                        <v-col cols="12" md="8" lg="10" class="py-0 pt-1">
                                            <v-text-field :value="invoice.address"
                                                @change="value => invoice.address = value" autocomplete="off"
                                                ref="refNewInvoiceTextAddress" outlined hide-details="auto" dense />
                                        </v-col>
                                    </v-row>
                                    <v-row row class="pt-2">
                                        <v-col class="pb-0" cols="12" md="4" lg="2">
                                            <h3>{{ use_language.zipcode }} :</h3>
                                        </v-col>
                                        <v-col class="pb-0" cols="12" md="8" lg="10">
                                            <v-autocomplete v-model="invoice.postcodeMappingID"
                                                ref="refNewInvoiceAddress"
                                                placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                                                :items="postcode_mapping"
                                                :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                                                outlined required return-object hide-details="auto" dense>
                                                <template v-slot:label> <span>{{ use_language.all_address }}</span>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="primary" @click="AddCustomer()"> {{ use_language.record }} </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-card>
    </div>
</template>
<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import { generalService_dotnet, shopService_dotnet } from '@/website/global'
import { isNumberWNoDot, get_languages, format_price } from '@/website/global_function'
import { token_api } from '@/website/backend/token'

export default {
    components: {
        Loading
    },
    data: () => ({
        header_token: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('authorization_token') },
        page_loading: true,
        loading: false,
        use_language: null,
        set_language: null,
        EditCustomerDisibled: true,
        dialog_shipping: false,
        dialog_invoice: false,
        dialog_addcustomer: false,
        isFullscreen: false,
        IsReadonly: false,
        ShopID: null,
        postcode_mapping: [],
        date: null,
        countcustomer: null,
        new_customer: {
            "CustomerName": null,
            "CustomerPhone": null,
            "CustomerEmail": null,
            "CustomerFacebook": null,
            "CustomerLine": null,
            "CustomerRemark": null,
            "ShippingName": null,
            "ShippingPhone": null,
            "ShippingTextAddress": null,
            "ShippingAddress": null,
            "InvoiceTaxID": null,
            "InvoiceName": null,
            "InvoicePhone": null,
            "InvoiceTextAddress": null,
            "InvoiceAddress": null,
        },
        customer: {
            "id": 0,
            "customerCode": null,
            "name": null,
            "phone": null,
            "email": null,
            "facebook": null,
            "line": null,
            "remark": null,
            "shopID": null,
            "created": null
        },
        shipping: {
            "id": 0,
            "name": null,
            "phone": null,
            "address": null,
            "shopCustomerID": 0,
            "shopID": null,
            "isDefault": false,
            "postcodeMappingID": 0
        },
        invoice: {
            "id": 0,
            "taxID": null,
            "name": null,
            "phone": null,
            "address": null,
            "shopCustomerID": 0,
            "isDefault": false,
            "shopID": null,
            "postcodeMappingID": 0
        },
        search: {
            "name": null,
            "tel": null,
            "line": null,
            "email": null,
            "facebook": null,
            "address": null,
        },
        data_customer: [],
        data_shippping: [],
        data_invoice: [],
        header_data_customer: [
            { text: 'ลำดับ', align: 'center', value: 'No', sortable: false, width: '50px' },
            { text: 'รหัสลูกค้า', align: 'center', value: 'customerCode', sortable: false, width: '50px' },
            { text: 'ชื่อ', align: 'center', value: 'Name', sortable: false, width: '150px' },
            { text: 'เบอร์โทร', align: 'center', value: 'Tel', sortable: false, width: '50px' },
            { text: 'ยอดเครดิต', align: 'center', value: 'balance', sortable: false, width: '80px' },
            { text: 'ที่อยู่', align: 'center', value: 'Address', sortable: false, width: '200px' },
            { text: 'อีเมลล์', align: 'center', value: 'Email', sortable: false, width: '100px' },
            { text: 'facebook', align: 'center', value: 'Facebook', sortable: false, width: '100px' },
            { text: 'line', align: 'center', value: 'Line', sortable: false, width: '100px' },
            { text: 'รายละเอียด', align: 'center', value: 'detail', sortable: false, width: '100px' }
        ],
        header_data_shippping: [
            // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
            { text: 'ชื่อ', align: 'left', value: 'Name', sortable: false, width: '100px' },
            { text: 'เบอร์โทร', align: 'left', value: 'Tel', sortable: false, width: '100px' },
            { text: 'ที่อยู่', align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
            { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false, width: '50px' },
            { text: 'ลบ', align: 'center', value: 'delete', sortable: false, width: '50px' },
        ],
        header_data_invoice: [
            // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
            { text: 'เลขผู้เสียภาษี', align: 'left', value: 'tax', sortable: false, width: '100px' },
            { text: 'ชื่อ', align: 'left', value: 'Name', sortable: false, width: '100px' },
            { text: 'เบอร์โทร', align: 'left', value: 'Tel', sortable: false, width: '100px' },
            { text: 'ที่อยู่', align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
            { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false, width: '50px' },
            { text: 'ลบ', align: 'center', value: 'delete', sortable: false, width: '50px' },
        ],
        tab: null,
        // tab_items: ['customer','customer_info'],
        tab_items: ['customer'],
        // :rules
        rules: {
            customername: [],
            customeremail: [],
            customerphone: [],
            customerfacebook: [],
            customerline: [],
            shippingname: [],
            shippingphone: [],
            shippingaddress: []
        },
        // paginations
        page: 1,
        pageTemp: 1,
        pageLength: 1,
        pageTotalVisible: 9,
        limits: 10,
        offsets: 0,
    }),
    async created() {

        //เก็บภาษามาไว้ใน use_language
        this.use_language = await this.get_languages(localStorage.getItem("set_language"));




        this.loading = true
        this.ShopID = localStorage.getItem("shop_id")
        this.date = new Date().toISOString()
        await this.get_postcode_mapping()
        await this.load_data()
        this.page_loading = false
        this.loading = false


        //ประกาศสร้าง หัวข้อ ใช้ 3 ภาษา
        this.created_header_data_table();
    },
    methods: {
        isNumberWNoDot, get_languages, format_price,
        async load_data_temp() {
            this.page = 1;
            this.offsets = 0;
            await this.load_data();
            this.pageTemp =this.page;
        },
        async load_data() {
            this.loading = true

            axios.all([
                axios.post(shopService_dotnet + 'Customer/get-customer-info-list', {
                    'shopID': localStorage.getItem("shop_id"),
                    'name': this.search.name,
                    'customerCode': this.search.customerCode,
                    'phone': this.search.tel,
                    'line': this.search.line,
                    'email': this.search.email,
                    'facebook': this.search.facebook,
                    'address': this.search.address,
                    'isAscending': false,
                    'skip': this.offsets,
                    'take': this.limits,
                    'orderBy': 1
                }, { headers: this.header_token }),
            ])
                .then(axios.spread((Customer) => {
                    if (Customer.status == 200) {
                        this.data_customer = Customer.data.customerList
                        this.pageLength = (Math.ceil(Customer.data.searchResultCount / this.limits))
                        this.countcustomer = Customer.data.searchResultCount
                        if (this.pageLength <= 0) { this.pageLength = 1 }
                        this.pageTemp = this.page
                    }
                }));
            this.loading = false
        },
        async DetailCustomer(customerid) {

            // this.loading = true
            // axios.all([
            //     axios.post(shopService_dotnet+'Customer/get-customer-info', {
            //         'ShopCustomerID':customerid,
            //     },{ headers: this.header_token}),
            // ])
            // .then(axios.spread((Customer) => {
            //     if (Customer.status == 200) {
            //         this.customer = Customer.data.customer
            //         this.data_shippping = Customer.data.shippingAddressList
            //         this.data_invoice = Customer.data.invoiceAddressList
            //         this.tab = 1
            //     } else {
            //         this.$swal({
            //             type: 'warning',
            //             title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
            //         })
            //     }
            // }));
            // this.loading = false
        },
        async EditCustomer() {
            this.loading = true
            axios.all([
                axios.post(shopService_dotnet + 'Customer/update-customer-info', {
                    "id": this.customer.id,
                    "customerCode": this.customer.customerCode,
                    "name": this.customer.name,
                    "phone": this.customer.phone,
                    "email": this.customer.email,
                    "facebook": this.customer.facebook,
                    "line": this.customer.line,
                    "remark": this.customer.remark
                }, { headers: this.header_token }),
            ])
                .then(axios.spread((customer) => {
                    if (customer.status == 200) {
                        this.$swal({
                            toast: true,
                            timer: 1500,
                            showConfirmButton: false,
                            position: 'top',
                            type: 'success',
                            title: customer.data
                        })
                        this.EditCustomerDisibled = true
                    }
                }));
            this.loading = false
        },
        async EditShipping(shippingid) {
            this.loading = true
            if (shippingid == 0) {
                this.clearshipping()
                this.shipping.shopCustomerID = this.customer.id
                this.shipping.IsDefault = false
                this.dialog_shipping = true
            }
            else {

                axios.all([
                    axios.post(shopService_dotnet + 'Customer/get-customer-address', {
                        'id': shippingid,
                    }, { headers: this.header_token }),
                ])
                    .then(axios.spread((shipping) => {
                        if (shipping.status == 200) {
                            this.shipping = shipping.data
                            for (var p in this.postcode_mapping) {
                                if (this.postcode_mapping[p]['Id'] == this.shipping.postcodeMappingID) {
                                    this.shipping.postcodeMappingID = {
                                        "Id": this.postcode_mapping[p]['Id'],
                                        "SubDistrict": this.postcode_mapping[p]['SubDistrict'],
                                        "District": this.postcode_mapping[p]['District'],
                                        "Province": this.postcode_mapping[p]['Province'],
                                        "Postcode": this.postcode_mapping[p]['Postcode']
                                    }
                                }
                            }
                            this.dialog_shipping = true
                        } else {
                            this.$swal({
                                type: 'warning',
                                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                            })
                        }
                    }));
            }
            this.loading = false
        },
        async EditShippingConfirm() {
            if (this.shipping.name == null || this.shipping.name == '') {
                this.$refs.refShippingName.focus()
                return
            } else if (this.shipping.phone == null || this.shipping.phone == '') {
                this.$refs.refShippingPhone.focus()
                return
            }
            this.loading = true
            axios.all([
                axios.post(shopService_dotnet + 'Customer/update-customer-address', {
                    "id": this.shipping.id,
                    "name": this.shipping.name,
                    "phone": this.shipping.phone,
                    "address": this.shipping.address,
                    "shopCustomerID": this.shipping.shopCustomerID,
                    "IsDefault": this.shipping.isDefault,
                    "postcodeMappingID": this.shipping.postcodeMappingID.Id
                }, { headers: this.header_token }),
            ])
                .then(axios.spread((shipping) => {
                    if (shipping.status == 200) {
                        this.data_shippping = shipping.data
                        this.dialog_shipping = false
                    } else {
                        this.$swal({
                            type: 'warning',
                            title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                        })
                    }
                }));
            this.loading = false
        },
        async DeleteShipping(shippingid) {
            this.$swal({
                position: "center",
                type: 'warning',
                title: 'ลบ ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.value) {
                    axios.all([
                        axios.post(shopService_dotnet + 'Customer/delete-customer-address', {
                            'id': shippingid,
                        }, { headers: this.header_token }),
                    ])
                        .then(axios.spread((shipping) => {
                            if (shipping.status == 200) {
                                this.data_shippping = shipping.data
                            } else {
                                this.$swal({
                                    type: 'warning',
                                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                                })
                            }
                        }));
                }
            })
        },
        async EditInvoice(invoiceid) {
            this.loading = true
            if (invoiceid == 0) {
                this.clearinvoice()
                this.invoice.shopCustomerID = this.customer.id
                this.invoice.IsDefault = false
                this.dialog_invoice = true
            }
            else {
                this.clearinvoice()
                axios.all([
                    axios.post(shopService_dotnet + 'Customer/get-customer-invoice-address', {
                        'id': invoiceid,
                    }, { headers: this.header_token }),
                ])
                    .then(axios.spread((invoice) => {
                        if (invoice.status == 200) {
                            this.invoice = invoice.data
                            for (var p in this.postcode_mapping) {
                                if (this.postcode_mapping[p]['Id'] == this.invoice.postcodeMappingID) {
                                    this.invoice.postcodeMappingID = {
                                        "Id": this.postcode_mapping[p]['Id'],
                                        "SubDistrict": this.postcode_mapping[p]['SubDistrict'],
                                        "District": this.postcode_mapping[p]['District'],
                                        "Province": this.postcode_mapping[p]['Province'],
                                        "Postcode": this.postcode_mapping[p]['Postcode']
                                    }
                                }
                            }
                            this.dialog_invoice = true
                        }
                    }));
            }
            this.loading = false
        },
        async EditInvoiceConfirm() {
            if (this.invoice.name == null || this.invoice.name == '') {
                this.$refs.refInvoiceName.focus()
                return
            } else if (this.invoice.taxID == null || this.invoice.taxID == '') {
                this.$refs.refInvoiceTaxID.focus()
                return
            }
            this.loading = true
            axios.all([
                axios.post(shopService_dotnet + 'Customer/update-customer-invoice-address', {
                    "id": this.invoice.id,
                    "taxID": this.invoice.taxID,
                    "name": this.invoice.name,
                    "phone": this.invoice.phone,
                    "address": this.invoice.address,
                    "shopCustomerID": this.invoice.shopCustomerID,
                    "isDefault": this.invoice.isDefault,
                    "postcodeMappingID": this.invoice.postcodeMappingID.Id
                }, { headers: this.header_token }),
            ])
                .then(axios.spread((invoice) => {
                    if (invoice.status == 200) {
                        this.data_invoice = invoice.data
                        this.dialog_invoice = false
                    } else {
                        this.$swal({
                            type: 'warning',
                            title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                        })
                    }
                }));
            this.loading = false
        },
        async DeleteInvoice(invoiceid) {
            this.$swal({
                position: "center",
                type: 'warning',
                title: 'ลบ ใช่หรือไม่ !!',
                confirmButtonText: 'ยืนยัน',
                cancelButtonText: 'ยกเลิก',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.value) {
                    axios.all([
                        axios.post(shopService_dotnet + 'Customer/delete-customer-invoice-address', {
                            'id': invoiceid,
                        }, { headers: this.header_token }),
                    ])
                        .then(axios.spread((invoice) => {
                            if (invoice.status == 200) {
                                this.data_invoice = invoice.data
                            } else {
                                this.$swal({
                                    type: 'warning',
                                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                                })
                            }
                        }));
                }
            })
        },
        async AddCustomer() {
            await this.rulesnewcustomer()
            if (this.$refs.form.validate()) {
                this.snackbar = true
                this.customer.shopID = this.ShopID
                this.shipping.shopID = this.ShopID
                this.invoice.shopID = this.ShopID
                this.customer.created = this.date
                this.shipping.postcodeMappingID = this.shipping.postcodeMappingID.Id
                if (this.invoice.postcodeMappingID != null) {
                    this.invoice.postcodeMappingID = this.invoice.postcodeMappingID.Id
                }
                this.loading = true
                if (this.invoice.taxID != null && this.invoice.taxID != null && this.invoice.name != null && this.invoice.address != null && this.invoice.phone != null && this.invoice.postcodeMappingID != 0) {
                    axios.all([
                        axios.post(shopService_dotnet + 'Customer/new-customer', {
                            'shopCustomer': this.customer,
                            'shippingAddress': this.shipping,
                            'shopCustomerAddressInvoice': this.invoice
                        }, { headers: this.header_token }),
                    ])
                        .then(axios.spread((shopcustomer) => {
                            if (shopcustomer.status == 200) {
                                this.$swal({
                                    timer: 1500,
                                    showConfirmButton: false,
                                    position: 'center',
                                    type: 'success',
                                    text: shopcustomer.data.message,
                                })
                                this.dialog_addcustomer = false
                                this.load_data()
                            }
                        }));
                } else {
                    axios.all([
                        axios.post(shopService_dotnet + 'Customer/new-customer', {
                            'shopCustomer': this.customer,
                            'shippingAddress': this.shipping,
                            'shopCustomerAddressInvoice': null
                        }, { headers: this.header_token }),
                    ])
                        .then(axios.spread((shopcustomer) => {
                            if (shopcustomer.status == 200) {
                                this.$swal({
                                    timer: 1500,
                                    showConfirmButton: false,
                                    position: 'center',
                                    type: 'success',
                                    text: shopcustomer.data.message,
                                })
                                this.dialog_addcustomer = false
                                this.load_data()
                            }
                        }));
                }
                this.loading = false
            }
        },
        async get_postcode_mapping() {
            var postcode_mapping = JSON.parse(localStorage.getItem('postcodeMapping_dotnet'))
            if (postcode_mapping === 'undefined') {
                postcode_mapping = null
            } else if (postcode_mapping == 'null') {
                postcode_mapping = null
            } else if (postcode_mapping == null) {
                postcode_mapping = null
            } else if (postcode_mapping == '') {
                postcode_mapping = null
            }
            if (postcode_mapping != null) {
                this.postcode_mapping = postcode_mapping
            } else {
                await axios.post(generalService_dotnet + 'General/get-postcodemapping', {},
                    { headers: this.header_token })
                    .then(response => {
                        localStorage.setItem('postcodeMapping_dotnet', JSON.stringify(response.data))
                        this.postcode_mapping = response.data
                    })
            }
        },
        async rulesnewcustomer() {
            this.rules.customername = [v => !!v || "กรุณาระบุ"]
            this.rules.customeremail = [v => !!v || "กรุณาระบุ"]
            this.rules.customerphone = [v => !!v || "กรุณาระบุ"]
            this.rules.customerfacebook = [v => !!v || "กรุณาระบุ"]
            this.rules.customerline = [v => !!v || "กรุณาระบุ"]
            this.rules.shippingname = [v => !!v || "กรุณาระบุ"]
            this.rules.shippingphone = [v => !!v || "กรุณาระบุ"]
            this.rules.shippingaddress = [v => !!v || "กรุณาระบุ"]
        },
        async changePage() {
            if (this.pageLength != 1 && this.pageTemp != this.page) {
                this.offsets = (this.page * this.limits) - this.limits
                await this.load_data()
                this.pageTemp = this.page
            }
        },
        async LimitChange(){
            this.page=1;
            this.offsets=0;
            await this.load_data()
            this.pageTemp = this.page
        },
        clearshipping() {
            this.shipping = {
                "id": 0,
                "name": null,
                "phone": null,
                "address": null,
                "postcodeMappingID": null,
                "shopCustomerID": null,
                "isDefault": false,
            }
        },
        clearinvoice() {
            this.invoice = {
                "id": 0,
                "taxID": null,
                "name": null,
                "phone": null,
                "address": null,
                "shopCustomerID": null,
                "IsDefault": null,
                "postcodeMappingID": null
            }
        },


        created_header_data_table() {
            this.header_data_customer = [
                { text: this.use_language.number, align: 'center', value: 'No', sortable: false, width: '50px' },
                { text: this.use_language.customerCode, align: 'center', value: 'customerCode', sortable: false, width: '100px' },
                { text: this.use_language.name, align: 'center', value: 'Name', sortable: false, width: '150px' },
                { text: this.use_language.tel, align: 'center', value: 'Tel', sortable: false, width: '50px' },
                { text: this.use_language.credit_balance, align: 'right', value: 'balance', sortable: false, width: '120px' },
                { text: this.use_language.address, align: 'center', value: 'Address', sortable: false, width: '200px' },
                { text: this.use_language.email, align: 'center', value: 'Email', sortable: false, width: '100px' },
                { text: this.use_language.facebook, align: 'center', value: 'Facebook', sortable: false, width: '100px' },
                { text: this.use_language.line, align: 'center', value: 'Line', sortable: false, width: '100px' },

                { text: this.use_language.details, align: 'center', value: 'detail', sortable: false, width: '100px' }
            ]
            this.header_data_shippping = [
                // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
                { text: this.use_language.name, align: 'left', value: 'Name', sortable: false, width: '100px' },
                { text: this.use_language.tel, align: 'left', value: 'Tel', sortable: false, width: '100px' },
                { text: this.use_language.address, align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
                { text: this.use_language.edit, align: 'center', value: 'edit', sortable: false, width: '50px' },
                { text: this.use_language.delete, align: 'center', value: 'delete', sortable: false, width: '50px' },
            ]
            this.header_data_invoice = [
                // { text: 'ลำดับ', align: 'center', value: 'No', sortable: false,width: '50px' },
                { text: this.use_language.tax_invoice_number, align: 'left', value: 'tax', sortable: false, width: '100px' },
                { text: this.use_language.name, align: 'left', value: 'Name', sortable: false, width: '100px' },
                { text: this.use_language.tel, align: 'left', value: 'Tel', sortable: false, width: '100px' },
                { text: this.use_language.address, align: 'left', value: 'FullAddress', sortable: false, width: '200px' },
                { text: this.use_language.edit, align: 'center', value: 'edit', sortable: false, width: '50px' },
                { text: this.use_language.delete, align: 'center', value: 'delete', sortable: false, width: '50px' },
            ]



        },
        openPageDetail(key) {
            window.open(`/manage-customer-detail?id=${key}`);

        },


    }
}
</script>
