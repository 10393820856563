<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        การเงิน
        <v-icon>mdi-chevron-right</v-icon>
        <a @click="toExpenseList()"> ค่าใช้จ่าย </a> 
        <v-icon>mdi-chevron-right</v-icon>
        <span v-if="id==null"> เพิ่มค่าใช้จ่าย</span>
        <span v-else> แก้ไขค่าใช้จ่ายเลขที่ : {{ idText }} </span>
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img
                class="hidden-sm-and-down"
                :src="ShowPhotoLink"
                width="450"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{
            use_language.newtab
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{
            use_language.close
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- รายละเอียด -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <div class="py-1">
          <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon
          ><span class="ml-3">{{ use_language.info }}</span>
        </div>
        <v-spacer />
        <div></div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        
            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">วันที่-เวลา :</h3>
              </v-col>
              <v-col cols="12" md="3" lg="2" class="pt-1 pb-2">
                <date-time-picker v-model="invoiceDate" :isLabel="'วันที่'" :isTime="false" style="width:100%" />
              </v-col>
              <v-col cols="12" md="2" lg="1" class="pt-1 pb-2">
                 
                <v-select
                        v-model="invoiceDateHour"
                        :items="DateHourList"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        label="ขั่วโมง"
                        style="width:100%"
                      ></v-select>
              </v-col>
              <v-col cols="12" md="2"  lg="1" class="pt-1 pb-2">
                <v-select
                        v-model="invoiceDateMinute"
                        :items="DateMinuteList"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        label="นาที"
                        style="width:100%"
                      ></v-select>
              </v-col>
             
            </v-row>

            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">รอบบิลภาษี :</h3>
              </v-col>
       
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <v-select
                        v-model="billingCycleYear"
                        :items="billingCycleYearShoW"
              
                        persistent-hint
                        dense hide-details
                        outlined
                        label="ปี"
                        style="width:100%"
                      ></v-select>
              </v-col>
              <v-col cols="12" md="2"  lg="2" class="pt-1 pb-2">
                <v-select
                        v-model="billingCycleMonth"
                        :items="billingCycleMonthList"
                        item-text="name"
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        label="เดือน"
                        style="width:100%"
                      ></v-select>
              </v-col>
             
            </v-row>

            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2"
                ><h3 class="fn-14">บริษัท :</h3>
              </v-col>
              <v-col cols="9" md="7" lg="4" class="pt-1 pb-2">
                <v-autocomplete
                  v-model="supplierID"
                  :items="supplierIDList"
                  item-value="id"
                  item-text="name"
                  dense
                  ref="supplierID"
                  hide-details
                  no-data-text="ไม่พบบริษัท"
                  outlined
                 >
                </v-autocomplete>
              </v-col>
              <v-col cols="3" md="1" lg="1" class="pt-1 pb-2">
                <v-btn color="primary" outlined @click="btnAddSupplier()">
                  <v-icon size="22" class="mr-1">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">ประเภท :</h3>
              </v-col>
              <v-col cols="9" md="7" lg="4" class="pt-1 pb-2">
                <v-autocomplete
                  v-model="expenseTypeID"
                  :items="expenseTypeIDList"
                  item-text="description"
                  item-value="id"
                  dense
                  outlined
                  hide-details
                  ref="expenseTypeID"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" md="1" lg="1" class="pt-1 pb-2">
                <v-btn color="primary" outlined @click="btnAddExpense()">
                  <v-icon size="22" class="mr-1">mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">ยอดเงิน(ก่อน VAT) :</h3>
              </v-col>
              <v-col cols="12" md="7"  lg="4"  class="pt-1 pb-2">
                <v-text-field  
                v-model.number="amount" 
                @change="calculateTotal()"
                outlined 
                dense 
                hide-details 
                required 
                type="number" 
                ref="amount"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">vat :</h3>
              </v-col>
              <v-col cols="12" md="7" lg="4" class="pt-1 pb-2">
                <v-text-field 
                v-model.number="vat" 
                @change="calculateTotal()"
                outlined 
                dense 
                hide-details
                required 
                type="number" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">ยอดรวม vat :</h3>
              </v-col>
              <v-col cols="12" md="7"  lg="4"  class="pt-1 pb-2">
                <v-text-field v-model.number="total" readonly outlined dense hide-details required type="number" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">เลขที่อ้างอิง :</h3>
              </v-col>
              <v-col cols="12" md="7"  lg="4"  class="pt-1 pb-2">
                <v-text-field
                  v-model="search.referenceNumber"
                  dense
                  hide-details
                  autocomplete="off"
                  outlined 
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">{{ use_language.note }} :</h3>
              </v-col>
              <v-col cols="12" md="7" lg="4" class="pt-1 pb-2">
                <v-textarea
                  ref="searchremark"
                  v-model="search.remark"
                  dense
                  hide-details
                  autocomplete="off"
                  outlined
                  rows="4"
                  row-height="15"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2" lg="2" class="pt-1 pb-2">
                <h3 class="fn-14">{{ use_language.note }} :</h3>
              </v-col>
              <v-col cols="12" md="10" lg="10" class="pt-1 pb-2">
                <v-radio-group row v-model="paymentStatusID">
                  <v-radio label="ยังไม่ชำระ" value="1" ></v-radio>
                  <v-radio label="ชำระบางส่วน" value="2" style="color:orange"></v-radio>
                  <v-radio label="ชำระเรียบร้อย" value="3" style="color:green"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          
      </v-card-text>
    </v-card>
    
     <!-- สินค้าเพื่อขาย -->
     <v-card elevation="1" class="v-card-bottom-30">
        
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-wallet</v-icon>
          <span class="ml-3">สินค้าเพื่อขาย</span>
          <v-spacer />
          <v-btn outlined :elevation="1" color="primary" class="px-2" @click="OpenPopupAddPackage()">
            <v-icon size="22" class="mr-1">mdi-plus</v-icon>
            {{ use_language.add_item }}
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table
            :headers="headerOrderProduct"
            :items="purchaseOrderProducts"
            :items-per-page="9999999999"
            hide-default-footer
            item-key="keyId"
            class="packhai-border-table"
          >
            <template v-slot:item.edit="{ item }">
              <a @click="btnEditOrderProduct(item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
            </template>
            <template v-slot:item.delete="{ item, index }">
              <a @click="btnDeleteOrderProduct(item, index)">
                <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
              </a>
            </template>
            <template v-slot:item.photoLink="{ item }">
              <div v-if="item.photoLink != null && item.photoLink != ''">
                <a
                  v-if="CheckUrlPhoto(item.photoLink) == 1"
                  @click="OpenPhoto(item.photoLink)"
                >
                  <v-avatar class="picsize-hover" tile>
                    <img :src="item.photoLink" class="picsize" />
                  </v-avatar>
                </a>
                <a v-else @click="Opentap(item.photoLink)">
                  <v-avatar  class="picsize-hover" tile>
                    <span> File.{{ CheckUrlPhoto(item.photoLink) }} </span>
                  </v-avatar>
                </a>
              </div>
               
            </template>
            <template v-slot:item.quantity="{ item , index}">
                  <v-text-field
                  v-model.number="item.quantity"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      :ref="'Productquantity_' + index "
                      type="number"
                      number
                  ></v-text-field>
            </template>
            <template v-slot:item.unitPrice="{ item , index}">
                    <v-text-field
                        v-model.number="item.unitPrice"
                        autocomplete="off"
                        dense
                        hide-details
                        outlined
                        number
                        :ref="'ProductunitPrice_' + index"
                        type="number"
                    ></v-text-field>
            </template>
            <template v-slot:item.totalPrice="{ item }">
                {{ calculateTotalEquipments(item) }}
            </template>
            <template v-slot:item.productName="{ item,index}">   
              <v-col cols="12" align="left" class="pa-0" >
                <span class="color-gray-13">
                    {{item.productName}}
                </span>
              </v-col>
               <v-col cols="12" align="left" class="pa-0" v-if="item.prop1Description!=null && item.prop1Description!=''">
                <span class="color-gray-13">
                  {{  use_language.type+ " : "+ item.prop1Description + (item.prop2Description==null? "":", "+item.prop2Description)}}
                </span>
              </v-col>

          </template>
          </v-data-table>
        </v-card-text>
     </v-card>
    
     <!-- วัสดุภายในบริษัท -->
     <v-card elevation="1" class="v-card-bottom-30">
        
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-wallet</v-icon>
          <span class="ml-3">วัสดุภายในบริษัท</span>
          <v-spacer />
          <v-btn outlined :elevation="1" color="primary" class="px-2" @click="OpenPopupAddEquipment()">
            <v-icon size="22" class="mr-1">mdi-plus</v-icon>
            {{ use_language.add_item }}
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table
            :headers="headerOrderEquipment"
            :items="purchaseOrderEquipments"
            item-key="keyId"
            :items-per-page="9999999999"
            hide-default-footer
            class="packhai-border-table"
          >

            <template v-slot:item.delete="{ item, index }">
              <a @click="btnDeleteEquipments(item, index)">
                <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
              </a>
            </template>
            
            <template v-slot:item.description="{ item,index}">
              <v-combobox
              v-model="item.description"
              :items="equipmentsItemList"
              @input="equipmenListInput(item,$event)"
              item-text="description"
              item-value="id"
              outlined
              hide-details
              dense
              :ref="'description_' + index"
            ></v-combobox>
            </template>
            <template v-slot:item.quantity="{ item , index}">
                  <v-text-field
                  v-model.number="item.quantity"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      :ref="'quantity_' + index "
                      type="number"
                      number
                  ></v-text-field>
            </template>
          <template v-slot:item.unitPrice="{ item , index}">
                  <v-text-field
                     v-model.number="item.unitPrice"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      number
                      :ref="'unitPrice_' + index"
                      type="number"
                  ></v-text-field>
            </template>
           <template v-slot:item.totalPrice="{ item }">
                {{ calculateTotalEquipments(item) }}
            </template>
        
           
         
          </v-data-table>
          <v-btn text   class="px-2 mt-2" @click="btnAddListEquipment()">
            <v-icon size="22" class="mr-1">mdi-plus</v-icon>
            เพิ่มวัสดุ
          </v-btn>
          
        </v-card-text>
     </v-card>


    <!-- รายการออเดอร์ -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="22" color="red darken-3">mdi mdi-view-list</v-icon
        ><span class="ml-3"> รายการออเดอร์</span>
        <v-spacer />
      </v-card-title>
      <v-divider />
      <v-form lazy-validation>
        <v-card-text class="overflow-y-auto">
          <v-row>
            <v-col cols="12" md="3" class="pb-4 px-0">
              <v-text-field
                v-model="SearchOrderID"
                label="เลขออเดอร์, เลข Package, เลข IE"
                dense
                hide-details
                outlined
                autocomplete="off"
                @keydown.enter.prevent="btnSearchOrder()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="5" class="pb-4">
              <v-btn :elevation="1" color="primary" outlined class="pa-2" @click="btnSearchOrder()">
                <v-icon size="14" class="pr-1"
                  >fa-solid fa-magnifying-glass</v-icon
                >
                ค้นหา
              </v-btn>
            </v-col>

            <v-col cols="12" md="4" class="pb-4 px-0" align="right">
              รวมทั้งหมด
              <span > {{ purchaseOrderCostMapping.length }} </span>
            </v-col>
          </v-row>

          <!-- PC hidden-xs-only -->
          
          <v-data-table
            :headers="headerOrder"
            :items="purchaseOrderCostMapping"
            :items-per-page="9999999999"
            item-key="orderMasterID"
            hide-default-footer
            class="packhai-border-table"
          >
            <template v-slot:item.delete="{ item ,index }">
               <a @click="btnDeletePurchaseOrderCostMapping(item, index)">
                <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
              </a>
            </template>
            <template v-slot:item.no="{ item }">
              <span>
                {{ indexOf(item) }}
              </span>
            </template>
            <template v-slot:item.orderMasterID="{ item }">
              <span v-if="item.orderMasterID" @click="detail_page(item.orderMasterID)">
                <a> {{ item.idText }} </a>
              </span>
              <span v-else>
                <a @click="EditStock(item.importExportMasterID)"> {{ item.idText }} </a>
              </span>
            </template>
            <template v-slot:item.name="{ item }">
              <b>{{ item.shippingAddressName }}</b>
            </template>
            <template v-slot:item.costPrice="{ item }">
              <v-text-field
                v-model.number="item.costPrice"
                @keypress="isNumberWithDot($event)"
                dense
                outlined
                hide-details
                autocomplete="off"
                class="center-input"
              />
            </template>
          </v-data-table>
          
        </v-card-text>
      </v-form>
    </v-card>

    <!-- ประวัติการชำระเงิน -->
    <v-card elevation="1" class="v-card-bottom-30">
        
      <v-card-title class="height-57">
        <v-icon size="22" color="orange darken-3">mdi mdi-wallet</v-icon>
        <span class="ml-3">ประวัติการชำระเงิน</span>
        <v-spacer />
        <v-btn outlined :elevation="1" color="primary" class="px-2" @click="btnAddPayment()">
          <v-icon size="22" class="mr-1">mdi-plus</v-icon>
          {{ use_language.add_item }}
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-data-table
          :headers="headerPayment"
          :items="purchaseOrderPayment"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-border-table"
        >
          <template v-slot:item.edit="{ item }">
            <a @click="btnEditPayment(item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
          </template>
          <template v-slot:item.delete="{ item, index }">
            <a @click="btnDeletePayment(item, index)">
              <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
            </a>
          </template>
          
          <template v-slot:item.paymentDate="{ item }">
            <span>{{set_format_date_time(item.paymentDate)}}</span>
          </template>
          <template v-slot:item.dueDate="{ item }">
            <span>{{set_format_date_time(item.dueDate).substr(0,10)}}</span>
          </template>
          <template v-slot:item.updated="{ item }">
            <span>{{set_format_date_time(item.updated)}}</span>
          </template>
          <template v-slot:item.statusID="{ item }">
            <span v-if="item.statusID==1">ร่าง</span>
            <span style="color:orange" v-if="item.statusID==2">รอชำระ</span>
            <span style="color:green" v-if="item.statusID==4">ชำระแล้ว</span>
          </template>
         
          <template v-slot:item.photoLink="{ item }">
            <div v-if="item.photoLink != null && item.photoLink != ''">
              <a
                v-if="CheckUrlPhoto(item.photoLink) == 1"
                @click="OpenPhoto(item.photoLink)"
              >
                <v-avatar class="picsize-hover" tile>
                  <img :src="item.photoLink" class="picsize" />
                </v-avatar>
              </a>
              <a v-else @click="Opentap(item.photoLink)">
                <v-avatar  class="picsize-hover" tile>
                  <span> File.{{ CheckUrlPhoto(item.photoLink) }} </span>
                </v-avatar>
              </a>
            </div>
             
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- เอกสาร -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="22" color="orange darken-3">mdi mdi-file-document</v-icon>
        <span class="ml-3">{{ use_language.document }}</span>
        <v-spacer />
        <v-btn outlined
          :elevation="1"
          color="primary"
          class="px-2"
          @click="addDocument()"
        >
          <v-icon size="22" class="mr-1">mdi-plus</v-icon>
          {{ use_language.add_item }}
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-data-table
          :headers="headerDoc"
          :items="purchaseOrderPhoto"
          :items-per-page="9999999999"
          item-key="keyId"
          hide-default-footer
          class="packhai-border-table"
        >
          <template v-slot:item.delete="{ item, index }">
            <a @click="btnDeletePicture(item, index)">
              <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
            </a>
          </template>
          <template v-slot:item.photoLink="{ item }">
            <div v-if="item.photoLink != null && item.photoLink != ''">
              <a
                v-if="CheckUrlPhoto(item.photoLink) == 1"
                @click="OpenPhoto(item.photoLink)"
              >
                <v-avatar class="picsize-hover" tile>
                  <img :src="item.photoLink" class="picsize" />
                </v-avatar>
              </a>
              <a v-else @click="Opentap(item.photoLink)">
                <v-avatar class="picsize-hover" tile>
                  <span> File.{{ CheckUrlPhoto(item.photoLink) }} </span>
                </v-avatar>
              </a>
            </div>
            <div v-else>
              <v-avatar class="picsize-hover" tile>
                <span> {{ use_language.no_file }} </span>
              </v-avatar>
            </div>
          </template>
          <template v-slot:item.select="{ item }">
            <a @click="Opentap(item.photoLink)">{{  item.photoLink  }}</a>
           
          </template>
          <template v-slot:item.btn="{ item }">
            <div v-if="item.id==0">

           
              <v-btn
                :elevation="0"
                class="btn-inputfile"
                @click="
                  SetIndex(purchaseOrderPhoto.indexOf(item)), $refs.inputfile.click()">
                  
                <span> {{ use_language.upload_files }} </span>
              </v-btn>
              <input
                class="display-none d-none"
                ref="inputfile"
                accept="image/*"
                type="file"
                @change="previewPicture(indexDoc, $event)"
              />
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card elevation="1" class="v-card-bottom-30">
        <v-row >
          <v-col cols="6"  >
             
          </v-col>
          <v-col cols="6" class="jt_end item-center" >
            <v-btn color="primary" @click="btnSubmitPurchaseOrder()">บันทึก</v-btn>
          </v-col>
        </v-row>
      </v-card>

    <!-- สินค้าเพื่อขาย -->
    <v-dialog v-model="addPackagePopup.Dialog" scrollable :max-width="addPackagePopup.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
        <div class="v-list-item">
          <div class="pa-2">
            ค้นหา 
          </div>
          <div class="v-list-item__content pa-2">
            <v-text-field
              v-model="addPackagePopup.searchStockModelPopup"
              autocomplete="off"
              class="pl-2"
              dense
              hide-details
               @keyup.enter="btnSearchPackagek(addPackagePopup.limits,0)"
                
            ></v-text-field>
          </div>
          <div class="pa-2">
            <v-btn
              :elevation="1"
              color="primary"
              class="pa-2"
              dark
              @click="btnSearchPackagek(addPackagePopup.limits,0)"
            >
              <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
              ค้นหา
            </v-btn>
          </div>
          <div>
            <v-btn icon @click="addPackagePopup.Dialog = false"
              ><v-icon color="danger">mdi-close</v-icon></v-btn
            >
          </div>
        </div>
      </v-card-title>
        <v-divider/>

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only" :style="{height: addPackagePopup.MaxHeight+'px'}">
          <v-data-table
            v-model="addPackagePopup.SelectPackage"
            :headers="addPackagePopup.headers"
            :items="addPackagePopup.Data"
            item-key="id"
            :items-per-page="90000000000"
            hide-default-footer
            show-select
            class="elevation-0 packhai-checkbox-select"
          >
          <template v-slot:header.data-table-select="{ on, props }">
            <span>เลือก</span>
          </template>
          <template v-slot:item.selectProduct="{ item }">
            <v-checkbox   color="red" hide-details ></v-checkbox>
          </template>
          <template v-slot:item.photoLink="{ item }">
            <v-btn v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" height="30">
            </v-btn>
          </template>
          <template v-slot:item.name="{ item }">
            <v-row class="mb-3 mt-3">
              <v-col cols="12" align="left" class="pa-0">
                <div class="fn-13" style="font-weight: bold ">
                  {{ item.name.length > 25 ? item.name.slice(0, 25) + '...' : item.name }}

                </div>
              </v-col>
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.productCode!=null && item.productCode!=''">
                <span class="color-gray-13">
                    {{!item.productCode ? "" :  use_language.number_product + " : "}}  {{ item.productCode }}
                </span>
              </v-col>
         
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.prop1Description!=null && item.prop1Description!=''">
                <span class="color-gray-13">
                  {{  use_language.type+ " : "+ item.prop1Description + (item.prop2Description==null? "":", "+item.prop2Description)}}
                </span>
              </v-col>
            
            
              <v-col cols="12" align="left" class="pa-0">
                <span class="color-gray-13">{{!item.barcode ? "" : use_language.barcode +" : "}} {{ item.barcode }}   </span>
              </v-col>

          

          
              <v-col cols="12" v-if="item.isProductSet" align="left" class="pa-0">
                <span  class="fn-13" >
                  <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                    {{use_language.set_products}}
                </span>
              </v-col>
            </v-row>
              
          </template>
          <template v-slot:item.packedQuantity="{ item }">
                  <v-text-field
                  v-model.number="item.packedQuantity"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      ref="quantity"
                      type="number"
                  ></v-text-field>
            </template>
          <template v-slot:item.unitPrice="{ item }">
                  <v-text-field
                  v-model.number="item.unitPrice"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      ref="quantity"
                      type="number"
                  ></v-text-field>
            </template>
          <template v-slot:item.quantityAvailable="{ item }">
            <span  > {{ formatMoney(item.quantityAvailable) }} </span>
          </template>
          </v-data-table>
        </v-card-text>
        <!-- PC -->

  
         
        <div v-if="addPackagePopup.pageLength>1 && addPackagePopup.Data.length>0" class="text-center pa-1" @click="btnChangePagePackage()">
          <v-pagination
            v-model="addPackagePopup.page"
            :length="addPackagePopup.pageLength"
            :total-visible="addPackagePopup.pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider/>
        <div class="height-57 pa-2 " align="right">
          <v-btn color="primary" class="mr-2" :loading="addPackagePopup.loading" @click="btnSubmitAddOrderProductPopup()">
              {{use_language.add_product}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- แก้ไขสินค้าเพื่อขาย -->
    <v-dialog v-model="editOrderProduct.dialog" 
          :max-width="editOrderProduct.MaxWidth"
          persistent 
          scrollable>
        <v-card>
            <v-card-title>
                 แก้ไขสินค้าเพื่อขาย
                <v-spacer />
                <v-btn icon @click="editOrderProduct.dialog  = false"> 
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>

                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>ภาพ :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <div v-if="editOrderProductDataSet.photoLink != null && editOrderProductDataSet.photoLink != ''">
                      <a
                        v-if="CheckUrlPhoto(editOrderProductDataSet.photoLink) == 1"
                        @click="OpenPhoto(editOrderProductDataSet.photoLink)"
                      >
                        <v-avatar class="picsize-hover" tile>
                          <img :src="editOrderProductDataSet.photoLink" class="picsize" />
                        </v-avatar>
                      </a>
                      <a v-else @click="Opentap(editOrderProductDataSet.photoLink)">
                        <v-avatar class="picsize-hover" tile>
                          <span> File.{{ CheckUrlPhoto(editOrderProductDataSet.photoLink) }} </span>
                        </v-avatar>
                      </a>
                    </div>
                    <div v-else>
                      <v-avatar class="picsize-hover" tile>
                        <span> {{ use_language.no_file }} </span>
                      </v-avatar>
                    </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>ชื่อสินค้า :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <span> 
                        {{editOrderProductDataSet.name}}
                      </span>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>จำนวน :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-text-field
                            v-model="editOrderProductDataSet.quantity"
                            autocomplete="off"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>ราคาต่อหน่วย :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-text-field
                            v-model="editOrderProductDataSet.unitPrice"
                            autocomplete="off"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
             
                
            </v-card-text>
            <v-divider />
            <v-card-actions class="py-5" style="justify-content: flex-end;">
                <v-btn @click="btnSubmitOrderProduct()"
                    outlined
                    color="primary"  
                >
                    บันทึก
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- วัสดุภายในบริษัท -->
    <v-dialog v-model="addEquipmentPopup.Dialog" scrollable :max-width="addEquipmentPopup.MaxWidth" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
        <div class="v-list-item">
          <div class="pa-2">
            ค้นหา 
          </div>
          <div class="v-list-item__content pa-2">
            <v-text-field
              v-model="addEquipmentPopup.searchStockModelPopup"
              autocomplete="off"
              class="pl-2"
              dense
              hide-details
               @keyup.enter="btnSearchPackagek(addEquipmentPopup.limits,0)"
                
            ></v-text-field>
          </div>
          <div class="pa-2">
            <v-btn
              :elevation="1"
              color="primary"
              class="pa-2"
              dark
              @click="btnSearchPackagek(addEquipmentPopup.limits,0)"
            >
              <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
              ค้นหา
            </v-btn>
          </div>
          <div>
            <v-btn icon @click="addEquipmentPopup.Dialog = false"
              ><v-icon color="danger">mdi-close</v-icon></v-btn
            >
          </div>
        </div>
      </v-card-title>
        <v-divider/>

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only" :style="{height: addEquipmentPopup.MaxHeight+'px'}">
          <v-data-table
            v-model="addEquipmentPopup.SelectPackage"
            :headers="addEquipmentPopup.headers"
            :items="addEquipmentPopup.Data"
            item-key="id"
            :items-per-page="90000000000"
            hide-default-footer
            show-select
            class="elevation-0 packhai-checkbox-select"
          >
          <template v-slot:header.data-table-select="{ on, props }">
            <span>เลือก</span>
          </template>
          <template v-slot:item.selectProduct="{ item }">
            <v-checkbox   color="red" hide-details ></v-checkbox>
          </template>
          <template v-slot:item.photoLink="{ item }">
            <v-btn v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
              <img :src="item.photoLink" height="30">
            </v-btn>
          </template>
          <template v-slot:item.name="{ item }">
            <v-row class="mb-3 mt-3">
              <v-col cols="12" align="left" class="pa-0">
                <div class="fn-13" style="font-weight: bold ">
                  {{ item.name.length > 25 ? item.name.slice(0, 25) + '...' : item.name }}

                </div>
              </v-col>
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.productCode!=null && item.productCode!=''">
                <span class="color-gray-13">
                    {{!item.productCode ? "" :  use_language.number_product + " : "}}  {{ item.productCode }}
                </span>
              </v-col>
         
        
              <v-col cols="12" align="left" class="pa-0" v-if="item.prop1Description!=null && item.prop1Description!=''">
                <span class="color-gray-13">
                  {{  use_language.type+ " : "+ item.prop1Description + (item.prop2Description==null? "":", "+item.prop2Description)}}
                </span>
              </v-col>
            
            
              <v-col cols="12" align="left" class="pa-0">
                <span class="color-gray-13">{{!item.barcode ? "" : use_language.barcode +" : "}} {{ item.barcode }}   </span>
              </v-col>

          

          
              <v-col cols="12" v-if="item.isProductSet" align="left" class="pa-0">
                <span  class="fn-13" >
                  <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                    {{use_language.set_products}}
                </span>
              </v-col>
            </v-row>
              
          </template>
          <template v-slot:item.quantity="{ item }">
                  <v-text-field
                  v-model.number="item.packedQuantity"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      ref="quantity"
                      type="number"
                  ></v-text-field>
            </template>
          <template v-slot:item.unitPrice="{ item }">
                  <v-text-field
                  v-model.number="item.unitPrice"
                      autocomplete="off"
                      dense
                      hide-details
                      outlined
                      ref="quantity"
                      type="number"
                  ></v-text-field>
            </template>
        
          </v-data-table>
        </v-card-text>
        <!-- PC -->

  
         
        <div v-if="addEquipmentPopup.pageLength>1 && addEquipmentPopup.Data.length>0" class="text-center pa-1" @click="btnChangePagePackage()">
          <v-pagination
            v-model="addEquipmentPopup.page"
            :length="addEquipmentPopup.pageLength"
            :total-visible="addEquipmentPopup.pageTotalVisible"
            color="error"
          ></v-pagination>
        </div>

        <v-divider/>
        <div class="height-57 pa-2 " align="right">
          <v-btn color="primary" class="mr-2" :loading="addEquipmentPopup.loading" @click="btnSubmitAddEquipmentPopup()">
              {{use_language.add_product}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


     <!-- เพิ่มชื่อบริษัท -->
    <v-dialog v-model="addSupplierPopup.dialog" 
          :max-width="addSupplierPopup.MaxWidth"
          persistent 
          scrollable>
        <v-card>
            <v-card-title>
                 เพิ่มบริษัท
                <v-spacer />
                <v-btn icon @click="addSupplierPopup.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>รหัส Supplier :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-text-field
                            v-model="addSupplierDataSet.code"
                            autocomplete="off"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>เลขผู้เสียภาษี :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-text-field
                            v-model="addSupplierDataSet.taxID"
                            autocomplete="off"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>ชื่อ :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-text-field
                            v-model="addSupplierDataSet.name"
                            autocomplete="off"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>ที่อยู่ :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                        <v-text-field
                            v-model="addSupplierDataSet.address"
                            autocomplete="off"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>เบอร์โทร :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-text-field
                              :value="addSupplierDataSet.tel"
                              @change="value => addSupplierDataSet.tel = value"
                              autocomplete="off"
                               maxlength="10"
                              outlined
                              hide-details
                              @keypress="isNumberWNoDot($event)"
                              dense
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>ไปรษณีย์ :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-autocomplete
                      v-model="addSupplierDataSet.AddressObj"
                      ref="refShopAddressManageAddress"
                      placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                      :items="postcode_mapping"
                      :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                      outlined
                      required
                      return-object
                      hide-details
                      dense

                    ></v-autocomplete>
                    </v-col>
                </v-row>
                
            </v-card-text>
            <v-divider />
            <v-card-actions class="py-5" style="justify-content: flex-end;">
                <v-btn @click="btnSummitAddSupplier()"
                    outlined
                    color="primary"  
                >
                    {{use_language.add_item}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
     <!-- เพิ่มประเภท -->
    <v-dialog v-model="addExpensePopup.dialog" 
          :max-width="addExpensePopup.MaxWidth"
          persistent 
          scrollable>
        <v-card>
            <v-card-title>
                 เพิ่มประเภท
                <v-spacer />
                <v-btn icon @click="addExpensePopup.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>

                <v-row>
                    <v-col cols="12" sm="2" lg="3" class="pb-0">
                        <h4>ชื่อประเภท :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="5" class="pb-0">
                      <v-text-field
                            v-model="addExpenseDataSet.description"
                            autocomplete="off"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
             
                
            </v-card-text>
            <v-divider />
            <v-card-actions class="py-5" style="justify-content: flex-end;">
                <v-btn @click="btnSummitAddExpense()"
                    outlined
                    color="primary"  
                >
                    {{use_language.add_item}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

     <!-- เพิ่มประวัติการชำระเงิน -->
    <v-dialog v-model="addPaymentHistoryPopup.dialog" 
          :max-width="addPaymentHistoryPopup.MaxWidth"
          persistent >
        <v-card>
            <v-card-title>
              <span v-if="addPaymentHistoryDataSet.id==0">เพิ่มรายการจ่ายเงิน</span>
              <span v-else>แก้ไขรายการจ่ายเงิน</span>
                 
                <v-spacer />
                <v-btn icon @click="addPaymentHistoryPopup.dialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="2" lg="2" class="pb-0">
                        <h4>สถานะ :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="8" class="pb-0">
                       <v-radio-group row v-model="itemPaymentStatusID">
                          <v-radio  label="ร่าง" value="1"> </v-radio>
                          <v-radio  label="รอชำระ" value="2"> </v-radio> 
                          <v-radio  label="ชำระแล้ว" value="4"> </v-radio>
                       </v-radio-group>
                    </v-col>
                   
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="2" class="pb-0">
                        <h4>ยอดเงิน :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="8" class="pb-0">
                        <v-text-field
                            v-model.number="addPaymentHistoryDataSet.paymentAmount"
                            autocomplete="off"
                            type="number"
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2" lg="2" class="pb-0">
                        <h4>วันครบกำหนด :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="8" class="pb-0">
                      <v-menu
                          v-model="menu_due_date"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                          <template v-slot:activator="{ on }">
                              <v-text-field
                                  :value="computed_due_date"
                                  @change="value => computed_due_date = value"
                                  autocomplete="off"
                                  ref="select_dueDate"
                                  outlined
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  hide-details
                                  clearable 
                                  @click:clear="select_dueDate = null"
                                  dense
                                  v-on="on"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="select_dueDate"
                              no-title 
                              @input="menu_due_date = false"
                          ></v-date-picker>
                      </v-menu>
                    </v-col>
                   
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2"  lg="2" class="pb-0">
                        <h4>วันที่ชำระเงิน :</h4>
                    </v-col>
                    <v-col cols="12" sm="4" lg="4" class="pb-0">
                      <v-menu
                          v-model="menu_transfer_date"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                      >
                          <template v-slot:activator="{ on }">
                              <v-text-field
                                  :value="computed_transfer_date"
                                  @change="value => computed_transfer_date = value"
                                  autocomplete="off"
                                  ref="select_transferDate"
                                  outlined
                                  prepend-icon=""
                                  prepend-inner-icon="mdi-calendar"
                                  readonly
                                  hide-details
                                  clearable 
                                  @click:clear="select_transferDate = null"
                                  dense
                                  v-on="on"
                              ></v-text-field>
                          </template>
                          <v-date-picker
                              v-model="select_transferDate"
                              no-title 
                              @input="menu_transfer_date = false"
                          ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" lg="2" class="pb-0">
                    <v-select
                            v-model="select_transferHour"
                            :items="DateHourList"
                            item-text="name"
                            item-value="value"
                            persistent-hint
                            dense hide-details
                            outlined
                            label="ขั่วโมง"
                             
                          ></v-select>
                  </v-col>
                  <v-col cols="12" sm="3" lg="2" class="pb-0">
                    <v-select
                            v-model="select_transferMinute"
                            :items="DateMinuteList"
                            item-text="name"
                            item-value="value"
                            persistent-hint
                            dense hide-details
                            outlined
                            label="นาที"
                            
                          ></v-select>
                  </v-col>
                </v-row>
               
              
                <v-row>
                    <v-col cols="12" sm="2" lg="2" class="pb-0">
                        <h4>หมายเหตุ :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="8" class="pb-0">
                        <v-text-field
                            v-model="addPaymentHistoryDataSet.remark"
                            autocomplete="off"
                            
                            outlined
                            hide-details
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" lg="2" class="pb-0">
                      <h4>{{use_language.bank}} :</h4>
                  </v-col>
                  <v-col cols="12" sm="9" lg="8" class="pb-0">
                    <v-select
                          v-model="paymentBank"
                          :items="cod_bank_data"
                          item-text="fullBankName"
                          item-value="id"
                          persistent-hint
                          dense hide-details
                          outlined
                          return-object

                          style="width:100%"
                        ></v-select>
                        
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2"  lg="2" class="pb-0">
                        <h4>แนบไฟล์ :</h4>
                    </v-col>
                    <v-col cols="12" sm="10" lg="8" class="pb-0">
                        <v-file-input
                            v-model="addPaymentHistoryDataSet.files"
                            outlined
                            hide-details="auto"
                            dense
                            prepend-icon=""
                            prepend-inner-icon="mdi-file-image"
                             
                        >
                            <template v-slot:selection="{ text }">
                                <v-chip
                                    small
                                    label
                                    color="primary"
                                >
                                    {{ text }}
                                </v-chip>
                            </template>
                        </v-file-input>
                     </v-col>
                </v-row>

             
            </v-card-text>
            <v-divider />
            <v-card-actions class="py-5" style="justify-content: flex-end;">
                <v-btn
                    outlined
                    color="primary"
                    @click="btnSubmitAddPayment()"
                >
                    {{use_language.save}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
>>> .v-select__slot {
  height: 40px;
}
>>> .v-input__prepend-inner {
  margin-top: 10px;
}
</style>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import moment from "moment";
import {
  stockService_dotnet,
  shopService_dotnet,
  orderService_dotnet,
  generalService_dotnet,
} from "@/website/global";
import { AlertSuccess, AlertError } from "@/website/global_alert";
import {
  formatMoney,
  isNumberWithDot,
  isNumberWNoDot,
  DateNowNotNull,
  GenIE,
  Trim_value,
  Trim_rules,
  formatDatetime,
  ChangeIE,
  get_languages,
  CheckUrlPhoto,
  ChangeOrderId,
  set_format_date_time,
  format_order_id,
  formatMoneyIsDecimal
} from "@/website/global_function";
import dateTimePicker from "@/website/components/dateTimePicker"

export default {
  components: {
    Loading,
    dateTimePicker,
  },
  data: (vm) => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    staffShopID:parseInt(localStorage.getItem("staff_id")),
    shopId: parseInt(localStorage.getItem("shop_id")),
    warehouseID:null,
    branchID: null,
    paymentStatusID:"1",
    page_loading: true,
    loading: false,
    use_language: null,
    set_language: null,
    postcode_mapping: [],
    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    authorize: false,

    //#region วันที่

    startDate: null,
    dateStart: null,
    menuStartDate: false,
    checkStart: true,
    endDate: null,
    dateEnd: null,
    menuEndDate: false,
    checkEnd: true,

    DateMinuteList: [
        { name: '00', value: '00' },
        { name: '01', value: '01' },
        { name: '02', value: '02' },
        { name: '03', value: '03' },
        { name: '04', value: '04' },
        { name: '05', value: '05' },
        { name: '06', value: '06' },
        { name: '07', value: '07' },
        { name: '08', value: '08' },
        { name: '09', value: '09' },
        { name: '10', value: '10' },
        { name: '11', value: '11' },
        { name: '12', value: '12' },
        { name: '13', value: '13' },
        { name: '14', value: '14' },
        { name: '15', value: '15' },
        { name: '16', value: '16' },
        { name: '17', value: '17' },
        { name: '18', value: '18' },
        { name: '19', value: '19' },
        { name: '20', value: '20' },
        { name: '21', value: '21' },
        { name: '22', value: '22' },
        { name: '23', value: '23' },
        { name: '24', value: '24' },
        { name: '25', value: '25' },
        { name: '26', value: '26' },
        { name: '27', value: '27' },
        { name: '28', value: '28' },
        { name: '29', value: '29' },
        { name: '30', value: '30' },
        { name: '31', value: '31' },
        { name: '32', value: '32' },
        { name: '33', value: '33' },
        { name: '34', value: '34' },
        { name: '35', value: '35' },
        { name: '36', value: '36' },
        { name: '37', value: '37' },
        { name: '38', value: '38' },
        { name: '39', value: '39' },
        { name: '40', value: '40' },
        { name: '41', value: '41' },
        { name: '42', value: '42' },
        { name: '43', value: '43' },
        { name: '44', value: '44' },
        { name: '45', value: '45' },
        { name: '46', value: '46' },
        { name: '47', value: '47' },
        { name: '48', value: '48' },
        { name: '49', value: '49' },
        { name: '50', value: '50' },
        { name: '51', value: '51' },
        { name: '52', value: '52' },
        { name: '53', value: '53' },
        { name: '54', value: '54' },
        { name: '55', value: '55' },
        { name: '56', value: '56' },
        { name: '57', value: '57' },
        { name: '58', value: '58' },
        { name: '59', value: '59' }
      ],
    DateHourList: [
      { name: '00', value: '00' },
      { name: '01', value: '01' },
      { name: '02', value: '02' },
      { name: '03', value: '03' },
      { name: '04', value: '04' },
      { name: '05', value: '05' },
      { name: '06', value: '06' },
      { name: '07', value: '07' },
      { name: '08', value: '08' },
      { name: '09', value: '09' },
      { name: '10', value: '10' },
      { name: '11', value: '11' },
      { name: '12', value: '12' },
      { name: '13', value: '13' },
      { name: '14', value: '14' },
      { name: '15', value: '15' },
      { name: '16', value: '16' },
      { name: '17', value: '17' },
      { name: '18', value: '18' },
      { name: '19', value: '19' },
      { name: '20', value: '20' },
      { name: '21', value: '21' },
      { name: '22', value: '22' },
      { name: '23', value: '23' },
    ],

    billingCycleMonthList:[    
      { name: '1', value: '01' },
      { name: '2', value: '02' },
      { name: '3', value: '03' },
      { name: '4', value: '04' },
      { name: '5', value: '05' },
      { name: '6', value: '06' },
      { name: '7', value: '07' },
      { name: '8', value: '08' },
      { name: '9', value: '09' },
      { name: '10', value: '10' },
      { name: '11', value: '11' },
      { name: '12', value: '12' },],
    
    billingCycleYearList:[ ],
    billingCycleMonth:  '01',
    billingCycleYear:new Date().getFullYear(),
      // #endregion
    // ค้นหา
    search: {
      branchID: null,
      startDate: null,
      endDate: null,
      remark: null,
      importExportMasterId: null,
      referenceNumber: null,
    },
    //#region  resBody
    invoiceDate:'',
    invoiceDateMinute :'',
    invoiceDateHour:'',
    remark: '',
    amount: null,
    vat: null,
    total: null,
    expenseTypeID: null,
    expenseTypeIDList: [],
    supplierID: null,
    supplierIDList:[],
    purchaseType: null,
    id:null,
    idText:null,
    // #endregion

    headerOrder: [
      {
        text: "ลบ",
        align: "center",
        sortable: false,
        value: "delete",
        width: "60px",
      },
      {
        text: "เลขออเดอร์",
        align: "center",
        sortable: false,
        value: "orderMasterID",
        width: "100px",
      },
      {
        text: "ชื่อลูกค้า",
        align: "center",
        sortable: false,
        value: "name",
        width: "130px",
      },
      {
        text: "ยอดเงิน",
        align: "center",
        sortable: false,
        value: "costPrice",
        width: "130px",
      },
    ],
    //#region  PurchaseOrderProduct
    purchaseOrderProducts:[],

    addPackagePopup: {
      "MaxWidth": "50%",
      "MaxHeight": 450,
      "Dialog": false,
      "headers": [
        // { text: 'เลือก',align: 'center',sortable: false,value: 'selectProduct', width: '50px'},
        // { text: 'No',align: 'center',sortable: false,value: 'No', width: '60px'},
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '60px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name'},
        { text: 'คงเหลือ',align: 'right',sortable: false,value: 'quantityAvailable', width: '90px'},
        { text: 'จำนวน',align: 'right',sortable: false,value: 'packedQuantity', width: '90px'},
        { text: 'ราคาต่อหน่วย',align: 'right',sortable: false,value: 'unitPrice', width: '90px'},
      ],
      "Select_Product": [],
      "Data": [],
      "ProductID_List": [],
      "loading": false,
      
      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 15,
      offsets: 0,
      ResultCount:0,
      Popupsearch: null,
      SelectPackage:[],

    },
  
    editOrderProduct: {
      dialog: false,
      MaxWidth: "45%",
      MaxHeight: 60,
    },
    
    editOrderProductDataSet: {
        id: null,
        shopID: null,
        branchID: null,
        description:null
    },
    headerOrderProduct:[
        // { text: 'แก้ไข', value: 'edit', align: 'center', sortable: false , width: '100px' ,},
        { text: 'ลบ', value: 'delete', align: 'center', sortable: false , width: '100px' ,},
        { text: 'ภาพ', value: 'photoLink', align: 'center', sortable: false , width: '100px' ,},
        { text: 'สินค้า', value: 'productName', align: 'left', sortable: false ,  },
        { text: "Barcode", value: "barcode", align: 'left', sortable: false, width: '50px'},
        { text: "Sku", value: "sku", align: 'left', sortable: false, width: '50px'},
        { text: 'จำนวน', value: 'quantity', align: 'center', sortable: false, width: '140px'},
        { text: 'ราคาต่อหน่อย', value: 'unitPrice', align: 'center', sortable: false , width: '140px' },
        { text: 'ราคารวม', value: 'totalPrice', align: 'center', sortable: false , width: '100px'},
    ],
   
    // #endregion
    //#region  PurchaseOrderEquipment
    purchaseOrderEquipments:[],
    addEquipmentPopup: {
      "MaxWidth": "50%",
      "MaxHeight": 450,
      "Dialog": false,
      "headers": [
        // { text: 'เลือก',align: 'center',sortable: false,value: 'selectProduct', width: '50px'},
        // { text: 'No',align: 'center',sortable: false,value: 'No', width: '60px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'description'},
        { text: 'จำนวน',align: 'right',sortable: false,value: 'quantity', width: '90px'},
        { text: 'ราคาต่อหน่วย',align: 'right',sortable: false,value: 'unitPrice', width: '90px'},
      ],
      "Select_Product": [],
      "Data": [],
      "ProductID_List": [],
      "loading": false,
      
      // paginations
      page: 1,
      pageTemp: 1,
      pageLength: 1,
      pageTotalVisible: 9,
      limits: 15,
      offsets: 0,
      ResultCount:0,
      Popupsearch: null,
      SelectPackage:[],

    },
    headerOrderEquipment:[
        // { text: 'แก้ไข', value: 'edit', align: 'center', sortable: false , width: '100px' ,},
        { text: 'ลบ', value: 'delete', align: 'center', sortable: false , width: '100px' ,},
        { text: 'รายละเอียด', value: 'description', align: 'left', sortable: false ,  },
        { text: 'จำนวน', value: 'quantity', align: 'center', sortable: false, width: '140px'},
        { text: 'ราคาต่อหน่อย', value: 'unitPrice', align: 'center', sortable: false , width: '140px' },
        { text: 'ราคารวม', value: 'totalPrice', align: 'center', sortable: false , width: '100px'},
    ],
    equipmentsItemList:[],


    // #endregion
    


    purchaseOrderCostMapping: [],
    headerPayment: [
      
      { text: 'แก้ไข',align: 'center',sortable: false,value: 'edit', width: '60px'},
      {
        text: "ลบ",
        align: "center",
        sortable: false,
        value: "delete",
        width: "60px",
      },
      
      {
        text: "วันที่ดิวชำระ",
        align: "center",
        sortable: false,
        value: "dueDate",
        width: "100px",
      },
      {
        text: "วันที่ชำระเงิน",
        align: "center",
        sortable: false,
        value: "paymentDate",
        width: "150px",
      },
      {
        text: "ยอดเงิน",
        align: "right",
        sortable: false,
        value: "paymentAmount",
        width: "130px",
      },
      {
        text: "ธนาคาร",
        align: "center",
        sortable: false,
        value: "bankAccountText", 
        width: "180px",
      },
      {
        text: "สถานะ",
        align: "center",
        sortable: false,
        value: "statusID",
        width: "100px",
      },
      {
        text: "หมายเหตุ",
        align: "center",
        sortable: false,
        value: "remark",
        width: "180px",
      },
      {
        text: "ไฟล์แนบ",
        align: "center",
        sortable: false,
        value: "photoLink",
        width: "130px",
      },
      {
        text: "อัพเดท",
        align: "center",
        sortable: false,
        value: "updated",
        width: "150px",
      },
      {
        text: "อัพเดทโดย",
        align: "center",
        sortable: false,
        value: "updatedByName",
        width: "90px",
      },
    ],
    itemPaymentStatusID:"2",
    purchaseOrderPayment: [],
    headerDoc: [
      {
        text: "ลบ",
        align: "center",
        sortable: false,
        value: "delete",
        width: "60px",
      },
    
      {
        text: "เอกสาร",
        align: "center",
        sortable: false,
        value: "photoLink",
        width: "100px",
      },
      {
        text: "ชื่อ",
        align: "center",
        sortable: false,
        value: "filename", 
      },
      { text: "link", align: "left", sortable: false, value: "select" },
      {
        text: "",
        align: "center",
        sortable: false,
        value: "btn", 
      },
    ],
    purchaseOrderPhoto: [],
    
    addSupplierDataSet: {
        id: null,
        shopID: null, 
        branchID: null,
        code: null,
        name: null,
        tel: null,
        address: null,
        postcodeMappingID: null,
        taxID: null,
        subDistrict: null,
        district: null,
        province: null,
        provinceID: 0,
        postcode: null ,
        AddressObj:[], 
    },
    addSupplierPopup: {
      dialog: false,
      MaxWidth: "45%",
      MaxHeight: 60,
    },

    addExpenseDataSet: {
        id: null,
        shopID: null,
        branchID: null,
        description:null
    },
    addExpensePopup: {
      dialog: false,
      MaxWidth: "45%",
      MaxHeight: 60,
    },

 
    addPaymentHistoryDataSet: {
      dataSet: {
       id : 0,
       paymentDate : '' ,
       dueDate :  '' ,
       paymentAmount : null,
       photoLink : '' ,
       bankAccountText:null,
       updated:null,
       updatedByName:null,
      },

      dueDateDate: null,
      datedueDate: null,
     
      
    },
    paymentBank:null,
    menu_due_date:null,
    menu_transfer_date:null,
    select_transferDate:null,
    select_transferHour:null,
    select_transferMinute:null,
    select_dueDate  :null,
    addPaymentHistoryPopup: {
      dialog: false,
      MaxWidth: "800",
      MaxHeight: 70,
    },
    SearchOrderID:null,
  }),
  computed: { 
    computed_due_date() {
        if(this.select_dueDate == null) {
          return null
        }else {
          return this.formatDate(this.select_dueDate)
        }
    },
    computed_transfer_date() {
        if(this.select_transferDate == null) {
          return null
        }else {
          return this.formatDate(this.select_transferDate)
        }
    },
    billingCycleYearShoW(){
      const currentYear = new Date().getFullYear();
      const billingCycleYears = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => 2023 + i);
      
      return billingCycleYears
    },
    
  },
  watch: {

  
  },
  async created() {
    //เก็บภาษามาไว้ใน use_language
    this.use_language = await this.get_languages( localStorage.getItem("set_language") );

    await this.get_postcode_mapping();

    
    
    // document.title = this.$router.history.current.meta.title;

    await this.load_data();
    let editID = this.$route.query.id
    if(editID != undefined ){
      this.editPurchaseOrder(editID)
    }else{
      this.getDateTimeNewInvoiceDate();
    }
    let shopBank = await axios.post(shopService_dotnet+'Shop/get-shop-bank-list', {"shopID": this.shopId},{ headers: this.header_token});
    this.cod_bank_data = shopBank.data;
    
    this.page_loading = false;
  },
  methods: {
    //#region  API
    async load_data() {
      this.loading = true;
      await axios
        .all([
          axios.post(
            orderService_dotnet +"PO/get-expense-type",
            {
              shopID:this.shopId ,
            },
            { headers: this.header_token }
          ),
          axios.post(
            orderService_dotnet +"PO/get-supplier-for-dropdown",
            {
              shopID:this.shopId ,
            },
            { headers: this.header_token }
          ),
          axios.post(
            shopService_dotnet +"Shop/get-warehouse-list",
            {
              shopID:this.shopId ,
            },
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((expense, supplier,warehouse) => {
            if (expense.status == 200 && supplier.status == 200 && warehouse.status == 200 ) {
              this.expenseTypeIDList = expense.data.items
              this.supplierIDList  = supplier.data.items
           
              this.warehouseID = warehouse.data[0].id
            }
          })
        );
      this.loading = false;
    },
    async get_postcode_mapping() {
      var postcode_mapping = JSON.parse(
        localStorage.getItem("postcodeMapping_dotnet")
      );
      if (postcode_mapping === "undefined") {
        postcode_mapping = null;
      } else if (postcode_mapping == "null") {
        postcode_mapping = null;
      } else if (postcode_mapping == null) {
        postcode_mapping = null;
      } else if (postcode_mapping == "") {
        postcode_mapping = null;
      }
      if (postcode_mapping != null) {
        this.postcode_mapping = postcode_mapping;
      } else {
        await axios
          .post(
            generalService_dotnet + "General/get-postcodemapping",
            {},
            { headers: this.header_token }
          )
          .then((response) => {
            localStorage.setItem(
              "postcodeMapping_dotnet",
              JSON.stringify(response.data)
            );
            this.postcode_mapping = response.data;
          });
      }
    },
  
    async getPurchaseOrderid(id,importexportmasterid,packageid) {
      return await axios
        .post(
          orderService_dotnet +"PO/get-purchase-orderid",
          {
            id:id,
            importexportmasterid:importexportmasterid,
            packageID:packageid,
            shopID: this.shopId,
          },
          { headers: this.header_token }
        )
        .then(async (res) => { 
            return res.data.items
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getPurchaseOrder(body) {
      return await axios
        .post(
          orderService_dotnet +"PO/get-purchase-order",body,
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async updatePurchaseOrder(body) {
      return await axios
        .post(
          orderService_dotnet +"PO/update-purchase-order",body,
          
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.alertError('กรุณาติดต่อเจ้าหน้าที่');
          return false;
        });
    },
    async deletePurchaseOrder(body) {
      return await axios
        .get(
          orderService_dotnet +"PO/delete-purchase-order?ID="+body.id,
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getExpenseType() {
      return await axios
        .post(
          orderService_dotnet +"PO/get-expense-type",
          {
            shopID:this.shopId , 
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
             return res.data.items
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async updateExpenseType(body) {
      return await axios
        .post(
          orderService_dotnet +"PO/update-expense-type",
          {
            shopID:this.shopId , 
            id: body.id,
            description: body.description,
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async deleteExpenseType(body) {
      return await axios
        .get(
          orderService_dotnet +"PO/delete-expense-type?ID="+body.id,
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async getSupplierForDropDown() {
      return await axios
        .post(
          orderService_dotnet +"PO/get-supplier-for-dropdown",
          {
            shopID:this.shopId , 
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data.items
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async updateSupplier(body) {
      return await axios
        .post(
          orderService_dotnet +"PO/update-supplier",
          {
            id: body.id, 
            shopID: this.shopId, 
            code: body.code,
            name: body.name,
            tel: body.tel,
            address: body.address,
            postcodeMappingID: body.postcodeMappingID,
            taxID: body.taxID,
            subDistrict: body.subDistrict,
            district: body.district,
            province: body.province,
            provinceID: body.provinceID,
            postcode: body.postcode
          },
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    async deleteSupplier(body) {
      return await axios
        .get(
          orderService_dotnet +"PO/delete-supplier?ID="+body.id,
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
    toExpenseList(){
      this.$router.push('/expense')
    },
    async UploadFile(files){
      let bodyUploadFile = [];

      
      var from_data = new FormData();
          from_data.append("bucket", "Doc_file");
          from_data.append("file", files);
          bodyUploadFile = from_data;

      return await axios
        .post(
          generalService_dotnet + "General/upload-file-with-bucket",
          bodyUploadFile,
          { headers: this.header_token }
        )
        .then(async (res) => {
            return res.data
        })
        .catch((error) => {
          this.loading = false;
          this.AlertError();
          return false;
        });
    },
     async getSelectPackage(limits,offsets,searchstock,page,isNeedResultCount,ProductIDList){
           try {               
             return await axios.post(stockService_dotnet+'Stock/get-stock-list-addorder', {
                "shopID": this.shopId,
                "keyword": searchstock,
                "sku": null,
                "name": null,
                "barcode": null,
                "warehouseID": this.warehouseID,
                "isNeedResultCount": isNeedResultCount,
                "isProductSet":false,
                "skipStockShopIDList": ProductIDList,
                "skip": offsets,
                "take": limits,

             },
             { headers: this.header_token})
             .then(async (res) => {
              return res.data;
             })
            .catch((error) => {
              this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: error,
             })
              return false;
            });

            
           } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }
         },
     async getSelectEquipment(limits,offsets,searchstock,page,isNeedResultCount,EquipmentIDList){
           try {               
             return await axios.post(orderService_dotnet+'PO/get-equipment-list-add', {
                "shopID": this.shopId,
                "keyword": searchstock,
                "sku": null,
                "name": null,
                "barcode": null,
                "branchID": null,
                "isNeedResultCount": isNeedResultCount,
                "skipShopEquipmentIDList": EquipmentIDList,
                "skip": offsets,
                "take": limits,

             },
             { headers: this.header_token})
             .then(async (res) => {
              return res.data;
             })
            .catch((error) => {
              this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: error,
             })
              return false;
            });

            
           } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }
         },
       
    
    // #endregion
    //#region  Btn
    async btnSearchOrder(){

        if(this.SearchOrderID)
        { 
          this.SearchOrderID = this.SearchOrderID.trim();
          let orderMasterID = null;
          let importexportmasterid = null;
          let packageid = null;
            //หาด้วย PA 
          
          if(this.SearchOrderID.substr(0,2).toUpperCase()=="PK"){
            packageid = parseInt(this.SearchOrderID.substr(2));
          }
          else if(this.SearchOrderID.substr(0,2).toUpperCase()=="IE"){
            importexportmasterid = parseInt(this.SearchOrderID.substr(2));
          }
          else if(this.SearchOrderID.substr(0,2).toUpperCase()=="PA"){
            orderMasterID= parseInt(this.SearchOrderID.substr(2));
          }
          else{
            orderMasterID= parseInt(this.SearchOrderID);
          }

          if(orderMasterID || importexportmasterid||packageid)
          {
            let res = await  this.getPurchaseOrderid(orderMasterID,importexportmasterid,packageid);

            if(res != null)
            {
              let findDubPA = null 
              let findDubIE = null
              if(res.orderMasterID){
                findDubPA = this.purchaseOrderCostMapping.find(i => i.orderMasterID == res.orderMasterID )
              }else if(res.importExportMasterID){
                findDubIE = this.purchaseOrderCostMapping.find(i => i.importExportMasterID == res.importExportMasterID )
              }
              if(findDubPA || findDubIE)
              {
                let dubID 
                if(findDubPA){
                  dubID ="PA"+ findDubPA.orderMasterID
                }else{
                  dubID = "IE" + findDubIE.importExportMasterID
                }
                this.alertError("รายการออเดอร์ซ้ำ "+dubID)
              }
              else
              {
              
                  res.costPrice = null
                  this.purchaseOrderCostMapping.push(res);
                
              
              }
            }
            else{
              this.alertFound()
            }
          }
        }
        this.SearchOrderID = null
  
    },
    async btnDeletePurchaseOrderCostMapping(index)
    {
        this.purchaseOrderCostMapping.splice(index, 1);
    },
    async btnDeletePicture(item, index) {
      var file = item;
          if (file != undefined) {
          // เก็บภาพเดิมที่มีเอาไปลบออก
          if (item.photoLink) {
            var pathRemove = item.photoLink;
            let response = await axios.post(
              generalService_dotnet + "General/delete-file",
              {
                url: pathRemove,
              },
              { headers: this.header_token }
            );

          }
          this.purchaseOrderPhoto.splice(index, 1);
         }
    },
    async btnAddSupplier(){
      this.addSupplierDataSet = {};
      this.addSupplierPopup.dialog = true;
      
    },
    async btnSummitAddSupplier(){
      try {
        if(this.addSupplierDataSet.name.trim()){
         
         this.addSupplierDataSet.id = 0
         this.addSupplierDataSet.shopID = this.shopId 
     
         if(this.addSupplierDataSet.AddressObj )
         {
           this.addSupplierDataSet.postcodeMappingID = this.addSupplierDataSet.AddressObj.Id
           this.addSupplierDataSet.subDistrict = this.addSupplierDataSet.AddressObj.SubDistrict
           this.addSupplierDataSet.district = this.addSupplierDataSet.AddressObj.District
           this.addSupplierDataSet.province = this.addSupplierDataSet.AddressObj.Province
           this.addSupplierDataSet.provinceID = this.addSupplierDataSet.AddressObj.ProvinceId
           this.addSupplierDataSet.postcode = this.addSupplierDataSet.AddressObj.Postcode
         }
           
         let res = await this.updateSupplier(this.addSupplierDataSet);
          if(res.status =="success")
          {
            this.supplierIDList = await this.getSupplierForDropDown();
            // let setSupplier = this.supplierIDList.find(s => s.name == this.addSupplierDataSet.name)
            // console.log("setSupplier",setSupplier);

            const maxId = Math.max(...this.supplierIDList.map(item => item.id));
            this.supplierID = maxId
            this.addSupplierPopup.dialog = false;

          }else{
            this.alertError(res.error_description);
          }
       }else{
        this.alertError("กรุณากรอกชื่อ")
       }
      } catch (error) {
        this.alertStop();
      }
      
      
    },
    async btnAddExpense(){
      this.addExpenseDataSet = {};
      this.addExpensePopup.dialog = true;
      
    },
    async btnSummitAddExpense(){
      try {
        if(this.addExpenseDataSet.description.trim()){
         
         this.addExpenseDataSet.id = 0
         this.addExpenseDataSet.shopID = this.shopId 
     
         let res = await this.updateExpenseType(this.addExpenseDataSet);
          if(res.status == "success")
          {
            this.expenseTypeIDList = await this.getExpenseType();
            // let setExpense =  this.expenseTypeIDList.find(e => e.description == this.addExpenseDataSet.description)
            // console.log("setExpense",setExpense);
            const maxId = Math.max(...this.expenseTypeIDList.map(item => item.id));

            this.expenseTypeID = maxId
            this.addExpensePopup.dialog = false;

          }else{
            this.alertError(res.error_description);
          }
       }else{
         this.alertStop();
       }
      } catch (error) {
        this.alertStop();
      }
      
    },
    async btnAddPayment(){
      this.addPaymentHistoryDataSet = { id:0,remark:null,paymentAmount:null}
      this.addPaymentHistoryPopup.dialog = true;
      this.select_transferDate=null;
      this.select_transferHour=null;
      this.select_transferMinute=null;
      this.select_dueDate  =null;
    },
    async btnEditPayment(data){
       
      if(data.paymentDate){
        let date =  data.paymentDate.substr(0,10);
        let hours = moment(data.paymentDate, "YYYY-MM-DDTHH:mm:ss.SS").format("HH");
        let minute = moment(data.paymentDate, "YYYY-MM-DDTHH:mm:ss.SS").format("mm");

        
        this.select_transferDate  =  date
        this.select_transferHour  = hours
        this.select_transferMinute  = minute
      }else{
        this.select_transferDate=null;
        this.select_transferHour=null;
        this.select_transferMinute=null;

      }
      
      if(data.dueDate){
        let date1 =  data.dueDate.substr(0,10);
        this.select_dueDate  =  date1
      }else{
        this.select_dueDate  = null;
      }
      let bankAccountID = this.cod_bank_data.filter(b => b.id == data.bankAccountID )
      if(bankAccountID.length>0 ){
        this.paymentBank =  bankAccountID[0];
      }
      else{
        this.paymentBank =null
      } 
      this.itemPaymentStatusID =  data.statusID.toString();
      this.addPaymentHistoryDataSet={}
      this.addPaymentHistoryDataSet.id = data.id;
      this.addPaymentHistoryDataSet.statusID = data.statusID.toString();
      this.addPaymentHistoryDataSet.files = null
      this.addPaymentHistoryDataSet.photoLink = data.photoLink
      this.addPaymentHistoryDataSet.paymentAmount = data.paymentAmount  
      this.addPaymentHistoryDataSet.remark = data.remark  
      
      
      this.addPaymentHistoryPopup.dialog = true;
      
    },
    async btnSubmitAddPayment(){ 
      if(parseInt(this.itemPaymentStatusID)<4 &&this.select_dueDate==null ){
        Swal.fire({
        // title: "The Internet?",
          text: "กรุณาระบุวันที่ดิวชำระ",
          type: "warning",
        });
        return;
      }
      if(parseInt(this.itemPaymentStatusID)==4 &&this.select_transferDate==null ){
        Swal.fire({
        // title: "The Internet?",
          text: "กรุณาระบุวันที่จ่ายเงิน",
          type: "warning",
        });
        return;
      }

      if(this.addPaymentHistoryDataSet.paymentAmount){
        this.addPaymentHistoryDataSet.statusID =parseInt( this.itemPaymentStatusID);
        if(this.addPaymentHistoryDataSet.files){
          this.loading=true;
          let resUpload = await this.UploadFile(this.addPaymentHistoryDataSet.files)
          if(resUpload.status == "success"){
            this.loading=false;
            this.addPaymentHistoryDataSet.photoLink = resUpload.file_path
          }else{
            this.loading=false;
            this.alertStop();
          }
       
        }
        
        if(this.select_transferDate !== null)
        {
          let hours = this.select_transferHour??"00"
          let Minute = this.select_transferMinute??"00"
          this.addPaymentHistoryDataSet.paymentDate  = this.select_transferDate + "T" + hours+ ":" + Minute+":00"
        }else{
          this.addPaymentHistoryDataSet.paymentDate  = null;
        }

        if(this.paymentBank!=null){
          this.addPaymentHistoryDataSet.bankAccountID=this.paymentBank.id;
          this.addPaymentHistoryDataSet.bankAccountText=this.paymentBank.fullBankName;
        }else{
          this.addPaymentHistoryDataSet.bankAccountID=null;
          this.addPaymentHistoryDataSet.bankAccountText=null;
        }
      
        if(this.select_dueDate){ 
          this.addPaymentHistoryDataSet.dueDate = this.select_dueDate;
        }else{
          this.addPaymentHistoryDataSet.dueDate=null;
        } 
        
        if(this.addPaymentHistoryDataSet.id!=0){
          var dataToEdit = this.purchaseOrderPayment.filter((x)=>x.id ==this.addPaymentHistoryDataSet.id )[0];
          dataToEdit.paymentAmount = this.addPaymentHistoryDataSet.paymentAmount
          dataToEdit.bankAccountID = this.addPaymentHistoryDataSet.bankAccountID
          dataToEdit.bankAccountText = this.addPaymentHistoryDataSet.bankAccountText
          dataToEdit.paymentDate = this.addPaymentHistoryDataSet.paymentDate
          dataToEdit.dueDate = this.addPaymentHistoryDataSet.dueDate
          dataToEdit.photoLink = this.addPaymentHistoryDataSet.photoLink
          dataToEdit.statusID = this.addPaymentHistoryDataSet.statusID
        }else{
          this.purchaseOrderPayment.push(this.addPaymentHistoryDataSet);
        }
        
        this.addPaymentHistoryPopup.dialog = false;
        this.itemPaymentStatusID="2";
      }else{
        Swal.fire({
        // title: "The Internet?",
          text: "กรุณาระบุยอดเงิน",
          type: "warning",
        });
      }
      
      
    },
    async btnDeletePayment(item, index) {
      Swal.fire({
           
        type: 'question',
        title: 'ต้องการลบ ?',
        text:  'ยอดเงิน : '+this.formatMoneyIsDecimal(item.paymentAmount),
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.value) {
          var file = item;
          if (file != undefined) {
          // เก็บภาพเดิมที่มีเอาไปลบออก
            if (item.photoLink) {
              var pathRemove = item.photoLink;
              let response = await axios.post(
                generalService_dotnet + "General/delete-file",
                {
                  url: pathRemove,
                },
                { headers: this.header_token }
              );

            }
            this.purchaseOrderPayment.splice(index, 1);
      }}});
    },
    async btnSubmitPurchaseOrder(){
     if(this.purchaseOrderEquipments.length > 0)
     {
      let checkValidate =  this.validateInputs(this.purchaseOrderEquipments);
      if(checkValidate == false){
        this.alertError("กรุณาใส่รายละเอียดวัสดุภายในบริษัทให้ครบ")
        return false;
        

      } 
     }
     if(this.purchaseOrderProducts.length > 0 )
     {
      let checkValidate = this.validateProducts(this.purchaseOrderProducts);
      if(checkValidate == false){
        this.alertError("กรุณาใส่รายละเอียดสินค้าเพื่อขายให้ครบ")
        return false;
      } 
     }
     if(!this.amount){
      this.$refs.amount.focus();
      this.alertError("กรุณาใส่ยอดเงิน")
      return false;
     }
     if(!this.expenseTypeID){
      this.$refs.expenseTypeID.focus();
      this.alertError("กรุณาใส่ประเภท")
      return false;
     }
     if(!this.supplierID){
      this.$refs.supplierID.focus();
      this.alertError("กรุณาใส่ชื่อบริษัท")
      return false;
     }
      let MatchPrice = false;
       //ผลรวมค่าใช้จ่ายจากออเดอร์
      let totalCostPrice =await  this.checkCostPrice(this.purchaseOrderCostMapping)
      
      if(totalCostPrice == null)
      {
        MatchPrice = true
      }
      else if(totalCostPrice != false && totalCostPrice != null)
      {
        if(totalCostPrice.toFixed(0) == this.amount)
        {
          MatchPrice = true;
        }
        else
        {
          MatchPrice = false;
        }
      }
      else
      {
        MatchPrice = false;
      }
      
      if(MatchPrice == true){

        let hours = this.invoiceDateHour??"00"
        let Minute = this.invoiceDateMinute??"00"
        let dateTime = this.invoiceDate + " " + hours+ ":" + Minute+":00"
        let reportDateTime = this.billingCycleMonth +'-'+ this.billingCycleYear+'-'+"01" ;

        let body = {
        "id": this.id == null ? 0 :this.id,
        "shopID": this.shopId, 
        "invoiceDate":  this.formatDateTimeToISO(dateTime),
        "reportDate":   reportDateTime,
        "staffID": this.staffShopID,
        "remark": this.search.remark,
        "amount": this.amount,
        "vat": !this.vat ?0 :this.vat,
        "total": this.total,
        "referenceNumber": this.search.referenceNumber,
        "expenseTypeID": this.expenseTypeID,
        "supplierID": this.supplierID,
        "paymentStatusID":parseInt(this.paymentStatusID),
        "purchaseType": this.purchaseType == null ? 2 :this.purchaseType,
        "purchaseOrderCostMapping": this.purchaseOrderCostMapping,
        "purchaseOrderPayment": this.purchaseOrderPayment,
        "purchaseOrderPhoto": this.purchaseOrderPhoto,
        "purchaseOrderProduct":this.purchaseOrderProducts,
        "purchaseOrderEquipment":this.purchaseOrderEquipments,
      }
     
      this.loading=true;
      let res = await this.updatePurchaseOrder(body);
      this.loading=false;
      if(res.status == "success"){
        this.expense_page();
      }else{
        this.alertError(res.error_description)
      }
     
      
      }else{
        this.alertError("ยอดเงินในรายการออเดอร์ไม่ถูกต้อง")
      }
      
    },
    async OpenPopupAddPackage(limits,offsets){
         try {
          
         
           let ResultCount = 0
           this.loading = true;
           this.addPackagePopup.searchStockModelPopup = null;
           this.addPackagePopup.Select_Product = [];
           this.addPackagePopup.Data = [];
           this.addPackagePopup.ProductIDList = [];
           this.addPackagePopup.loading = false;

           let ProductIDList = this.purchaseOrderProducts.map(m => m.stockShopId);
            

           this.addPackagePopup.Popupsearch = null
           this.addPackagePopup.ProductIDList = ProductIDList

           this.addPackagePopup.page = 1
           this.addPackagePopup.pageTemp = 1
           let response  =await this.getSelectPackage(this.addPackagePopup.limits,this.addPackagePopup.offsets,this.addPackagePopup.searchStockModelPopup,this.addPackagePopup.page,true,this.addPackagePopup.ProductIDList)
             
            
          
            if(response.resultCount){
               ResultCount = response.resultCount;
             
              
              this.addPackagePopup.pageLength =  (Math.ceil(ResultCount/this.addPackagePopup.limits)) 
              
              if (this.addPackagePopup.pageLength<=0){this.addPackagePopup.pageLength=1}
            }
          
              this.addPackagePopup.Data = response.stockList

              this.loading = false
              this.addPackagePopup.Dialog = true

          
            } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }



    },
    async btnChangePagePackage() {
        if (this.addPackagePopup.pageLength!=1 && this.addPackagePopup.pageTemp!=this.addPackagePopup.page){
        this.addPackagePopup.offsets = (this.addPackagePopup.page*this.addPackagePopup.limits)-this.addPackagePopup.limits
          this.loading = true
          let response = await this.getSelectPackage(this.addPackagePopup.limits,this.addPackagePopup.offsets,this.addPackagePopup.Popupsearch,this.addPackagePopup.page,false,this.addPackagePopup.ProductIDList)
        
          this.addPackagePopup.Data = response.stockList

          this.addPackagePopup.pageTemp = this.addPackagePopup.page
        }
        this.loading = false;
    },
    async btnSubmitAddOrderProductPopup(){
      this.loading = true

      
      // this.addPackagePopup.SelectPackage =[ ...
      //   {orderItemId: this.addPackagePopup.SelectPackage.id,
      //   productName : this.addPackagePopup.SelectPackage.name,
      //   packageNo : this.packageNo,
      //   packedQuantity:null,
      //   quantity:1,}
      //   ]
      // this.addPackagePopup.SelectPackage.orderItemId = this.addPackagePopup.SelectPackage.id

      // this.addPackagePopup.SelectPackage.productName = this.addPackagePopup.SelectPackage.name
      // this.addPackagePopup.SelectPackage.packageNo = this.packageNo
      // this.addPackagePopup.SelectPackage.packedQuantity = null
      // this.addPackagePopup.SelectPackage.quantity = 1

      let transformedItems = this.addPackagePopup.SelectPackage.map((item) => { 
        
        return {...item,
          stockShopId: item.id || 0,
          productName: item.name || '',
          packageNo: this.packageNo || 0,
          packedQuantity: null,
          quantity: item.packedQuantity == undefined ? 1: item.packedQuantity ,
          unitPrice :  item.unitPrice == undefined ? 1: item.unitPrice ,
          totalPrice :   item.packedQuantity == undefined ? 1: item.unitPrice *  item.packedQuantity, 
          id : 0
        };
      });

    
      

      this.purchaseOrderProducts.push(...transformedItems)
      

      this.$forceUpdate();
      this.addPackagePopup.SelectPackage = []
      this.loading = false
      this.addPackagePopup.Dialog = false
      
      
    },
    async btnDeleteOrderProduct(item, index) {
      Swal.fire({
           
        type: 'question',
        title: 'ต้องการลบ ?',
        // text:  'ยอดเงิน : '+this.formatMoneyIsDecimal(item.paymentAmount),
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.value) {
          var file = item;
        
         
            this.purchaseOrderProducts.splice(index, 1);
      }});
    },
    async btnSearchPackagek(limits,offsets){
      let response =[]
      let ResultCount = 0
      // this.addPackagePopup.searchStockModelPopup

      // let response  =await this.getSelectPackage(this.addPackagePopup.limits,this.addPackagePopup.offsets,this.addPackagePopup.searchStockModelPopup,this.addPackagePopup.page,true,this.addPackagePopup.ProductIDList)


        
        if (this.addPackagePopup.searchStockModelPopup != null){
        this.addPackagePopup.Popupsearch = this.addPackagePopup.searchStockModelPopup.trim()
          this.loading = true
          if (this.addPackagePopup.Popupsearch.length>0){
            response  =await this.getSelectPackage(limits,offsets,this.addPackagePopup.Popupsearch,1,true,this.addPackagePopup.ProductIDList)
          }else{
            response  = await this.getSelectPackage(limits,offsets,null,1,true,this.addPackagePopup.ProductIDList)
            }
          if(response.resultCount){
            ResultCount = response.resultCount;
          
          
          this.addPackagePopup.pageLength =  (Math.ceil(ResultCount/this.addPackagePopup.limits))
          
          if (this.addPackagePopup.pageLength<=0){ this.addPackagePopup.pageLength = 1}
        }
      
          this.addPackagePopup.Data = response.stockList
          this.loading = false
        }
        
        
        this.loading = false;
    },
    async btnEditOrderProduct(data){
     console.log('ที่เข้ามา',data);
     this.editOrderProductDataSet = data
     this.editOrderProduct.dialog = true;

     
    },
    async btnSubmitOrderProduct(){
      console.log("this.editOrderProductDataSet",this.editOrderProductDataSet);
      
      console.log('แก้ไข');

    },
    async OpenPopupAddEquipment(limits,offsets){
         try {
          
         
           let ResultCount = 0
           this.loading = true;
           this.addEquipmentPopup.searchStockModelPopup = null;
           this.addEquipmentPopup.Select_Product = [];
           this.addEquipmentPopup.Data = [];
           this.addEquipmentPopup.ProductIDList = [];
           this.addEquipmentPopup.loading = false;
           
           
          //  let ProductIDList = this.purchaseOrderEquipments.filter(m => m.equipmentID != null && m.equipmentID != 0).map(m => m.equipmentID);
            
          
           this.addEquipmentPopup.Popupsearch = null
           this.addEquipmentPopup.ProductIDList = []

           this.addEquipmentPopup.page = 1
           this.addEquipmentPopup.pageTemp = 1
           let response  =await this.getSelectEquipment(this.addEquipmentPopup.limits,this.addEquipmentPopup.offsets,this.addEquipmentPopup.searchStockModelPopup,this.addEquipmentPopup.page,true,this.addEquipmentPopup.ProductIDList)
            
            
          
            if(response.resultCount){
               ResultCount = response.resultCount;
             
              
              this.addEquipmentPopup.pageLength =  (Math.ceil(ResultCount/this.addEquipmentPopup.limits)) 
              
              if (this.addEquipmentPopup.pageLength<=0){this.addEquipmentPopup.pageLength=1}
            }
          
              this.addEquipmentPopup.Data = response.items

              this.loading = false
              this.addEquipmentPopup.Dialog = true

          
            } catch (e) {
             this.$swal({
                 position: "top",
                 type: "info",
                 title: "เกิดข้อผิดพลาดกรูณาติดต่อ IT !!",
                 text: e.message,
             })
           }



    },
    async btnSubmitAddEquipmentPopup(){
      this.loading = true


     
      let transformedItems = this.addEquipmentPopup.SelectPackage.map((item) => { 
        
        return {...item,
          quantity: item.packedQuantity == undefined ? 1: item.packedQuantity ,
          unitPrice :  item.unitPrice == undefined ? 1: item.unitPrice ,
          totalPrice :   item.packedQuantity == undefined ? 1: item.unitPrice *  item.packedQuantity, 
          equipmentID : item.id,
          id : 0
        };
      });

    
      

      this.purchaseOrderEquipments.push(...transformedItems)
      

      this.$forceUpdate();
     
      
 
      
      let ItemList = this.addEquipmentPopup.Data.filter(dataItem => 
        !this.addEquipmentPopup.SelectPackage.some(selectItem => selectItem.id === dataItem.id)
      );
      this.addEquipmentPopup.SelectPackage = []
      this.equipmentsItemList =  ItemList
      this.loading = false
      this.addEquipmentPopup.Dialog = false
      
      
    },
    async btnAddListEquipment(){  
      // let ProductIDList = this.purchaseOrderEquipments.filter(m => m.equipmentID != null && m.equipmentID != 0).map(m => m.equipmentID);

      let response  =await this.getSelectEquipment(99999,0,null,1,true,[])
      this.equipmentsItemList =  response.items

      this.purchaseOrderEquipments.push({
        
        "equipmentID": 0,
        "description": "",
        "quantity": 0,
        "unitPrice": 0
      });
    },
    async btnDeleteEquipments(item, index) {
      Swal.fire({
           
        type: 'question',
        title: 'ต้องการลบ ?',
        // text:  'ยอดเงิน : '+this.formatMoneyIsDecimal(item.paymentAmount),
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async (result) => {
        if (result.value) {
          var file = item;
        
         
            this.purchaseOrderEquipments.splice(index, 1);
      }});
    },
    async equipmenListInput(item,newItem){

      if(typeof newItem === "object")
      {

        item.equipmentID =  newItem.id
        item.description =  newItem.description
   

          
      
      }else{
        
        item.equipmentID =  0
        item.description =  newItem
      }
      // let ProductIDList = this.purchaseOrderEquipments.filter(m => m.equipmentID != null && m.equipmentID != 0).map(m => m.equipmentID);
      let response  = await this.getSelectEquipment(99999,0,null,1,true,[])
      this.equipmentsItemList =  response.items
      
      
    },

    // #endregion
    //#region  methods
    async previewPicture(index, event) {
      var file = event.target.files[0];
     
      let bodyUploadFile = [];
        if (file != undefined) {
          // เก็บภาพเดิมที่มีเอาไปลบออก
        //   if (
        //     this.purchaseOrderPhoto[index].id > 0 &&
        //     this.purchaseOrderPhoto[index].fileUrl != "" &&
        //     this.purchaseOrderPhoto[index].fileUrl != null
        //   ) {
        //     var pathRemove = this.purchaseOrderPhoto[index].fileUrl;
        //     let response = await axios.post(
        //       generalService_dotnet + "General/delete-file",
        //       {
        //         url: pathRemove,
        //       },
        //       { headers: this.header_token }
        //     );

        //   }

          // เช็ตข้อมูลก่อนอัพภาพ
           
          var file_type = file.type.split("/");
          var from_data = new FormData();
          from_data.append("bucket", "Doc_file");
          from_data.append("file", file);

          bodyUploadFile = from_data;

          // อัพโหลดภาพ
          let UploadPicResponse = await axios.post(
            generalService_dotnet + "General/upload-file-with-bucket",
            bodyUploadFile,
            { headers: this.header_token }
          );
          if (UploadPicResponse.status == 200) {
            this.purchaseOrderPhoto[index].photoLink = UploadPicResponse.data.file_path;
            this.purchaseOrderPhoto[index].filename = file.name;
          }
        }
    },
    async editPurchaseOrder(Id){
      let body ={"id": Id,"shopID":this.shopId}
      let res = await this.getPurchaseOrder(body);
      
      if(res.status == "success" )
      {
        
        let obj = res.items[0]
        let date = moment(obj.invoiceDate, "YYYY-MM-DDTHH:mm:ss.SS").format("DD-MM-YYYY");
        let hours = moment(obj.invoiceDate, "YYYY-MM-DDTHH:mm:ss.SS").format("HH");
        let minute = moment(obj.invoiceDate, "YYYY-MM-DDTHH:mm:ss.SS").format("mm");
        this.billingCycleMonth = moment(obj.reportDate, "YYYY-MM-DDTHH:mm:ss.SS").format("MM");
        let billingCycleYear = moment(obj.reportDate, "YYYY-MM-DDTHH:mm:ss.SS").format("YYYY");
        this.billingCycleYear = parseInt(billingCycleYear)
        this.paymentStatusID = obj.paymentStatusID.toString();
        this.id = obj.id
        this.idText = obj.idText
        this.invoiceDate =  date
        this.invoiceDateHour = hours
        this.invoiceDateMinute = minute
        this.search.remark = obj.remark
        this.search.referenceNumber = obj.referenceNumber
        this.amount = obj.amount
        this.vat = obj.vat
        this.total = obj.total
        this.expenseTypeID = obj.expenseTypeID
        this.supplierID = obj.supplierID
        this.total = obj.total
        this.purchaseOrderEquipments = obj.purchaseOrderEquipments
        this.purchaseOrderProducts = obj.purchaseOrderProducts
        this.purchaseOrderPayment = obj.purchaseOrderPayments 
        this.purchaseOrderCostMapping = obj.purchaseOrderCostMapping
        this.purchaseOrderPhoto = obj.purchaseOrderPhoto
 
      }
      
      
    },
    // #endregion
    //#region  function
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    formatDateTimeToISO(dateTime) {
      return moment(dateTime, "DD-MM-YYYY HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SS");
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async getDateTimeNewInvoiceDate(){
      let dataTime =  new Date();
      let date = moment(dataTime, "YYYY-MM-DDTHH:mm:ss.SS").format("DD-MM-YYYY");
        let hours = moment(dataTime, "YYYY-MM-DDTHH:mm:ss.SS").format("HH");
        let minute = moment(dataTime, "YYYY-MM-DDTHH:mm:ss.SS").format("mm");
        this.invoiceDate =  date
        this.invoiceDateHour = hours
        this.invoiceDateMinute = minute
      
    },
   
    SetIndex(index) {
      this.indexDoc = index;
    },
    getName(file_path){
        var filename =''
        if(file_path)
        {
            filename = file_path.split(/(\\|\/)/g).pop();
        }
        return  filename;
    },
    addDocument() {
     let OrderPhoto =
     {
      "id": 0,
      "created": new Date().toISOString(),
      "createdBy": this.staffShopID,
      "photoLink": "",
      "filename":""
    }
      this.purchaseOrderPhoto.push(OrderPhoto);
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    async calculateTotal(){
      this.total = parseInt((this.amount * (1 + this.vat  / 100)).toFixed(0));
    },
    async checkCostPrice(data) {
      data = data.map(item => {
        if (item.costPrice === "") {
          item.costPrice = null;
        }
        return item;
      });
      // ตรวจสอบว่าทุกค่าเป็น null
      const allNull = data.every(item => item.costPrice == null );
      
      // หากทุกค่าเป็น null
      if (allNull) {
         return null; 
      }

      // ตรวจสอบว่าทุกค่าเป็นตัวเลข
      const allNumbers = data.every(item => typeof item.costPrice === 'number' && item.costPrice !== null);

      // หากทุกค่าเป็นตัวเลข
      if (allNumbers) {
        const totalCostPrice = data.reduce((sum, item) => sum + item.costPrice, 0)
        return totalCostPrice; // คืนค่าผลรวม
      }

      // ค่าอื่น ๆ
      return false; // ค่าไม่ตรงกับเงื่อนไขใด ๆ
    },
    detail_page (id) {
              window.open('order-detail?id='+id, '_blank');
     },
     async EditStock(ImportExportMasterID){
        window.open("transfer-list/update?id="+btoa(ImportExportMasterID));
    },
    async expense_page(){
      this.$router.push('/Expense')
      // window.open("Expense");
    },
     calculateTotalEquipments(item){
      return item.quantity * item.unitPrice
      
    },
    validateInputs(item) {
      // this.$refs.description_0.focus();
    for (let i = 0; i < item.length; i++) {
      const item = this.purchaseOrderEquipments[i];
      
      // ตรวจสอบ description
       
      if (!item.description || item.description.trim() === "") {
        
        console.log("เข้าเงื่อนไข",item);
        const ref = this.$refs[`description_${i}`];
        ref.focus();
        return false
      }

      // ตรวจสอบ quantity
      if (!item.quantity || item.quantity <= 0) {
        const ref = this.$refs[`quantity_${i}`];
        ref.focus();
        return false
      }

      // ตรวจสอบ unitPrice
      if (!item.unitPrice || item.unitPrice <= 0) {
        const ref = this.$refs[`unitPrice_${i}`];
        ref.focus();
        return false
      }
      
    }

    return true;
   },
   validateProducts(item) {
      // this.$refs.description_0.focus();
    for (let i = 0; i < item.length; i++) {
      const item = this.purchaseOrderProducts[i];
      
       

      // ตรวจสอบ quantity
      if (!item.quantity || item.quantity <= 0) {
        const ref = this.$refs[`Productquantity_${i}`];
        ref.focus();
        return false
      }

      // ตรวจสอบ unitPrice
      if (!item.unitPrice || item.unitPrice <= 0) {
        const ref = this.$refs[`ProductunitPrice_${i}`];
        ref.focus();
        return false
      }
      
    }

    return true;
    },
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    // #endregion
     //#region  Alert
    async alertConfirmText(description) {
      return Swal.fire({
        type: "question",
        text: description,
        confirmButtonText: "ใช้",
        cancelButtonText: "ไม่ใช้",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          return true;
        } else {
          return false;
        }
      });
    },
    async alertStop() {
      Swal.fire({
        // title: "The Internet?",
        text: "ไม่สามารถทำรายการได้",
        type: "error",
      });
    },
    async alertError(error_description) {
      Swal.fire({
        text: error_description,
        title: "ไม่สามารถทำรายการได้",
        type: "error",
      });
    },
    async alertFound(error_description) {
      Swal.fire({
        text: error_description,
        title: "ไม่พบข้อมูล",
        type: "question",
      });
    },
    // #endregion
    //#region  import
    formatMoney,
    isNumberWithDot,
    isNumberWNoDot,
    DateNowNotNull,
    GenIE,
    Trim_value,
    Trim_rules,
    formatDatetime,
    ChangeIE,
    AlertSuccess,
    AlertError,
    CheckUrlPhoto,
    get_languages,
    ChangeOrderId,
    formatMoneyIsDecimal,
    set_format_date_time,
    format_order_id,
     // #endregion
  },
};
</script>
<style scoped>

.jt_end {
  display: flex;
  justify-content: flex-end;
}
</style>
