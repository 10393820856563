<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    
    
    <!-- นำทาง -->
    <v-card class="v-card-bottom-30">
      <v-card-title>
        {{use_language.stock}}
        <v-icon>mdi-chevron-right</v-icon>
        {{use_language.warehouse_management}}
      </v-card-title>
    </v-card>

    <!-- popup -->
    <v-dialog v-model="dialog" scrollable max-width="45%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1">
        <v-card-title class="height-57">
          <span v-if="warehouse.id==0">{{use_language.add_warehouse}}</span>
          <span v-else>{{use_language.edit_warehouse}}</span>
          <v-spacer/>
          <v-btn icon @click="dialog=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-x-auto">
          <v-form ref="addWarehouseForm" lazy-validation>

            <v-row>
              <v-col cols="12" md="3" class="pa-0">
                {{use_language.name_warehouse}} :
              </v-col>
              <v-col cols="12" md="9" class="pa-0">
                <v-text-field
                  :value="warehouse.name"
                  @change="value => warehouse.name = Trim_value(value)"
                  autocomplete="off"
                  :rules="rulesname"
                  ref="warehousename"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pa-0">
                {{use_language.number_warehouse}} :
              </v-col>
              <v-col cols="12" md="9" class="pa-0">
                <v-text-field
                  :value="warehouse.warehouseCode"
                  @change="value => warehouse.warehouseCode = Trim_value(value)"
                  autocomplete="off"
                  ref="warehousewarehouseCode"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pa-0">
                {{use_language.address}} :
              </v-col>
              <v-col cols="12" md="9" class="pa-0">
                <v-text-field
                  :value="warehouse.address"
                  @change="value => warehouse.address = Trim_value(value)"
                  :rules="rulesaddress"
                  autocomplete="off"
                  ref="warehouseaddress"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pa-0">
                {{use_language.zipcode}} :
              </v-col>
              <v-col cols="12" md="9" class="pa-0">
                <v-autocomplete
                  v-model="warehouse.postcodeMapping"
                  ref="warehousepostcodeMapping"
                  placeholder="รหัสไปรษณีย์ / จังหวัด / (เขต/อำเภอ) / (แขวง/ตำบล)"
                  :items="postcode_mapping"
                  :item-text="item => item.Postcode + ' ' + item.Province + ' ' + item.District + ' ' + item.SubDistrict"
                  :rules="rulespostcodeMapping"
                  outlined
                  required
                  return-object
                  dense
                >
                <!-- แก้จากเดิมเป็น 3 ภาษา -->
                                 <template v-slot:label> <span>{{use_language.all_address}}</span> </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pa-1">
              </v-col>
              <v-col cols="12" md="9" class="pa-1">
                <v-checkbox
                    label="ให้เปิดออเดอร์ได้ถ้าสต๊อกไม่เพียงพอ"
                    v-model="warehouse.isAllowAddOrderWhenStockShortage"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pa-0" align="right">
                <v-btn :elevation="1" color="primary" class="px-2" @click="UpdateWarehouse()">
                  <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                  <span class="fn-14"><b> {{use_language.record}} </b></span>
                </v-btn>
              </v-col>
            </v-row>

          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ข้อมูลคลัง -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title class="height-57">
        <v-icon size="22" color="success darken-3">fa-map-marked-alt</v-icon><span class="ml-3">{{use_language.warehouse}}</span>
        <v-spacer/>
        <v-btn :disabled="!authorize" :elevation="1" color="primary" class="px-2" @click="Edit(null)">
          <v-icon size="22" class="mr-1">mdi-plus</v-icon>
          {{use_language.add_warehouse}} 
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <!-- PC hidden-xs-only -->
        <v-data-table
          :headers="header"
          :items="dataset"
          :items-per-page="9999999999"
          hide-default-footer
          class="packhai-border-table hidden-xs-only"
        >
          <template v-slot:item.no="{ item }">
            {{ formatMoney(dataset.indexOf(item)+1) }}
          </template>
          <template v-slot:item.address="{ item }">
            <span v-if="item.postcodemappingID!=null"> {{item.fullAddress}} </span>
            <span v-else> {{item.address}} </span>
          </template>
          <template v-slot:item.addorderwhenstockshortage="{ item }">
            <span v-if="item.isAllowAddOrderWhenStockShortage" style="color: darkgreen;">เปิดออเดอร์ได้</span>
            <span v-else-if="!item.isAllowAddOrderWhenStockShortage" style="color: red;">เปิดออเดอร์ไม่ได้</span>
          </template>
          <template v-slot:item.edit="{ item }">
           
          
            <a v-if="item.shopID!=null&&  authorize" @click="Edit(item)"><v-icon size="18">fa-edit</v-icon></a>
          </template>
          <template v-slot:item.delete="{ item }">
           
            <a v-if="item.shopID!=null &&  authorize" @click="Delete(item,dataset.indexOf(item))"><v-icon size="18" color="red">fa-trash-alt</v-icon></a>
          </template>
        </v-data-table>

        <!-- Mobile hidden-sm-and-up -->
        <v-data-iterator
          :items="dataset"
          :items-per-page="9999999999"
          hide-default-header
          hide-default-footer
          class="hidden-sm-and-up"
        >
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                <v-card>
                  <v-row class="pa-2">
                    <v-col cols="12" class="pa-0">
                      {{use_language.name}} : {{ item.name }}
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      {{use_language.address}} :
                      <span v-if="item.postcodemappingID!=null"> {{item.fullAddress}} </span>
                      <span v-else> {{item.address}} </span>
                    </v-col>
                    <v-col cols="12" class="pa-0">
                      ในกรณีสต๊อกไม่เพียงพอ :
                      <span v-if="item.isAllowAddOrderWhenStockShortage" style="color: darkgreen;">เปิดออเดอร์ได้</span>
                      <span v-else-if="!item.isAllowAddOrderWhenStockShortage" style="color: red;">เปิดออเดอร์ไม่ได้</span>
                    </v-col>
                    <v-col cols="12" class="pa-0 text-right">
                      <v-icon v-if="item.shopID==null" disabled size="16" class="mr-2">fa-edit</v-icon>
                      <v-icon v-else-if="!authorize" disabled size="18">fa-edit</v-icon>
                      <a v-else @click="Edit(item)"><v-icon size="16" class="mr-2">fa-edit</v-icon></a>
                      <v-icon v-if="item.shopID==null" disabled size="16" color="red">fa-trash-alt</v-icon>
                      <v-icon v-else-if="!authorize" disabled size="18">fa-trash-alt</v-icon>
                      <a v-else @click="Delete(item,dataset.indexOf(item))"><v-icon size="16" color="red">fa-trash-alt</v-icon></a>
                    </v-col>
                  </v-row>

                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

      </v-card-text>
    </v-card>



  </div>
</template>

<script>
  import Loading from '@/website/components/Loading'
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import { shopService_dotnet, stockService_dotnet, generalService_dotnet } from '@/website/global'
  import { formatMoney, isNumberWithDot, isNumberWNoDot, DateNowNotNull, Trim_value, Trim_rules,get_languages ,checkAuthorize} from '@/website/global_function'
  import { warehouse_language } from "@/website/language/warehouse_language";



  export default {
    components: {
        Loading
    },
    data: () => ({
      // page
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      page_loading: true,
      loading: false,
      postcode_mapping: null,
      
      use_language: null,
      set_language: null,
      //เช็คสิทธิ์
      authorize:false,
      header: [
        { text: 'no', align: 'center', sortable: false, value: 'no', width: '60px' },
        { text: 'warehouse', align: 'center', value: 'warehouseCode', sortable: false, width: '150px' },
        { text: 'name', align: 'left', value: 'name', sortable: false},
        { text: 'address', align: 'left', value: 'address', sortable: false },
        { text: 'edit', align: 'center', value: 'edit', sortable: false , width: '90px'},
        { text: 'delete', align: 'center', value: 'delete', sortable: false , width: '90px'},
      ],
      dataset: [],

      // popup
      dialog: false,
      warehouse: {},
      rulesname: [],
      rulesaddress: [],
      rulespostcodeMapping: []

    }),
    computed: {
    },
    watch: {
    },
    async created() {
       //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));

      //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
      this.get_authorize_staffshop();

        // เรียก data tabel 3 ภาษา
        this.getHeaderDatatabel();

        document.title = this.$router.history.current.meta.title
        await this.Load_data()
        this.page_loading = false
    },

    methods: {
      get_languages,
      async Load_data(){
        this.loading = true

        let postcode = await axios.post(generalService_dotnet+'General/get-postcodeMapping', {}, {headers: this.header_token})
        if (postcode.status == 200) { this.postcode_mapping = postcode.data }

        let warehouse = await axios.post(shopService_dotnet+'Shop/get-warehouse-list', {
          "shopId": localStorage.getItem('shop_id'),
        }, {headers: this.header_token})

        this.loading = false
        if (warehouse.status==200){
          this.dataset = warehouse.data
          for (var i in this.dataset){
            if (this.dataset[i].postcodemappingID!=null){
              for (var j in this.postcode_mapping){
                if (this.dataset[i].postcodemappingID==this.postcode_mapping[j].Id){
                  this.dataset[i].fullAddress = (this.dataset[i].address==null ? '' : this.dataset[i].address+' ') + (this.postcode_mapping[j].SubDistrict) +' '+ (this.postcode_mapping[j].District) +' '+ (this.postcode_mapping[j].Province)
                  break
                }
              }
            }
          }
        }else{
          this.$swal({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      },
      Edit(item){
        if (item!=null){
          var postcodemapping = null
          if (item.postcodemappingID!=null){
            for (var j in this.postcode_mapping){
              if (item.postcodemappingID==this.postcode_mapping[j].Id){
                postcodemapping = this.postcode_mapping[j]
                break
              }
            }
          }
          item.postcodeMapping = postcodemapping
          item.postcodemappingId = item.postcodemappingID
          this.warehouse = item
        }else{
          this.warehouse = {
            id: 0,
            name: null,
            branchId: null,
            shopId: localStorage.getItem('shop_id'),
            warehouseCode: null,
            address: null,
            postcodeMapping: null,
            postcodemappingId: null,
            created: null,
            createdBy: localStorage.getItem('staff_id'),
            isPackhaiWarehouse: false,
            isDeleted: false,
            isAllowAddOrderWhenStockShortage:false,
          }
        }
        this.rulesname = [],
        this.rulesaddress = [],
        this.rulespostcodeMapping = [],
        this.dialog = true
      },
      async Delete(item,index){
        Swal.fire({
       
          type: 'question',
          text: 'คลัง: '+ item.name +' ต้องการลบใช่หรือไม่',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            this.loading = true
            let response = await axios.post(stockService_dotnet+'Warehouse/delete-warehouse', {
              "warehouseId": item.id
            }, {headers: this.header_token})
            if (response.status == 200){
              this.loading = false
              this.dataset.splice(index,1)
              Swal.fire({
                toast: true,
                timer: 1500,
                showConfirmButton: false,
                position: 'top',
                type: 'success',
                title: 'Success'
              })
            }else{
              this.loading = false
              this.$swal({
                position: "top",
                type: "error",
                text: "ไม่สามารถทำรายการได้",
              })
            }
           }
        })

      },
      async UpdateWarehouse(){
        await this.rulesAddWarehouseCheck()
        if (this.$refs.addWarehouseForm.validate()) {
          this.loading = true
          if (this.warehouse.postcodeMapping!=null){ this.warehouse.postcodemappingId = this.warehouse.postcodeMapping.id }
          if (this.warehouse.warehouseCode==''){ this.warehouse.warehouseCode = null }
          let response = await axios.post(stockService_dotnet+'Warehouse/update-warehouse', {
            "warehouse": this.warehouse
          }, {headers: this.header_token})
          if (response.status == 200){
            await this.Load_data()
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: 'top',
              type: 'success',
              title: 'Success'
            })
            this.dialog = false
          }else{
            this.loading = false
            this.$swal({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้",
            })
          }
        }
      },
      async get_authorize_staffshop () {
        this.authorize =  this.checkAuthorize(18)
          // if(localStorage.getItem("210") == 2)
          // {
          //  this.authorize = true
          // }
          
        },
      async rulesAddWarehouseCheck(){
        this.rulesname = [ v => !!v || 'กรุณาระบุ', ]
        this.rulesaddress = [ v => !!v || 'กรุณาระบุ', ]
        this.rulespostcodeMapping = [ v => !!v || 'กรุณาระบุ', ]
      },
      async getHeaderDatatabel(){
      this.header = [
        { text: this.use_language.no_2, align: 'center', sortable: false, value: 'no', width: '60px' },
        { text: this.use_language.warehouse, align: 'center', value: 'warehouseCode', sortable: false, width: '150px' },
        { text: this.use_language.name, align: 'left', value: 'name', sortable: false},
        { text: this.use_language.address, align: 'left', value: 'address', sortable: false },
        { text: 'ในกรณีสต๊อกไม่เพียงพอ', align: 'center', value: 'addorderwhenstockshortage', sortable: false , width: '120px'},
        { text: this.use_language.edit , align: 'center', value: 'edit', sortable: false , width: '90px'},
        { text: this.use_language.delete, align: 'center', value: 'delete', sortable: false , width: '90px'},
      ]
      },
      formatMoney,
      isNumberWithDot,
      isNumberWNoDot,
      DateNowNotNull,
      Trim_value,
      Trim_rules,
      checkAuthorize,
    }
  }
</script>
