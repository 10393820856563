<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <v-dialog v-model="updateHistoryDialog" scrollable  width="700px"   persistent >
        <v-card >
          <v-card-title >
            <span>ประวัติการอัพเดทสต๊อกไปยัง Platform</span>
            <v-spacer></v-spacer>
            <v-btn icon  @click="updateHistoryDialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-data-table
                :headers="headerDataHistory"
                :items="itemDataHistory"
                :items-per-page="9999999999"
                hide-default-footer
                class="packhai-border-table packhai-table"
                >
                <template v-slot:item.updated="{ item }">
                  {{ formatDatetime(item.updated) }}
                </template>
                <template v-slot:item.quantity="{ item }">
                  {{ item.quantity }}
                </template>
                 <template v-slot:item.error="{ item }">
                  {{ item.error }}
                </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- นำทาง -->
    <v-card class="v-card-margin-bottom-30">
      <v-card-title >
         {{use_language.stock}}
        <v-icon>mdi-chevron-right</v-icon>
        <a @click="toBack()"> {{use_language.product_stock}} </a>
        <v-icon>mdi-chevron-right</v-icon>
        {{ stockShop.name }}
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ข้อมูลสินค้า -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-icon size="22" color="orange darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3">{{use_language.info_product}}</span>
        <v-spacer/>
        <v-btn :disabled='!authorize'   :elevation="1" color="primary" class="pa-2" dark @click="UpdateStock()">
          <v-icon size="16" class="pr-1">fa-edit</v-icon>
          {{use_language.edit_product}}
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="px-0 v-list-item">
              <a v-if="stockShop.photoLink!=null && stockShop.photoLink!=''" @click="OpenPhoto(stockShop.photoLink)" class="v-avatar v-list-item__avatar my-0 rounded-0 v-avatar--tile">
                <img :src="stockShop.photoLink" class="picsize">
              </a>
              <div class="v-list-item__content">
                <div class="v-list-item__title subtitle-1 packhai-showproduct">
                  <div>
                    <div>
                      {{use_language.name_product}}
                    </div>
                    <div>
                      : {{ stockShop.name }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="v-markdown subtitle-1 packhai-showproduct">
                    <div>
                      <div>
                        {{use_language.type}}
                      </div>
                      <div>
                        : {{ (stockShop.prop1Description!=null && stockShop.prop1Description!="" ? stockShop.prop1Description : "") + (stockShop.prop2Description!=null && stockShop.prop2Description!="" ? " , "+stockShop.prop2Description : "") }}
                      </div>
                    </div>

                    <br/>
                    <div>
                      <div>
                        {{use_language.sku}}
                      </div>
                      <div>
                        : {{ stockShop.sku }}
                      </div>
                    </div>

                    <br/>
                    <div>
                      <div>
                        {{use_language.barcode}}
                      </div>
                      <div>
                        : {{ stockShop.barcode }}
                      </div>
                    </div>

                    <br/>
                    <div v-if="stockShop.isProductSet">
                      <div>
                        ประเภท
                      </div>
                      <span  class="fn-13" >
                        : <v-icon size="22" color="orange darken-3" disabled >mdi-package-variant-closed-plus</v-icon>
                        {{use_language.set_products}}
                      </span>
                 
                    </div>

                    <br/>
                    <div>
                      <div>
                        {{use_language.balance}}
                      </div>
                      <div>
                        : {{ formatMoney(stockShop.quantity) }}
                      </div>
                    </div>

                  


                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ข้อมูลสต๊อก -->
    <v-card elevation="1">
      <v-card-title>
        <v-icon size="22" color="red darken-3">mdi mdi-file-document</v-icon><span class="ml-3">{{use_language.info_stock}}</span>
        <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-tabs v-model="tab">
          <v-tabs-slider color="red"></v-tabs-slider>
          <v-tab v-for="i in tab_list" :key="i.id" @click="SeeDetails(i.id,false)"> {{ i.name }} </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item v-for="i in tab_list" :key="i.id">

            <div v-if="i.id==0">
              <br>
                <h3>ภาพรวมสต๊อกในคลัง</h3>
                <br>
                <!-- PC hidden-xs-only -->
                <v-data-table
                  :headers="header"
                  :items="dataset"
                  :items-per-page="9999999999"
                  hide-default-footer
                  class="packhai-border-table hidden-xs-only mt-1"
                  style="width:670px;"
                >
                  <template v-slot:item.warehouseName="{ item }">
                    <span v-if="dataset.length-1==dataset.indexOf(item)" class="fw-b">
                      {{ item.warehouseName }}
                    </span>
                    <span v-else>
                      {{ item.warehouseName }}
                    </span>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <span v-if="dataset.length-1==dataset.indexOf(item)" class="fw-b">
                      {{ formatMoney(item.quantity) }}
                    </span>
                    <span v-else>
                      {{ formatMoney(item.quantity) }}
                    </span>
                  </template>
                  <template v-slot:item.totalPrice="{ item }">
                    <span v-if="dataset.length-1==dataset.indexOf(item)" class="fw-b">
                      {{ formatMoney(item.totalPrice) }}
                    </span>
                    <span v-else>
                      {{ formatMoney(item.totalPrice) }}
                    </span>
                  </template>
                  <template v-slot:item.detail="{ item }">
                    <a @click="SeeDetails(item.warehouseId,true)"> {{ item.detail }} </a>
                  </template>
                </v-data-table>

                <!-- Mobile hidden-sm-and-up -->
                <v-data-iterator
                  :items="dataset"
                  :items-per-page="9999999999"
                  hide-default-header
                  hide-default-footer
                  class="hidden-sm-and-up"
                >
                  <template v-slot:default="props">
                    <v-row>
                      <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                        <v-card>
                          <v-row class="ma-0 px-2 py-1">
                            {{use_language.warehouse}}
                            <v-spacer/>
                            <span v-if="dataset.length-1==dataset.indexOf(item)" class="fw-b">
                              {{ item.warehouseName }}
                            </span>
                            <span v-else>
                              {{ item.warehouseName }}
                            </span>
                          </v-row>
                          <v-row class="ma-0 px-2 py-1">
                            {{use_language.quantity}}
                            <v-spacer/>
                            <span v-if="dataset.length-1==dataset.indexOf(item)" class="fw-b">
                              {{ formatMoney(item.quantity) }}
                            </span>
                            <span v-else>
                              {{ formatMoney(item.quantity) }}
                            </span>
                          </v-row>
                          <v-row class="ma-0 px-2 py-1">
                            {{use_language.residual_value}}
                            <v-spacer/>
                            <span v-if="dataset.length-1==dataset.indexOf(item)" class="fw-b">
                              {{ formatMoney(item.totalPrice) }}
                            </span>
                            <span v-else>
                              {{ formatMoney(item.totalPrice) }}
                            </span>
                          </v-row>
                          <v-row class="ma-0 px-2 py-1">
                            {{use_language.info}}
                            <v-spacer/>
                            <a @click="SeeDetails(item.warehouseId,true)"> {{ item.detail }} </a>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-iterator>
                <br>
                <br> <br>
                
                
                <div v-if="stockShop.isProductSet">
                  <h3>สินค้าภายในเซ็ต</h3>
                  <v-data-table
                    :headers="ProductDataHeader"
                    :items="ProductDataItem"
                    :items-per-page="9999999999"
                    hide-default-footer
                    class="packhai-border-table hidden-xs-only"
                  >
                    <template v-slot:item.no="{ item }">
                      {{ formatMoney(ProductDataItem.length - ProductDataItem.indexOf(item)) }}
                    </template>
                    <template v-slot:item.photoLink="{ item }">
                      <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                        <img :src="item.photoLink" class="picsize mt-1">
                      </a>
                    </template>
                    <template v-slot:item.prop1="{ item }">
                      <span class="fn-13">
                        {{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                      </span>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      {{ formatMoney(item.quantity) }}
                    </template>
                    <template v-slot:item.created="{ item }">
                      {{item.created}}
                      <br>
                      {{item.createdByName}}
                      </template>
                      <template v-slot:item.updated="{ item }">
                      {{item.updated}}
                      <br>
                      {{item.updatedByName}}
                    </template>
                  </v-data-table>
                </div>
                <br>
                <br> <br>
                <div v-if="datasetStockMarketplace.length>0">

                
                <v-row>
                  <table style="width:100%">
                    <tr>
                      <td>
                        <h3>ข้อมูลสต๊อกใน Platform</h3>
                      </td>
                      <td class="text-right">
                        <v-btn v-if="!isEditSyncStock &&  datasetStockMarketplace.length>0"  @click="isEditSyncStock = !isEditSyncStock" outlined color="primary"><v-icon left>fa-pen</v-icon> แก้ไข</v-btn>
                        <v-btn v-else-if="isEditSyncStock &&  datasetStockMarketplace.length>0" @click="save_edit_stock_sync()" color="primary"><v-icon left>fa-save</v-icon> บันทึก</v-btn>
                      </td>
                    </tr>
                  </table>
                </v-row> 
                <v-data-table
                  :headers="headerStockMarketplace"
                  :items="datasetStockMarketplace"
                  :items-per-page="9999999999"
                  hide-default-footer
                  class="packhai-border-table  "
                   
                >
                <template v-slot:item.marketplaceLogo="{ item }">
                    <v-avatar size="25px" v-if="item.marketplaceLogo!=null && item.marketplaceLogo!=''">
                      <img :src="item.marketplaceLogo"/>
                    </v-avatar>
                  </template>
                  <template v-slot:item.photoLink="{ item }" >
                    <a v-if="item.photoLink!=null && item.photoLink!=''" @click="OpenPhoto(item.photoLink)" class="v-avatar v-list-item__avatar my-0 rounded-0 v-avatar--tile">
                      <img :src="item.photoLink" class="picsize">
                    </a>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <span>
                      <b>{{ item.name}}</b>
                    </span>
                    <span v-if="item.sku!=null">
                      <br> sku : {{ item.sku}}
                    </span>
                    <span v-if="item.prop1Description!=null">
                      <br>  ลักษณะ :{{ (item.prop1Description!=null && item.prop1Description!="" ? item.prop1Description : "") + (item.prop2Description!=null && item.prop2Description!="" ? " , "+item.prop2Description : "") }}
                    </span>
                  </template>
                  <template v-slot:item.isUpdateStock="{ item }">
                    <div v-if="isEditSyncStock">
                      <v-switch v-if="item.isEnableChangeUpdateStock" v-model="item.isUpdateStock" @change="ItemUpdateChanged(item)" color="red" hide-details ></v-switch>
                      <div v-else class="color-red"  >ยังไม่เปิดใช้</div>
                    </div>
                    <div v-else>
                      <span  v-if="item.isUpdateStock" class="color-green text-center">
                        เปิดใช้งาน
                      </span>
                      <span v-else class="color-red text-center">
                        ไม่เปิดใช้
                      </span>
                    </div>
                </template>
                  <template v-slot:item.percentUpdateStock="{ item }">
                    <span v-if="isEditSyncStock">
                      <v-text-field 
                        v-model="item.percentUpdateStock"
                        autocomplete="off"
                        outlined
                        dense
                        hide-details
                        type="number"
                      ></v-text-field>
                    </span>
                    <span v-else class="text-right" align="right" >
                      {{ formatMoney(item.percentUpdateStock) }}%
                    </span>
                  </template>
                  <template v-slot:item.remark="{ item }">
                 

                  
                    <span v-if="isEditSyncStock">
                      <table style="border: none;width:320px">
                        <tr>
                          <td style="border-left: 0px">
                            เมื่อสต๊อกต่ำกว่า :
                          </td>
                          <td  style="border-left: 0px" class="pl-2">
                            <v-text-field 
                              v-model="item.quantityLowStock"
                              style="width:50px"
                              autocomplete="off"
                              outlined
                              dense
                              hide-details
                              type="number"
                            ></v-text-field>
                          </td>
                          <td  style="border-left: 0px" class="pl-2">
                            ให้อัพเดทเป็น :
                          </td>
                          <td  style="border-left: 0px" class="pl-2">
                        
                            <v-text-field 
                              v-model="item.staticQuantityWhenLowStock"
                              style="width:50px"
                              autocomplete="off"
                              outlined
                              dense
                              hide-details
                              type="number"
                            ></v-text-field>
                          </td>
                        </tr>
                      </table>
                    </span>
                    <span v-else class="text-left" align="left" >
                       {{item.remark}}
                        <span v-if="item.staticQuantityWhenLowStock!=null" class="text-right" align="right" >
                          เมื่อสต๊อกต่ำกว่า {{item.quantityLowStock}} ให้อัพเดทไปเป็น {{  item.staticQuantityWhenLowStock  }} 
                        </span>
                    </span>
                  </template>
                  <template v-slot:item.dialog="{ item }">
                    <a @click="HistoryMapper(item)">รายละเอียด</a>
                  </template>
                </v-data-table>
                </div>
            </div>

           
            <div v-else-if="i.id==1">
              <v-row class="mt-1">
                <v-col cols="12" md="2" lg="1" class="pt-1 pb-2">
                  <h3 class="fn-14"> {{use_language.date}} : </h3>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="2" class="pt-1 pb-2">
                  <v-menu
                    ref="menuStartDate"
                    v-model="menuStartDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStart"
                        outlined
                        readonly
                        label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar"
                        @blur="startDate = parseDate(dateStart)"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        clearable
                        @click:clear="dateStart = null"
                        style="width: 290px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" @input="menuStartDate = false" @change="dateStart=formatDate(startDate),CheckDateTime(i.id,startDate,endDate)" no-title :allowed-dates="allowedDates"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="2" class="pt-1 pb-2">
                  <v-menu
                    ref="menuEndDate"
                    v-model="menuEndDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEnd"
                        outlined
                        readonly
                        label="สิ้นสุด"
                        prepend-inner-icon="mdi-calendar"
                        @blur="endDate = parseDate(dateEnd)"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        clearable
                        @click:clear="dateEnd = null"
                        style="width: 290px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" @input="menuEndDate = false" @change="dateEnd=formatDate(endDate),CheckDateTime(i.id,startDate,endDate)" no-title :allowed-dates="allowedDates"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" lg="7" class="pa-0"/>
              </v-row>

              <v-row>
                <v-col cols="12" md="2" lg="1" class="pt-1 pb-2">
                  <h3 class="fn-14"> {{use_language.show_products}} : </h3>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pt-1 pb-2">
                  <v-select
                    v-model="search.showProduct"
                    :items="search.showProductList"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    return-object
                    dense hide-details
                    outlined
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" lg="7" class="pa-0"/>
              </v-row>

              <v-row>
                <v-col cols="12" md="2" lg="1" class="pt-1 pb-2">
                  <h3 class="fn-14"> {{use_language.warehouse}} : </h3>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pt-1 pb-2">
                  <v-select
                    v-model="search.warehouse"
                    :items="search.warehouseList"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    return-object
                    dense hide-details
                    outlined
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" lg="7" class="pt-1 pb-2">
                  <v-btn :elevation="1" color="primary" class="pa-2" dark @click="SearchStockShopItem()">
                    <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                    {{use_language.search}}
                  </v-btn>
                </v-col>
              </v-row>

              <!-- PC hidden-xs-only -->
              <v-data-table
                :headers="header1"
                :items="dataset1"
                :items-per-page="9999999999"
                hide-default-footer
                class="packhai-border-table hidden-xs-only mt-2"
              >
                <template v-slot:item.createdDatetime="{ item }">
                  <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                    {{ item.createdDatetime }}
                  </span>
                  <span v-else>
                    {{ item.createdDatetime }}
                  </span>
                </template>
                <template v-slot:item.quantity="{ item }">
                  <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                    {{ formatMoney(item.quantity) }}
                  </span>
                  <span v-else>
                    {{ formatMoney(item.quantity) }}
                  </span>
                </template>
                <template v-slot:item.quantityLeft="{ item }">
                  <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                    {{ formatMoney(item.quantityLeft) }}
                  </span>
                  <span v-else>
                    {{ formatMoney(item.quantityLeft) }}
                  </span>
                </template>
                <template v-slot:item.costPrice="{ item }">
                  <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                    {{ formatMoney(item.costPrice) }}
                  </span>
                  <span v-else>
                    {{ formatMoney(item.costPrice) }}
                  </span>
                </template>
                <template v-slot:item.totalPrice="{ item }">
                  <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                    {{ formatMoney(item.totalPrice) }}
                  </span>
                  <span v-else>
                    {{ formatMoney(item.totalPrice) }}
                  </span>
                </template>
              </v-data-table>

              <!-- Mobile hidden-sm-and-up -->
              <v-data-iterator
                :items="dataset1"
                :items-per-page="9999999999"
                hide-default-header
                hide-default-footer
                class="hidden-sm-and-up"
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                      <v-card>
                        <v-row class="pa-2">
                          <v-col class="text-center pa-0">
                            <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                              {{ item.createdDatetime }}
                            </span>
                            <span v-else>
                              {{ item.createdDatetime }}
                            </span>
                          </v-col>
                        </v-row>
                        <v-divider/>
                        <v-row class="ma-0 px-2 py-1">
                          {{use_language.number_admissions}}
                          <v-spacer/>
                          <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                            {{ formatMoney(item.quantity) }}
                          </span>
                          <span v-else>
                            {{ formatMoney(item.quantity) }}
                          </span>
                        </v-row>
                        <v-row class="ma-0 px-2 py-1">
                          {{use_language.remaining_amount}}
                          <v-spacer/>
                          <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                            {{ formatMoney(item.quantityLeft) }}
                          </span>
                          <span v-else>
                            {{ formatMoney(item.quantityLeft) }}
                          </span>
                        </v-row>
                        <v-row class="ma-0 px-2 py-1">
                          {{use_language.cost_price}}
                          <v-spacer/>
                          <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                            {{ formatMoney(item.costPrice) }}
                          </span>
                          <span v-else>
                            {{ formatMoney(item.costPrice) }}
                          </span>
                        </v-row>
                        <v-row class="ma-0 px-2 py-1">
                          {{use_language.residual_value}}
                          <v-spacer/>
                          <span v-if="dataset1.length-1==dataset1.indexOf(item)" class="fw-b">
                            {{ formatMoney(item.totalPrice) }}
                          </span>
                          <span v-else>
                            {{ formatMoney(item.totalPrice) }}
                          </span>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>

            <div v-else-if="i.id==2">
              <v-row class="mt-1">
                <v-col cols="12" md="2" lg="1" class="pt-1 pb-2">
                  <h3 class="fn-14"> {{use_language.date}} : </h3>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="2" class="pt-1 pb-2">
                  <v-menu
                    ref="menuStartDate2"
                    v-model="menuStartDate2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateStart2"
                        outlined
                        readonly
                        label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar"
                        @blur="startDate2 = parseDate(dateStart2)"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        @click:clear="dateStart2 = null"
                        style="width: 290px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate2" @input="menuStartDate2 = false" @change="dateStart2=formatDate(startDate2),CheckDateTime(i.id,startDate2,endDate2)" no-title :allowed-dates="allowedDates"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="3" lg="2" class="pt-1 pb-2">
                  <v-menu
                    ref="menuEndDate2"
                    v-model="menuEndDate2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                    max-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateEnd2"
                        outlined
                        readonly
                        label="สิ้นสุด"
                        prepend-inner-icon="mdi-calendar"
                        @blur="endDate2 = parseDate(dateEnd2)"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        @click:clear="dateEnd2 = null"
                        style="width: 290px;"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate2" @input="menuEndDate2 = false" @change="dateEnd2=formatDate(endDate2),CheckDateTime(i.id,startDate2,endDate2)" no-title :allowed-dates="allowedDates"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" lg="7" class="pa-0"/>
              </v-row>

              <v-row>
                <v-col cols="12" md="2" lg="1" class="pt-1 pb-2">
                  <h3 class="fn-14"> {{use_language.warehouse}} : </h3>
                </v-col>
                <v-col cols="12" md="6" lg="4" class="pt-1 pb-2">
                  <v-select
                    v-model="search2.warehouse"
                    :items="search2.warehouseList"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    return-object
                    dense hide-details
                    outlined
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4" lg="7" class="pt-1 pb-2">
                  <v-row class="ma-0">
                    <v-btn :elevation="1" color="primary" class="pa-2" dark @click="SearchStockShopHistory()">
                      <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                      {{use_language.search}}
                    </v-btn>
                    <v-spacer/>
                    <v-btn :elevation="1" outlined color="success" class="px-2" @click="ExportExcelStockHistory()">
                      <v-icon size="18" class="ml-1" left>fa-download</v-icon>
                      {{use_language.export_excel}}
                      <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                    </v-btn>
                    <vue-excel-xlsx
                        id="GetExcelStockHistory"
                        v-show="false"
                        :data="dataExcelStockHistory"
                        :columns="headerExcelStockHistory"
                        :file-name="stockHistoryFileName"
                        :sheet-name="'shee1'"
                        >
                    </vue-excel-xlsx>
                  </v-row>
                </v-col>
              </v-row>

              <!-- PC hidden-xs-only -->
              <v-data-table
                :headers="header2"
                :items="dataExcelStockHistory"
                :items-per-page="9999999999"
                hide-default-footer
                class="packhai-border-table hidden-xs-only mt-2"
              >
                <template v-slot:item.referenceNo="{ item }">
                  <a @click="NewPage(item.referenceNo)"> {{ item.referenceNo }} </a>
                </template>
                <template v-slot:item.description="{ item }">
                  <font v-if="item.removeQuantity>0" class="pl-10">{{item.description}}</font>
                  <span v-else> {{ item.description }} </span>
                </template>
                <template v-slot:item.addQuantity="{ item }">
                  <span class="color-green"><b> {{ item.addQuantity }} </b></span>
                </template>
                <template v-slot:item.removeQuantity="{ item }">
                  <span class="color-red"><b> {{ item.removeQuantity }} </b></span>
                </template>
                <template v-slot:item.totalQuantity="{ item }">
                  <b> {{ item.totalQuantity }} </b>
                </template>
              </v-data-table>

              <!-- Mobile hidden-sm-and-up -->
              <v-data-iterator
                :items="dataExcelStockHistory"
                :items-per-page="9999999999"
                hide-default-header
                hide-default-footer
                class="hidden-sm-and-up"
              >
                <template v-slot:default="props">
                  <v-row>
                    <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                      <v-card>
                        <v-row class="ma-0 px-2 py-1">
                          <a @click="NewPage(item.referenceNo)"> {{ item.referenceNo }} </a>
                          <v-spacer/>
                          {{ item.createdDatetime }}
                        </v-row>
                        <v-divider/>
                        <v-row class="px-2 py-1">
                          <v-col cols="4" class="text-center pa-0">
                            <b>{{use_language.import}}</b>
                          </v-col>
                          <v-col cols="4" class="text-center pa-0">
                            <b>{{use_language.remove}}</b>
                          </v-col>
                          <v-col cols="4" class="text-center pa-0">
                            <b>{{use_language.remaining}}</b>
                          </v-col>
                        </v-row>
                        <v-row class="px-2 py-1">
                          <v-col cols="4" class="text-center pa-0">
                            <span class="color-green"><b> {{ item.addQuantity }} </b></span>
                          </v-col>
                          <v-col cols="4" class="text-center pa-0">
                            <span class="color-red"><b> {{ item.removeQuantity }} </b></span>
                          </v-col>
                          <v-col cols="4" class="text-center pa-0">
                            <b> {{ item.totalQuantity }} </b>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
              </v-data-iterator>
            </div>
            
            <div v-else-if="i.id ==3">
              <v-row>
                <v-col cols="12" align="right">
                  <span>
                    <vue-excel-xlsx
                      id="GetExcelWaitPack"
                      v-show="false"
                      :data="waitingPackBody.dataset"
                      :columns="waitingPackBody.headerExcel"
                      file-name="รายการรอแพ็ก"
                      :sheet-name="'sheet1'"
                      >
                    </vue-excel-xlsx>
                    <v-btn :elevation="1" outlined color="success" class="pa-2 ml-1 " @click="ExportExcelWaitPack()">
                      <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                      <span class="fn-12"> Export Excel </span>
                      <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                    </v-btn>
                  </span>
                </v-col>
              </v-row>
              <v-row class="pb-1 ">

              <v-col cols="12" sm="8" md="10" lg="10" class="py-2" />
              <v-col cols="12" md="2" lg="2" class="py-2 pl-0 ">
              <table style="width:100% ">
                  <tr>
                  <td>

                  </td>
                  <td align="right" >

                  </td>
                  </tr>
                  </table>


              </v-col>
              </v-row>
                  <!-- PC hidden-xs-only -->
                  <v-data-table
                    :headers="waitingPackBody.header"
                    :items="waitingPackBody.dataset"
                    :items-per-page="9999999999"
                    hide-default-footer
                    class="packhai-border-table hidden-xs-only"
                  >
                    <template v-slot:item.orderID="{ item }">
                      <a @click="NewPage(item.orderID)"> {{ item.orderID }} </a>
                    </template>
                    <template v-slot:item.created="{ item }">
                      <span> {{ formatDatetime(item.created) }} </span>
                    </template>
                    <template v-slot:item.readyToPackedDate="{ item }">
                      <span> {{ formatDatetime(item.readyToPackedDate) }} </span>
                    </template>
                    <template v-slot:item.expressCompanyName="{ item }">
                     <img v-if="item.expressCompanyLogo != null && item.expressCompanyLogo != ''" width="60px"  :src="item.expressCompanyLogo"    />
                    </template>
                    <template v-slot:item.referenceName="{ item }">
                      <v-avatar size="25px" tile>
                        <v-img v-if="item.referenceSourcePhoto != null && item.referenceSourcePhoto != ''" :src="item.referenceSourcePhoto"/>
                      </v-avatar>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      {{ formatMoney(item.quantity) }}
                    </template>

                  </v-data-table>

                                    <!-- Mobile hidden-sm-and-up -->
                                    <v-data-iterator
                    :items="waitingPackBody.dataset"
                    :items-per-page="9999999999"
                    hide-default-header
                    hide-default-footer
                    class="hidden-sm-and-up"
                  >
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1">
                              <a @click="NewPage(item.orderID)"> {{ item.orderID }} </a>
                              <v-spacer/>
                             <span> {{ formatDatetime(item.created) }} </span>
                            </v-row>
                            <v-divider/>
                            <v-row class="px-2 py-1">
                              <v-col cols="4" class="text-center">
                                <b>สถานะ</b>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b>ช่องทาง</b>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b>ขนส่ง</b>
                              </v-col>
                            </v-row>
                            <v-row class="px-2 py-1">
                              <v-col cols="4" class="text-center">
                                <span class="color-green"><b> {{ item.orderStatus }} </b></span>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <span class="color-red"><b> {{ item.referenceName }} </b></span>
                              </v-col>
                              <v-col cols="4" class="text-center">
                                <b> {{ item.expressCompanyName }} </b>
                              </v-col>
                            </v-row>
                            <v-row  class=" py-1" >
                              <v-col class="col-4 col-md-2 text-right" > <b>เลขอ้างอิง :</b></v-col>
                              <v-col  class="col-8 col-md-6 " >
                                 <b> {{ item.referenceNumber }} </b>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
        

            </div>

            <div v-else-if="i.id==4">
                  <v-row>
                    <v-col cols="12" align="right">
                      <span>
                        <v-btn  class="" text icon   @click="refresh()">
                            <v-icon size="18">fa-solid fa-arrows-rotate</v-icon>
                              </v-btn>
                      </span>
                      <span>
                        <vue-excel-xlsx
                          id="GetExcelWaitExport"
                          v-show="false"
                          :data="exportWaitingBody.dataset"
                          :columns="exportWaitingBody.headerExcel"
                          :file-name="'รายการรอนำออก'"
                          :sheet-name="'sheet1'"
                          >
                        </vue-excel-xlsx>
                          <v-btn :elevation="1" outlined color="success" class="pa-2 ml-1 " @click="ExportExcelWaitExport()">
                          <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                          <span class="fn-12"> Export Excel </span>
                          <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                          </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pb-1 ">
                    <v-col cols="12" sm="8" md="10" lg="10" class="py-2" />
                    <v-col cols="12" md="2" lg="2" class="py-2 pl-0 ">
                      <table style="width:100% ">
                        <tr> <td> </td>
                           <td align="right" > </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <!-- PC hidden-xs-only -->
                  <v-data-table
                    :headers="exportWaitingBody.header"
                    :items="exportWaitingBody.dataset"
                    :items-per-page="9999999999"
                    hide-default-footer
                    class="packhai-border-table hidden-xs-only"
                  >

                    <template v-slot:item.index="{ item }">
                      <span v-if="exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)"  >
                        {{exportWaitingBody.dataset.indexOf(item)+1}}
                      </span>
                      <span v-else  >
                        {{item.no}}
                      </span>
                    </template>
                    <template v-slot:item.id="{ item }">
                      <a  v-if="exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)"
                       @click="EditStock(item.id)">
                        {{ GenIE(item.id) }}
                         </a>
                    </template>
                    <template v-slot:item.createdDatetime="{ item }">
                      <span> {{ formatDatetime(item.createdDatetime) }} </span>
                    </template>
                     <template v-slot:item.staffShopName="{ item }">
                      <span v-if="item.staffShopName == null && exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)">  คลัง </span>
                      <span v-else> {{ item.staffShopName }} </span>
                    </template>
                  </v-data-table>
                    <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator
                    :items="exportWaitingBody.dataset"
                    :items-per-page="9999999999"
                    hide-default-header
                    hide-default-footer
                    class="hidden-sm-and-up"
                  >
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1" v-if=" exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)">
                               <a  v-if="exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)"      @click="EditStock(item.id)">
                              {{ GenIE(item.id) }}
                              </a>
                              <v-spacer/>
                             <span> {{ formatDatetime(item.createdDatetime) }} </span>
                            </v-row>
                            <v-divider/>
                            <v-row class="px-2 py-1"  >
                              <v-col cols="6" class="text-center">
                                <b v-if=" exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)">สร้างโดย</b>

                              </v-col>
                              <v-col cols="6" class="text-center">
                                <b>จำนวน</b>
                              </v-col>
                            </v-row>
                            <v-row class="px-2 py-1">
                              <v-col cols="6" class="text-center">
                                <span v-if="item.staffShopName == null
                                 && exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)"
                                 class="color-green" >
                                  คลัง
                                </span>
                                <b v-else-if="exportWaitingBody.dataset.length-1 == exportWaitingBody.dataset.indexOf(item)">รวม</b>
                                <span v-else class="color-green"> {{ item.staffShopName }} </span>
                              </v-col>
                              <v-col cols="6" class="text-center">
                                <span class="color-red"><b> {{ item.quantity }} </b></span>
                              </v-col>

                            </v-row>
                            <v-row  class=" py-1" v-if=" exportWaitingBody.dataset.length-1 != exportWaitingBody.dataset.indexOf(item)" >
                              <v-col class="col-4 col-md-2 text-right"  > <b>หมายเหตุ :</b></v-col>
                              <v-col  class="col-8 col-md-6 " >
                                 <b > {{ item.remark }} </b>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>

            </div>

            <div v-else-if="i.id==5">
                  <v-row>
                    <v-col cols="12" align="right">
                      <span>
                        <v-btn  class="" text icon   @click="refresh()">
                            <v-icon size="18">fa-solid fa-arrows-rotate</v-icon>
                              </v-btn>
                      </span>
                      <span>
                        <vue-excel-xlsx
                          id="GetExcelWaitImport"
                          v-show="false"
                          :data="importWaitingBody.dataset"
                          :columns="importWaitingBody.headerExcel"
                          :file-name="'รายการรอนำเข้า'"
                          :sheet-name="'sheet1'"
                          >
                        </vue-excel-xlsx>
                          <v-btn :elevation="1" outlined color="success" class="pa-2 ml-1 " @click="ExportExcelWaitImport()">
                          <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                          <span class="fn-12"> Export Excel </span>
                          <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
                          </v-btn>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="pb-1 ">
                    <v-col cols="12" sm="8" md="10" lg="10" class="py-2" />
                    <v-col cols="12" md="2" lg="2" class="py-2 pl-0 ">
                      <table style="width:100% ">
                        <tr> <td> </td>
                           <td align="right" > </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>

                  <!-- PC hidden-xs-only -->
                  <v-data-table
                    :headers="importWaitingBody.header"
                    :items="importWaitingBody.dataset"
                    :items-per-page="9999999999"
                    hide-default-footer
                    class="packhai-border-table hidden-xs-only"
                  >

                    <template v-slot:item.index="{ item }">
                      <span v-if="importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)"  >
                        {{importWaitingBody.dataset.indexOf(item)+1}}
                      </span>
                      <span v-else  >
                        {{item.no}}
                      </span>
                    </template>
                    <template v-slot:item.id="{ item }">
                      <a  v-if="importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)"
                       @click="EditStock(item.id)">
                        {{ GenIE(item.id) }}
                         </a>
                    </template>
                    <template v-slot:item.createdDatetime="{ item }">
                      <span> {{ formatDatetime(item.createdDatetime) }} </span>
                    </template>
                    <template v-slot:item.staffShopName="{ item }">
                      <span v-if="item.staffShopName == null && importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)">  คลัง </span>
                      <span v-else> {{ item.staffShopName }} </span>
                    </template>
                  </v-data-table>
                        <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator
                    :items="importWaitingBody.dataset"
                    :items-per-page="9999999999"
                    hide-default-header
                    hide-default-footer
                    class="hidden-sm-and-up"
                  >
                    <template v-slot:default="props">
                      <v-row>
                        <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                          <v-card>
                            <v-row class="ma-0 px-2 py-1" v-if=" importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)">
                               <a  v-if="importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)"      @click="EditStock(item.id)">
                              {{ GenIE(item.id) }}
                              </a>
                              <v-spacer/>
                             <span> {{ formatDatetime(item.createdDatetime) }} </span>
                            </v-row>
                            <v-divider/>
                            <v-row class="px-2 py-1"  >
                              <v-col cols="6" class="text-center">
                                <b v-if=" importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)">สร้างโดย</b>

                              </v-col>
                              <v-col cols="6" class="text-center">
                                <b>จำนวน</b>
                              </v-col>
                            </v-row>
                            <v-row class="px-2 py-1">
                              <v-col cols="6" class="text-center">
                                <span v-if="item.staffShopName == null
                                 && importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)"
                                 class="color-green" >
                                  คลัง
                                </span>
                                <b v-else-if="importWaitingBody.dataset.length-1 == importWaitingBody.dataset.indexOf(item)">รวม</b>
                                <span v-else class="color-green"> {{ item.staffShopName }} </span>
                              </v-col>
                              <v-col cols="6" class="text-center">
                                <span class="color-red"><b> {{ item.quantity }} </b></span>
                              </v-col>

                            </v-row>
                            <v-row  class=" py-1" v-if=" importWaitingBody.dataset.length-1 != importWaitingBody.dataset.indexOf(item)" >
                              <v-col class="col-4 col-md-2 text-right"  > <b>หมายเหตุ :</b></v-col>
                              <v-col  class="col-8 col-md-6 " >
                                 <b > {{ item.remark }} </b>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>

            </div>

          </v-tab-item>
        </v-tabs-items>

      </v-card-text>
    </v-card>

  </div>
</template>

<style scoped>
  >>>.v-input__prepend-inner{
    margin-top: 10px;
  }
</style>

<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import Swal from 'sweetalert2' 
import { stockService_dotnet, shopService_dotnet,ecomService_dotnet } from '@/website/global'
import { formatDatetime, formatDate_thai, formatMoney, isNumberWithDot, isNumberWNoDot, DateNowNotNull, CheckUrlPhoto, GenIE, GenPA, Trim_value, Trim_rules ,get_languages,getdatelongstring} from '@/website/global_function'


 export default {
    components: {
      Loading,
    },
    data: vm => ({
      header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
      ShopID: parseInt(localStorage.getItem('shop_id')),
      page_loading: true,
      loading: false,
      use_language: null,
      set_language: null,
      // แสดงภาพ
      ShowPhoto: false,
      ShowPhotoLink: '',
      ProductDataHeader: [
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px'},
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '70px'},
        { text: 'ชื่อ',align: 'left',sortable: false,value: 'name', width: '350px'},
        { text: 'ลักษณะ',align: 'center',sortable: false,value: 'prop1', width: '100px'},
        { text: 'รหัสสินค้า',align: 'center',sortable: false,value: 'productCode', width: '90px'},
        { text: 'SKU',align: 'center',sortable: false,value: 'sku'},
        { text: 'Barcode',align: 'center',sortable: false,value: 'barcode'},
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '90px'},
        { text: 'วันที่สร้าง',align: 'center',sortable: false,value: 'created', width: '160px'},
        { text: 'วันที่อัพเดท',align: 'center',sortable: false,value: 'updated', width: '160px'},
      ],
      ProductDataItem: [],

      // ข้อมูลสินค้า
      stockShopId: 0,
      stockShop: [],
      isEditSyncStock:false,
      // tab
      tab: 0,
      tab_list: [
        {"id": 0, "name": "สต๊อกคงเหลือ"},
        {"id": 1, "name": "รายการนำเข้าสต๊อก"},
        {"id": 2, "name": "รายการเดินสต๊อก"},
        {"id": 3, "name": "รายการรอแพ็ค"},
        {"id": 4, "name": "รายการรอนำออก"},
        {"id": 5, "name": "รายการรอนำเข้า"},
      ],

      headerStockMarketplace: [
        { text: 'ช่องทาง',align: 'center',sortable: false,value: 'marketplaceLogo', width: '80px' },
        { text: 'ร้านค้า',align: 'left',sortable: false,value: 'marketplaceShopName', width: '100px' },
        { text: 'รูป',align: 'center',sortable: false,value: 'photoLink', width: '80px' },
        { text: 'ชื่อสินค้า',align: 'left',sortable: false,value: 'name', width: '300px' },
        { text: 'คลังที่เชื่อมต่อ',align: 'left',sortable: false,value: 'warehouseName', width: '110px' },
        { text: 'สต๊อกพร้อมขาย',align: 'right',sortable: false,value: 'quantityAvailable', width: '110px' },
        { text: 'สต๊อกใน platform',align: 'right',sortable: false,value: 'marketplaceQuantity', width: '120px' },
        { text: 'platform Reserved',align: 'right',sortable: false,value: 'marketplaceReservedQuantity', width: '120px' },
        { text: 'สถานะ sync',align: 'right',sortable: false,value: 'isUpdateStock', width: '95px' },
        { text: '%การอัพเดท',align: 'right',sortable: false,value: 'percentUpdateStock', width: '95px' },
        { text: 'หมายเหตุ',align: 'left',sortable: false,value: 'remark'  },
        { text: 'ประวัติการอัพเดท',align: 'left',sortable: false,value: 'dialog' , width: '110px' },
      ],
      datasetStockMarketplace: [],

      header: [
        { text: 'คลังสินค้า',align: 'left',sortable: false,value: 'warehouseName', width: '300px' },
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '110px' },
        { text: 'มูลค่าคงเหลือ',align: 'right',sortable: false,value: 'totalPrice', width: '130px' },
        { text: 'รายละเอียด',align: 'center',sortable: false,value: 'detail', width: '120px' },
      ],
      dataset: [],
      header1: [
        { text: 'วันที่',align: 'center',sortable: false,value: 'createdDatetime', width: '170px'},
        { text: 'จำนวนที่รับเข้า',align: 'center',sortable: false,value: 'quantity', width: '120px'},
        { text: 'จำนวนที่เหลือ',align: 'center',sortable: false,value: 'quantityLeft', width: '120px'},
        { text: 'ราคาทุน',align: 'right',sortable: false,value: 'costPrice', width: '110px'},
        { text: 'มูลค่าคงเหลือ',align: 'right',sortable: false,value: 'totalPrice', width: '130px'},
        { text: 'วันที่ผลิต',align: 'center',sortable: false,value: 'manufactureDate', width: '170px'},
        { text: 'วันที่หมดอายุ',align: 'center',sortable: false,value: 'expirationDate', width: '170px'},
        { text: 'RackNo',align: 'center',sortable: false,value: 'rackNo', width: '120px'},
        { text: 'รายละเอียด',align: 'left',sortable: false,value: 'remark'},
      ],
      dataset1: [],
      startDate: null,
      dateStart: null,
      menuStartDate: false,
      endDate: null,
      dateEnd: null,
      menuEndDate: false,
      search: {
        "warehouseList": [{"id": 0, "name": "--ทั้งหมด--"}],
        "warehouse": {"id": 0, "name": "--ทั้งหมด--"},
        "showProductList": [{"id": 0, "name": "--ทั้งหมด--"},{"id": 1, "name": "สินค้าที่มีสต๊อก"},{"id": 2, "name": "สินค้าที่ไม่มีสต๊อก"}],
        "showProduct": {"id": 0, "name": "--ทั้งหมด--"},
        "startDate": null,
        "endDate": null,
      },
      itemDataHistory:[],
      headerDataHistory:[                    
            { text: 'วันที่อัพเดท',   align: 'center',   value: 'updated', width: '40%', sortable: false },
            { text: 'จำนวนที่อัพเดท',   align: 'center',   value: 'quantity', width: '20%', sortable: false },
            { text: 'หมายเหตุ',   align: 'center',   value: 'error', width: '40%', sortable: false },
          ],

      header2: [
        { text: 'วันที่',align: 'center',sortable: false,value: 'createdDatetime', width: '170px'},
        { text: 'เลขอ้างอิง',align: 'center',sortable: false,value: 'referenceNo', width: '110px'},
        { text: 'รายละเอียด',align: 'left',sortable: false,value: 'description'},
        { text: 'นำเข้า',align: 'right',sortable: false,value: 'addQuantity', width: '90px'},
        { text: 'นำออก',align: 'right',sortable: false,value: 'removeQuantity', width: '90px'},
        { text: 'คงเหลือ',align: 'right',sortable: false,value: 'totalQuantity', width: '90px'},
      ],
      
      startDate2: null,
      updateHistoryDialog:false,
      dateStart2: null,
      menuStartDate2: false,
      endDate2: null,
      dateEnd2: null,
      menuEndDate2: false,
      search2: {
        "warehouseList": [],
        "warehouse": {"id": 0, "name": "--ทั้งหมด--"},
        "startDate": null,
        "endDate": null,
      },
      // Export Excel
      dataExcelStockHistory: [],
      stockHistoryFileName:'',
      loadingExcel: 0,
      headerExcelStockHistory: [
        { label: "วันที่", field: "createdDatetime", width: 30},
        { label: "เลขอ้างอิง", field: "referenceNo", width: 15},
        { label: "รายละเอียด", field: "description", width: 30},
        { label: "นำเข้า", field: "addQuantity", width: 15},
        { label: "นำออก", field: "removeQuantity", width: 15},
        { label: "คงเหลือ", field: "totalQuantity", width: 15},
        { label: "เลขอ้างอิง", field: "referenceNo2", width: 15},
      ],

      waitingPackBody:{
       header : [
        { text: 'No',align: 'center',sortable: false,value: 'no', width: '70px'},
        { text: 'วันที่เปิดออเดอร์',align: 'center',sortable: false,value: 'created', width: '150px'},
        { text: 'พร้อมแพ็ค',align: 'center',sortable: false,value: 'readyToPackedDate', width: '150px'},
        { text: 'ออเดอร์',align: 'center',sortable: false,value: 'orderID', width: '110px'},
        { text: 'สถานะ',align: 'center',sortable: false,value: 'orderStatus', width: '90px'},
        { text: 'ช่องทาง',align: 'center',sortable: false,value: 'referenceName', width: '90px'},
        { text: 'ขนส่ง',align: 'center',sortable: false,value: 'expressCompanyName', width: '90px'},
        { text: 'เลขอ้างอิง',align: 'center',sortable: false,value: 'referenceNumber',width: '110px'},
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity',width: '110px'},
      ],
       dataset : [],
        headerExcel: [
        { label: "No", field: "no", width: 30},
        { label: "วันที่เปิดออเดอร์", field: "createdDatetimeExcel", width: 30},
        { label: "พร้อมแพ็ค", field: "readyToPackedDateExcel", width: 30},
        { label: "ออเดอร์", field: "orderID", width: 15},
        { label: "สถานะ", field: "orderStatus", width: 30},
        { label: "ช่องทาง", field: "referenceName", width: 15},
        { label: "ขนส่ง", field: "expressCompanyName", width: 15},
        { label: "เลขอ้างอิง", field: "referenceNumber", width: 15},
        { label: "จำนวน", field: "quantity", width: 15},
      ],
       },

      importWaitingBody:{
       header : [

        { text: 'No',align: 'center',sortable: false,value: 'index', width: '70px'},
        { text: 'เลขที่บิล',align: 'center',sortable: false,value: 'id', width: '150px'},
        { text: 'วัน',align: 'center',sortable: false,value: 'createdDatetime', width: '150px'},
        { text: 'สร้างโดย',align: 'center',sortable: false,value: 'staffShopName', width: '150px'},
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '150px'},
        { text: 'หมายเหตุ',align: 'center',sortable: false,value: 'remark', width: '150px'},

      ],
       dataset : [],
        headerExcel: [
        { label: "No", field: "index", width: 30},
        { label: "เลขที่บิล", field: "id", width: 30},
        { label: "วัน", field: "createdDatetime", width: 30},
        { label: "สร้างโดย", field: "staffShopName", width: 30},
        { label: "จำนวน", field: "quantity", width: 30},
        { label: "หมายเหตุ", field: "หมายเหตุ", width: 30},


                ],
                     },
      exportWaitingBody:{
       header : [

        { text: 'No',align: 'center',sortable: false,value: 'index', width: '70px'},
        { text: 'เลขที่บิล',align: 'center',sortable: false,value: 'id', width: '150px'},
        { text: 'วัน',align: 'center',sortable: false,value: 'createdDatetime', width: '150px'},
        { text: 'สร้างโดย',align: 'center',sortable: false,value: 'staffShopName', width: '150px'},
        { text: 'จำนวน',align: 'center',sortable: false,value: 'quantity', width: '150px'},
        { text: 'หมายเหตุ',align: 'center',sortable: false,value: 'remark', width: '150px'},

      ],
       dataset : [],
        headerExcel: [
        { label: "No", field: "index", width: 30},
        { label: "เลขที่บิล", field: "id", width: 30},
        { label: "วัน", field: "createdDatetime", width: 30},
        { label: "สร้างโดย", field: "staffShopName", width: 30},
        { label: "จำนวน", field: "quantity", width: 30},
        { label: "หมายเหตุ", field: "หมายเหตุ", width: 30},


                ],
                     },
    authorize:false,


    }),
    async created() {

    //เก็บภาษามาไว้ใน use_language
      this.stockShopId = this.$route.query.id
      this.getStockMarketplace();
      this.use_language = await this.get_languages(localStorage.getItem("set_language"));
    //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
      this.get_authorize_staffshop();
      

      document.title = ""
 
      await this.Load_Data(this.stockShopId)
      document.title = this.stockShop.name
      this.page_loading = false
      
    },
    methods: {
      async Load_Data(stockShopId){
        await axios.all([
          axios.post(stockService_dotnet+'Stock/get-stockShop', {
            "stockShopId": parseInt(stockShopId),
          }, {headers: this.header_token}),

          axios.post(shopService_dotnet+'Shop/get-warehouse-list', {
            "shopId": this.ShopID,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/inventories-group', {
            "shopId": this.ShopID,
            "stockShopId": parseInt(stockShopId),
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-stockshop-item-list', {
            "stockShopId": parseInt(stockShopId),
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-stockshop-history-list', {
            "stockShopId": parseInt(stockShopId),
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-unpacked-by-stockshop', {
            "stockShopId": parseInt(stockShopId),
          }, {headers: this.header_token}),
        ])
        .then(axios.spread((stockShop, warehouseList, inventories, stockItem, stockHistory, waitingPack) => {
          if (stockShop.status==200 && warehouseList.status==200 && inventories.status==200 && stockItem.status==200 && stockHistory.status==200){
            stockShop = stockShop.data
            inventories = inventories.data
            stockHistory = stockHistory.data
            stockItem = stockItem.data
            waitingPack = waitingPack.data
           
            // ไว้เทสดูข้อมูล
          //  this.getimportexportListByStockShopId(957,634);

          //  ยิง API รายการรอนำเข้า,รายการรอนำออก
           this.getimportexportListByStockShopId(stockShopId,stockShop.warehouseId);

            this.stockShop = stockShop
            this.stockHistoryFileName = this.stockShop.name+"_"+ (this.stockShop.sku? this.stockShop.sku+"_":"")+"exported_"+ this.getdatelongstring();
            if(this.stockShop.isProductSet){
              this.ProductSetOfDetail(this.stockShop);
            }
            this.dataset = inventories
            this.dataset1 = this.SetDataStockItem(stockItem.itemList)
            this.dataExcelStockHistory = this.SetDataExcel(stockHistory.itemList)

            for (var i in warehouseList.data){
              var warehouse = {"id": warehouseList.data[i].id,"name": warehouseList.data[i].name}
              this.search.warehouseList.push(warehouse)
              this.search2.warehouseList.push(warehouse)
              if (parseInt(warehouseList.data[i].id) == parseInt(stockShop.warehouseId)){
                this.search2.warehouse = warehouse
              }
              if (stockItem.warehouseID == warehouseList.data[i].id){
                this.search.warehouse = warehouse
              }
            }
            for (var j in this.search.showProductList){
              if (stockItem.inStock == this.search.showProductList[j].id){
                this.search.showProduct = this.search.showProductList[j]
                break
              }
            }

            this.waitingPackBody.dataset  = this.SetDataWaitingPack(waitingPack)

            this.SearchDateDataset1(stockItem)
            this.SearchDateDataset2(stockHistory)
          } else {
            this.loading = false
            this.$swal({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้",
            })
          }

        }));
      },

      async getStockMarketplace(){
        try {
          let response = await axios.get(ecomService_dotnet+'Stock/view-stock-marketplace?StockShopID='+this.stockShopId,   {headers: this.header_token})
        
          if (response.status == 200){
            this.datasetStockMarketplace =response.data.data;
          }
        } catch (error) {
          // error
        }
      },
      async ProductSetOfDetail(item){
        this.loading = true
        this.productSetItem = item
        await axios.all([
          axios.post(stockService_dotnet+'Stock/get-productset-vstockmaster-list', {
            'productMasterId': item.productMasterId,
          }, {headers: this.header_token}),

          axios.post(stockService_dotnet+'Stock/get-productset-list', {
            'productMasterId': item.productMasterId,
          }, {headers: this.header_token}),
        ])
        .then(axios.spread((productsetVStockMaster, productset) => {
          if (productsetVStockMaster.status==200 && productset.status==200){
            productsetVStockMaster = productsetVStockMaster.data
            productset = productset.data
            for (var k in productsetVStockMaster){
              for (var j in productset){
                if (productsetVStockMaster[k]["productMasterId"]==productset[j]["productMasterId"] && productsetVStockMaster[k]["id"]==productset[j]["productMasterItemId"]){
                  productsetVStockMaster[k]["quantity"] = parseInt(productset[j]["quantity"])
                  productsetVStockMaster[k]["created"] =  productset[j]["created"] 
                  productsetVStockMaster[k]["updated"] =  productset[j]["updated"] 
                  productsetVStockMaster[k]["createdByName"] =  productset[j]["createdByName"] 
                  productsetVStockMaster[k]["updatedByName"] =  productset[j]["updatedByName"] 
                }
              }
            }
            this.ProductDataItem = productsetVStockMaster
            this.loading = false
         
          }else{
            this.loading = false
            Swal.fire({
              position: "top",
              type: "error",
              text: "ไม่สามารถทำรายการได้",
            })
          }
        }));
      },
      SearchDateDataset1(data){
        this.dateStart = this.formatDatetime(data.startDate)
        this.startDate = data.startDate
        this.dateEnd = this.formatDatetime(data.endDate)
        this.endDate = data.endDate
      },
      SearchDateDataset2(data){
        this.dateStart2 = this.formatDatetime(data.startDate)
        this.startDate2 = data.startDate
        this.dateEnd2 = this.formatDatetime(data.endDate)
        this.endDate2 = data.endDate
      },
      async SeeDetails(warehouseId,e){
        if (e){
          for (var i in this.search.warehouseList){
            if (warehouseId==this.search.warehouseList[i].id){
              this.search.warehouse = this.search.warehouseList[i]
              this.dateStart = null
              this.startDate = null
              this.dateEnd = null
              this.endDate = null
              await this.SearchStockShopItem()
              await this.SetSearchDateDataset1(this.dataset1)
            }
          }
          this.tab = 1
        } else {
          this.tab = warehouseId
        }
      },
      SetSearchDateDataset1(data){
        if (data.length>1){
          this.dateEnd = data[data.length-2].createdDatetime.substr(0,10)
          this.endDate = this.parseDate(data[data.length-2].createdDatetime.substr(0,10))
          this.dateStart = data[0].createdDatetime.substr(0,10)
          this.startDate = this.parseDate(data[0].createdDatetime.substr(0,10))
        }
      },
      async SearchStockShopHistory(){
        this.loading = true
        this.search2.startDate = this.startDate2
        this.search2.endDate = this.endDate2
        let response = await axios.post(stockService_dotnet+'Stock/get-stockshop-history-list', {
          "shopId": localStorage.getItem('shop_id'),
          "stockShopId": this.stockShopId,
          "warehouseId": this.search2.warehouse.id,
          "startDate": this.startDate2,
          "endDate": this.endDate2,
        }, {headers: this.header_token})
        this.loading = false
        if (response.status == 200){
          this.dataExcelStockHistory = await this.SetDataExcel(response.data.itemList)
          this.stockHistoryFileName = this.stockShop.name+"_"+ (this.stockShop.sku? this.stockShop.sku+"_":"")+"exported_"+ this.getdatelongstring();
        }
      },
      async SearchStockShopItem(){
        this.loading = true
        if (this.startDate==null || this.endDate==null){
          this.dateStart = null
          this.startDate = null
          this.dateEnd = null
          this.endDate = null
        }
        this.search.startDate = this.startDate
        this.search.endDate = this.endDate

        let response = await axios.post(stockService_dotnet+'Stock/get-stockshop-item-list', {
          "shopId": localStorage.getItem('shop_id'),
          "stockShopId": this.stockShopId,
          "warehouseId": this.search.warehouse.id,
          "inStock": this.search.showProduct.id,
          "startDate": this.startDate,
          "endDate": this.endDate,
        }, {headers: this.header_token})
        this.loading = false
        if (response.status == 200){
          this.dataset1 = await this.SetDataStockItem(response.data.itemList)
        }
      },
      async save_edit_stock_sync(){
          this.isEditSyncStock = !this.isEditSyncStock

          var  datarequest=[];

          this.datasetStockMarketplace.forEach(element => {
            datarequest.push({
              "id": element.id,
              "platformID": element.platformID, 
              "isUpdateStock":  element.isUpdateStock,
              "percentUpdateStock":  element.percentUpdateStock==''? null: parseFloat(element.percentUpdateStock),
              "quantityLowStock":   element.quantityLowStock==''? null: parseInt(element.quantityLowStock),
              "staticQuantityWhenLowStock": element.staticQuantityWhenLowStock==''? null: parseInt(element.staticQuantityWhenLowStock),
              "stockShopID":this.stockShopId,
              "shopID":this.ShopID
            })
          }); 

          this.loading = true;
          try {
            let response = await axios.post(
              ecomService_dotnet + "Stock/update-product-sync-stock-marketplace",
                datarequest,
              { headers: this.header_token }
            ); 
            if(response.data.status=="success"){
              Swal.fire({
                position: "top",
                toast:true,
                timer:1500,
                type: "success",
                text: "บันทึกสำเร็จ",
              });
            }else{
              this.loading = false;
              Swal.fire({
                type: "error",
                text: response.data.error_description,
              });
            }
     
          } catch (e) {
            this.loading = false;
            Swal.fire({
             
              type: "error",
              text: "บันทึไม่สำเร็จกรุณาทำรายการอีกครั้ง",
            }); 
          }
          this.loading = false;
      },
      SetDataStockItem(data){
        if (data.length>0){
          var response = []
          var TotalQuantity = 0
          var TotalQuantityLeft = 0
          var TotalCostPrice = 0
          var TotalPrice = 0

          for (var i in data){
            data[i].costPrice = data[i].costPrice==null || data[i].costPrice=='' ? 0 : parseFloat(data[i].costPrice)
            data[i].quantity = data[i].quantity==null || data[i].quantity=='' ? 0 : parseInt(data[i].quantity)
            data[i].quantityLeft = data[i].quantityLeft==null || data[i].quantityLeft=='' ? 0 : parseInt(data[i].quantityLeft)

            response.push({
              createdDatetime: this.formatDatetime(data[i].createdDatetime),
              quantity: this.formatMoney(data[i].quantity),
              quantityLeft: this.formatMoney(data[i].quantityLeft),
              costPrice: this.formatMoney(data[i].costPrice),
              totalPrice: this.formatMoney(data[i].quantityLeft * data[i].costPrice),
              manufactureDate: this.formatDate_thai(data[i].manufactureDate),
              expirationDate: this.formatDate_thai(data[i].expirationDate),
              rackNo: data[i].rackNo,
              remark: data[i].remark,
            })

            TotalQuantity += data[i].quantity
            TotalQuantityLeft += data[i].quantityLeft
            TotalCostPrice += data[i].costPrice
            TotalPrice += data[i].quantityLeft * data[i].costPrice
          }

          response.push({
            createdDatetime: "รวม",
            quantity: this.formatMoney(TotalQuantity),
            quantityLeft: this.formatMoney(TotalQuantityLeft),
            costPrice: this.formatMoney(TotalCostPrice),
            totalPrice: this.formatMoney(TotalPrice),
            manufactureDate: "",
            expirationDate: "",
            rackNo: "",
            remark: "",
          })

          return response
        } else {
          return data
        }
      },
      async HistoryMapper(item){
        this.updateHistoryDialog = true
        if( item.updateHistory == null)
        {
          this.itemDataHistory = []
          return
        }
        for(var i in item.updateHistory)
        {
          this.itemDataHistory = item.updateHistory
        }
      },
      async ExportExcel(){
        this.loadingExcel = 0
        this.datasetExcel = []
        this.$swal({
          position: "top",
          type: 'warning',
          text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false
        }).then(async(result) => {
          if (result.value) {
            this.loadingExcel = 1
            this.loadingExcel = this.dataset2.length==0 ? 0 : 100
            document.getElementById("GetExcel").click();
          }
        })
      },
      async ExportExcelWaitImport(){
        document.getElementById("GetExcelWaitImport").click();
      },
      async ExportExcelWaitExport(){
        document.getElementById("GetExcelWaitExport").click();
      },
      async ExportExcelWaitPack(){
        document.getElementById("GetExcelWaitPack").click();
      },
      async ExportExcelStockHistory(){
        document.getElementById("GetExcelStockHistory").click();
      },
      async get_authorize_staffshop () {
          if(localStorage.getItem("230") == 2)
          {
           this.authorize = true
          }
        },
        
      SetDataExcel(data){
        var response = []
        for (var i in data) {
          response.push({
            createdDatetime: this.formatDatetime(data[i].createdDatetime),
            referenceNo: this.CheckReferenceNo(data[i].referenceNo),
            referenceNo2:  data[i].referenceNo2 ,
            description: data[i].description,
            addQuantity: this.formatMoney(data[i].addQuantity),
            removeQuantity: this.formatMoney(data[i].removeQuantity),
            totalQuantity: this.formatMoney(data[i].totalQuantity),
          })
        }
        console.log(response);
        
        return response
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      ItemUpdateChanged(item){
        if(item.isUpdateStock){
          if(item.percentUpdateStock==""||item.percentUpdateStock==''){
            item.percentUpdateStock=100;
          }
        }
      },
      toBack(){
        this.$router.push('/my-stock')
      },
      UpdateStock(){
        var data = {
          "productMasterId": this.stockShop.productMasterId,
          "productMasterItemId": this.stockShop.productMasterItemId,
          "isProductSet": this.stockShop.isProductSet ? 1 : 0,
           "part_return": "/my-stock",
        }
        localStorage.setItem('data', JSON.stringify(data))
        window.open("/my-product/update");
        // this.$router.push("/my-product/update")
      },
      async NewPage(ReferenceNo){
        if (ReferenceNo==null){ return ReferenceNo}
        var paie = ReferenceNo.substr(0, 2)
        var ID = parseInt(ReferenceNo.substr(2, ReferenceNo.length))
        if (paie=='PA'){
          let response = await axios.post(stockService_dotnet+'Stock/get-order-token', {
            "orderId": ID
          }, {headers: this.header_token})
          if (response.status == 200){
            window.open("order-detail?token=" + response.data);
          }
        }else if (paie=='IE'){
          window.open("transfer-list/update?id="+btoa(ID));
        }
      },
      CheckReferenceNo(ReferenceNo){
        if (ReferenceNo==null){ return ReferenceNo}
        var paie = ReferenceNo.substr(0, 2)
        var ID = parseInt(ReferenceNo.substr(2, ReferenceNo.length))
        if (paie=='PA'){
          return this.GenPA(ID)
        }else if (paie=='IE'){
          return this.GenIE(ID)
        }else{
          return ReferenceNo
        }
      },
      CheckDateTime(tab,startDate,endDate){
        if (startDate!=null && endDate!=null){
          var PstartDate = startDate
          var PendDate = endDate
          if (new Date(startDate) > new Date(endDate)){
            PstartDate = endDate
            PendDate = startDate
          }
          startDate = PstartDate
          endDate = PendDate
        }
        if (tab==1){
          this.startDate = startDate
          this.endDate = endDate
          this.dateStart = this.formatDate(startDate)
          this.dateEnd = this.formatDate(endDate)
        }
        if (tab==2){
          this.startDate2 = startDate
          this.endDate2 = endDate
          this.dateStart2 = this.formatDate(startDate)
          this.dateEnd2 = this.formatDate(endDate)
        }
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      SetDataWaitingPack(data){
        if (data.length>0){
          var response = []
          var TotalQuantity = 0
          for (var i in data){

            response.push(
              data[i]
            )

            TotalQuantity += data[i].quantity
          }

          response.push({
            no: "รวม",
            quantity: this.formatMoney(TotalQuantity),

          })
          return response
        } else {
          return data
        }
      },
      async getimportexportListByStockShopId(stockShopId,warehouseID){
    var exportDatatList = await this.SetData_importexportmasterListByStockShopId(stockShopId,warehouseID,[2,4])
    var importDatatList = await this.SetData_importexportmasterListByStockShopId(stockShopId,warehouseID,[1,3])
    this.importWaitingBody.dataset =  this.SetDataWaitingPack(importDatatList)
    this.exportWaitingBody.dataset =  this.SetDataWaitingPack(exportDatatList)
      },
      async SetData_importexportmasterListByStockShopId(stockShopId,warehouseID,transacionType){
      return axios.post(stockService_dotnet+'Stock/get-importexportmaster-list-by-stockshopid', {
        "stockShopId": parseInt(stockShopId),
        "warehouseID": parseInt(warehouseID),
        "transacionType": transacionType,
      }, {headers: this.header_token})
      .then( (res) => {
        return res.data ;
      })
      .catch((error) => {
        return [{}];
       });

      },
      async EditStock(ImportExportMasterID){
        window.open("transfer-list/update?id="+btoa(ImportExportMasterID));
      },
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      get_languages,
      formatDatetime,
      formatDate_thai,
      formatMoney,
      isNumberWithDot,
      isNumberWNoDot,
      DateNowNotNull,
      CheckUrlPhoto,
      GenIE,
      GenPA,
      Trim_value,
      Trim_rules,
      getdatelongstring
    }
  }
</script>
