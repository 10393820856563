<template>
    <div v-if="page_loading">
      <loading :page_loading="page_loading" />
    </div>
    <div v-else>
      <loading :loading="loading" />
  
      <!-- นำทาง -->
      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
         การเงิน
          <v-icon>mdi-chevron-right</v-icon>
          ค่าใช้จ่าย
        </v-card-title>
      </v-card>
  
      <!-- แสดงภาพ -->
      <v-dialog v-model="ShowPhoto" max-width="500">
        <v-card class="ma-0">
          <v-card-text>
            <v-row align="center">
              <v-col cols="12" class="text-center">
                <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
                <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- หัวข้อเมนู -->
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          <div class="py-1">
            <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon><span class="ml-3">{{use_language.info}}</span>
          </div>
          <v-spacer/>
          <div>
            <vue-excel-xlsx
                id="GetExcel"
                v-show="false"
                :data="datasetExcel"
                :columns="headerExcel"
                :file-name="ExcelName"
              
                >
            </vue-excel-xlsx>
            <v-btn :elevation="1" outlined color="success" class="px-2" @click="ExportExcel()">
              <v-icon size="18" class="ml-1" left>fa-download</v-icon>
              {{use_language.export_excel}}
              <span v-if="loadingExcel!=0">({{loadingExcel}}%)</span>
            </v-btn>
            <v-btn  :elevation="1" color="primary" class="px-2 ml-1" @click="AddPurchaseOrder()">
              <v-icon  size="22" class="mr-1">mdi-plus</v-icon>
              เพิ่มค่าใช้จ่าย
            </v-btn>
          </div>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="px-0">
              
              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> วันที่บิล : </h3>
                </v-col>
                <v-col cols="12" md="4"   class="pt-1 pb-2">
                  <v-menu
                    ref="invoiceDatemenuStartDate"
                    v-model="invoiceDate.menuStartDate"
                    :close-on-content-click="false"
                    :return-value.sync="invoiceDate.startDate"
                    transition="scale-transition"
                    offset-y
  
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="invoiceDate.dateStart"
                        label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        @blur="invoiceDate.startDate = parseDate(invoiceDate.dateStart)"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="invoiceDate.dateStart = null"
                        hide-details
  
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="invoiceDate.startDate" @change="invoiceDate.dateStart=formatDate(invoiceDate.startDate)" no-title scrollable :allowed-dates="allowedDates">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4"  class="pt-1 pb-2">
                  <v-menu
                    ref="invoiceDatemenuEndDate"
                    v-model="invoiceDate.menuEndDate"
                    :close-on-content-click="false"
                    :return-value.sync="invoiceDate.endDate"
                    transition="scale-transition"
                    offset-y
  
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="invoiceDate.dateEnd"
                        label="สิ้นสุด"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        @blur="invoiceDate.endDate = parseDate(invoiceDate.dateEnd)"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="invoiceDate.dateEnd = null"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="invoiceDate.endDate" @change="invoiceDate.dateEnd=formatDate(invoiceDate.endDate)" no-title scrollable :allowed-dates="allowedDates">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> วันที่สร้าง : </h3>
                </v-col>
                <v-col cols="12" md="4"   class="pt-1 pb-2">
                  <v-menu
                    ref="createDatemenuStartDate"
                    v-model="createDate.menuStartDate"
                    :close-on-content-click="false"
                    :return-value.sync="createDate.startDate"
                    transition="scale-transition"
                    offset-y
  
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="createDate.dateStart"
                        label="เริ่มต้น"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        @blur="createDate.startDate = parseDate(createDate.dateStart)"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="createDate.dateStart = null"
                        hide-details
  
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="createDate.startDate" @change="createDate.dateStart=formatDate(createDate.startDate)" no-title scrollable :allowed-dates="allowedDates">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4"  class="pt-1 pb-2">
                  <v-menu
                    ref="createDatemenuEndDate"
                    v-model="createDate.menuEndDate"
                    :close-on-content-click="false"
                    :return-value.sync="createDate.endDate"
                    transition="scale-transition"
                    offset-y
  
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="createDate.dateEnd"
                        label="สิ้นสุด"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        @blur="createDate.endDate = parseDate(createDate.dateEnd)"
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="createDate.dateEnd = null"
                        hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="createDate.endDate" @change="createDate.dateEnd=formatDate(createDate.endDate)" no-title scrollable :allowed-dates="allowedDates">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> รอบบิลภาษี : </h3>
                </v-col>
                <v-col cols="12" md="4"   class="pt-1 pb-2">
                  <v-select
                        v-model="billingCycleYear"
                        :items="billingCycleYearShoW"
                        clearable
                        persistent-hint
                        dense hide-details
                        outlined
                        label="ปี"
                        style="width:100%"
                      ></v-select>
                </v-col>
                <v-col cols="12" md="4"  class="pt-1 pb-2">
                  <v-select
                        v-model="billingCycleMonth"
                        :items="billingCycleMonthList"
                        item-text="name"
                        clearable
                        item-value="value"
                        persistent-hint
                        dense hide-details
                        outlined
                        label="เดือน"
                        style="width:100%"
                      ></v-select>
                </v-col>
              
              </v-row>

              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> เลขที่บิล : </h3>
                </v-col>
                <v-col cols="12" md="8"   class="pt-1 pb-2">
                  <v-text-field
                    :value="invoiceidlist"
                    @change="value => invoiceidlist = Trim_value(value)"
                    dense hide-details
                    autocomplete="off"
                    outlined 
                    placeholder="ใส่ , เพื่อค้นหาหลายรายการ"
                  ></v-text-field>
                </v-col>
              
              </v-row>
            
            </v-col>
            <v-col cols="6" class="px-0">
              <v-row>  
                
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"> <h3 class="fn-14"> ประเภทค่าใช้จ่าย : </h3>  </v-col>
                <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                  <v-autocomplete
                        dense
                        clearable
                        hide-details
                        no-data-text= "ไม่พบสินค้า"
                        outlined
                        v-model="expenseTypeID"
                        :items="expenseTypeIDList"
                        item-value="id"
                        item-text="description"
                    >
                </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>  
                
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"><h3 class="fn-14"> การชำระเงิน : </h3>  </v-col>
                <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                    <v-autocomplete
                        dense
                        clearable
                        hide-details 
                        outlined
                        v-model="paymentTypeID"
                        :items="paymentTypeIDList"
                        item-value="id"
                        item-text="name"
                    >
                </v-autocomplete>
                </v-col>
              </v-row>
              
              <v-row> 
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2">
                  <h3 class="fn-14"> {{use_language.note}} : </h3>
                </v-col>
                <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                  <v-textarea
                    :value="search.remark"
                    @change="value => search.remark = Trim_value(value)"
                    dense hide-details
                    autocomplete="off"
                    outlined
                    rows="1"
                    row-height="15"
                  ></v-textarea>
                </v-col>
              </v-row>
               
              <v-row>
                <v-col cols="12" md="4" lg="3" class="pt-1 pb-2"/>
         
                  <v-col cols="12" md="7" lg="8" class="pt-1 pb-2">
                    <v-btn    :elevation="1" color="primary" class="pa-2" dark @click="SearchData(limits,0,search,1)">
                      <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                      {{use_language.search}}
                    </v-btn>
                  </v-col>
                </v-row>
            </v-col>
         
          </v-row>
        </v-card-text>
      </v-card>
  
      <!-- ข้อมูลทั้งหมด -->
      <v-row class="">
        <v-col cols="12" class="pa-0">
          {{use_language.all_}} {{ formatMoney(total) }} {{use_language.row}}
        </v-col>
      </v-row>
  
      <!-- แสดงตาราง -->
      <v-card elevation="1">
        <v-card-text class="pa-0">
  
          <!-- PC hidden-xs-only -->
          <v-data-table
            :headers="header"
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-footer
            class="packhai-border-table hidden-xs-only"
          >
          <template v-slot:item.edit="{ item }">
           <a @click="editPurchase(item.id)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
           </template>
            <template v-slot:item.delete="{ item }">
              <span @click="btnDeletePurchase(item)">
                <a><v-icon  size="18" color="red darken-3">fa-trash-alt</v-icon></a>
              </span>
            </template>
            <template v-slot:item.paymentStatus="{ item }">
               <span v-if="item.paymentStatusID==1">
                {{ item.paymentStatus }}
               </span>
               <span v-else-if="item.paymentStatusID==2" style="color:orange">
                {{ item.paymentStatus }}
              </span>
              <span v-else-if="item.paymentStatusID==3"  style="color:green">
                {{ item.paymentStatus }}
              </span>
            </template>
            <template v-slot:item.amount="{ item }">
               {{ formatMoneyIsDecimal(item.amount) }}
            </template>
            <template v-slot:item.vat="{ item }">
               {{ formatMoneyIsDecimal(item.vat) }}
            </template>
            <template v-slot:item.total="{ item }">
               {{ formatMoneyIsDecimal(item.total) }}
            </template>
            <template v-slot:item.invoiceDate="{ item }">
               {{ set_format_date_time(item.invoiceDate) }}
            </template>
            <template v-slot:item.idText="{ item }">
              <a @click="editPurchase(item.id)"> {{ item.idText }} </a>
            </template>
            <template v-slot:item.created="{ item }">
               {{ set_format_date_time(item.created) }}
            </template>
            <template v-slot:item.created="{ item }">
               {{ set_format_date_time(item.created) }}
            </template>
            
            <template v-slot:item.updated="{ item }">
             {{ set_format_date_time(item.updated) }} 
            </template>
            <template v-slot:item.reportDate="{ item }">
             {{ formatReportDate(item.reportDate) }} 
            </template>
            
          </v-data-table>
  
          <!-- Mobile hidden-sm-and-up -->
          <!-- <v-data-iterator
            :items="dataset"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
            class="hidden-sm-and-up"
          >
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                  <v-card>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="6" class="pa-0 pt-1">
                        ({{ formatMoney((total-dataset.indexOf(item))-offsets) }})
                        <a  > {{ GenIE(item.id) }} </a>
                      </v-col>
                      <v-col cols="6" class="text-right pa-0 pt-1">
                        {{ formatDatetime(item.createdDatetime) }}
                        <span v-if="(item.approvedStaffBranchId==null && item.approvedStaffShopId==null)" class="pa-1">
                          <a @click="DeleteTransfer(item.id)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="12" class="text-center pa-0">
                        <span v-if="item.approvedStaffBranchName!=null && item.approvedStaffBranchName!= ''"><v-chip color="success"> {{use_language.approve}} </v-chip></span>
                        <span v-else-if="item.approvedStaffShopName!=null && item.approvedStaffShopName!= ''"><v-chip color="success"> {{use_language.approve}} </v-chip></span>
                        <span v-else><v-chip color="warning"> {{use_language.pending_approval}} </v-chip></span>
                      </v-col>
                    </v-row>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="4" class="pa-0 py-1 text-cut">
                        <span v-if="item.staffBranchName!=null && item.staffBranchName!= ''"> {{use_language.creator}} : {{ item.staffBranchName }}</span>
                        <span v-else-if="item.staffShopName!=null && item.staffShopName!= ''"> {{use_language.creator}} : {{ item.staffShopName }}</span>
                      </v-col>
                      <v-col cols="4" class="pa-0 py-1 text-center">
                        <span v-if="item.transactionType==1" class="color-green">{{ item.transactionName }}</span>
                        <span v-else-if="item.transactionType==2" class="color-red">{{ item.transactionName }}</span>
                        <span v-else-if="item.transactionType==3" class="color-orange">{{ item.transactionName }}</span>
                        <span v-else class="color-orange">{{ item.transactionName }}</span>
                      </v-col>
                      <v-col cols="4" class="text-right pa-0 py-1 text-cut">
                        <span v-if="item.approvedStaffBranchName!=null && item.approvedStaffBranchName!= ''"> {{use_language.approver}} : {{ item.approvedStaffBranchName }}</span>
                        <span v-else-if="item.approvedStaffShopName!=null && item.approvedStaffShopName!= ''"> {{use_language.approver}} : {{ item.approvedStaffShopName }}</span>
                      </v-col>
                    </v-row>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="12" class="pa-0">
                        {{ item.shopName }}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator> -->
  
        </v-card-text>
      </v-card>
  
      <!-- เปลี่ยนหน้า -->
    
  
      <v-row
        v-if="pageLength > 1 && dataset.length > 0"
        class="text-center pa-2 ma-0"
        @click="changePage()"
      >
        <v-col cols="2"  md="3" lg="2"  xl="1" > </v-col>
        <v-col  cols="8"  md="6" lg="8"  xl="10"  class="pa-0">
          <v-pagination
            v-model="page"
            :length="pageLength"
            :total-visible="pageTotalVisible"
            color="error"
          ></v-pagination>
        </v-col>
        <v-col cols="2" md="3"  lg="2" xl="1" class="text-right">
          <v-select
            v-model="limits"
            label="จำนวนแสดง"
            :items="[10, 20, 50, 100, 500, 1000]"
            @change="LimitChange()"
            outlined
          ></v-select>
        </v-col>
      </v-row>
  
    </div>
  </template>
  
  <style scoped>
    >>>.v-select__slot{
      height: 40px;
    }
    >>>.v-input__prepend-inner{
      margin-top: 10px;
    }
  </style>
  
  <script>
  import axios from 'axios'
  import Swal from 'sweetalert2' 
  import Loading from '@/website/components/Loading'
  import { stockService_dotnet, shopService_dotnet,orderService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertError } from '@/website/global_alert'
  import { formatMoney, isNumberWithDot, isNumberWNoDot, DateNowNotNull, GenIE, Trim_value, Trim_rules, formatDatetime, ChangeIE,get_languages,set_format_date_time,set_format_date_time_for_excel,formatMoneyIsDecimal,getdatelongstring } from '@/website/global_function'
  
   export default {
      components: {
        Loading
      },
      data: vm => ({
         header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
        page_loading: true,
        loading: false,
        use_language: null,
        set_language: null,
        // แสดงภาพ
        ShowPhoto: false,
        ShowPhotoLink: '',
        ExcelName:'',
        authorize:false,
  
        // วันที่
        invoiceidlist:null,
        invoiceDate:{   
        startDate: null,
        dateStart: null,
        menuStartDate: false,
        checkStart: true,
        endDate: null,
        dateEnd: null,
        menuEndDate: false,
        checkEnd: true,
        },
        createDate:{   
        startDate: null,
        dateStart: null,
        menuStartDate: false,
        checkStart: true,
        endDate: null,
        dateEnd: null,
        menuEndDate: false,
        checkEnd: true,
        },
      
        
        // ค้นหา
        search: {
           
          startDate: null,
          endDate: null,
          remark: null,
          importExportMasterId: null,
          referenceNumber: null,
         
        },
        billingCycleMonthList:[    
          { name: '1', value: '01' },
          { name: '2', value: '02' },
          { name: '3', value: '03' },
          { name: '4', value: '04' },
          { name: '5', value: '05' },
          { name: '6', value: '06' },
          { name: '7', value: '07' },
          { name: '8', value: '08' },
          { name: '9', value: '09' },
          { name: '10', value: '10' },
          { name: '11', value: '11' },
          { name: '12', value: '12' },
        ],
        
        billingCycleYearList:[ ],
        billingCycleMonth:  null,
        billingCycleYear:null,

        paymentTypeID:0,
        paymentTypeIDList:[{id:0,name:'--ทั้งหมด--'},{id:1,name:'รอชำระ'},{id:2,name:'ชำระบางส่วน'},{id:3,name:'ชำระแล้ว'}],
        expenseTypeIDList:[],
        expenseTypeID:null,
        // แสดงตาราง
        total: 0,
        dataset: [],
        header: [
          { text: 'แก้ไข',align: 'center',sortable: false,value: 'edit', width: '60px'},
          { text: 'ลบ',align: 'center',sortable: false,value: 'delete', width: '60px'},
          { text: 'No',align: 'center',sortable: false,value: 'no', width: '60px' },
          { text: 'เลยรายการ',align: 'center',sortable: false,value: 'idText', width: '100px'},
          { text: 'วันที่บิล',align: 'center',sortable: false,value: 'invoiceDate', width: '200px'},
          { text: 'ชื่อบริษัท',align: 'center',sortable: false,value: 'supplierName', width: '180px'},
          { text: 'ประเภทค่าใช่จ่าย',align: 'center',sortable: false,value: 'expenseType', width: '130px'},
          { text: 'ยอดเงิน',align: 'center',sortable: false,value: 'amount', width: '120px'},
          { text: 'VAT',align: 'center',sortable: false,value: 'vat', width: '120px'},
          { text: 'ยอดรวม',align: 'center',sortable: false,value: 'total', width: '120px'},
          { text: 'สถานะการชำระเงิน',align: 'center',sortable: false,value: 'paymentStatus', width: '150px'},
          { text: 'หมายเหตุ',align: 'center',sortable: false,value: 'remark', width: '150px'},
          { text: 'รอบบิลภาษี',align: 'center',sortable: false,value: 'reportDate', width: '120px'},
          { text: 'วันที่สร้าง',align: 'center',sortable: false,value: 'created', width: '150px'},
          { text: 'สร้างโดย',align: 'center',sortable: false,value: 'createdByName', width: '100px'},
          { text: 'วันที่อัพเดท',align: 'center',sortable: false,value: 'updated', width: '150px'},
          { text: 'อัพเดทโดย',align: 'center',sortable: false,value: 'updatedByName', width: '100px'},
        ],
        // Export Excel
        loadingExcel: 0,
        datasetExcel: [],
        headerExcel: [
          { label: "No", field: "no", width: "50px" },
          { label: "เลขที่บิล", field: "idText", width: "100px" },
          { label: "วันที่บิล", field: "invoiceDate", width: "200px" },
          { label: "ชื่อบริษัท", field: "supplierName", width: "180px" },
          { label: "เลขผู้เสียภาษี", field: "supplierTaxID", width: "180px" },
          { label: "ประเภทค่าใช้จ่าย", field: "expenseType", width: "130px" },
          { label: "ยอดเงิน", field: "amount", width: "155px" },
          { label: "VAT", field: "vat", width: "155px" },
          { label: "ยอดรวม", field: "total", width: "155px" },
          { label: "สถานะการชำระเงิน", field: "paymentStatus", width: "150px" },
          { label: "หมายเหตุ", field: "remark", width: "150px" },
          { label: "วันที่สร้าง", field: "created", width: "130px" },
          { label: "สร้างโดย", field: "createdByName", width: "130px" },
          { label: "วันที่อัพเดท", field: "updated", width: "130px" },
          { label: "อัพเดทโดย", field: "updatedByName", width: "130px" },
        ],
  
        // paginations
        page: 1,
        pageTemp: 1,
        pageLength: 1,
        pageTotalVisible: 9,
        limits: 10,
        offsets: 0,
        shopId: parseInt(localStorage.getItem('shop_id')),
      }),
      computed:{
          billingCycleYearShoW(){
          const currentYear = new Date().getFullYear();
          const billingCycleYears = Array.from({ length: currentYear - 2023 + 1 }, (_, i) => currentYear- i);
          
          return billingCycleYears
        },
      },
      watch: {
        'invoiceDate.startDate': async function(newVal, oldVal) {
            console.log("asdfasdasdasd");
            
          if (new Date(newVal)>new Date(this.invoiceDate.endDate) && this.invoiceDate.endDate!=null && newVal!=null ){
            if (oldVal!=null){
              this.invoiceDate.checkStart = false
              this.invoiceDate.startDate = oldVal
            }else{
              this.invoiceDate.dateStart = oldVal
              this.$refs.invoiceDatemenuStartDate.save(oldVal)
            }
          }else if(this.invoiceDate.checkStart){
            try {
              this.$refs.invoiceDatemenuStartDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.invoiceDate.dateStart = this.formatDate(newVal)
            this.$refs.invoiceDatemenuStartDate.save(newVal)
            this.invoiceDate.checkStart = true
          }
        },
        'invoiceDate.endDate': async function(newVal, oldVal) {
          if (new Date(newVal)<new Date(this.invoiceDate.startDate) && this.invoiceDate.startDate!=null  && newVal!=null){
            if (oldVal!=null){
              this.invoiceDate.checkEnd = false
              this.invoiceDate.endDate = oldVal
            }else{
              this.invoiceDate.dateEnd = oldVal
              this.$refs.invoiceDatemenuEndDate.save(oldVal)
            }
          }else if(this.checkEnd){
            try {
              this.$refs.invoiceDatemenuEndDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.invoiceDate.dateEnd = this.formatDate(newVal)
            this.$refs.invoiceDatemenuEndDate.save(newVal)
            this.invoiceDate.checkEnd = true
          }
        },
        'createDate.startDate': async function(newVal, oldVal) {
          if (new Date(newVal)>new Date(this.createDate.endDate) && this.createDate.endDate!=null && newVal!=null ){
            if (oldVal!=null){
              this.createDate.checkStart = false
              this.createDate.startDate = oldVal
            }else{
              this.createDate.dateStart = oldVal
              this.$refs.createDatemenuStartDate.save(oldVal)
            }
          }else if(this.createDate.checkStart){
            try {
              this.$refs.createDatemenuStartDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.createDate.dateStart = this.formatDate(newVal)
            this.$refs.createDatemenuStartDate.save(newVal)
            this.createDate.checkStart = true
          }
        },
        'createDate.endDate': async function(newVal, oldVal) {
          if (new Date(newVal)<new Date(this.createDate.startDate) && this.createDate.startDate!=null  && newVal!=null){
            if (oldVal!=null){
              this.createDate.checkEnd = false
              this.createDate.endDate = oldVal
            }else{
              this.createDate.dateEnd = oldVal
              this.$refs.createDatemenuEndDate.save(oldVal)
            }
          }else if(this.checkEnd){
            try {
              this.$refs.createDatemenuEndDate.save(newVal)
            }catch(err) {
              console.log("");
            }
          }else{
            this.createDate.dateEnd = this.formatDate(newVal)
            this.$refs.createDatemenuEndDate.save(newVal)
            this.createDate.checkEnd = true
          }
        },
      },
      async created() {
  
            //เก็บภาษามาไว้ใน use_language
           this.use_language = await this.get_languages(localStorage.getItem("set_language"));
  
            //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
            this.get_authorize_staffshop();
  
        document.title = this.$router.history.current.meta.title
     
        await this.getExpenseType();
        await this.SearchData(this.limits,0,this.search,1);
        this.page_loading = false
      },
      methods: {
        get_languages,
        async SearchData(limits,offsets,search,page){
          var ispass=false
          let reportDateTime = null;
            if(this.billingCycleYear && this.billingCycleMonth)
              reportDateTime = this.billingCycleYear+'-'+ this.billingCycleMonth+'-01' ;
          
          while(!ispass){
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'PO/get-purchase-order', {
                "id":this.invoiceidlist,
                "shopID":this.shopId,
                
                "expenseTypeID":this.expenseTypeID,
                "paymentStatusID":this.paymentTypeID,
                "invoiceDateFrom": this.invoiceDate.startDate ,
                "invoiceDateTo": this.invoiceDate.endDate ,
                "createDateFrom": this.createDate.startDate ,
                "createDateTo": this.createDate.endDate ,
                "reportDate":reportDateTime,
                "purchaseType" :!parseInt(this.search.purchaseType) ? 2 : parseInt(this.search.purchaseType),
                "remark": this.search.remark=='' ? null : this.search.remark,
                "isNeedResultCount": true,
                "skip": offsets,
                "take": limits,
              }, {headers: this.header_token})
              ispass=true
              this.loading = false
              if (response.status==200){
                this.page = page
                this.pageTemp = page
                this.offsets = offsets
                this.dataset = response.data.items
                this.total = response.data.resultCount
             
                this.pageLength =  (Math.ceil(this.total/this.limits))
                if (this.pageLength<=0){this.pageLength=1}
              } else {
                Swal.fire({
                  icon: "error",
                  title:"ไม่สามารถทำรายการได้ ",
                  text: "กรุณาแจ้งผู้ดูแลระบบ",
                  showConfirmButton: true,
                })
              }
            } catch (error) {
              this.loading = false
              Swal.fire({
                toast: true,
                icon: 'error',
                title: 'ไม่สามารถติดต่อฐานข้อมูลได้',
                text: 'กำลังดำเนินการใหม่อีกครั้ง',
                timer: 1500,
                showConfirmButton: false,
                position:'bottom-end'
              })
            }
          }
        },
        formatReportDate(val){
          if(!val)
            return '';
          var splited = val.substr(0,10).split('-');
          return splited[1]+'/'+splited[0];
        },
        async getPurchaseOrderid(limits,offsets,search,page){

            let reportDateTime = null;
            if(this.billingCycleYear && this.billingCycleMonth)
              reportDateTime = this.billingCycleYear+'-'+ this.billingCycleMonth+'-01' ;
            try {
              this.loading = true
              let response = await axios.post(orderService_dotnet+'PO/get-purchase-order', {
                "shopID":this.shopId,
                "id":this.invoiceidlist,
                "expenseTypeID":this.expenseTypeID,
                "paymentStatusID":this.paymentTypeID,
                "invoiceDateFrom": this.invoiceDate.startDate ,
                "invoiceDateTo": this.invoiceDate.endDate ,
                "createDateFrom": this.createDate.startDate ,
                "createDateTo": this.createDate.endDate ,
                "reportDate":reportDateTime,
                "purchaseType" :!parseInt(this.search.purchaseType) ? 2 : parseInt(this.search.purchaseType),
                "remark": this.search.remark=='' ? null : this.search.remark,
                "isNeedResultCount": true,
                "skip": offsets,
                "take": limits,
              }, {headers: this.header_token})
              
              this.loading = false
              if (response.status==200){
                return response.data.items
              } else {
                Swal.fire({
                  icon: "error",
                  title:"ไม่สามารถทำรายการได้ ",
                  text: "กรุณาแจ้งผู้ดูแลระบบ",
                  showConfirmButton: true,
                })
              }
            } catch (error) {
              this.loading = false
              Swal.fire({
                toast: true,
                icon: 'error',
                title: 'ไม่สามารถติดต่อฐานข้อมูลได้',
                text: 'กำลังดำเนินการใหม่อีกครั้ง',
                timer: 1500,
                showConfirmButton: false,
                position:'bottom-end'
              })
            }
          
        },
        
        async getExpenseType() {
        return await axios
          .post(
            orderService_dotnet +"PO/get-expense-type",
            {
              shopID:this.shopId ,
              
            },
            { headers: this.header_token }
          )
          .then(async (res) => {
            if(res.data.status == "success")
          {
            this.expenseTypeIDList = [{"id": null,  "description": "--ทั้งหมด--",}] 
            this.expenseTypeIDList.push(...res.data.items)
          }
              return res.data.items
          })
          .catch((error) => {
            this.loading = false;
            this.AlertError();
            return false;
          });
      },
        async editPurchase(id){
          
          window.open("addExpense?id="+id);
        },
        async deletePurchaseOrder(body) {
        return await axios
          .get(
            orderService_dotnet +"PO/delete-purchase-order?ID="+body.id,
            { headers: this.header_token }
          )
          .then(async (res) => {
              return res.data
          })
          .catch((error) => {
            this.loading = false;
            this.AlertError();
            return false;
          });
        },
        async btnDeletePurchase(item){
          console.log("item",item);
          Swal.fire({
             
            type: 'warning',
            title: 'ลบ ใช่หรือไม่',
            text: 'เลขรายการ '+item.id,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            allowOutsideClick: false
          }).then(async (result) => {
            if (result.value) {
              await this.deletePurchaseOrder(item);
              await this.SearchData(this.limits,0,this.search,1)

            }}
          )
        },
        async AddPurchaseOrder(){
          window.open("addExpense");
        },
        // เรียกใช้
      
        async ExportExcel(){
          this.datasetExcel = []
          this.loadingExcel = 0
          const datenow = new Date();
          this.ExcelName = 'รายงานค่าใช้จ่าย_'+ this.getdatelongstring();
          Swal.fire({
         
            type: 'warning',
            text: 'ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            allowOutsideClick: false
          }).then(async(result) => {
            if (result.value) {
  
              var limits = 50
              var offsets = 0
              this.loadingExcel = 1
              var total = this.total
              var round = parseInt( total/limits)
  
              if ( limits * round < total ){
                round = round+1
              }
  
              var no = 0
              for (var i = 1; i <= round; i++) {
  
                var resultData = await this.getPurchaseOrderid(limits,offsets,this.search)
                resultData.forEach(element => {
                  element.invoiceDate = this.set_format_date_time_for_excel(element.invoiceDate)
                  element.created = this.set_format_date_time_for_excel(element.created)
                  element.updated = this.set_format_date_time_for_excel(element.updated)

                }); 
                this.datasetExcel.push(...resultData);
                this.loadingExcel = parseInt(
                  (parseFloat(i) / parseFloat(round)) * parseFloat(100)
                );
              offsets = offsets + limits;
            }
              this.loadingExcel = this.datasetExcel.length== 0 ? 0 : 100
              document.getElementById("GetExcel").click();
            }
          })
        },
        async LimitChange() {
          await this.SearchData(this.limits, 0, this.search, 1);
        },
        async EditStock(ImportExportMasterID){
          window.open("transfer-list/update?id="+btoa(ImportExportMasterID));
        },
        async changePage() {
          if (this.pageLength!=1 && this.pageTemp!=this.page){
            var offsets = (this.page*this.limits)-this.limits
            await this.SearchData(this.limits,offsets,this.search,this.page)
            this.pageTemp = this.page
          }
        },
        formatDate (date) {
         
          if (!date) return null
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        },
        parseDate (date) {
          
          if (!date) return null
          const [day, month, year] = date.split('/')
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        formatMFG (datetime) {
        if (datetime==null || datetime==""){
          return null
        }
        return datetime.substring(0,4)+"-"+datetime.substring(5,7)+"-"+datetime.substring(8,10);
      },
        allowedDates: val => val <= new Date().toISOString().substr(0, 10),
        OpenPhoto(Photo){
          this.ShowPhotoLink = Photo
          this.ShowPhoto = true
        },
        async get_authorize_staffshop () {
            if(localStorage.getItem("240") == 2)
            {
             this.authorize = true
            }
          },
        formatMoney,
        formatMoneyIsDecimal,
        isNumberWithDot,
        isNumberWNoDot,
        DateNowNotNull,
        GenIE,
        Trim_value,
        Trim_rules,
        formatDatetime,
        ChangeIE,
        AlertSuccess,
        AlertError,
        set_format_date_time,
        set_format_date_time_for_excel,
        getdatelongstring
      }
    }
  </script>
  