<template>
    <div class="mt-0" align="center">
        <v-card class="screen-only" :elevation="0" width="793" >
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  depressed :disabled="!authorize"  @click="printpdf()" outlined v-if="print.length > 0">
                     <v-icon left>mdi-printer</v-icon> {{use_language.print}}
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="this.coverpage.sizepage == 'a4' " :elevation="0" width="793" >
            <v-card-text v-if="print.length > 0">
                <v-row class="mb-2" style="break-after:page">
                    <v-row align="center" justify="center">
                        <v-col
                            v-for="(p,index) in print" :key="index"
                            cols="5"
                            align="left"
                            class="py-0 pl-3 pr-0"
                        >
                            <div class="mb-2 border-a px-0" style="height:330px; ">
                                <v-row align="center">
                                    <v-col cols="6" align="center" class="pa-2">
                                        <p class="mb-0" >
                                            <img
                                            v-if="branchLogo != null"
                                            :src="branchLogo"
                                            style="width: 80%"
                                            />
                                            <img v-else src="@/assets/C_logopackhai.png" style="width: 80%" />
                                         </p>
                                        <!-- <p class="mb-0" ><img src="@/assets/C_logopackhai.png" width="80%"></p> -->
                                        <p class="mb-0">
                                            <img :src="p.order['expressCompanyLogo']" width="40%">
                                        </p>
                                        <p class="mb-0 font-weight-bold" style="font-size: 10px;" >{{p.order['expressCompanyName']}}</p>
                                    </v-col>
                                    <v-col cols="6" align="center" class="pa-2">
                                        <barcode v-bind:value="format_order_id(p.order['id'])" height="35px" width="1" fontSize= "14"  />
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="pt-1">
                                    <v-col cols="6" class="py-0">
                                        <span class="mb-0 pl-2 font-weight-bold">{{use_language.recipient_address_name}}</span>
                                    </v-col>
                                    <v-col cols="12" v-if="p.order['isCOD'] != false">
                                            <span class="font-weight-bold">{{use_language.cod}} : {{format_price(p.order['codAmount'])}}</span>
                                    </v-col>
                                </v-row>
                                <v-row align="center" >
                                    <v-col cols="12" align="left" class="pb-0 pt-1 pr-0">
                                        <v-row justify="center">
                                            <v-col cols="11" align="left" class="py-0">
                                                <p class="mb-0 pl-2 " v-if="p.order['receiverName']!=null && p.order['receiverPhone'] != null"> {{p.order['receiverName']}} {{p.order['receiverPhone']}}</p>
                                                <p class="mb-0 pl-2 " v-else-if="p.order['receiverName']!=null && p.order['receiverPhone'] == null"> {{p.order['receiverPhone']}} </p>
                                                <p class="mb-0 pl-2 " v-else>{{use_language.pick_up_goods_by_yourself}}</p>
                                                <p class="mb-0 pl-2 ">{{p.order['receiverAddress']}} {{p.order['receiverSubDistrict']}} {{p.order['receiverDistrict']}} {{p.order['receiverProvince']}} {{p.order['receiverPostcode']}} </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row align="center" >
                                    <v-col cols="12" class="py-1">
                                        <v-row justify="center">
                                            <v-col cols="12" align="left" class="py-0" style="font-size: 8px;">
                                                <p class="mb-0 pl-2 font-weight-bold">{{use_language.product}} : </p>
                                                <p class="mb-0 pl-2 " >
                                                    <span v-for="(i,index) in p.orderItems" :key="index" >
                                                        {{i.productName}} ({{i.quantity}}){{p.orderItems.length-1>index?',':''}}
                                                    </span>
                                                </p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row >
                                    <v-col cols="12" class="pt-1" align = "left">
                                        <p class="mb-0 pl-2 font-weight-bold"> {{use_language.note}} :</p>
                                        <p  class="mb-0 pl-2 ">{{p.order['remark']}}</p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-row>
            </v-card-text>
        </v-card>
        <div v-else >
            <div>
                <v-card :elevation="0"  class="pages" v-for="(p,index) in print" :key="index" style="break-after:page">
                    <v-card-text class="pa-0">
                        <div >
                            <v-row justify="center" align="center">
                                <v-col cols="7">
                                    <p class="mb-0" >
                                            <img
                                            v-if="branchLogo != null"
                                            :src="branchLogo"
                                            style="width: 80%"
                                            />
                                            <img v-else src="@/assets/C_logopackhai.png" style="width: 80%" />
                                    <p class="mb-1" ><img :src="p.order['expressCompanyLogo']==null ? '': p.order['expressCompanyLogo']" width="50%"></p>
                                    <p class="mb-1 font-weight-bold" >{{p.order['expressCompanyName']}}</p>
                                </v-col>
                                <v-col cols="5" class="pa-0" align="center">
                                    <barcode v-bind:value="format_order_id(p.order['id'])" height="35px" width="1" fontSize= "14"  />
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row align="center" >
                                <v-col cols="12" align="left" class="pb-0 pt-1 pr-0">
                                    <v-row>
                                        <v-col cols="6" class="py-0">
                                            <span class="mb-0 pl-2 font-weight-bold">{{use_language.recipient_address_name}}</span>
                                        </v-col>
                                            <v-col cols="12" v-if="p.order['isCOD'] != false">
                                                <span class="font-weight-bold">{{use_language.cod}} : {{format_price(p.order['codAmount'])}}</span>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-col cols="10" align="left" class="py-0" >
                                            <p class="mb-0 pl-2 ">{{p.order['receiverName']}}</p>
                                            <p class="mb-0 pl-2 ">{{p.order['receiverAddress']}} {{p.order['receiverSubDistrict']}} {{p.order['receiverDistrict']}} {{p.order['receiverProvince']}} {{p.order['receiverPostcode']}}</p>
                                            <p class="mb-0 pl-2 " v-if="p.order['receiverPhone']!=null">{{use_language.tel}} : {{p.order['receiverPhone']}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row align="center" >
                                <v-col cols="12" class="py-1">
                                    <v-row justify="center">
                                        <v-col cols="12" align="left" class="py-0">
                                            <p class="mb-0 pl-2 font-weight-bold">{{use_language.product}} : </p>
                                            <p class="mb-0 pl-2 " style="font-size: 8px;" >
                                              <span v-for="(i,index) in p.orderItems" :key="index" >
                                                  {{i.productName}} ({{i.quantity}}){{p.orderItems.length-1>index?',':''}}
                                              </span>
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row >
                                <v-col cols="12" class="pt-1" align = "left">
                                    <p class="mb-0 pl-2 font-weight-bold"> {{use_language.note}} </p>
                                    <p  class="mb-0 pl-2 ">{{p.order['remark']}}</p>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script>
  import axios from 'axios'
  import Swal from "sweetalert2"
  import { orderService_dotnet} from '@/website/global'
  import {  format_order_id, format_price,get_languages,checkAuthorize } from '@/website/global_function'
  import { token_api, header_token } from '@/website/backend/token'
  import VueBarcode from 'vue-barcode';
  import {
  branchService_dotnet,
} from "@/website/global";


    export default {
        components: {
            // Loading,
            'barcode': VueBarcode
        },
        data: () => ({
            coverpage:[],
            print:[],
            use_language: null,
            set_language: null,
            branchLogo: null,
            authorize:false,
        }),
    async created() {


             //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));
              //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
            this.get_authorize_staffshop();

            // this.loaddata()

            await this.loaddata()
            this.GetBranchLogo();
        },
        methods: {
            get_languages,
            checkAuthorize,
            async loaddata () {
              var order_list = []
              this.coverpage = JSON.parse(localStorage.getItem('Print_Coverpage'))

              for (var item of this.coverpage.OrderID) {
                order_list.push(item.OrderId)
              }


              if(order_list.length == 0){
                return
              } else {
                try {
                  let response = await axios.post(orderService_dotnet+'Order/get-data-orders-print-cover-pages', {
                    "shopID": localStorage.getItem('shop_id'),
                    "orderList": order_list
                  },
                  { headers: header_token})
                  this.print = response.data
                } catch (e) {
                  this.loading = false
                  Swal.fire({
                    position: "top",
                    type: "error",
                    title: "เกิดข้อผิดพลาด !!",
                    text: e.message,
                  })
                }
              }
            },
            async GetBranchLogo() {
                let branchid= localStorage.getItem("branch_id") 
                let response =  await axios.post(branchService_dotnet+'Branch/get-branch-info?BranchID='+branchid, {},{ headers: header_token})
                if (response.status == 204 || response.status == 200) {
                    if (response.data != "" && response.data != null) {
                        if(response.data.logoAWB != null && response.data.logoAWB != "")
                            this.branchLogo = response.data.logoAWB;
                    }
                }
            },
            async get_authorize_staffshop () {
                this.authorize =  this.checkAuthorize(24);
        //   if(localStorage.getItem("320") == 2 )
        //   {
        //    this.authorize = true
        //   }
        },
            printpdf(){
                print()
            },
            format_order_id,
            format_price,
            
        }
    }
</script>
<style scoped>

    >>>.set-font-size thead tr th{

      font-weight: bold !important;
      font-size: 11px !important;
    }

    /* border table */

    >>>.border-left{
      border-left: 1px solid black !important;
    }
    >>>.border-right{
      border-right: 1px solid black !important;
    }
    >>>.border-top{
      border-top: 1px solid black !important;
    }
    >>>.border-bottom{
      border-bottom: 1px solid black !important;
    }
    >>>.border-x{
      border-left: 1px solid black !important;
      border-right: 1px solid black !important;
    }
    >>>.border-y{
      border-top: 1px solid black !important;
      border-bottom: 1px solid black !important;
    }
    >>>.border-a{
      border: 1px solid black !important;
    }
    @media print {
      .print-only { }
      .screen-only {display: none;}
      .size-pic{
        margin-top: 4px;
        width: 100%
      }
      .font-size-print{
          font-size: 0.7rem !important;
        }
      .pages {
        size: 10cm 10cm;
        width: 10cm;
        height: 10cm;
        margin: 0;
        margin-top: 4px !important;
        margin-left: 4px  !important;
        margin-right: 4px  !important;
        padding-left: 4px !important;
      }
    }
    @media screen {
        .print-only {
          display: none;
        }
        .screen-only {}
        .size-pic{
          width: 100%
        }
        .pages{
        width: 10cm;
        height: auto;
        margin: 0;
        margin-top: 4px !important;
        margin-left: 4px  !important;
        margin-right: 4px  !important;
        padding-left: 4px !important;
      }
    }
</style>
