<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title style="height: 60px">
        <h4>Invoice</h4>
        <v-icon>mdi-chevron-right</v-icon
        ><span class="font-weight-regular body-1">{{use_language.bill}}</span>
        <v-spacer />
      </v-card-title>
    </v-card>
    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-bottom-30">
      <v-card-title>
        <v-icon color="primary">mdi-file-search-outline</v-icon>
        <span class="ml-2">{{use_language.search}}</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div>
          <!-- วันที่ -->
          <v-row row>
            <v-col class="pb-0" cols="12" md="2">
              <h3 class="fn-14">{{use_language.date}} :</h3></v-col
            >
            <v-col class="pb-0" cols="12" md="2">
              <v-menu
                ref="search_menu_date_from"
                v-model="search_menu_date_from"
                :close-on-content-click="false"
                :return-value.sync="search_date_from"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="computed_search_date_from"
                    @change="(value) => (computed_search_date_from = value)"
                    autocomplete="off"
                    label="วันที่เริ่มต้น"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    dense
                    v-on="on"
                  >
                   <template v-slot:label> <span>{{use_language.start_date}}</span> </template> 
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="search_date_from"
                  @input="
                    (search_menu_date_from = false),
                      $refs.search_menu_date_from.save(search_date_from)
                  "
                  no-title
                  :allowed-dates="allowedDates"
                  class="my-0"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pb-0" cols="12" md="2">
              <v-menu
                ref="search_menu_date_to"
                v-model="search_menu_date_to"
                :close-on-content-click="false"
                :return-value.sync="search_date_to"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="computed_search_date_to"
                    @change="(value) => (computed_search_date_to = value)"
                    autocomplete="off"
                    label="วันที่สิ้นสุด"
                    outlined
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    dense
                    v-on="on"
                  >
                   <template v-slot:label> <span>{{use_language.end_date}}</span> </template> 
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="search_date_to"
                  @input="
                    (search_menu_date_to = false),
                      $refs.search_menu_date_to.save(search_date_to)
                  "
                  no-title
                  :allowed-dates="allowedDates"
                  class="my-0"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- เลข invoice -->
          <v-row row>
            <v-col class="pb-0" cols="12" md="2">
              <h3 class="fn-14">{{use_language.number_invoice}}:</h3></v-col
            >
            <v-col class="pb-0" cols="12" md="4">
              <v-text-field
                v-model="search.invoiceno"
                @change="(value) => (search.invoiceno = value)"
                outlined
                dense
                hide-details
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row row>
            <v-col class="pb-0" cols="12" md="2">
              <h3 class="fn-14">{{use_language.number_order}} :</h3></v-col
            >
            <v-col class="pb-0" cols="12" md="4">
              <v-text-field
                v-model="search.orderid"
                @change="(value) => (search.orderid = value)"
                outlined
                dense
                hide-details
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <!-- ชื่อลูกค้า -->
          <v-row row>
            <v-col class="pb-0" cols="12" md="2">
              <h3 class="fn-14">{{use_language.name}} :</h3></v-col
            >
            <v-col class="pb-0" cols="12" md="4">
              <v-text-field
                v-model="search.namecustomer"
                outlined
                dense
                hide-details
                autocomplete="off"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0" cols="12" md="2"></v-col>
            <v-col class="pb-0" cols="12" md="4">
              <v-btn dark color="primary" @click="loaddata()" class="mr-3"
                >{{use_language.search}}</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <!-- Data -->
    <v-row>
      <v-col cols="12" align="left">
        <v-btn
          size="1"
          color="primary"
          dark
          :disabled="!authorize" 
          @click="inv_pre_print()"
          class="mr-1"
          >{{use_language.print_documents}}</v-btn
        >
      </v-col>
    </v-row>

    <v-card class="v-card-bottom-30">
      <v-card-title>
        <v-icon color="primary">mdi-file-outline</v-icon>
        <span class="ml-2">Invoice</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="datatable"
          item-key="invoiceNo"
          v-model="selected"
          :single-select="singleSelect"
          show-select
          class="elevation-0 packhai-border-table"
          hide-default-footer
          :items-per-page="10000000"
        >
          <!-- i.Discount == true ? true : flase -->
          <template v-slot:item.no="{ item }">
            {{datatable.indexOf(item) + 1}}
          </template>
          <template v-slot:item.invoiceno="{ item }">
            {{item.invoiceNo}}
          </template>
          <template v-slot:item.orderid="{ item }">
            {{format_order_id(item.orderMasterId)}}
          </template>
          <template v-slot:item.name="{ item }">{{ item.shopName }}</template>
          <template v-slot:item.address="{ item }">
            {{
              (item.address == null ? "" : item.address) +
              " " +
              (item.tumbon == null ? "" : item.tumbon) +
              " " +
              (item.amphoe == null ? "" : item.amphoe) +
              " " +
              (item.province == null ? "" : item.province) +
              " " +
              (item.postcode == null ? "" : item.postcode)
            }}
          </template>
          <template v-slot:item.date="{ item }">{{ formatDatetime(item.created) }}</template>
          <template v-slot:item.edit="{ item }" >
            <v-btn :disabled="!authorize"  icon @click="EditInvoiceNo(item)">
              <v-icon size="18" color="light-blue accent-3">fa-edit</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.delete="{ item }" >
            <v-btn  :disabled="!authorize"  icon @click="DeleteInvoice(item)">
              <v-icon size="18" color="danger">fa-trash-alt</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.print="{ item }">
            <v-btn :disabled="!authorize"  icon @click="print(item)" v-if="item.invoiceNo != null">
              <v-icon color="success">mdi-printer</v-icon></v-btn
            >
            <v-btn icon disabled v-else> <v-icon>mdi-printer</v-icon></v-btn>
          </template>
        </v-data-table>
        <v-row class="packhai-card-padding">
          <v-col cols="12" class="pa-3">
            <div class="text-center" @click="changePage()">
              <v-pagination
                v-model="page"
                :length="pageLength"
                :total-visible="pageTotalVisible"
                color="error"
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="inv_dialog_printinvoice"
      scrollable
      persistent
      max-width="45%"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span> {{use_language.print_document}} </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="inv_dialog_printinvoice = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >
        <v-card-text class="py-1">
          <v-row row align="center">
            <v-col class="py-0" cols="12" md="3">
              <h4>{{use_language.choose_document_size}} :</h4>
            </v-col>
            <v-col class="pb-0" cols="12" md="9">
              <v-radio-group v-model="sizepage" row>
                <v-radio label="ขนาด a4" value="a4"></v-radio>
                <v-radio label="ขนาด 80 mm" value="small"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green " dark @click="printinvoice()">
            {{use_language.print_document}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="DialogEditInvoiceNo"
      scrollable
      persistent
      max-width="45%"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span> {{use_language.edit_number_invoice}} </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="DialogEditInvoiceNo = false">
            <v-icon>mdi-close</v-icon>
          </v-btn></v-card-title
        >
        <v-card-text class="py-1">
          <v-row row align="center">
            <v-col class="py-0" cols="12" md="3">
              <h4>{{use_language.number_invoice_}} :</h4>
            </v-col>
            <v-col class="pb-0" cols="12" md="9">
              <v-text-field
                :value="EditInvoice.InvoiceNo"
                @change="value => EditInvoice.InvoiceNo = value"
                autocomplete="off"
                ref="refInvoiceNo"
                outlined
                hide-details
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green " dark @click="confirmeditinvoice()">
            {{use_language.save}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Loading from '@/website/components/Loading'
import axios from "axios";
import { reportService_dotnet } from "@/website/global";
import { format_order_id,formatDatetime,get_languages,checkAuthorize } from "@/website/global_function";
import { token_api, header_token } from '@/website/backend/token'
import { invoice_language } from "@/website/language/invoice_language";

export default {
  components: {
    Loading
  },
  data: () => ({
    page_loading: true,
    loading: false,
    use_language: null,
    set_language: null,
    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 50,
    offsets: 0,

    offsetTop: 0,

    search_menu_date_from: false,
    search_menu_date_to: false,
    search_date_from: null,
    search_date_to: null,
    ShopID: localStorage.getItem("shop_id"),
    search: {
      namecustomer: "",
      invoiceno: "",
      orderid: "",
    },
    headers: [
      { text: "ลำดับ", align: "center", value: "no", sortable: false, width: '2%' },
      {text: "เลขที่เอกสาร", align: "center", value: "invoiceno", sortable: false, width: '10%' },
      { text: "เลขที่ออเดอร์", align: "center", value: "orderid", sortable: false, width: '10%' },
      { text: "ชื่อ", align: "center", value: "name", sortable: false, width: '30%' },
      { text: "ที่อยู่", align: "center", value: "address", sortable: false, width: '40%' },
      { text: "วันที่จัดเก็บ", align: "center", value: "date", sortable: false, width: '20%' },
      { text: "แก้ไข", align: "center", value: "edit", sortable: false, width: '5%' },
      { text: "ลบ", align: "center", value: "delete", sortable: false, width: '5%' },
      { text: "ปริ้น", align: "center", value: "print", sortable: false, width: '5%' },
    ],
    datatable: [],
    invoice_print: {
      ShopID: localStorage.getItem("shop_id"),
      printorder: [],
    },
    // invoice_print:[],
    inv_dateprint: null,
    inv_dialog_printinvoice: false,
    sizepage: "a4",
    singleSelect: false,
    selected: [],
    authorize:false,

    DialogEditInvoiceNo:false,
    EditInvoice:{"InvoiceId":null, "InvoiceNo":null}
  }),
  async created() {
    this.loaddata();

     //เก็บภาษามาไว้ใน use_language
         this.use_language = await this.get_languages(localStorage.getItem("set_language"));

    //เช็คสิทธิ์แก้ไขหรือดูได้อย่างเดียว
         this.get_authorize_staffshop();


    this.page_loading = false
  },
  computed: {
    computed_search_date_from() {
      return this.formatDates(this.search_date_from);
    },
    computed_search_date_to() {
      return this.formatDates(this.search_date_to);
    },
  },
  methods: {
    get_languages,
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    formatDate(date) {
      if (date.length == 0) return null;

      if (date.length == 1) {
        const [year, month, day] = date[0].split("-");
        return `${day}-${month}-${year}`;
      } else {
        const [year_from, month_from, day_from] = date[0].split("-");
        const [year_to, month_to, day_to] = date[1].split("-");
        if (date[0] < date[1]) {
          return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`;
        } else {
          return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`;
        }
      }
    },
    formatDates(date) {
      if (date == null) {
        return null;
      } else {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      }
    },
    SetSearch(search) {
      localStorage.setItem("search", JSON.stringify(search));
    },
    async loaddata() {
      this.loading = true;
      var Data = {
        ShopID: this.ShopID,
        search: this.search,
        limits: this.limits,
        offsets: this.offsets,
        search_date_from: this.search_date_from,
        search_date_to: this.search_date_to,
      };
      axios.all([
        axios.post(reportService_dotnet+'ShopInvoiceOrder/get-invoice-shop-list', {
          'ShopId': this.ShopID,
          'Name': this.search.namecustomer,
          'InvoiceNo': this.search.invoiceno,
          'OrderId': this.search.orderid,
          'DateFrom': this.search_date_from,
          'DateTo': this.search_date_to,
          'Skip': this.offsets,
          'Take': this.limits,
        },{ headers: header_token}),
      ])
      .then(axios.spread((Customer) => {
          if (Customer.status == 200) {
            this.datatable = Customer.data.shopInvoices
            this.search_date_from = Customer.data.dateFrom
            this.search_date_to = Customer.data.dateTo
            this.CountPage = Customer.data.countShopInvoice
            this.pageLength =  (Math.ceil(this.CountPage/this.limits))
            if (this.pageLength<=0){this.pageLength=1}
              this.pageTemp = this.page
            this.selected = []
          }
      }));
      this.loading = false;
    },
    async get_authorize_staffshop () {
      this.authorize =  this.checkAuthorize(25);
          // if(localStorage.getItem("330") == 2 )
          // {
          //  this.authorize = true
          // }
        },
    async changePage() {
      var pages = this.page;
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        this.offsets = this.page * this.limits - this.limits;
        await this.loaddata();
        this.page = pages;
        this.pageTemp = this.page;
      }
    },
    inv_pre_print() {
      if (this.selected.length > 0) {
        for (var i = 0; i < this.selected.length; i++) {
          this.invoice_print.printorder.push({
            titleprint_main: { text: this.selected[i]["header1"] },
            titleprint_secondary: { text: this.selected[i]["header2"] },
            invorder: [
              {
                OrderId: this.selected[i]["orderMasterId"],
                InvoiceNo: this.selected[i]["invoiceNo"],
              },
            ],
            date: this.formatDates(this.selected[i]["invoiceDate"]),
          });
        }
        this.inv_dialog_printinvoice = true;
      }
    },
    print(item) {
      if (this.selected.length == 0) {
        this.invoice_print.printorder.push({
          titleprint_main: { text: item.header1 },
          titleprint_secondary: { text: item.header2 },
          invorder: [
            { OrderId: item.orderMasterId, InvoiceNo: item.invoiceNo },
          ],
          date: item.invoiceDate,
        });
      }
      this.inv_dialog_printinvoice = true;
    },
    printinvoice() {
      localStorage.setItem("INVPrint", JSON.stringify(this.invoice_print));
      localStorage.setItem("Sizepage", this.sizepage);
      window.open("/print-tax-invoice");
      this.selected = [];
      this.invoice_print =  {
                                    ShopID: localStorage.getItem("shop_id"),
                                    printorder: [],
                                  },
      this.inv_dialog_printinvoice = false;
    },
    EditInvoiceNo(inv){
      this.EditInvoice.InvoiceId = inv.id;
      this.EditInvoice.InvoiceNo = inv.invoiceNo;
      this.DialogEditInvoiceNo = true;
    },
    DeleteInvoice(inv){
      this.$swal({
        position: "center",
        type: 'warning',
        title: 'ลบ ใช่หรือไม่ !!',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async(result) => {
          if (result.value) {
            axios.all([
                axios.post(reportService_dotnet+'ShopInvoiceOrder/delete-invoice', {
                    'Id' : inv.id,
                    'InvoiceNo': inv.invoiceNo
                },{ headers: header_token}),
            ])
            .then(axios.spread((invoice) => {
                if (invoice.status == 200) {
                    this.loaddata();
                    this.$swal({
                        type: 'success',
                        title: invoice.data,
                    })
                } else {
                    this.$swal({
                        type: 'warning',
                        title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                    })
                }
            }));
          }
      })
    },
    confirmeditinvoice(){
      axios.all([
        axios.post(reportService_dotnet+'ShopInvoiceOrder/edit-invoiceno', {
            'Id':this.EditInvoice.InvoiceId,
            'InvoiceNo':this.EditInvoice.InvoiceNo
        },{ headers: header_token}),
      ])
      .then(axios.spread((invoice) => {
        if (invoice.status == 200) {
            this.loaddata();
            this.DialogEditInvoiceNo = false;
            this.$swal({
                type: 'success',
                title: invoice.data,
            })
        } else {
            this.$swal({
                type: 'warning',
                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
            })
        }
      }));
    },
    format_order_id,
    formatDatetime,
    checkAuthorize,
  },
};
</script>
